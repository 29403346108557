import React, { createContext, useState } from 'react';

export const EmailContext = createContext();

export const EmailProvider = ({ children }) => {
    const [emails, setEmails] = useState([]);

    const addEmails = (newEmails) => {
        setEmails(prevEmails => [...prevEmails, ...newEmails]);
    };

    return (
        <EmailContext.Provider value={{ emails, addEmails }}>
            {children}
        </EmailContext.Provider>
    );
};
