import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { EmailLink } from '../Component/Icons'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Theme from '../Theme/Theme'
import EmailContentEditor from './EmailContentEditor/EmailContentEditor'
import { NotificationEmailContext } from '../Context/NotificationEmailContext';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';
import InitialValue from './EmailContentEditor/InitialValue';
import { Hourglass } from 'react-loader-spinner';
import JoditEditor from 'jodit-react';
import { AutoResponderContext } from '../Context/AutoResponderContext';




const CustomizeSaveEmail = ({ page, name }) => {


    const [show, setShow] = useState(false)
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [subject, setSubject] = useState('')


    const editor = useRef(null);


    const { setNotificationEmailPayload } = useContext(NotificationEmailContext);

    const { setAutoResponderPayload } = useContext(AutoResponderContext);


    const config = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/docs/,
            // placeholder: placeholder || 'Start typings...'
        }),
        []
    );


    const [formData, setFormData] = useState([
        { id: 1, name: 'Address', title: 'آدرس ایمیل', placeholder: 'john@smith.com', value: '' },
        { id: 2, name: 'Host', title: 'نام Host', placeholder: 'smtp.smith.com', value: '' },
        { id: 3, name: 'Port', title: 'Port', placeholder: '25', value: '' },
        { id: 4, name: 'UserName', title: 'نام کاربری', placeholder: 'john', value: '' },
        { id: 5, name: 'Password', title: 'رمز عبور', placeholder: '#supersecretpassword', value: '' },
    ]);



    const handleAdvanceEmailChange = (id, event) => {
        const updatedFormData = formData.map(item => {
            if (item.id === id) {
                return { ...item, value: event.target.value };
            }
            return item;
        });
        setFormData(updatedFormData);
    };





    const handleShowDropDown = () => {
        setShow(!show)
    }

    const handleSubjectChange = (event) => {
        setSubject(event.target.value)
    }



    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };


    const isSm = useMediaQuery(Theme.breakpoints.up('sm'));


    let variant;
    let subVariant;
    if (isSm) {
        variant = 'h5';
        subVariant = 'h6'
    } else {
        variant = 'h6'
        subVariant = 'subtitle1'
    }




    // const handleSelectChange = (name, value) => {
    //     // Check if a select with the given name already exists in the array
    //     const existingIndex = selectValues.findIndex((select) => select.name === name);

    //     if (existingIndex !== -1) {
    //         // If a select with the given name exists, update its value
    //         const updatedSelects = [...selectValues];
    //         updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value };
    //         setSelectValues(updatedSelects);
    //     } else {
    //         // If a select with the given name doesn't exist, add it to the array
    //         setSelectValues((prevSelectValues) => [...prevSelectValues, { name, value }]);
    //     }
    // };


    const handleSubmit = () => {

        if (editor.current) {

            const content = editor.current.value;

            const uniqueId = uuidv4();

            const payload = [
                {
                    name: name,
                    subject: subject,
                    id: uniqueId,
                    content: content
                }
            ];
            
            if (name === 'NotificationEmail') {
                setNotificationEmailPayload(payload)
            } else if (name === 'AutoResponderEmail') {
                setAutoResponderPayload(payload)
            }

        }
    };


    useEffect(() => {

        if (editor.current) {

            const content = editor.current.value;

            const uniqueId = uuidv4();

            const payload = [
                {
                    name: name,
                    id: uniqueId,
                    content: content
                }
            ];

            if (name === 'NotificationEmail') {
                setNotificationEmailPayload(payload)
            } else if (name === 'AutoResponderEmail') {
                setAutoResponderPayload(payload)
            }

        }

    }, [editor.current])


    useEffect(() => {
        // Convert the InitialValue component to an HTML string
        const initialContent = ReactDOMServer.renderToStaticMarkup(<InitialValue page={page} />);
        setContent(initialContent);

    }, [page]);


    useEffect(() => {
        if (content) {
            setLoading(false);
        }
    }, [content]);


    return (
        <>
            <Grid className='main' bgcolor={'white'} mb={'14px'} p={{ xs: '21px', sm: '21px 42px' }}>
                <Box sx={{ width: '100%' }}>
                    <Grid className='subject' mb={'22px'}>
                        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'8px'}>
                            <Grid display={'flex'} alignItems={'center'} >
                                <Typography variant={variant} fontWeight={700} color={'#23283a'}>موضوع نامه الکترونیکی</Typography>
                                <Typography variant={variant} fontWeight={700} color={'#d53049'}>*</Typography>
                            </Grid>
                            <Grid position={'relative'}>
                                <Button onClick={handleShowDropDown} sx={{ padding: '7px', paddingLeft: '21px', display: 'flex', alignItems: 'center', gap: '4px', '&:hover': { backgroundColor: '#f0f2fd' } }} >
                                    <Grid width={'16px'} height={'16px'} borderRadius={'50%'} bgcolor={'#5e74e6'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        <EmailLink />
                                    </Grid>
                                    <Typography color={'#5e74e6'} variant={subVariant} fontWeight={500} whiteSpace={'nowrap'}>فیلدهای فرم</Typography>
                                    <KeyboardArrowDownIcon fontSize='small' style={{ color: '#5e74e6' }} />
                                </Button>
                                <Grid display={show ? 'block' : 'none'} p={'8px 4px 16px 4px'} borderRadius={'4px'} bgcolor={'#F3F3FE'} position={'absolute'} width={'100%'} left={0} zIndex={100} top={'30px'}>
                                    <Grid width={'100%'} bgcolor={'#f2f3f8'} p={'8px'} mb={'10px'}>
                                        <Typography variant='h6' color={'#6a6e8f'} fontWeight={500}>فرم</Typography>
                                    </Grid>
                                    <Button onClick={handleShowDropDown} sx={{ width: '100%', backgroundColor: 'transparent', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', padding: '7px', '&:hover': { backgroundColor: 'white' } }}>
                                        <Typography variant='h6' color={'#23283a'} fontWeight={500}>عنوان فرم</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className='input'>
                            <input
                                value={subject}
                                onChange={handleSubjectChange}
                                style={{
                                    lineHeight: '32px',
                                    padding: '2px 9px',
                                    width: '100%',
                                    fontSize: '12px',
                                    border: '1px solid #d8dae9',
                                    borderRadius: '4px',
                                    whiteSpace: 'break-spaces',
                                    outline: 0
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid className='content'>
                        <Grid display={'flex'} alignItems={'center'} mb={'8px'}>
                            <Typography variant={variant} fontWeight={700} color={'#23283a'}>محتوای ایمیل</Typography>
                            <Typography variant={variant} fontWeight={700} color={'#d53049'}>*</Typography>
                        </Grid>
                        <Grid>
                            {loading && (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
                                    <Hourglass
                                        visible={true}
                                        height="80"
                                        width="80"
                                        ariaLabel="hourglass-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        colors={['#306cce', '#72a1ed']}
                                    />
                                </Box>
                            )}
                            <JoditEditor
                                ref={editor}
                                value={content}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                onChange={(newContent) => { }}
                                onInit={() => setLoading(false)} // Call the function when the editor is initialized
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={"8px"}>
                <Grid style={{ cursor: "pointer" }} className="save" p={"12px 16px"} bgcolor={"rgba(100,178,0,1)"} fontSize={"14px"} color={"white"} borderRadius={"4px"} onClick={handleSubmit}>ذخیره</Grid>
                <Grid style={{ cursor: "pointer" }} className="text" p={"12px 16px"} bgcolor={"rgba(218,222,243,1)"} fontSize={"14px"} color={"rgba(52,60,106,1)"} borderRadius={"4px"}>تست ایمیل</Grid>
            </Grid>

        </>
    )
}

export default CustomizeSaveEmail