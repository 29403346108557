import { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { motion } from 'framer-motion';

const DraggableComponent = ({ id, index, moveComponent, children }) => {
    const [isDragging, setIsDragging] = useState(false);

    const [, drag] = useDrag({
        type: "ITEM",
        item: () => ({ id, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => setIsDragging(false),
    });

    const [, drop] = useDrop({
        accept: "ITEM",
        hover: (item) => {
            if (item.index !== index) {
                moveComponent(item.index, index);
                item.index = index;
            }
        },
    });

    return (
        <motion.div
            ref={(node) => drag(drop(node))}
            style={{ marginBottom: "8px" }}
            animate={{ scale: isDragging ? 1.1 : 1 }} // Scale up while dragging
            transition={{ duration: 0.2 }} // Animation duration
            // whileHover={{ scale: 1.1 }} // Scale up while hovering
            layout // Enable layout animation
        >
            {children}
        </motion.div>
    );
};

export default DraggableComponent;
