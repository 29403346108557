import { Grid, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import Switch from 'react-switch'
import { LinkSetting } from '../Icons';
import DrogAndDropFile from '../DrogAndDropFile';
import AdvanceTab from '../AdvanceTab';
import FormSettingSelect from '../FormSettingSelect';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { EmailContext } from '../../Context/EmailContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import axios from 'axios';
import './advance.css'
import { FileUploadContext } from '../../Context/FileUploadContext';
import Theme from '../../Theme/Theme';
import './advance.css'



const Advance = ({ flag }) => {

    //page direction
    const location = useLocation();
    const currentPage = location.pathname;

    //context
    const { addEmails } = useContext(EmailContext);
    const { uploadedFile } = useContext(FileUploadContext);


    const sendEmail = [
        { value: 'بلافاصله', label: 'بلافاصله' },
        { value: '1 روز', label: '1 روز' },
        { value: '2 روز', label: '2 روز' },
        { value: '3 روز', label: '3 روز' },
        { value: '7 روز', label: '7 روز' },
        { value: '14 روز', label: '14 روز' },
        { value: '30 روز', label: '30 روز' },
        { value: 'در تاریخ بعد', label: 'در تاریخ بعد' },
    ]



    const [checked, setChecked] = useState(false);
    const [send, setSend] = useState(true)
    const [edit, setEdit] = useState(false)
    const [defaultDocument, setDefaultDocument] = useState(false)
    const [pdf, setPdf] = useState(false);
    const [hide, setHide] = useState(true);
    const [update, setUpdate] = useState(true);
    const [attachments, setAttachments] = useState(false)
    const [pdfPassword, setPdfPassword] = useState('')
    const [received, setReceived] = useState(true)

    const [selectValues, setSelectValues] = useState([
        { id: 1, name: 'SendEmailLater', value: sendEmail[0].value }
    ])



    const initialAdvanceTabData = [
        { id: uuidv4(), name: 'sendCheckBox', value: send },
        { id: uuidv4(), name: 'editCheckBox', value: edit },
        { id: uuidv4(), name: 'SendUploadedFilesAsAttachments', value: attachments },
        { id: uuidv4(), name: 'AttachPDF', value: checked },
        { id: uuidv4(), name: 'defaultDocument', value: defaultDocument },
        { id: uuidv4(), name: 'PDFPasswordCheckBox', value: pdf },
        { id: uuidv4(), name: 'pdfPasswordContent', value: pdfPassword },
        { id: uuidv4(), name: 'HideEmptyFields', value: hide },
        { id: uuidv4(), name: 'UpdateEmail', value: update },
    ];

    const initialAdvanceTabDataResponder = [
        { id: uuidv4(), name: 'sendCheckBoxResponder', value: send },
        { id: uuidv4(), name: 'editCheckBox', value: edit },
        { id: uuidv4(), name: 'AttachPDF', value: checked },
        { id: uuidv4(), name: 'defaultDocument', value: defaultDocument },
        { id: uuidv4(), name: 'PDFPasswordCheckBox', value: pdf },
        { id: uuidv4(), name: 'pdfPasswordContent', value: pdfPassword },
        { id: uuidv4(), name: 'FileUpload', value: uploadedFile },
        { id: uuidv4(), name: 'SendEmailLater', value: sendEmail[0].value },
        { id: uuidv4(), name: 'HideEmptyFields', value: hide },
        { id: uuidv4(), name: 'UpdateEmail', value: update },
    ];


    const [advanceTabData, setAdvanceTabData] = useState(initialAdvanceTabData);
    const [advanceTabDataResponder, setAdvanceTabDataResponder] = useState(initialAdvanceTabDataResponder);




    //mui 


    const isSm = useMediaQuery(Theme.breakpoints.up('sm'));


    let variant;
    if (isSm) {
        variant = 'h5';
    }else{
        variant = 'h6'
    }

    const CustomToast = ({ message, state }) => {
        return (
            <Grid display="flex" alignItems="start" justifyContent="end" gap="5px" style={{
                backgroundColor: 'white',
                color: state === 'success' ? 'green' : 'red',
                fontSize: '16px',
                padding: '15px 10px',
                fontWeight: 900,
            }}>
                {state === 'success' ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
                {message}
            </Grid>
        );
    };



    const handleSelectChange = (name, value) => {
        // Check if a select with the given name already exists in the array
        const existingIndex = selectValues.findIndex((select) => select.name === name);

        if (existingIndex !== -1) {
            // If a select with the given name exists, update its value
            const updatedSelects = [...selectValues];
            updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value };
            setSelectValues(updatedSelects);
        } else {
            // If a select with the given name doesn't exist, add it to the array
            setSelectValues((prevSelectValues) => [...prevSelectValues, { name, value }]);
        }
    };


    const handleChangeSend = () => {
        setSend(!send);
    };


    const handleChangeEdit = () => {
        setEdit(!edit);
    };


    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const handleChangePdf = nextChecked => {
        setPdf(nextChecked);
    };


    const handleChangeHide = () => {
        setHide(!hide);
    };

    const handleChangeUpdate = () => {
        setUpdate(!update);
    };

    const handleChangeAttachments = nextChecked => {
        setAttachments(nextChecked);
    };

    const handleChangePassword = (e) => {
        setPdfPassword(e.target.value);
    }



    const handleAddEmail = (state) => {
        addEmails(state)
    }


    const handleChangeDefualt = () => {
        setDefaultDocument(!defaultDocument)
    }


    const updateAdvanceTabData = () => {
        if (currentPage === '/Setting/EmailSetting/NotificationEmail/Advance') {
            setAdvanceTabData(prevState => prevState.map(item => {
                switch (item.name) {
                    case 'sendCheckBox':
                        return { ...item, value: send };
                    case 'editCheckBox':
                        return { ...item, value: edit };
                    case 'SendUploadedFilesAsAttachments':
                        return { ...item, value: attachments };
                    case 'AttachPDF':
                        return { ...item, value: checked };
                    case 'defaultDocument':
                        return { ...item, value: defaultDocument };
                    case 'PDFPasswordCheckBox':
                        return { ...item, value: pdf };
                    case 'pdfPasswordContent':
                        return { ...item, value: pdfPassword };
                    case 'HideEmptyFields':
                        return { ...item, value: hide };
                    case 'UpdateEmail':
                        return { ...item, value: update };
                    default:
                        return item;
                }
            }));
        } else {
            setAdvanceTabDataResponder(prevState => prevState.map(item => {
                switch (item.name) {
                    case 'sendCheckBoxResponder':
                        return { ...item, value: send };
                    case 'editCheckBox':
                        return { ...item, value: edit };
                    case 'AttachPDF':
                        return { ...item, value: checked };
                    case 'defaultDocument':
                        return { ...item, value: defaultDocument };
                    case 'PDFPasswordCheckBox':
                        return { ...item, value: pdf };
                    case 'pdfPasswordContent':
                        return { ...item, value: pdfPassword };
                    case 'HideEmptyFields':
                        return { ...item, value: hide };
                    case 'UpdateEmail':
                        return { ...item, value: update };
                    case 'SendEmailLater':
                        const sendEmailLater = selectValues.find(select => select.name === 'SendEmailLater');
                        return sendEmailLater ? { ...item, value: sendEmailLater.value } : item;
                    default:
                        return item;
                }
            }));
        }
    };


    useEffect(() => {
        if (uploadedFile) {
            setAdvanceTabDataResponder(prevState =>
                prevState.map(item =>
                    item.name === 'FileUpload' ? { ...item, value: uploadedFile } : item
                )
            );
        }
    }, [uploadedFile]);



    useEffect(() => {
        updateAdvanceTabData();
    }, [send, edit, attachments, checked, defaultDocument, pdf, pdfPassword, hide, update, selectValues]);

    useEffect(() => {
        if (currentPage === '/Setting/EmailSetting/NotificationEmail/Advance') {
            handleAddEmail(advanceTabData);
        } else {
            handleAddEmail(advanceTabDataResponder);
        }
    }, [advanceTabData, advanceTabDataResponder, currentPage]);




    return (
        <React.Fragment>
            <Grid mb={'24px'} >
                <Grid bgcolor={'white'} p={{ xs: "21px", sm: '21px 42px' }} borderBottom={'1px solid #eaebf2'}>
                    <Typography variant={variant} fontWeight={600} color={'#23283a'}>{'ارسال ایمیل زمانی که'}</Typography>
                    <Typography variant={variant} fontWeight={500} color={'#23283a'} lineHeight={'24px'} mb={'12px'}>{'کاربران بتوانند هر بار که فرم ارسالی ویرایش می‌شود، یک ایمیل جدید دریافت کنند.'}</Typography>
                    <Grid>
                        <Grid mb={'20px'} className="checkbox-wrapper">
                            <label style={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                                <input id={''} className={send ? "checked" : ""} type="checkbox" checked={send} onChange={handleChangeSend} />
                                <span style={{ paddingTop: '5px', fontSize:isSm?'16px':'14px' }}>{'هنگامی که یک ارسال دریافت می شود'}</span>
                            </label>
                        </Grid>
                        <Grid className="checkbox-wrapper">
                            <label style={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                                <input id={''} className={edit ? "checked" : ""} type="checkbox" checked={edit} onChange={handleChangeEdit} />
                                <span style={{ paddingTop: '5px' , fontSize:isSm?'16px':'14px' }}>{'هنگامی که یک ارسال دریافت می شود'}</span>
                            </label>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid bgcolor={'white'} p={{ xs: "21px", sm: '21px 42px' }} borderBottom={'1px solid #eaebf2'} display={flag ? 'block' : 'none'}>
                    <Grid display={'flex'} alignItems={'center'} justifyContent={"space-between"} mb={attachments ? '36px' : '0px'}>
                        <Grid>
                            <Typography variant={variant} fontWeight={600} color={'#23283a'}>{'ارسال فایل های آپلود شده به عنوان پیوست'}</Typography>
                            <Typography variant={variant} fontWeight={500} color={'#23283a'} lineHeight={'24px'} mb={'12px'}>{'فایل های آپلود شده از طریق فرم خود را به این ایمیل ضمیمه کنید'}</Typography>
                        </Grid>
                        <Grid>
                            <label htmlFor="small-radius-switch">
                                <Switch
                                    checked={attachments}
                                    onChange={handleChangeAttachments}
                                    handleDiameter={28}
                                    onColor={'#0099FF'}
                                    offColor="#ddd"
                                    height={40}
                                    width={70}
                                    borderRadius={6}
                                    checkedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "white",
                                                paddingRight: 2,
                                                paddingTop: '4px'

                                            }}
                                        >
                                            Yes
                                        </div>
                                    }
                                    uncheckedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "white",
                                                paddingRight: 2,
                                                paddingTop: '4px'

                                            }}
                                        >
                                            No
                                        </div>
                                    }
                                    uncheckedHandleIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                backgroundColor: '#fff',
                                                borderRadius: '4px'

                                            }}
                                        >
                                            <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                        </div>
                                    }
                                    checkedHandleIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                backgroundColor: '#fff',
                                                borderRadius: '4px'
                                            }}
                                        >
                                            <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                        </div>
                                    }
                                    className="react-switch"
                                    id="small-radius-switch"
                                />
                            </label>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid bgcolor={'white'} p={{ xs: "21px", sm: '21px 42px' }} borderBottom={'1px solid #eaebf2'}>
                    <Grid display={'flex'} alignItems={'center'} justifyContent={"space-between"} mb={checked ? '36px' : '0px'}>
                        <Grid>
                            <Typography variant={variant} fontWeight={600} color={'#23283a'}>{'PDF را ضمیمه کنید'}</Typography>
                            <Typography variant={variant} fontWeight={500} color={'#23283a'} lineHeight={'24px'} mb={'12px'}>{'فایل های PDF را برای پیوست به این ایمیل انتخاب کنید'}</Typography>
                        </Grid>
                        <Grid>
                            <label htmlFor="small-radius-switch">
                                <Switch
                                    checked={checked}
                                    onChange={handleChange}
                                    handleDiameter={28}
                                    onColor={'#0099FF'}
                                    offColor="#ddd"
                                    height={40}
                                    width={70}
                                    borderRadius={6}
                                    checkedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "white",
                                                paddingRight: 2,
                                                paddingTop: '4px'

                                            }}
                                        >
                                            Yes
                                        </div>
                                    }
                                    uncheckedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "white",
                                                paddingRight: 2,
                                                paddingTop: '4px'

                                            }}
                                        >
                                            No
                                        </div>
                                    }
                                    uncheckedHandleIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                backgroundColor: '#fff',
                                                borderRadius: '4px'

                                            }}
                                        >
                                            <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                        </div>
                                    }
                                    checkedHandleIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                backgroundColor: '#fff',
                                                borderRadius: '4px'
                                            }}
                                        >
                                            <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                        </div>
                                    }
                                    className="react-switch"
                                    id="small-radius-switch"
                                />
                            </label>
                        </Grid>
                    </Grid>
                    <Grid mb={'22px'} border={'1px solid #d8dae9'} borderRadius={'4px'} width={'100%'} p={{xs:'8px' , sm:'12px'}} display={checked ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                        <Grid className="checkbox-wrapper">
                            <label style={{ display: 'flex', alignItems: 'center', gap: "8px" }}>
                                <input id={''} className={defaultDocument ? "checked" : ""} type="checkbox" checked={defaultDocument} onChange={handleChangeDefualt} />
                                <span style={{ paddingTop: '8px', fontSize:isSm?'16px':'14px' }}>{'هنگامی که یک ارسال دریافت می شود'}</span>
                            </label>
                        </Grid>
                        <Grid pt={'12px'}>
                            <LinkSetting />
                        </Grid>
                    </Grid>
                    <Grid display={checked ? 'flex' : 'none'} alignItems={'flex-start'} justifyContent={'space-between'}>
                        <Grid>
                            <Typography variant={variant} fontWeight={600} color={'#23283a'}>{'رمز عبور PDF را فعال کنید'}</Typography>
                            <Typography variant={variant} fontWeight={500} color={'#23283a'} lineHeight={'24px'} mb={'12px'}>{'PDF خود را با رمز عبور ایمن کنید.'}</Typography>
                        </Grid>
                        <Grid>
                            <label htmlFor="small-radius-switch">
                                <Switch
                                    checked={pdf}
                                    onChange={handleChangePdf}
                                    handleDiameter={28}
                                    onColor={'#0099FF'}
                                    offColor="#ddd"
                                    height={40}
                                    width={70}
                                    borderRadius={6}
                                    // activeBoxShadow="0px 0px 1px 2px #fffc35"
                                    checkedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "white",
                                                paddingRight: 2,
                                                paddingTop: '4px'

                                            }}
                                        >
                                            Yes
                                        </div>
                                    }
                                    uncheckedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "white",
                                                paddingRight: 2,
                                                paddingTop: '4px'

                                            }}
                                        >
                                            No
                                        </div>
                                    }
                                    uncheckedHandleIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                backgroundColor: '#fff',
                                                borderRadius: '4px'

                                            }}
                                        >
                                            <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                        </div>
                                    }
                                    checkedHandleIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                backgroundColor: '#fff',
                                                borderRadius: '4px'
                                            }}
                                        >
                                            <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                        </div>
                                    }
                                    className="react-switch"
                                    id="small-radius-switch"
                                />
                            </label>
                        </Grid>
                    </Grid>
                    <Grid display={pdf ? 'block' : 'none'}>
                        <input
                            type='password'
                            value={pdfPassword}
                            onChange={handleChangePassword}
                            name='pdf-password'
                            style={{
                                width: '100%',
                                height: '44px',
                                border: '1px solid #cfd8dc',
                                borderRadius: '4px',
                                backgroundColor: 'white',
                                padding: '0 12px',
                                fontSize: '18px',
                                color: '#23283a',
                                outline: 0
                            }}
                        />
                        <Typography variant='h6' lineHeight={'24px'} color={'rgb(51, 51, 51)'} fontWeight={500} mb={'16px'}>رمز عبور شما باید حداقل شامل باشد</Typography>
                        <Grid display={'flex'} alignItems={'flex-start'} gap={'8px'}>
                            <Grid width={'16px'} height={'16px'} borderRadius={'50%'} bgcolor={'rgb(195, 202, 216)'}></Grid>
                            <Typography variant='h6' color={'rgb(141, 143, 168)'}>8 کاراکتر</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid bgcolor={'white'} p={{ xs: "21px", sm: '21px 42px' }} borderBottom={'1px solid #eaebf2'} display={flag ? 'none' : 'block'}>
                    <Typography variant={variant} fontWeight={600} color={'#23283a'}>{'فایل پیوست کنید'}</Typography>
                    <Typography variant={variant} fontWeight={500} color={'#23283a'} lineHeight={'24px'} mb={'12px'}>{'فایلی را به این ایمیل پیوست کنید'}</Typography>
                    <Grid>
                        <DrogAndDropFile />
                    </Grid>
                </Grid>
                <Grid bgcolor={'white'} p={{ xs: "21px", sm: '21px 42px' }} borderBottom={'1px solid #eaebf2'} display={flag ? 'none' : 'block'}>
                    <Typography variant={variant} fontWeight={600} color={'#23283a'}>{"بعداً ایمیل بفرستید"}</Typography>
                    <Typography variant={variant} fontWeight={500} color={'#23283a'} lineHeight={'24px'} mb={'12px'}>{"انتخاب کنید که این ایمیل را فوراً پس از ارسال، ارسال کنید، یا در تاریخی بعدی"}</Typography>
                    <Grid>
                        <FormSettingSelect name={'SendEmailLater'} options={sendEmail} id={0} onSelectChange={handleSelectChange} />
                    </Grid>
                </Grid>
                <Grid bgcolor={'white'} p={{ xs: "21px", sm: '21px 42px' }} borderBottom={'1px solid #eaebf2'}>
                    <Grid display={'flex'} alignItems={'flex-start'} justifyContent={"space-between"}>
                        <Grid>
                            <Typography variant={variant} fontWeight={600} color={'#23283a'}>{'پنهان کردن فیلدهای خالی'}</Typography>
                            <Typography variant={variant} fontWeight={500} color={'#23283a'} lineHeight={'24px'} mb={'12px'}>{'فیلدهای بدون پاسخ را در ایمیل نشان ندهید'}</Typography>
                        </Grid>
                        <Grid>
                            <label htmlFor="small-radius-switch">
                                <Switch
                                    checked={hide}
                                    onChange={handleChangeHide}
                                    handleDiameter={28}
                                    onColor={'#0099FF'}
                                    offColor={"#ddd"}
                                    height={40}
                                    width={70}
                                    borderRadius={6}
                                    // activeBoxShadow="0px 0px 1px 2px #fffc35"
                                    checkedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "white",
                                                paddingRight: 2,
                                                paddingTop: '4px'

                                            }}
                                        >
                                            Yes
                                        </div>
                                    }
                                    uncheckedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "white",
                                                paddingRight: 2,
                                                paddingTop: '4px'

                                            }}
                                        >
                                            No
                                        </div>
                                    }
                                    uncheckedHandleIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                backgroundColor: '#fff',
                                                borderRadius: '4px'

                                            }}
                                        >
                                            <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                        </div>
                                    }
                                    checkedHandleIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                backgroundColor: '#fff',
                                                borderRadius: '4px'
                                            }}
                                        >
                                            <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                        </div>
                                    }
                                    className="react-switch"
                                    id="small-radius-switch"
                                />
                            </label>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid bgcolor={'white'} p={{ xs: "21px", sm: '21px 42px' }} borderBottom={'1px solid #eaebf2'}>
                    <Grid display={'flex'} alignItems={'flex-start'} justifyContent={"space-between"}>
                        <Grid>
                            <Typography variant={variant} fontWeight={600} color={'#23283a'}>{'ایمیل را به روز کنید'}</Typography>
                            <Typography variant={variant} fontWeight={500} color={'#23283a'} lineHeight={'24px'} mb={'12px'}>{'هنگامی که فرم آن ویرایش می شود، این ایمیل را به طور خودکار به روز کنید'}</Typography>
                        </Grid>
                        <Grid>
                            <label htmlFor="small-radius-switch">
                                <Switch
                                    checked={update}
                                    onChange={handleChangeUpdate}
                                    handleDiameter={28}
                                    onColor={'#0099FF'}
                                    offColor="#ddd"
                                    height={40}
                                    width={70}
                                    borderRadius={6}
                                    // activeBoxShadow="0px 0px 1px 2px #fffc35"
                                    checkedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "white",
                                                paddingRight: 2,
                                                paddingTop: '4px'

                                            }}
                                        >
                                            Yes
                                        </div>
                                    }
                                    uncheckedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "white",
                                                paddingRight: 2,
                                                paddingTop: '4px'

                                            }}
                                        >
                                            No
                                        </div>
                                    }
                                    uncheckedHandleIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                backgroundColor: '#fff',
                                                borderRadius: '4px'

                                            }}
                                        >
                                            <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                        </div>
                                    }
                                    checkedHandleIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 20,
                                                backgroundColor: '#fff',
                                                borderRadius: '4px'
                                            }}
                                        >
                                            <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                        </div>
                                    }
                                    className="react-switch"
                                    id="small-radius-switch"
                                />
                            </label>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid pb={'0px'}>
                    <AdvanceTab />
                </Grid>
            </Grid>
        </React.Fragment>

    )
}

export default Advance