import { Grid, Typography } from '@mui/material'
import React from 'react'
import FullName from './FullName'
import Email from './Email'
import ShortText from './ShortText'
import DatePickers from './DatePicker'
import DropDown from './DropDown'
import Address from './Address'
import Phone from './Phone'
import LongText from './LongText'

const CourseRegistrationForm = () => {


    const genderDropDownData = ['آقا', 'خانم', 'N/A']

    const courseDropDownData = [
        'Windows 8',
        'مقدمه ای بر لینوکس',
        'انگلیسی 101',
        'انگلیسی 102',
        'نوشتن خلاق 1',
        'نوشتن خلاق 2',
        'تاریخ 101',
        'تاریخ 102',
        'ریاضی 101',
        'ریاضی 102'
    ]



    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Typography variant='h2' fontWeight={700} color={'#000000'} mb={'12px'}>فرم ثبت نام</Typography>
            <Typography variant='h5' fontWeight={500} color={'#57647e'} mb={'40px'}>برای ثبت نام فرم را با دقت پر کنید</Typography>
            <Grid width={'100%'} height={'1px'} bgcolor={'#57647e'} mb={'40px'}></Grid>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام دانش آموز'} />
                <DatePickers header={'تاریخ تولد'} />
                <DropDown options={genderDropDownData} header={'جنسیت'} />
                <Address />
                <Email header={'ایمیل دانشجویی'} />
                <Phone header={'شماره موبایل'} />
                <Phone header={'شماره تلفن'} />
                <Phone header={'شماره کاری'} />
                <ShortText header={'شرکت'} />
                <DropDown options={courseDropDownData} header={'دوره ها'} />
                <LongText header={'نظرات اضافی'} />
            </Grid>
        </Grid>
    )
}

export default CourseRegistrationForm