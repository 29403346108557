import React, { useState, useRef, useEffect } from 'react'
import { Grid, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import { useDroppable } from '@dnd-kit/core';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableComponent from '../Component/DraggableComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import { Droppable } from '../Component/Droppable';





const Edit = () => {

    const [components, setComponents] = useState([]);
    const refs = useRef([]);
    const [draggedItem, setDraggedItem] = useState(null);
    const [deletedIndexes, setDeletedIndexes] = useState([]);
    const [hovered, setHovered] = useState(false);





    const navigate = useNavigate();


    useEffect(() => {
        setComponents(components.filter((item) => !deletedIndexes.includes(item.id)));
    }, [components, deletedIndexes]);

    useEffect(() => {
        refs.current = refs.current.slice(0, components.length);
    }, [components]);

    useEffect(() => {
        // Remove ID from deletedIndexes if it's back in drawerData
        const updatedDeletedIndexes = deletedIndexes.filter((deletedId) => components.some((item) => item.id === deletedId));
        setDeletedIndexes(updatedDeletedIndexes);
    }, [components]);

    const moveComponent = (dragIndex, hoverIndex) => {
        const draggedComponent = components[dragIndex];
        const updatedComponents = [...components];

        if (dragIndex < hoverIndex) {
            updatedComponents.splice(dragIndex, 1);
            updatedComponents.splice(hoverIndex, 0, draggedComponent);
        } else {
            updatedComponents.splice(hoverIndex, 0, draggedComponent);
            updatedComponents.splice(dragIndex + 1, 1);
        }

        setComponents(updatedComponents);
    };

    const deleteComponent = (id) => {
        const updatedComponents = components.filter(item => item.id !== id);
        setComponents(updatedComponents);
        setDeletedIndexes([...deletedIndexes, id]); // Store the ID of the deleted component
    };

    const showDeleteIcon = (index) => {
        const icon = refs.current[index]?.current?.querySelector('.delete-icon');
        if (icon) {
            icon.style.display = 'flex';
        }
    };

    const hideDeleteIcon = (index) => {
        const icon = refs.current[index]?.current?.querySelector('.delete-icon');
        if (icon) {
            icon.style.display = 'none';
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        refs.current.forEach((ref, index) => {
            if (ref.current && !ref.current.contains(event.target)) {
                console.log(index, 'index')
                hideDeleteIcon(index);
            } else {
                showDeleteIcon(index);
            }
        });
    };

    let theme = useTheme()
    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let Typo = isUpXxl ? 'h6' : 'subtitle2';



    const { isOver, setNodeRef } = useDroppable({ id: 'droppable-area' });



    const CustomToast = ({ closeToast, message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                state === 'warning' ?
                    <Grid display={"flex"} alignItems={'start'} justifyContent={'flex-end'} gap={'5px'} style={{
                        backgroundColor: 'white',
                        color: 'orange',
                        fontSize: '16px',
                        padding: '15px 10px',
                        fontWeight: 900,
                        width: '100%',
                        height: '100%'
                    }}>
                        <ErrorIcon fontSize={'large'} style={{ color: 'orange' }} />
                        {message}
                    </Grid>
                    :
                    <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                        backgroundColor: 'white',
                        color: 'red',
                        fontSize: '16px',
                        padding: '15px 10px',
                        fontWeight: 900,
                    }}>
                        <ErrorIcon fontSize={'large'} />
                        {message}
                    </Grid>

        );
    };



    const sendDataToServer = () => {
        // Make sure components array is not empty
        if (components.length === 0) {
            console.error("Components array is empty. Cannot send empty data.");
            toast(<CustomToast message="لطفا ابتدا فرم را پر کنید" state={'warning'} />);
            return;
        }

        // Make API call to send components array to the server
        axios.post('api-endpoint', { components })
            .then(response => {
                console.log("Data sent successfully:", response.data);
                // Show success toast
                toast(<CustomToast message="فرم با موفقیت ذخیره شد" state={'success'} />);
                // Navigate to Preview page
                navigate('/Preview');
            })
            .catch(error => {
                console.error("Error sending data to server:", error);
                toast(<CustomToast message="فرم ذخیره نشد" state={'not'} />);
            });
    };


    useEffect(() => {
        // Function to fetch data from the server
        const fetchData = async () => {
            try {
                // Make GET request to fetch components array from the server
                const response = await axios.get('get-api-endpoint');
                // Update components state with the data received from the server
                setComponents(response.data.components);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Optionally, handle errors here
            }
        };

        // Call the fetchData function when the component mounts
        fetchData();

        // Cleanup function to cancel any pending requests if the component unmounts
        return () => {
            // Cancel any pending requests or cleanup tasks if needed
        };
    }, []);


    return (
        <>
            <Grid width={{ xs: '90%', xxs: '90%' }} maxWidth={'752px'} bgcolor={'#fff'} mx={'auto'} borderRadius={'3px'}
                boxShadow={'0 4px 4px rgba(87,100,126,.21)'} py={'40px'} mt={'16px'} px={'16px'} mb={'40px'}>
                <DndProvider backend={HTML5Backend}>
                    {components.length === 0 ?
                        <Grid height={'126px'} width={'85%'} mx={'auto'} borderRadius={'10px'} bgcolor={'#f3f3fe'}
                            border={'2px dashed #c8ceed'} display={'flex'} alignItems={'center'}
                            justifyContent={'center'}>
                            <Grid width={'20px'} height={'20px'}>
                                <img src={'/assets/images/pointer.svg'} alt={''} />
                            </Grid>
                            <Typography variant={Typo} color={'#636a96'}>اولین سوال خود را از منوی سمت راست انتخاب
                                کنید.</Typography>
                        </Grid>
                        :
                        <Grid style={{ cursor: 'move' }}>
                            <Droppable>
                                {components.map((componentData, index) => {
                                    const Com = components[componentData.title];
                                    if (!refs.current[index]) {
                                        refs.current[index] = React.createRef();
                                    }
                                    return (
                                        <DraggableComponent
                                            key={index}
                                            index={index}
                                            id={index + 1}
                                            moveComponent={moveComponent}
                                            draggedItem={draggedItem}
                                            setDraggedItem={setDraggedItem}
                                        >
                                            <Grid style={{ position: 'relative' }} ref={refs.current[index]}>
                                                {React.createElement(Com)}
                                                <Grid className="delete-icon" display={'flex'} alignItems={'center'}
                                                    justifyContent={'center'} gap={'5px'} width={hovered ? '80px' : '40px'}
                                                    height={'40px'} borderRadius={hovered ? '20px' : '50%'}
                                                    position={'absolute'} top={{ xs: "105%", md: '35%' }}
                                                    right={{ xs: hovered ? '41%' : '40%', md: hovered ? '101%' : '100%' }}
                                                    zIndex={1} bgcolor={'rgba(220,38,38,1)'}
                                                    onMouseEnter={() => setHovered(true)}
                                                    onMouseLeave={() => setHovered(false)}
                                                    onClick={() => deleteComponent(componentData.id)}
                                                    style={{ cursor: 'pointer', transition: 'right 0.3s ease' }}>
                                                    <DeleteIcon fontSize={'large'} style={{ color: 'white' }} />
                                                    <Typography pt={'4px'} color={'white'} variant={'h6'}
                                                        display={hovered ? 'block' : 'none'}>حذف</Typography>
                                                </Grid>
                                            </Grid>
                                        </DraggableComponent>
                                    );
                                })}
                            </Droppable>

                        </Grid>
                    }
                </DndProvider>
            </Grid>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Button variant='contained' color='primary' style={{ width: '15%', padding: '15px 0px' }} onClick={sendDataToServer}>
                    <Typography variant='h4' fontWeight={700}>ذخیره</Typography>
                </Button>
            </Grid>
        </>
    )
}

export default Edit