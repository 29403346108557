import React, { useEffect, useRef, useState } from 'react'
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from 'react-router-dom';

const Divider = ({ flag, bgcolor }) => {

    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const containerRef = useRef(null);



    const location = useLocation();
    const currentPage = location.pathname;



    // Add a global click event listener to handle clicks outside the first grid


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);



    return (
        <Grid mb={(currentPage === '/build'|| currentPage === '/Publish') && flag ? '28px' : '50px'} p={(currentPage === '/build'|| currentPage === '/Publish') && flag ? '12px 10px' : '24px 36px'} mx={(currentPage === '/build'|| currentPage === '/Publish') && flag ? '4px' : '0px'}
            bgcolor={'white'} ref={containerRef}
            border={flag ? 'none' : `2px solid ${isHeadingFocused ? '#0293e2' : 'transparent'}`} borderRadius={flag ? '0px' : '4px'}
            onClick={handleClickOutside}>
            <Grid width={'100%'} height={'1px'} bgcolor={flag ? 'rgb(222, 217, 211)' : 'rgb(243, 243, 254)'}></Grid>
        </Grid>
    )
}
export default Divider
