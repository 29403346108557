import { Grid, selectClasses, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import SelectWithSerch from './SelectWithSearch/SelectWithSerch';
import { SelectedOptionContext } from '../Context/SelectedOptionContext';
import FormSettingSelect from './FormSettingSelect';
import PassOption from './PassOption';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import ThankYouEditor from './ThankYouEditor/ThankYouEditor';
import { PayloadContext } from '../Context/PayloadContext';
import { SelectValuesContext } from '../Context/SelectValuesContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ThankYouInitialContentContext from '../Context/ThankYouInitialContentContext';
import { v4 as uuidv4 } from 'uuid';
import FilteredValuesContext from '../Context/FilteredValuesContext';
import ThankYouConditionEditor from './ThankYouConditionEditor/ThankYouConditionEditor';
import Calculator from './Calculator/Calculator';



const Condition = ({ flag, color, fieldFlag, value, placeholder, emialFlag, options, mbFlag }) => {

    const location = useLocation();
    const currentPage = location.pathname;
    const navigate = useNavigate()


    const { selectedOption } = useContext(SelectedOptionContext);
    const { payload } = useContext(PayloadContext);
    const { selectValues, setSelectValues } = useContext(SelectValuesContext);
    const { setFinalSelectValues } = useContext(FinalValuesContext);
    const { src, id } = useContext(ThankYouInitialContentContext);
    const { filteredSelectValuesList, setFilteredSelectValuesList } = useContext(FilteredValuesContext);




    const [conditionValue, setConditionValue] = useState("");
    const peTitle = [];
    const [updateTitle, setUpdateTitle] = useState([]);
    const [filterTitle, setFilterTitle] = useState([])
    const [filterPassTitle, setFilterPassTitle] = useState([])
    const [show, setShow] = useState(true);
    const [urlValue, setUrlValue] = useState('')
    const [emailValue, setEmailValue] = useState('')
    const [showTarget, setShowTarget] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');


    function checkCondition(value) {
        return value === "پنهان کردن چندگانه" ||
            value === "نمایش چندگانه" ||
            value === "نیاز به چندین گزینه" ||
            value === "نیاز به چندین گزینه نداشته باشید";
    }

    let condition = checkCondition(selectValues[18].value) ||
        checkCondition(selectValues[19].value) ||
        checkCondition(selectValues[20].value) ||
        checkCondition(selectValues[21].value) ||
        checkCondition(selectValues[22].value) ||
        checkCondition(selectValues[23].value) ;


    let marginCondition = selectValues[1].value === 'پر شده است' || selectValues[1].value === 'خالی است' ? '0px' : '20px'

    let doIndex = selectValues.findIndex(item => item.name.includes("DoCondition") && item.value !== '')

    let DoConditionUpdateAndCalculateIndex = selectValues.findIndex(item => item.name === "DoConditionUpdateAndCalculate")




    const doOption = [
        { value: 'پنهان کردن', label: 'پنهان کردن' },
        { value: 'نشان دادن', label: 'نشان دادن' },
        { value: 'پنهان کردن چندگانه', label: 'پنهان کردن چندگانه' },
        { value: 'نمایش چندگانه', label: 'نمایش چندگانه' },
    ]


    const doOptionUpdateAndCalculate = [
        { value: 'کپی مقدار فیلد', label: 'کپی مقدار فیلد' },
        { value: 'چندین مقدار فیلد را کپی کنید', label: 'چندین مقدار فیلد را کپی کنید' },
        { value: 'محاسبه مقدار فیلد', label: 'محاسبه مقدار فیلد' },
    ]


    const doOptionEnableAndRequire = [
        { value: 'لازم بودن', label: 'لازم بودن' },
        { value: 'مورد نیاز نیست', label: 'مورد نیاز نیست' },
        { value: "نیاز به چندین گزینه", label: "نیاز به چندین گزینه" },
        { value: "نیاز به چندین گزینه نداشته باشید", label: "نیاز به چندین گزینه نداشته باشید" },
        { value: 'غیر فعال کردن', label: 'غیر فعال کردن' },
        { value: 'فعال کردن', label: 'فعال کردن' },
        { value: 'ماسک را تنظیم کنید', label: 'ماسک را تنظیم کنید' },
    ]


    const doOptionSkip = [
        { value: 'پرش به صفحه', label: 'پرش به صفحه' },
        { value: 'پنهان کردن صفحه', label: 'پنهان کردن صفحه' },
    ]


    const doOptionThankYou = [
        { value: 'نمایش پیام سفارشی', label: 'نمایش پیام سفارشی' },
        { value: "هدایت به URL", label: "هدایت به URL" },
    ]

    const doOptionEmail = [
        { value: 'پاسخگوی خودکار 1', label: 'پاسخگوی خودکار 1' },
    ]

    const pageOption = [
        { value: 'صفحه 1', label: 'صفحه 1' },
    ]


    const targetStatus = [
        { value: 'مقدار', label: 'مقدار' },
        { value: 'یک فیلد دیگر', label: 'یک فیلد دیگر' },
    ]


    const defaultValues = [

        { id: uuidv4(), name: 'ifCondition', value: '' },
        { id: uuidv4(), name: 'ifConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'ifConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'ifConditionThankYou', value: '' },
        { id: uuidv4(), name: 'ifConditionSkip', value: '' },
        { id: uuidv4(), name: 'ifConditionEmail', value: '' },
        { id: uuidv4(), name: 'StateCondition', value: '' },
        { id: uuidv4(), name: 'StateConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'StateConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'StateConditionThankYou', value: '' },
        { id: uuidv4(), name: 'StateConditionSkip', value: '' },
        { id: uuidv4(), name: 'StateConditionEmail', value: '' },
        { id: uuidv4(), name: 'valueCondition', value: '' },
        { id: uuidv4(), name: 'valueConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'valueConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'valueConditionThankYou', value: '' },
        { id: uuidv4(), name: 'valueConditionSkip', value: '' },
        { id: uuidv4(), name: 'valueConditionEmail', value: '' },
        { id: uuidv4(), name: 'DoCondition', value: '' },
        { id: uuidv4(), name: 'DoConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'DoConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'DoConditionThankYou', value: '' },
        { id: uuidv4(), name: 'DoConditionSkip', value: '' },
        { id: uuidv4(), name: 'DoConditionEmail', value: '' },
        { id: uuidv4(), name: 'FieldCondition', value: '' },
        { id: uuidv4(), name: 'FieldConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'FieldConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'TargetStatus', value: null },
        { id: uuidv4(), name: 'TargetStatusUpdateAndCalculate', value: null },
        { id: uuidv4(), name: 'TargetStatusEnableAndRequire', value: null },
        { id: uuidv4(), name: 'TargetStatusThankYou', value: null },
        { id: uuidv4(), name: 'TargetStatusSkip', value: null },
        { id: uuidv4(), name: 'TargetStatusEmail', value: null },
        { id: uuidv4(), name: 'ifFieldCondition', value: null },
        { id: uuidv4(), name: 'ifFieldConditionUpdateAndCalculate', value: null },
        { id: uuidv4(), name: 'ifFieldConditionEnableAndRequire', value: null },
        { id: uuidv4(), name: 'ifFieldConditionThankYou', value: null },
        { id: uuidv4(), name: 'valueEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'urlValue', value: '' },
        { id: uuidv4(), name: 'emailValue', value: '' },
        { id: uuidv4(), name: 'editorValue', value: '' },
        { id: uuidv4(), name: 'fromValue', value: '' },
        { id: uuidv4(), name: 'toValue', value: '' },
        { id: uuidv4(), name: 'summaryValue', value: '' },
        { id: uuidv4(), name: 'FinalThankYouContent', value: '' },
        { id: uuidv4(), name: 'CalculateValue', value: '' },
    ];


    const renderView = () => {
        switch (currentPage) {
            case '/Setting/Conditional/ShowAndHide':
                return doOption
            case '/Setting/Conditional/UpdateAndCalculate':
                return doOptionUpdateAndCalculate
            case '/Setting/Conditional/EnableAndRequire':
                return doOptionEnableAndRequire;
            case '/Setting/Conditional/Skip':
                return doOptionSkip;
            case '/Setting/Conditional/ThankYou':
                return doOptionThankYou;
            case '/Setting/Conditional/EmailRecipient':
                return doOptionEmail;
            default:
                return doOption;
        }
    };

    const CustomToast = ({ message, state }) => {
        return (
            <Grid display="flex" alignItems="start" justifyContent="end" gap="5px" style={{
                backgroundColor: 'white',
                color: state === 'success' ? 'green' : 'red',
                fontSize: '16px',
                padding: '15px 10px',
                fontWeight: 900,
            }}>
                {state === 'success' ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
                {message}
            </Grid>
        );
    };



    const handleChangeValue = (e, name) => {
        setConditionValue(e.target.value)
        setSelectValues((prevSelectValues) =>
            prevSelectValues.map((select) =>
                select.name === name ? { ...select, value: conditionValue } : select
            )
        );
    };


    const handleChangeEnableAndRequireValue = (e) => {
        const newValue = e.target.value;
        setSelectValues((prevSelectValues) =>
            prevSelectValues.map((select) =>
                select.name === 'valueEnableAndRequire' ? { ...select, value: newValue } : select
            )
        );
    };


    const handleChangeUrlValue = (e) => {
        const newValue = e.target.value;
        if (!newValue.startsWith('https://')) {
            setErrorMessage('آدرس اینترنتی باید با "https://" شروع شود');
        } else {
            setErrorMessage('');
        }
        setUrlValue(e.target.value)

        setSelectValues((prevSelectValues) =>
            prevSelectValues.map((select) =>
                select.name === 'urlValue' ? { ...select, value: urlValue } : select
            )
        );
    };



    const handleChangeEmailValue = (e) => {
        setEmailValue(e.target.value)
        setSelectValues((prevSelectValues) =>
            prevSelectValues.map((select) =>
                select.name === 'emailValue' ? { ...select, value: emailValue } : select
            )
        );
    };


    const handleSelectChange = (name, value) => {
        // Check if a select with the given name already exists in the array
        const existingIndex = selectValues.findIndex((select) => select.name === name);

        if (existingIndex !== -1) {
            // If a select with the given name exists, update its value
            const updatedSelects = [...selectValues];
            updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value };
            setSelectValues(updatedSelects);
        } else {
            // If a select with the given name doesn't exist, add it to the array
            setSelectValues((prevSelectValues) => [...prevSelectValues, { name, value }]);
        }
    };


    const handleChangePeTitle = () => {
        options.map(
            item =>
                peTitle.push({ value: item.peTitle, label: item.peTitle })
        )
    }


    // const filterData = () => {
    //     if (currentPage === '/Setting/Conditional/UpdateAndCalculate') {
    //         const update = [...selectValues]
    //         // update.splice(4, 1)
    //         setSelectValues(update)
    //     }
    // }


    const handleFilterTitle = () => {
        let index = options.findIndex(title => title.peTitle === selectValues[0].value)
        const filter = [...options]
        filter.splice(index, 1)
        setFilterTitle(filter)
        setFilterPassTitle(
            filter.map(
                item => ({ value: item.peTitle, label: item.peTitle })
            )
        )
    }


    const handleShowTarget = () => {

        let index;

        let targetIndex;

        if (currentPage === '/Setting/Conditional/ShowAndHide') {

            index = selectValues.findIndex(item => item.name === 'StateCondition')
            targetIndex = selectValues.findIndex(item => item.name === "TargetStatus")

        } else if (currentPage === '/Setting/Conditional/UpdateAndCalculate') {

            index = selectValues.findIndex(item => item.name === "StateConditionUpdateAndCalculate")
            targetIndex = selectValues.findIndex(item => item.name === "TargetStatusUpdateAndCalculate")

        } else if (currentPage === '/Setting/Conditional/EnableAndRequire') {

            index = selectValues.findIndex(item => item.name === "StateConditionEnableAndRequire")
            targetIndex = selectValues.findIndex(item => item.name === "TargetStatusEnableAndRequire")

        } else if (currentPage === '/Setting/Conditional/ThankYou') {

            index = selectValues.findIndex(item => item.name === "StateConditionThankYou")
            targetIndex = selectValues.findIndex(item => item.name === "TargetStatusThankYou")

        } else if (currentPage === '/Setting/Conditional/Skip') {

            index = selectValues.findIndex(item => item.name === "StateConditionSkip")
            targetIndex = selectValues.findIndex(item => item.name === "TargetStatusSkip")

        } else if (currentPage === '/Setting/Conditional/EmailRecipient') {

            index = selectValues.findIndex(item => item.name === "StateConditionEmail")
            targetIndex = selectValues.findIndex(item => item.name === "TargetStatusEmail")

        }

        if (selectValues[index]?.value === 'خالی است' || selectValues[index]?.value === 'پر شده است') {
            setShow(false)
        } else {
            setShow(true)
        }

        if (selectValues[targetIndex]?.value === 'یک فیلد دیگر') {
            setShowTarget(false)
        } else {
            setShowTarget(true)
        }
    }






    const handleSave = async () => {


        console.log(selectValues)


        const filteredSelectValues = selectValues.filter(item => item.value !== '' && item.value !== null);

        setFilteredSelectValuesList(prevList => [...prevList, filteredSelectValues]);

        navigate('/Setting/ConditionList')

    };


    useEffect(() => {
        handleChangePeTitle()
        let updateState = peTitle.slice(0, peTitle.length / 2)
        setUpdateTitle(updateState)
    }, [options])


    useEffect(() => {
        handleFilterTitle()
        handleShowTarget()
    }, [selectValues])


    useEffect(() => {

        if (currentPage === '/Setting/Conditional/ShowAndHide') {

            setSelectValues(prevSelectValues =>
                prevSelectValues.map(select =>
                    select.name === 'ifCondition' ? { ...select, value: selectedOption } : select
                )
            );

        } else if (currentPage === '/Setting/Conditional/UpdateAndCalculate') {

            setSelectValues(prevSelectValues =>
                prevSelectValues.map(select =>
                    select.name === 'ifConditionUpdateAndCalculate' ? { ...select, value: selectedOption } : select
                )
            );

        } else if (currentPage === '/Setting/Conditional/EnableAndRequire') {

            setSelectValues(prevSelectValues =>
                prevSelectValues.map(select =>
                    select.name === 'ifConditionEnableAndRequire' ? { ...select, value: selectedOption } : select
                )
            );

        } else if (currentPage === '/Setting/Conditional/ThankYou') {

            setSelectValues(prevSelectValues =>
                prevSelectValues.map(select =>
                    select.name === 'ifConditionThankYou' ? { ...select, value: selectedOption } : select
                )
            );

        } else if (currentPage === '/Setting/Conditional/Skip') {

            setSelectValues(prevSelectValues =>
                prevSelectValues.map(select =>
                    select.name === 'ifConditionSkip' ? { ...select, value: selectedOption } : select
                )
            );

        } else if (currentPage === '/Setting/Conditional/EmailRecipient') {

            setSelectValues(prevSelectValues =>
                prevSelectValues.map(select =>
                    select.name === 'ifConditionEmail' ? { ...select, value: selectedOption } : select
                )
            );

        }


    }, [selectedOption]);



    useEffect(() => {
        console.log(currentPage)
        if (!currentPage.includes('/Setting/Conditional')) {
            setSelectValues(prevSelectValues =>
                prevSelectValues.map(select =>
                    select.name === 'editorValue' ? { ...select, value: payload } : select
                )
            );
        }

    }, [payload]);


    // useEffect(() => {
    //     filterData()
    // }, [currentPage])


    useEffect(() => {
        setSelectValues(defaultValues)
    }, [currentPage])




    return (
        <Grid width={'100%'}>
            <Grid className='MobileShowAndHideCondition' display={{ xs: 'block', xxs: 'none' }}>
                <Grid className='IF' mb={'16px'}>
                    <Grid mb={'8px'} width={'32px'} height={'32px'} borderRadius={'4px'} bgcolor={'#0075e3'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <AddIcon style={{ color: 'white' }} fontSize='large' />
                    </Grid>
                    <Grid width={'100%'} p={'24px 16px'} bgcolor={'white'} borderRight={`7px solid ${color}`} borderTop={'1px solid #c8ceed'} borderLeft={'1px solid #c8ceed'} borderBottom={'1px solid #c8ceed'} borderRadius={'4px'}>
                        <Grid mb={'20px'}>
                            <Typography variant='h6' fontWeight={700}>اگر</Typography>
                            <SelectWithSerch options={options} flag={true} />
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/ShowAndHide' ? 'block' : 'none'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <PassOption selectedOption={selectedOption} name={'StateCondition'} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/UpdateAndCalculate' ? 'block' : 'none'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <PassOption selectedOption={selectedOption} name={'StateConditionUpdateAndCalculate'} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'block' : 'none'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <PassOption selectedOption={selectedOption} name={'StateConditionEnableAndRequire'} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/ThankYou' ? 'block' : 'none'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <PassOption selectedOption={selectedOption} name={'StateConditionThankYou'} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/Skip' ? 'block' : 'none'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <PassOption selectedOption={selectedOption} name={'StateConditionSkip'} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/EmailRecipient' ? 'block' : 'none'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <PassOption selectedOption={selectedOption} name={'StateConditionEmail'} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                        </Grid>
                        <Grid display={show ? 'block' : 'none'}>
                            <Typography variant='h6' fontWeight={700}>هدف</Typography>
                            <Grid mb={'20px'} display={currentPage === '/Setting/Conditional/ShowAndHide' ? 'block' : 'none'}>
                                <FormSettingSelect options={targetStatus} id={0} name={'TargetStatus'} onSelectChange={handleSelectChange} multi={false} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                            </Grid>
                            <Grid mb={'20px'} display={currentPage === '/Setting/Conditional/UpdateAndCalculate' ? 'block' : 'none'}>
                                <FormSettingSelect options={targetStatus} id={0} name={'TargetStatusUpdateAndCalculate'} onSelectChange={handleSelectChange} multi={false} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                            </Grid>
                            <Grid mb={'20px'} display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'block' : 'none'}>
                                <FormSettingSelect options={targetStatus} id={0} name={'TargetStatusEnableAndRequire'} onSelectChange={handleSelectChange} multi={false} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                            </Grid>
                            <Grid mb={'20px'} display={currentPage === '/Setting/Conditional/ThankYou' ? 'block' : 'none'}>
                                <FormSettingSelect options={targetStatus} id={0} name={'TargetStatusThankYou'} onSelectChange={handleSelectChange} multi={false} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                            </Grid>
                            <Grid mb={'20px'} display={currentPage === '/Setting/Conditional/Skip' ? 'block' : 'none'}>
                                <FormSettingSelect options={targetStatus} id={0} name={'TargetStatusSkip'} onSelectChange={handleSelectChange} multi={false} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                            </Grid>
                            <Grid display={showTarget ? 'block' : 'none'}>
                                <Typography variant='h6' fontWeight={700}>مقدار</Typography>
                                <Grid display={currentPage === '/Setting/Conditional/ShowAndHide' ? 'block' : 'none'}>
                                    <input
                                        onChange={(e) => { handleChangeValue(e, 'valueCondition') }}
                                        onBlur={() => { handleSelectChange('valueCondition', conditionValue) }}
                                        value={conditionValue}
                                        placeholder='لطفا مقدار را اینجا تایپ کنید'
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/UpdateAndCalculate' ? 'block' : 'none'}>
                                    <input
                                        onChange={(e) => { handleChangeValue(e, 'valueConditionUpdateAndCalculate') }}

                                        onBlur={() => { handleSelectChange('valueConditionUpdateAndCalculate', conditionValue) }}
                                        value={conditionValue}
                                        placeholder='لطفا مقدار را اینجا تایپ کنید'
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'block' : 'none'}>
                                    <input
                                        onChange={(e) => { handleChangeValue(e, 'valueConditionEnableAndRequire') }}

                                        onBlur={() => { handleSelectChange('valueConditionEnableAndRequire', conditionValue) }}
                                        value={conditionValue}
                                        placeholder='لطفا مقدار را اینجا تایپ کنید'
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/ThankYou' ? 'block' : 'none'}>
                                    <input
                                        onChange={(e) => { handleChangeValue(e, 'valueConditionThankYou') }}

                                        onBlur={() => { handleSelectChange('valueConditionThankYou', conditionValue) }}
                                        value={conditionValue}
                                        placeholder='لطفا مقدار را اینجا تایپ کنید'
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/Skip' ? 'block' : 'none'}>
                                    <input
                                        onChange={(e) => { handleChangeValue(e, 'valueConditionSkip') }}

                                        onBlur={() => { handleSelectChange('valueConditionSkip', conditionValue) }}
                                        value={conditionValue}
                                        placeholder='لطفا مقدار را اینجا تایپ کنید'
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </Grid>

                            </Grid>
                            <Grid display={showTarget ? 'none' : 'block'}>
                                <Typography variant='h6' fontWeight={700}>فیلد</Typography>
                                <Grid display={currentPage === '/Setting/Conditional/ShowAndHide' ? 'block' : 'none'}>
                                    <FormSettingSelect options={filterPassTitle} flag={false} onSelectChange={handleSelectChange} multi={false} name={'ifFieldCondition'} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/UpdateAndCalculate' ? 'block' : 'none'}>
                                    <FormSettingSelect options={filterPassTitle} flag={false} onSelectChange={handleSelectChange} multi={false} name={'ifFieldConditionUpdateAndCalculate'} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'block' : 'none'}>
                                    <FormSettingSelect options={filterPassTitle} flag={false} onSelectChange={handleSelectChange} multi={false} name={'ifFieldConditionEnableAndRequire'} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/ThankYou' ? 'block' : 'none'}>
                                    <FormSettingSelect options={filterPassTitle} flag={false} onSelectChange={handleSelectChange} multi={false} name={'ifFieldConditionThankYou'} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/Skip' ? 'block' : 'none'}>
                                    <FormSettingSelect options={filterPassTitle} flag={false} onSelectChange={handleSelectChange} multi={false} name={'ifFieldConditionSkip'} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className='Do'>
                    <Grid mb={'8px'} width={flag ? '32px' : '0px'} height={flag ? '32px' : '0px'} borderRadius={'4px'} bgcolor={flag ? '#0075e3' : 'transparent'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <AddIcon style={{ color: flag ? 'white' : 'transparent' }} fontSize='large' />
                    </Grid>
                    <Grid width={'100%'} p={'24px 16px'} bgcolor={'white'} borderRight={`7px solid ${color}`} borderTop={'1px solid #c8ceed'} borderLeft={'1px solid #c8ceed'} borderBottom={'1px solid #c8ceed'} borderRadius={'4px'}>
                        <Grid mb={'20px'}>
                            <Typography variant='h6' fontWeight={700}>{emialFlag ? 'ارسال' : 'انجام دادن'}</Typography>
                            <Grid display={currentPage === '/Setting/Conditional/ShowAndHide' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'ایمیل را انتخاب کنید' : 'عملیات را انتخاب کنید'} options={renderView()} id={0} name={'DoCondition'} onSelectChange={handleSelectChange} multi={condition} search={true} />
                            </Grid>
                            <Grid display={currentPage === '/Setting/Conditional/UpdateAndCalculate' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'ایمیل را انتخاب کنید' : 'عملیات را انتخاب کنید'} options={renderView()} id={0} name={'DoConditionUpdateAndCalculate'} onSelectChange={handleSelectChange} multi={false} search={true} />
                            </Grid>
                            <Grid display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'ایمیل را انتخاب کنید' : 'عملیات را انتخاب کنید'} options={renderView()} id={0} name={'DoConditionEnableAndRequire'} onSelectChange={handleSelectChange} multi={false} search={true} />
                            </Grid>
                            <Grid display={currentPage === '/Setting/Conditional/ThankYou' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'ایمیل را انتخاب کنید' : 'عملیات را انتخاب کنید'} options={renderView()} id={0} name={'DoConditionThankYou'} onSelectChange={handleSelectChange} multi={false} search={true} />
                            </Grid>
                            <Grid display={currentPage === '/Setting/Conditional/Skip' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'ایمیل را انتخاب کنید' : 'عملیات را انتخاب کنید'} options={renderView()} id={0} name={'DoConditionSkip'} onSelectChange={handleSelectChange} multi={false} search={true} />
                            </Grid>
                        </Grid>
                        <Grid mb={'20px'} display={fieldFlag ? 'block' : 'none'}>
                            <Typography variant='h6' fontWeight={700}>{emialFlag ? 'ایمیل' : value}</Typography>
                            <Grid width={'100%'} display={emialFlag ? 'none' : 'block'}>
                                <Grid display={currentPage === '/Setting/Conditional/ShowAndHide' ? 'block' : 'none'}>
                                    <FormSettingSelect placeholder={placeholder} options={updateTitle} id={0} name={'FieldCondition'} onSelectChange={handleSelectChange} search={true} multi={condition} />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/UpdateAndCalculate' ? 'block' : 'none'}>
                                    <FormSettingSelect placeholder={placeholder} options={updateTitle} id={0} name={'FieldConditionUpdateAndCalculate'} onSelectChange={handleSelectChange} search={true} multi={condition} />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'block' : 'none'}>
                                    <FormSettingSelect placeholder={placeholder} options={updateTitle} id={0} name={'FieldConditionEnableAndRequire'} onSelectChange={handleSelectChange} search={true} multi={condition} />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/ThankYou' ? 'block' : 'none'}>
                                    <FormSettingSelect placeholder={placeholder} options={updateTitle} id={0} name={'FieldConditionThankYou'} onSelectChange={handleSelectChange} search={true} multi={condition} />
                                </Grid>
                                <Grid display={currentPage === '/Setting/Conditional/ThankYou' ? 'block' : 'none'}>
                                    <FormSettingSelect placeholder={placeholder} options={updateTitle} id={0} name={'FieldConditionSkip'} onSelectChange={handleSelectChange} search={true} multi={condition} />
                                </Grid>
                            </Grid>
                            <Grid width={'100%'} display={emialFlag ? 'block' : 'none'}>
                                <input
                                    onChange={handleChangeEmailValue}
                                    onBlur={() => { handleSelectChange('emailValue', emailValue) }}
                                    value={conditionValue}
                                    placeholder='لطفا مقدار را اینجا تایپ کنید'
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        border: '1px solid #ced4da',
                                        borderRadius: '4px'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid display={currentPage === '/Setting/Conditional/UpdateAndCalculate' && selectValues[DoConditionUpdateAndCalculateIndex].value !== 'محاسبه مقدار فیلد' ? 'block' : 'none'} mb={'20px'}>
                            <Typography variant='h6' fontWeight={700}>{selectValues[selectValues.findIndex(item => item.name === "DoConditionUpdateAndCalculate")].value === "کپی مقدار فیلد" ? "از" : "فیلدها"}</Typography>
                            <Grid width={'100%'}>
                                <FormSettingSelect placeholder={emialFlag ? 'لطفا آدرس ایمیل خود را بنویسید' : placeholder} options={updateTitle} id={0} name={'FieldConditionUpdateAndCalculate'} onSelectChange={handleSelectChange} search={true} multi={selectValues[3].value === "کپی مقدار فیلد" ? false : true} />
                            </Grid>
                        </Grid>
                        <Grid display={currentPage === '/Setting/Conditional/UpdateAndCalculate' && selectValues[DoConditionUpdateAndCalculateIndex].value !== 'محاسبه مقدار فیلد' ? 'block' : 'none'} mb={'20px'}>
                            <Typography variant='h6' fontWeight={700}>{selectValues[selectValues.findIndex(item => item.name === "DoConditionUpdateAndCalculate")].value !== 'چندین مقدار فیلد را کپی کنید' ? "به" : "نتیجه"}</Typography>
                            <Grid width={'100%'}>
                                <FormSettingSelect placeholder={emialFlag ? 'لطفا آدرس ایمیل خود را بنویسید' : placeholder} options={updateTitle} id={0} name={'FieldConditionUpdateAndCalculate'} onSelectChange={handleSelectChange} search={true} multi={false} />
                            </Grid>
                        </Grid>
                        {/* <Grid display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'block' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>مقدار</Typography>
                            <Grid width={'70%'}>
                                <input
                                    onChange={handleChangeEnableAndRequireValue}
                                    onBlur={() => { handleSelectChange('valueEnableAndRequire', valueEnableAndRequire) }}
                                    value={valueEnableAndRequire}
                                    placeholder='لطفا مقدار را اینجا تایپ کنید'
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        border: '1px solid #ced4da',
                                        borderRadius: '4px'
                                    }}
                                />
                            </Grid>
                        </Grid> */}
                        <Grid display={currentPage === '/Setting/Conditional/Skip' ? 'block' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>{"صفحه"}</Typography>
                            <Grid width={'100%'}>
                                <FormSettingSelect placeholder={emialFlag ? 'لطفا آدرس ایمیل خود را بنویسید' : placeholder} options={pageOption} id={0} name={'FieldConditionSkip'} onSelectChange={handleSelectChange} search={true} multi={false} />
                            </Grid>
                        </Grid>
                        {/* <Grid display={currentPage === '/Setting/Conditional/Skip' ? 'block' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>{"صفحه"}</Typography>
                            <Grid width={'70%'}>
                                <FormSettingSelect placeholder={emialFlag ? 'لطفا آدرس ایمیل خود را بنویسید' : placeholder} options={pageOption} id={0} name={'FieldCondition'} onSelectChange={handleSelectChange} search={true} multi={false} />
                            </Grid>
                        </Grid> */}
                        <Grid display={currentPage === '/Setting/Conditional/ThankYou' && selectValues[selectValues.findIndex(item => item.name === "DoConditionThankYou")].value === "نمایش پیام سفارشی" ? 'block' : 'none'}>
                            {/* <ThankYouEditor src={src} id={id} conditionFlag={true} /> */}
                            <ThankYouConditionEditor />
                        </Grid>
                        <Grid display={currentPage === '/Setting/Conditional/ThankYou' && selectValues[selectValues.findIndex(item => item.name === "DoConditionThankYou")].value === "هدایت به URL" ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>{"آدرس اینترنتی"}</Typography>
                            <input
                                onChange={handleChangeUrlValue}
                                onBlur={() => { handleSelectChange('urlValue', urlValue) }}
                                value={urlValue}
                                placeholder='لطفا مقدار را اینجا تایپ کنید'
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    border: '1px solid #ced4da',
                                    borderRadius: '4px'
                                }}
                            />
                            {errorMessage !== '' && urlValue !== '' && <Typography variant='subtitle1' color={'#F5004F'} fontWeight={700}>{errorMessage}</Typography>}
                        </Grid>
                        <Grid display={currentPage === '/Setting/Conditional/UpdateAndCalculate' && selectValues[DoConditionUpdateAndCalculateIndex].value === 'محاسبه مقدار فیلد' ? 'flex' : 'none'}>
                            <Calculator />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className='DesktopShowAndHideCondition' display={{ xs: 'none', xxs: 'block' }}>
                <Grid className='IF' mb={'16px'} display={'flex'} alignItems={'center'} flexDirection={'row-reverse'} gap={'8px'}>
                    <Grid width={'32px'} height={'32px'} borderRadius={'4px'} bgcolor={'#0075e3'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <AddIcon style={{ color: 'white' }} fontSize='large' />
                    </Grid>
                    <Grid width={'100%'} p={'24px 16px'} bgcolor={'white'} borderRight={`7px solid ${color}`} borderTop={'1px solid #c8ceed'} borderLeft={'1px solid #c8ceed'} borderBottom={'1px solid #c8ceed'} borderRadius={'4px'}>
                        <Grid mb={'20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>اگر</Typography>
                            <SelectWithSerch options={options} />
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/ShowAndHide' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <Grid width={'70%'}>
                                <PassOption selectedOption={selectedOption} name={'StateCondition'} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                            </Grid>
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/UpdateAndCalculate' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <Grid width={'70%'}>
                                <PassOption selectedOption={selectedOption} name={'StateConditionUpdateAndCalculate'} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                            </Grid>
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <Grid width={'70%'}>
                                <PassOption selectedOption={selectedOption} name={'StateConditionEnableAndRequire'} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                            </Grid>
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/ThankYou' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <Grid width={'70%'}>
                                <PassOption selectedOption={selectedOption} name={"StateConditionThankYou"} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                            </Grid>
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/Skip' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <Grid width={'70%'}>
                                <PassOption selectedOption={selectedOption} name={"StateConditionSkip"} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                            </Grid>
                        </Grid>
                        <Grid mb={marginCondition} display={currentPage === '/Setting/Conditional/EmailRecipient' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>وضعیت</Typography>
                            <Grid width={'70%'}>
                                <PassOption selectedOption={selectedOption} name={"StateConditionEmail"} onSelectChange={handleSelectChange} placeholder={'لطفا جستجو یا انتخاب کنید ...'} multi={false} search={true} />
                            </Grid>
                        </Grid>
                        <Grid display={show ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'} mb={'20px'}>
                            <Typography variant='h6' fontWeight={700}>هدف</Typography>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/ShowAndHide' ? 'block' : 'none'}>
                                <FormSettingSelect options={targetStatus} id={0} name={'TargetStatus'} onSelectChange={handleSelectChange} multi={false} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/UpdateAndCalculate' ? 'block' : 'none'}>
                                <FormSettingSelect options={targetStatus} id={0} name={'TargetStatusUpdateAndCalculate'} onSelectChange={handleSelectChange} multi={false} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'block' : 'none'}>
                                <FormSettingSelect options={targetStatus} id={0} name={'TargetStatusEnableAndRequire'} onSelectChange={handleSelectChange} multi={false} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/ThankYou' ? 'block' : 'none'}>
                                <FormSettingSelect options={targetStatus} id={0} name={'TargetStatusThankYou'} onSelectChange={handleSelectChange} multi={false} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/Skip' ? 'block' : 'none'}>
                                <FormSettingSelect options={targetStatus} id={0} name={'TargetStatusSkip'} onSelectChange={handleSelectChange} multi={false} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/EmailRecipient' ? 'block' : 'none'}>
                                <FormSettingSelect options={targetStatus} id={0} name={'TargetStatusEmail'} onSelectChange={handleSelectChange} multi={false} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                            </Grid>
                        </Grid>
                        <Grid display={show ? 'block' : 'none'}>
                            <Grid display={showTarget ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant='h6' fontWeight={700}>مقدار</Typography>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/ShowAndHide' ? 'block' : 'none'}>
                                    <input
                                        onChange={(e) => { handleChangeValue(e, 'valueCondition') }}

                                        onBlur={() => { handleSelectChange('valueCondition', conditionValue) }}
                                        value={conditionValue}
                                        placeholder='لطفا مقدار را اینجا تایپ کنید'
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </Grid>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/UpdateAndCalculate' ? 'block' : 'none'}>
                                    <input
                                        onChange={(e) => { handleChangeValue(e, 'valueConditionUpdateAndCalculate') }}

                                        onBlur={() => { handleSelectChange('valueConditionUpdateAndCalculate', conditionValue) }}
                                        value={conditionValue}
                                        placeholder='لطفا مقدار را اینجا تایپ کنید'
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </Grid>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'flex' : 'none'}>
                                    <input
                                        onChange={(e) => { handleChangeValue(e, 'valueConditionEnableAndRequire') }}

                                        onBlur={() => { handleSelectChange('valueConditionEnableAndRequire', conditionValue) }}
                                        value={conditionValue}
                                        placeholder='لطفا مقدار را اینجا تایپ کنید'
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </Grid>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/ThankYou' ? 'flex' : 'none'}>
                                    <input
                                        onChange={(e) => { handleChangeValue(e, 'valueConditionThankYou') }}

                                        onBlur={() => { handleSelectChange('valueConditionThankYou', conditionValue) }}
                                        value={conditionValue}
                                        placeholder='لطفا مقدار را اینجا تایپ کنید'
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </Grid>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/Skip' ? 'flex' : 'none'}>
                                    <input
                                        onChange={(e) => { handleChangeValue(e, 'valueConditionSkip') }}

                                        onBlur={() => { handleSelectChange('valueConditionSkip', conditionValue) }}
                                        value={conditionValue}
                                        placeholder='لطفا مقدار را اینجا تایپ کنید'
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </Grid>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/EmailRecipient' ? 'flex' : 'none'}>
                                    <input
                                        onChange={(e) => { handleChangeValue(e, 'valueConditionEmail') }}

                                        onBlur={() => { handleSelectChange('valueConditionEmail', conditionValue) }}
                                        value={conditionValue}
                                        placeholder='لطفا مقدار را اینجا تایپ کنید'
                                        style={{
                                            width: '100%',
                                            padding: '8px',
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid display={showTarget ? 'none' : 'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant='h6' fontWeight={700}>فیلد</Typography>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/ShowAndHide' ? 'block' : 'none'}>
                                    <FormSettingSelect options={filterPassTitle} flag={false} onSelectChange={handleSelectChange} multi={false} name={'ifFieldCondition'} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                                </Grid>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/UpdateAndCalculate' ? 'block' : 'none'}>
                                    <FormSettingSelect options={filterPassTitle} flag={false} onSelectChange={handleSelectChange} multi={false} name={'ifFieldConditionUpdateAndCalculate'} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                                </Grid>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'block' : 'none'}>
                                    <FormSettingSelect options={filterPassTitle} flag={false} onSelectChange={handleSelectChange} multi={false} name={'ifFieldConditionEnableAndRequire'} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                                </Grid>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/ThankYou' ? 'block' : 'none'}>
                                    <FormSettingSelect options={filterPassTitle} flag={false} onSelectChange={handleSelectChange} multi={false} name={'ifFieldConditionThankYou'} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                                </Grid>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/Skip' ? 'block' : 'none'}>
                                    <FormSettingSelect options={filterPassTitle} flag={false} onSelectChange={handleSelectChange} multi={false} name={'ifFieldConditionSkip'} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                                </Grid>
                                <Grid width={'70%'} display={currentPage === '/Setting/Conditional/EmailRecipient' ? 'block' : 'none'}>
                                    <FormSettingSelect options={filterPassTitle} flag={false} onSelectChange={handleSelectChange} multi={false} name={'ifFieldConditionEmail'} search={true} placeholder={'لطفا جستجو یا انتخاب کنید ...'} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className='Do' display={'flex'} alignItems={'center'} flexDirection={'row-reverse'} gap={'8px'}>
                    <Grid width={'32px'} height={'32px'} borderRadius={'4px'} bgcolor={flag ? '#0075e3' : 'transparent'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <AddIcon style={{ color: flag ? 'white' : 'transparent' }} fontSize='large' />
                    </Grid>
                    <Grid width={'100%'} p={'24px 16px'} bgcolor={'white'} borderRight={`7px solid ${color}`} borderTop={'1px solid #c8ceed'} borderLeft={'1px solid #c8ceed'} borderBottom={'1px solid #c8ceed'} borderRadius={'4px'}>
                        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={currentPage === '/Setting/Conditional/ThankYou' && selectValues[selectValues.findIndex(item => item.name === "DoConditionThankYou")]?.value === "نمایش پیام سفارشی" ? '100px' : '20px'}>
                            <Typography variant='h6' fontWeight={700}>{emialFlag ? 'ارسال' : 'انجام دادن'}</Typography>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/ShowAndHide' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'ایمیل را انتخاب کنید' : 'عملیات را انتخاب کنید'} options={renderView()} id={0} name={'DoCondition'} onSelectChange={handleSelectChange} multi={false} search={true} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/UpdateAndCalculate' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'ایمیل را انتخاب کنید' : 'عملیات را انتخاب کنید'} options={renderView()} id={0} name={'DoConditionUpdateAndCalculate'} onSelectChange={handleSelectChange} multi={false} search={true} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/EnableAndRequire' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'ایمیل را انتخاب کنید' : 'عملیات را انتخاب کنید'} options={renderView()} id={0} name={'DoConditionEnableAndRequire'} onSelectChange={handleSelectChange} multi={false} search={true} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/ThankYou' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'ایمیل را انتخاب کنید' : 'عملیات را انتخاب کنید'} options={renderView()} id={0} name={'DoConditionThankYou'} onSelectChange={handleSelectChange} multi={false} search={true} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/Skip' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'ایمیل را انتخاب کنید' : 'عملیات را انتخاب کنید'} options={renderView()} id={0} name={'DoConditionSkip'} onSelectChange={handleSelectChange} multi={false} search={true} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/EmailRecipient' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'ایمیل را انتخاب کنید' : 'عملیات را انتخاب کنید'} options={renderView()} id={0} name={'DoConditionEmail'} onSelectChange={handleSelectChange} multi={false} search={true} />
                            </Grid>
                        </Grid>
                        <Grid display={fieldFlag ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'} mb={mbFlag ? '20px' : '0px'}>
                            <Typography variant='h6' fontWeight={700}>{emialFlag ? 'ایمیل' : value}</Typography>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/ShowAndHide' && !emialFlag ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={placeholder} options={updateTitle} id={0} name={'FieldCondition'} onSelectChange={handleSelectChange} search={true} multi={condition} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/UpdateAndCalculate' && !emialFlag ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={placeholder} options={updateTitle} id={0} name={'FieldConditionUpdateAndCalculate'} onSelectChange={handleSelectChange} search={true} multi={condition} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/EnableAndRequire' && !emialFlag ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={placeholder} options={updateTitle} id={0} name={'FieldConditionEnableAndRequire'} onSelectChange={handleSelectChange} search={true} multi={condition} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/ThankYou' && !emialFlag ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={placeholder} options={updateTitle} id={0} name={'FieldConditionThankYou'} onSelectChange={handleSelectChange} search={true} multi={condition} />
                            </Grid>
                            <Grid width={'70%'} display={currentPage === '/Setting/Conditional/Skip' && !emialFlag ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={placeholder} options={updateTitle} id={0} name={'FieldConditionSkip'} onSelectChange={handleSelectChange} search={true} multi={condition} />
                            </Grid>
                            <Grid width={'70%'} display={emialFlag ? 'block' : 'none'}>
                                <input
                                    onChange={handleChangeEmailValue}
                                    onBlur={() => { handleSelectChange('emailValue', emailValue) }}
                                    value={emailValue}
                                    placeholder='لطفا مقدار را اینجا تایپ کنید'
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        border: '1px solid #ced4da',
                                        borderRadius: '4px'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid display={currentPage === '/Setting/Conditional/UpdateAndCalculate' && selectValues[DoConditionUpdateAndCalculateIndex].value !== 'محاسبه مقدار فیلد' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'} mb={mbFlag ? '20px' : '0px'}>
                            <Typography variant='h6' fontWeight={700}>{selectValues[doIndex]?.value !== 'چندین مقدار فیلد را کپی کنید' ? "از" : "فیلدها"}</Typography>
                            <Grid width={'70%'} display={selectValues[doIndex]?.value !== 'چندین مقدار فیلد را کپی کنید' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'لطفا آدرس ایمیل خود را بنویسید' : placeholder} options={updateTitle} id={0} name={'fromValue'} onSelectChange={handleSelectChange} search={true} multi={selectValues[doIndex]?.value === "کپی مقدار فیلد" ? false : true} />
                            </Grid>
                            <Grid width={'70%'} display={selectValues[doIndex]?.value !== 'چندین مقدار فیلد را کپی کنید' ? 'none' : 'block'}>
                                <FormSettingSelect placeholder={emialFlag ? 'لطفا آدرس ایمیل خود را بنویسید' : placeholder} options={updateTitle} id={0} name={'fromValue'} onSelectChange={handleSelectChange} search={true} multi={selectValues[doIndex]?.value === "کپی مقدار فیلد" ? false : true} />
                            </Grid>
                        </Grid>
                        <Grid display={currentPage === '/Setting/Conditional/UpdateAndCalculate' && selectValues[DoConditionUpdateAndCalculateIndex].value !== 'محاسبه مقدار فیلد' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>{selectValues[doIndex]?.value !== 'چندین مقدار فیلد را کپی کنید' ? "به" : "نتیجه"}</Typography>
                            <Grid width={'70%'} display={selectValues[doIndex]?.value !== 'محاسبه مقدار فیلد' ? 'block' : 'none'}>
                                <FormSettingSelect placeholder={emialFlag ? 'لطفا آدرس ایمیل خود را بنویسید' : placeholder} options={updateTitle} id={0} name={'toValue'} onSelectChange={handleSelectChange} search={true} multi={false} />
                            </Grid>
                            <Grid width={'70%'} display={selectValues[doIndex]?.value !== 'محاسبه مقدار فیلد' ? 'none' : 'block'}>
                                <FormSettingSelect placeholder={emialFlag ? 'لطفا آدرس ایمیل خود را بنویسید' : placeholder} options={updateTitle} id={0} name={'summaryValue'} onSelectChange={handleSelectChange} search={true} multi={false} />
                            </Grid>
                        </Grid>
                        {/* <Grid display={currentPage === '/Setting/Conditional/EnableAndRequire' && selectValues[3].value === "ماسک را تنظیم کنید" ? 'flex' : 'none'} alignItems={'baseline'} justifyContent={'space-between'} mt={'20px'}>
                            <Typography variant='h6' fontWeight={700}>مقدار</Typography>
                            <Grid width={'70%'}>
                                <input
                                    onChange={handleChangeEnableAndRequireValue}
                                    onBlur={() => { handleSelectChange('valueEnableAndRequire', valueEnableAndRequire) }}
                                    value={valueEnableAndRequire}
                                    placeholder='لطفا مقدار را اینجا تایپ کنید'
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        border: '1px solid #ced4da',
                                        borderRadius: '4px'
                                    }}
                                />
                                <Typography variant='subtitle1' fontWeight={500} color={'#6c73a8'}>{'از نماد @ برای پوشاندن حروف، # برای اعداد و * برای هر دو استفاده کنید.'}</Typography>
                            </Grid>
                        </Grid> */}
                        <Grid display={currentPage === '/Setting/Conditional/Skip' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>{"صفحه"}</Typography>
                            <Grid width={'70%'}>
                                <FormSettingSelect placeholder={emialFlag ? 'لطفا آدرس ایمیل خود را بنویسید' : placeholder} options={pageOption} id={0} name={'FieldCondition'} onSelectChange={handleSelectChange} search={true} multi={false} />
                            </Grid>
                        </Grid>
                        <Grid display={currentPage === '/Setting/Conditional/ThankYou' && selectValues[selectValues.findIndex(item => item.name === "DoConditionThankYou")].value === "نمایش پیام سفارشی" ? 'block' : 'none'}>
                            {/* <ThankYouEditor src={src} id={id} conditionFlag={true} /> */}
                            <ThankYouConditionEditor />
                        </Grid>
                        <Grid display={currentPage === '/Setting/Conditional/ThankYou' && selectValues[selectValues.findIndex(item => item.name === "DoConditionThankYou")].value === "هدایت به URL" ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>{"آدرس اینترنتی"}</Typography>
                            <Grid width={'70%'}>
                                <input
                                    onChange={handleChangeUrlValue}
                                    onBlur={() => { handleSelectChange('urlValue', urlValue) }}
                                    value={urlValue}
                                    placeholder='لطفا مقدار را اینجا تایپ کنید'
                                    style={{
                                        width: '100%',
                                        padding: '8px',
                                        border: '1px solid #ced4da',
                                        borderRadius: '4px'
                                    }}
                                />
                                {errorMessage !== '' && urlValue !== '' && <Typography variant='subtitle1' color={'#F5004F'} fontWeight={700}>{errorMessage}</Typography>}
                            </Grid>
                        </Grid>
                        <Grid mb={'24px'} display={currentPage === '/Setting/Conditional/UpdateAndCalculate' && selectValues[DoConditionUpdateAndCalculateIndex].value === 'محاسبه مقدار فیلد' ? 'flex' : 'none'}>
                            <Calculator />
                        </Grid>
                        <Grid display={currentPage === '/Setting/Conditional/UpdateAndCalculate' && selectValues[DoConditionUpdateAndCalculateIndex].value === 'محاسبه مقدار فیلد' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='h6' fontWeight={700}>{"نتیجه"}</Typography>
                            <Grid width={'70%'}>
                                <FormSettingSelect placeholder={emialFlag ? 'لطفا آدرس ایمیل خود را بنویسید' : placeholder} options={updateTitle} id={0} name={'summaryValue'} onSelectChange={handleSelectChange} search={true} multi={false} />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Grid onClick={handleSave} mt={'32px'} className='SaveButton' borderRadius={'0.25rem'} height={'40px'} bgcolor={'rgba(100,178,0,1)'} display={'flex'} alignItems={'center'} justifyContent={'center'} px={'0.625rem'} width={{ xs: '30%', md: '15%' }} mb={'48px'} style={{ cursor: 'pointer' }}>
                <Typography variant='h6' color={'white'}>ذخیره</Typography>
            </Grid>
        </Grid>
    )
}

export default Condition