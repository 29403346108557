import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import NewCustomerCard from '../Component/NewCustomerCard';
import ITServiceTicket from '../Component/ITServiceTicket';
import CustomSimpleOrderForm from '../Component/CustomSimpleOrderForm';
import CourseRegistrationForm from '../Component/CourseRegistrationForm';
import ClassRegistration from '../Component/ClassRegistration';
import BusinessRegistrationForm from '../Component/BusinessRegistrationForm';




const cardTemplateData = [
    {
        id: 1,
        title: 'فرم ثبت نام مشتری جدید',
        src: '/assets/images/NewCustomerCard.png',
        state: [
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue : "چطور در مورد ما شنیدید؟",
                    subDropDownValue: "",
                    option: ['روزنامه', 'اینترنت', 'مجله', 'دیگر'],
                    textareaValue: "",
                    mainTextareaValue: "",
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "بازخورد درباره ما:",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "پیشنهاداتی در صورت وجود برای بهبود بیشتر:",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(), title: 'SingleChoice', state: {
                    titleValue: "آیا حاضرید به ما توصیه ای کنید؟",
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "شاید",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
        ],
        modalContent: <NewCustomerCard />
    },
    {
        id: 2,
        title: 'فرم بلیط خدمات فناوری اطلاعات',
        src: '/assets/images/ItService.png',
        state: [
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "بخش",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شناسه کامپیوتر",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: "آپلود فایل",
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "مشکل را شرح دهید",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <ITServiceTicket />
    },
    {
        id: 3,
        title: 'فرم سفارش ساده سفارشی',
        src: '/assets/images/CustomSimpleOrderForm.png',
        state: [
            {
                id: uuidv4(),
                title: "ProductList",
                state:
                {
                    products: [],
                    productValue: "لیست محصولات",
                    totalPrice: 10000,
                    totalQuantity: 1,
                    mainProducts: [
                        {
                            id: 608,
                            name: "نام محصولات",
                            description: "توضیحات را وارد کنید",
                            image: null,
                            price: "10000",
                            quantity: 1
                        }
                    ],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "یک عنوان وارد کنید",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "یک عنوان وارد کنید",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس تحویل",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "دستورالعمل های ویژه تحویل",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <CustomSimpleOrderForm />
    },
    {
        id: 4,
        title: 'فرم ثبت نام دوره',
        src: '/assets/images/course.png',
        state: [
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام دانش آموز",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ تولد",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "جنسیت",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: "",
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل دانشجویی",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره موبایل",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره کاری",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شرکت",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "دوره ها",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: "",
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "نظرات اضافی",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <CourseRegistrationForm />
    },
    {
        id: 5,
        title: 'ثبت نام کلاس',
        src: '/assets/images/ClassRegistration.png',
        state: [
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام دانش آموز",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "جنسیت",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: "",
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل دانشجویی",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شناسه دانشجویی",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "لیست کلاس ها",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: "",
                    mainOption: null,
                    readOnly: false
                }
            },
        ],
        modalContent: <ClassRegistration />
    },
    {
        id: 6,
        title: 'فرم ثبت نام تجاری',
        src: '/assets/images/BusinessRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "صاحب کسب و کار",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "نام کسب و کار",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تماس",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "نوع کسب و کار",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: "",
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "پیام",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <BusinessRegistrationForm />
    },
]


export default cardTemplateData;