import { Grid, Typography } from '@mui/material'
import React from 'react'

const InitialNotificationEmail = () => {



    return (
        <Grid style={{width:'99%' , height:'99%' , backgroundColor:'#f3f3fe' , margin:'auto' , padding:'35px 15px'}}>
            <Grid style={{display:'flex' , alignItems:'center' , justifyContent:'center' , marginBottom:'24px'}}>
                <img src='/assets/images/AltynLogo.svg' alt='' />
            </Grid>
            <Grid style={{width:'100%' , height:'100%' , backgroundColor:'white' , borderTop:'8px solid rgb(255, 97, 0)' , padding:'17px 35px'}}>
                <Typography variant='h4' color={'black'} fontWeight={700}>
                عنوان فرم
                </Typography>
            </Grid>
        </Grid>
    )
}

export default InitialNotificationEmail