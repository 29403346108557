import { Button, Grid, Typography } from '@mui/material'
import React from 'react'

const AutoResponder = () => {
    return (
        <Grid style={{ width: '99%', height: '99%', backgroundColor: '#f3f3fe', margin: 'auto', padding: '35px 15px' }}>
            <Grid style={{ width: '100%', height: '100%', backgroundColor: 'white', borderTop: '8px solid rgb(255, 97, 0)', padding: '17px 35px' , gap:'8px' , marginBottom:'24px' }}>
                <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '40px', height: '40px' , margin:'auto' }}>
                    <img src='/assets/images/AltynLogo.svg' alt='' />
                </Grid>
                <Typography variant='h4' color={'black'} fontWeight={700}>عنوان فرم</Typography>
            </Grid>
            <Grid style={{display:'flex' , alignItems:'center' , justifyContent:'center'}}>
                <Button style={{padding:'0px 12px' , backgroundColor:'#78BB07' , border:'none' ,width:'180px' , height:'36px' , display:'flex' , alignItems:'center' , justifyContent:'center' , borderRadius:'4px'}}>
                    <Typography style={{fontSize:'13px' , color:'white' , fontWeight:500}}>یک فرم ایجاد کنید</Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default AutoResponder