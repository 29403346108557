import React from 'react'
import LayoutCondition from '../Component/LayoutCondition'
import SettingDrawer from '../Component/SettingDrawer'
import { Grid, Typography } from '@mui/material'
import HeaderSetting from '../Component/HeaderSetting'
import Navbar from '../Component/Navbar'
import { SettingApproval } from '../Component/Icons'
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Approval = () => {
    return (
        <Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'جریان های تایید'} subTitle={'به کارمندان/همکاران خود اجازه دهید موارد ارسالی را تأیید یا رد کنند.'} link={'/Setting'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={6} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={6} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'جریان های تایید'} subTitle={'به کارمندان/همکاران خود اجازه دهید موارد ارسالی را تأیید یا رد کنند.'} link={''} icon={<SettingApproval fill={'white'} />} color={'rgb(55, 131, 111)'} />
                    <Grid width={'100%'} height={'40px'} borderRadius={'4px'} bgcolor={'rgba(0,117,227,1)'} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'8px'}>
                        <AddCircleIcon style={{ color: 'white' }} fontSize='large' />
                        <Typography color={'white'} variant='h6' fontWeight={500} lineHeight={'18px'}>یک جریان تأیید ایجاد کنید</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Approval