import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import React, { useContext, useState } from 'react'
import Navbar from '../Component/Navbar'
import HeaderSetting from '../Component/HeaderSetting'
import SettingDrawer from '../Component/SettingDrawer'
import LayoutCondition from '../Component/LayoutCondition'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Switch from 'react-switch'
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ThankYouEditor from '../Component/ThankYouEditor/ThankYouEditor'
import { SettingThankYouPage } from '../Component/Icons'
import CloseIcon from '@mui/icons-material/Close';
import '../App.css'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import ThankYouInitialContentContext from '../Context/ThankYouInitialContentContext'
import Editor from '../Component/Editor/Editor'
import UrlPayloadContext from '../Context/UrlPayloadContext'





const SettingThankYou = () => {


    const { src, setSrc, id, setId } = useContext(ThankYouInitialContentContext);
    const { urlPayload, setUrlPayload } = useContext(UrlPayloadContext); // Using UrlPayloadContext


    const [active, setActive] = useState(1)
    const [checked, setChecked] = useState(false);
    const [drawer, setDrawer] = useState(false)
    const [address, setAddress] = useState('')
    const [error, setError] = useState('');



    const imageData = [
        { id: 0, src: '/assets/images/sIconUp.png', passSrc: '/assets/images/Thankyou1_4.png' },
        { id: 1, src: '/assets/images/sIconB.png', passSrc: '/assets/images/Thankyou1_4.png' },
        { id: 2, src: '/assets/images/sIconL.png', passSrc: '/assets/images/Thankyou1_4.png' },
        { id: 3, src: '/assets/images/sIconR.png', passSrc: '/assets/images/Thankyou1_4.png' },

        { id: 4, src: '/assets/images/sIconCircleUp.png', passSrc: '/assets/images/ThankYou4_7.png' },
        { id: 5, src: '/assets/images/sIconCircleB.png', passSrc: '/assets/images/ThankYou4_7.png' },
        { id: 6, src: '/assets/images/sIconCircleL.png', passSrc: '/assets/images/ThankYou4_7.png' },
        { id: 7, src: '/assets/images/sIconCircleR.png', passSrc: '/assets/images/ThankYou4_7.png' },

        { id: 8, src: '/assets/images/mediumTop.png', passSrc: '/assets/images/Thank.svg.webp' },
        { id: 9, src: '/assets/images/mediumDown.png', passSrc: '/assets/images/Thank.svg.webp' },
        { id: 10, src: '/assets/images/mediumRight.png', passSrc: '/assets/images/Thank.svg.webp' },
        { id: 11, src: '/assets/images/mediumLeft.png', passSrc: '/assets/images/Thank.svg.webp' },

        { id: 12, src: '/assets/images/centerRect.png', passSrc: '' },
        { id: 13, src: '/assets/images/centerSqr.png', passSrc: '' },
        { id: 14, src: '/assets/images/bigCenter.png', passSrc: '' },

        { id: 15, src: '/assets/images/smallRight.png', passSrc: '/assets/images/Thank.svg.webp' },
        { id: 16, src: '/assets/images/smallLeft.png', passSrc: '/assets/images/Thank.svg.webp' },
        { id: 17, src: '/assets/images/smallDown.png', passSrc: '/assets/images/Thank.svg.webp' },
        { id: 18, src: '/assets/images/smallTop.png', passSrc: '/assets/images/Thank.svg.webp' },

        { id: 19, src: '/assets/images/bigDown.png', passSrc: '/assets/images/Thank.svg.webp' },
        { id: 20, src: '/assets/images/bigUp.png', passSrc: '/assets/images/Thank.svg.webp' },
        { id: 21, src: '/assets/images/bigLeft.png', passSrc: '/assets/images/Thank.svg.webp' },
        { id: 22, src: '/assets/images/bigRight.png', passSrc: '/assets/images/Thank.svg.webp' },

        { id: 23, src: '/assets/images/none.png', passSrc: '' },
    ]


    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const handleActive = (id) => {
        setActive(id)
    }



    const handleOpenDrawer = () => {
        setDrawer(true)
    }


    const handleCloseDrawer = () => {
        setDrawer(false)
    }

    const handleSetSrcImage = (image, activeId) => {
        setSrc(image)
        setId(activeId)
    }


    const CustomToast = ({ closeToast, message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'red',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <ErrorIcon fontSize={'large'} />
                    {message}
                </Grid>

        );
    };


    const handleChangeAddress = (event) => {
        const value = event.target.value;
        const urlPattern = /^(https?:\/\/)/i;

        if (!urlPattern.test(value)) {
            setError('آدرس اینترنتی باید با https:// یا http:// شروع شود');
        } else {
            setError('');
        }

        setAddress(value);
    };

    const handleSubmit = () => {

        if (error === '') {

            const uniqueId = uuidv4();

            const payload = [
                {
                    id: uniqueId,
                    redirectUri: address,
                    redirectHttpPost: !checked
                }
            ];

            if (payload[0].redirectUri === '') {
                toast(<CustomToast message={'لطفا آدرس اینترنتی را وارد کنید'} state={'error'} />);
                return;
            } else {
                setUrlPayload(payload);
                toast(<CustomToast message={'آدرس اینترنتی با موفقیت ذخیره شد'} state={'success'} />);
            }


            axios.post('/your-content-upload-endpoint', payload)
                .then(response => {
                    console.log('Content successfully uploaded:', response.data);
                })
                .catch(error => {
                    console.error('Error uploading content:', error);
                });
        } else {
            toast(<CustomToast message={error} state={'error'} />);
        }
    };



    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));


    return (
        <Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid className='drawer hide-scrollbars'
                style={{
                    transition: 'transform 0.3s ease',
                    transform: drawer ? 'translateX(0)' : 'translateX(-100%)',
                    position: 'fixed',
                    top: isUpXxl ? '0px' : '0px',
                    left: '0',
                    width: isUpXxl ? '410px' : '80%',
                    height: isUpXxl ? '100vh' : '100vh',
                    zIndex: '1002',
                    backgroundColor: '#33384a',
                    overflowY: 'auto'
                }}
            >
                <Grid className='title' p={'32px 18px 14px 18px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant='h4' color={'white'} fontWeight={700}>تنظیمات صفحه تشکر</Typography>
                    <CloseIcon fontSize='large' style={{ color: 'white', cursor: 'pointer' }} onClick={handleCloseDrawer} />
                </Grid>
                <Grid className='components' p={'20px 12px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} flexWrap={'wrap'}>
                    {
                        imageData.map(
                            item =>
                                <Grid position={'relative'} onClick={() => { handleSetSrcImage(item.passSrc, item.id) }} p={'5px'} bgcolor={'#555f6e'} display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'10px'} width={{ xs: '48%', xxs: '48%' }} height={{ xs: '72px', xxs: "106px" }} style={{ cursor: 'pointer' }} border={`3px solid ${item.id === id ? '#0075e3' : 'transparent'}`}>
                                    <img src={item.src} alt={item.id} style={{ width: '100%', height: '100%' }} />
                                    <Grid position={'absolute'} top={'6%'} left={'6%'} display={item.id === id ? 'block' : 'none'}>
                                        <CheckCircleIcon style={{ color: '#0075e3' }} fontSize='large' />
                                    </Grid>
                                </Grid>
                        )
                    }
                </Grid>
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'صفحه تشکر'} subTitle={'بعد از ارسال یک اقدام را انتخاب کنید:'} link={'/Setting'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={4} flag={drawer} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={4} flag={drawer} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <Grid display={'flex'} alignItems={'baseline'} justifyContent={'space-between'}>
                        <LayoutCondition title={'صفحه تشکر'} subTitle={'بعد از ارسال یک اقدام را انتخاب کنید:'} link={''} icon={<CheckCircleIcon style={{ color: 'white' }} fontSize='large' />} color={'rgb(88, 185, 102)'} />
                        <Grid mb={{ xs: '20px', xss: "0px" }} onClick={handleOpenDrawer} width={'54px'} height={'54px'} borderRadius={'50%'} bgcolor={'#0075e3'} display={'flex'} alignItems={'center'} justifyContent={'center'} style={{ cursor: 'pointer' }}>
                            <SettingThankYouPage fill={'white'} />
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid mb={'24px'} className='choose' bgcolor={'white'} borderRadius={'2px'} boxShadow={'0 2px 2px #57647e36'} display={'flex'} alignItems={'center'}>
                            <Grid display={'flex'} alignItems={'center'} gap={'4px'} className='show' p={'16px 14px 14px 12px'} borderLeft={'1px solid #57647e36'} width={'100%'} onClick={() => { handleActive(1) }} style={{ cursor: 'pointer' }}>
                                <Grid width={'20px'} height={'20px'} borderRadius={'50%'} border={active === 1 ? '6px solid rgba(0,102,195,1)' : '1px solid rgba(200,206,237,1)'} style={{ outline: active === 1 ? '1px solid rgba(119,207,255,1)' : '1px solid white' }}></Grid>
                                <Typography variant='h6' color={'rgba(10,21,81,1)'} lineHeight={'20px'}>نمایش یک صفحه تشکر پس از ارسال</Typography>
                            </Grid>
                            <Grid display={'flex'} alignItems={'center'} gap={'4px'} className='redirect' p={'16px 14px 14px 12px'} width={'100%'} onClick={() => { handleActive(2) }} style={{ cursor: 'pointer' }}>
                                <Grid width={'20px'} height={'20px'} borderRadius={'50%'} border={active === 2 ? '6px solid rgba(0,102,195,1)' : '1px solid rgba(200,206,237,1)'} style={{ outline: active === 2 ? '1px solid rgba(119,207,255,1)' : '1px solid white' }}></Grid>
                                <Typography variant='h6' color={'rgba(10,21,81,1)'} lineHeight={'20px'}>
                                    پس از ارسال به یک لینک خارجی هدایت شود
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid className='showPage' display={active === 1 ? 'block' : 'none'} mb={'30px'}>
                                {/* <ThankYouEditor src={src} id={id} /> */}
                                <Editor src={src} id={id} />
                            </Grid>
                            <Grid className='redirectPage' p={'24px'} bgcolor={'white'} borderRadius={'4px'} width={'100%'} display={active === 2 ? 'block' : 'none'}>
                                <Grid mb={'36px'}>
                                    <Typography mb={'8px'} variant='h6' color={'#2c3345'} lineHeight={'18px'} fontWeight={500}>آدرس اینترنتی را وارد کنید</Typography>
                                    <input
                                        value={address}
                                        onChange={handleChangeAddress}
                                        placeholder='https://'
                                        style={{
                                            borderRadius: '4px',
                                            border: '1px solid #d8dae9',
                                            height: '38px',
                                            width: '100%',
                                            textAlign: 'left',
                                            outline: 'none',
                                        }}
                                    />
                                    {error && (
                                        <Typography variant='h6' mt={'8px'} color={'#C40C0C'} fontWeight={700}>
                                            {error}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={'8px'}>
                                    <Grid>
                                        <Typography variant='h5' color={'#2b3245'} mb={'4px'}>تغییر مسیر با HTTP POST</Typography>
                                        <Typography variant='subtitle1' color={'#6c73a8'} fontWeight={500}>از HTTP POST برای درج داده های ارسالی در صفحه ای که کاربر به آن هدایت می شود استفاده کنید. این صفحه باید از HTTP POST پشتیبانی کند.</Typography>
                                    </Grid>
                                    <Grid>
                                        <label htmlFor="small-radius-switch">
                                            <Switch
                                                checked={checked}
                                                onChange={handleChange}
                                                handleDiameter={28}
                                                offColor="#f98e03"
                                                onColor="#ddd"
                                                height={40}
                                                width={70}
                                                borderRadius={6}
                                                uncheckedIcon={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            fontSize: 15,
                                                            color: "white",
                                                            paddingRight: 2,
                                                            paddingTop: '4px'

                                                        }}
                                                    >
                                                        Yes
                                                    </div>
                                                }
                                                checkedIcon={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            fontSize: 15,
                                                            color: "white",
                                                            paddingRight: 2,
                                                            paddingTop: '4px'

                                                        }}
                                                    >
                                                        No
                                                    </div>
                                                }
                                                uncheckedHandleIcon={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            fontSize: 20,
                                                            backgroundColor: '#fff',
                                                            borderRadius: '4px'

                                                        }}
                                                    >
                                                        <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                                    </div>
                                                }
                                                checkedHandleIcon={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            fontSize: 20,
                                                            backgroundColor: '#fff',
                                                            borderRadius: '4px'
                                                        }}
                                                    >
                                                        <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                                    </div>
                                                }
                                                className="react-switch"
                                                id="small-radius-switch"
                                            />
                                        </label>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid mt={'24px'} display={active === 2 ? 'block' : 'none'}>
                                <Button variant='contained' color='success' onClick={handleSubmit} sx={{ padding: '12px 24px' }}>
                                    <Typography variant='h6' fontWeight={700} color={'white'}>ذخیره</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SettingThankYou