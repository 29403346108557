import React, { createContext, useState, useContext } from 'react';

const InputValuesContext = createContext();

export const useInputValues = () => useContext(InputValuesContext);

export const InputValuesProvider = ({ children }) => {



    const initialInputValues = {

        ThisFieldIsRequired: 'این فیلد الزامی است.',
        AtLeastOneFieldIsRequired: 'حداقل یک فیلد مورد نیاز است.',

        SorryOnlyOneEntryIsAllowedMultipleSubmissionsForThisFormAreDisabled: 'متاسفیم! فقط یک بار ورود مجاز است. ارسال چندگانه برای این فرم غیرفعال است.',
        ThereAreErrorsInTheFormPleaseCorrectThemBeforeContinuing: 'اشکالاتی در فرم وجود دارد. لطفا قبل از ادامه آنها را اصلاح کنید.',
        ThereAreErrorsOnThisPagePleaseCorrectThemBeforeContinuing: 'خطاهایی در این صفحه وجود دارد. لطفا قبل از ادامه آنها را اصلاح کنید.',
        RequiredFieldsAreIncompletePleaseCompleteThem: 'فیلدهای الزامی ناقص وجود دارد. لطفا آنها را تکمیل کنید.',

        ThisFieldCanOnlyContainLetters: 'این فیلد فقط می تواند شامل حروف باشد',
        ThisFieldCanOnlyContainCyrillicCharacters: 'این فیلد فقط می تواند حاوی نویسه های سیریلیک باشد',
        ThisFieldCanOnlyContainAValidURL: 'این فیلد فقط می تواند حاوی یک URL معتبر باشد',
        ThisFieldCanOnlyContainNumericValues: 'این فیلد فقط می تواند حاوی مقادیر عددی باشد',
        ThisFieldCanOnlyContainLettersAndNumbers: 'این قسمت فقط می تواند شامل حروف و اعداد باشد.',
        ThisFieldCanOnlyContainCurrencyValues: 'این فیلد فقط می تواند حاوی مقادیر ارز باشد.',
        TheFieldValueMustFillTheMask: 'مقدار فیلد باید ماسک را پر کند.',
        TheNumberOfCharactersShouldNotBeLessThanTheMinimumValue: 'تعداد کاراکترها نباید از حداقل مقدار کمتر باشد:',
        EnterAValidTime: 'زمان معتبری وارد کنید',

        TheLimitationIsThatThereAreTooManyWords: 'محدودیت این است که ، کلمات زیادی هستند',
        TheNumberOfWordsIsTooFew: 'تعداد کلمات بسیار کم است',
        TheNumberOfCharactersIsTooHigh: 'تعداد کاراکترها بسیار زیاد است',
        TheNumberOfCharactersIsTooLow: 'تعداد کاراکترها بسیار کم است',

        EnterAValidEmailAddress: 'یک آدرس ایمیل معتبر وارد کنید',
        EmailDoesNotMatch: 'ایمیل مطابقت ندارد',
        FreeEmailAccountsAreNotAllowed: 'حساب های ایمیل رایگان مجاز نیستند',
        YouMustConfirmThisEmail: 'شما باید این ایمیل را تایید کنید',

        SelectTheMinimumRequiredQuantity: 'حداقل تعداد مورد نیاز را انتخاب کنید',
        MaximumRequiredQuantityIsAllowed: 'حداکثر تعداد مورد نیاز مجاز است',

        TheInputShouldNotBeLessThanTheMinimumValue: 'ورودی نباید کمتر از حداقل مقدار باشد:',
        TheInputShouldNotExceedTheMaximumValue: 'ورودی نباید از حداکثر مقدار بیشتر باشد:',
        MaximumDigitLimitIsAllowed: 'حداکثر رقم مجاز است',

        TheDateShouldNotBeInThePast: 'تاریخ نباید در گذشته باشد.',
        ThisDateIsNotAvailable: 'این تاریخ در دسترس نیست.',
        ThisDateIsInvalidTheDateFormatIs: 'این تاریخ معتبر نیست. قالب تاریخ {yyyy/mm/dd} است',
        ThisDateIsInvalidEnterAValidDate: 'این تاریخ معتبر نیست. یک تاریخ معتبر وارد کنید.',
        YouMustBeOverYearsOldToSubmitThisForm: 'برای ارسال این فرم باید بیش از {minAge} سال سن داشته باشید.',

        InvalidFileExtensionOnlyExtensionsAreAllowed: 'پسوند {file} نامعتبر است. فقط {extensions} مجاز است.',
        FileIsTooLargeTheMaximumFileSizeIsSizeLimit: '{file} خیلی بزرگ است، حداکثر اندازه فایل {sizeLimit} است.',
        FileIsTooSmallTheMinimumFileSizeIsMinSizeLimit: '{file} خیلی کوچک است، حداقل اندازه فایل {minSizeLimit} است.',
        FileIsEmptyPleaseSelectFilesAgainWithoutIt: '{file} خالی است، لطفاً دوباره فایل‌ها را بدون آن انتخاب کنید.',
        FileUploadFailedPleaseDeleteItAndUploadTheFileAgain: 'آپلود فایل انجام نشد، لطفا آن را حذف کنید و دوباره فایل را آپلود کنید.',
        FilesAreCurrentlyUploadingIfYouLeaveNowTheUploadWillBeCanceled: 'فایل ها در حال آپلود هستند، اگر اکنون از آنجا خارج شوید، آپلود لغو می شود.',
        OnlyFileLimitFileUploadsAreAllowed: 'فقط آپلود فایل {fileLimit} مجاز است.',
        DragAndDropFilesHere: 'فایل ها را در اینجا بکشید و رها کنید',
        MaximumFileSize: 'حداکثر حجم فایل',
        SelectAFile: 'یک فایل را انتخاب کنید',

        AtLeastOneFieldIsRequiredMatrix: 'حداقل یک فیلد مورد نیاز است.',
        EachRowIsRequired: 'هر ردیف مورد نیاز است.',
        EachCellIsRequired: 'هر سلول مورد نیاز است.',

        SelectedTime: 'زمان انتخاب شده',
        PreviousTime: 'زمان سابق',
        CancelAppointment: 'لغو قرار',
        CancelSelection: 'لغو انتخاب',
        TimeSlotNotAvailable: 'بازه زمانی موجود نیست',
        TimeOnDateIsTakenPleaseSelectAnotherTimeSlot: '{time} در {date} گرفته شده است. لطفا یک بازه زمانی دیگر انتخاب کنید.',

        InvalidCreditCardNumber: 'شماره کارت اعتباری نامعتبر است.',
        InvalidCVCNumber: 'شماره CVC نامعتبر است.',
        InvalidExpirationDate: 'تاریخ انقضا نامعتبر است.',
        PleaseFillInCreditCardDetails: 'لطفا مشخصات کارت اعتباری را پر کنید.',
        PleaseSelectAtLeastOneItem: 'لطفا حداقل یک کالا را برگزینید.',
        PleaseEnterNumericValuesForTheAmount: 'لطفا مقادیر عددی را برای مبلغ کمک وارد کنید.',
        PleaseEnterAWholeNumber: 'لطفا یک عدد کامل وارد کنید',
        TheMinimumAmountIsMinAmountCurrency: 'حداقل مقدار {minAmount} {currency} است',
        ThereIsNotEnoughBalanceForTheCurrentSelection: 'موجودی کافی برای انتخاب فعلی وجود ندارد',
        ThereIsNotEnoughBalanceForTheCurrentSelectionCountRemaining: 'موجودی کافی برای انتخاب فعلی موجود نیست ({count} مورد باقی مانده است)',
        SoldOut: 'فروخته شده',
        Sold: 'فروخته شد',
        SelectedAsSold: 'انتخاب فروخته شد',
        CountRemaining: '({count} مورد باقی مانده است)',

        PleaseWait: 'لطفا صبر کنید...',
        AreYouSureYouWantToClearTheForm: 'آیا مطمئن هستید که می خواهید فرم را پاک کنید؟',

        YourScoreMustBeLessThanOrEqualTo: 'امتیاز شما باید کمتر یا مساوی باشد',
        TotalScoreMustBeLessThanOrEqualTo: 'کل امتیاز فقط باید کمتر یا مساوی باشد',

        ViewAll: 'مشاهده همه',
        From: 'از',

        CheckAndSubmit: 'بررسی و ارسال کنید',
        ReturnToForm: 'بازگشت به فرم',

        AllFieldsMarkedWithAreMandatoryAndMustBeFilled: 'تمام فیلدهایی که با * مشخص شده اند الزامی هستند و باید پر شوند.',
        FieldHasError: 'فیلد خطا دارد',
        Error: 'خطا',

        ThereAreCountErrorsOnThisPagePleaseCorrectThemBeforeProceeding: 'در این صفحه {count} خطا وجود دارد. لطفا قبل از حرکت آنها را اصلاح کنید.',
        ThereIsAnErrorOfCountOnThisPagePleaseCorrectItBeforeProceeding: 'خطای {count} در این صفحه وجود دارد. لطفا قبل از ادامه آن را اصلاح کنید.',
        WellDoneAllErrorsHaveBeenResolved: 'آفرین! تمام خطاها رفع شده است.',
    }


    const initialInputValuesArray = Object.entries(initialInputValues).map(([name, value]) => ({ name, value }));



    const [inputValues, setInputValues] = useState(initialInputValuesArray);


    const handleReset = () => {
        setInputValues(initialInputValuesArray);
    };

    return (
        <InputValuesContext.Provider value={{ inputValues, setInputValues, handleReset }}>
            {children}
        </InputValuesContext.Provider>
    );
};
