import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../Component/Navbar'
import { Grid, Typography } from '@mui/material';
import HeaderSetting from '../Component/HeaderSetting';
import SettingDrawer from '../Component/SettingDrawer';
import { ArrowEmailSetting, AutoEmail, DigestEmail, EmailListSetting, EmailNotofication, EmailSettingPage } from '../Component/Icons';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SenderNotificationEmailContext } from '../Context/SenderNotificationEmailContext';
import { Calculate, CloseSetting, ConditionArrow, ConditionPage, ConditionSetting, DeleteConditionSetting, DisableConditionSetting, Hide, Require, Show, TickSetting } from '../Component/Icons'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { EmailContext } from '../Context/EmailContext';
import { NotificationEmailContext } from '../Context/NotificationEmailContext';


const EmailSetting = () => {



    let combineEmail = []


    const { selectValues, setSelectValues, notificationEmails, setNotificationEmails } = useContext(SenderNotificationEmailContext);
    const { emails , addEmails } = useContext(EmailContext);
    const {notificationEmailPayload ,  setNotificationEmailPayload } = useContext(NotificationEmailContext);




    console.log(notificationEmails)

    const [show, setShow] = useState(false)
    const [hoveredOptionIndex, setHoveredOptionIndex] = useState(null);
    const [hoveredOptionListIndex, setHoveredOptionListIndex] = useState(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [openIndex, setOpenIndex] = useState(null);



    const CustomToast = ({ message, state }) => {
        return (
            <Grid display="flex" alignItems="start" justifyContent="end" gap="5px" style={{
                backgroundColor: 'white',
                color: state === 'success' ? 'green' : 'red',
                fontSize: '16px',
                padding: '15px 10px',
                fontWeight: 900,
                width: '100%',
                height: '100%'
            }}>
                {state === 'success' ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
                {message}
            </Grid>
        );
    };


    const handleopenOption = () => {
        setShow(true)
    }




    const handleMouseEnter = (index) => setHoveredOptionIndex(index);
    const handleMouseLeave = () => setHoveredOptionIndex(null);


    const handleListMouseEnter = (index) => setHoveredOptionListIndex(index);
    const handleListMouseLeave = () => setHoveredOptionListIndex(null);


    const handleOpenOption = (index) => {
        setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const handleCloseOption = () => {
        setShow(false)
    }


    const handleDeleteCondition = (index) => {

        const newList = notificationEmails.filter((_, i) => i !== index);
        setNotificationEmails(newList);
        toast(<CustomToast message={'ایمیل با موفقیت حذف شد'} state={'success'} />)

    };

    const EmailOptions = [
        { id: 1, link: '/Setting/EmailSetting/NotificationEmail', borderColor: 'rgba(255,123,28,1)', image: <EmailNotofication />, title: 'ایمیل اطلاع رسانی', subTitle: 'زمانی که شخصی فرم شما را پر می کند، ایمیلی دریافت کنید' },
        { id: 2, link: '/Setting/EmailSetting/AutoresponderEmail', borderColor: 'rgba(127,202,0,1)', image: <AutoEmail />, title: 'ایمیل پاسخگوی خودکار', subTitle: 'برای شخصی که فرم شما را پر می کند ایمیل بفرستید' },
        { id: 3, borderColor: 'rgba(156,77,211,1)', image: <DigestEmail />, title: 'ایمیل خلاصه', subTitle: 'برای ارسال‌های جدید ایمیل‌های خلاصه دریافت کنید' },
    ]


    useEffect(() => {

        return () => {
            combineEmail.push(notificationEmails)
            combineEmail.push(emails)
            combineEmail.push(notificationEmailPayload)
            console.log(combineEmail)
        };
    }, []);


    useEffect(()=>{
        combineEmail = []
    } , [])



    return (
        <>
            <Grid>
                <Grid display={{ xs: 'none', xxs: 'block' }}>
                    <Navbar active={2} state={true} />
                </Grid>
                <Grid display={{ xs: 'block', xxs: 'none' }} >
                    <HeaderSetting title={'ایمیل ها'} subTitle={'ارسال خودکار و اعلان ها'} flag={show} link={'/Setting'} />
                </Grid>
                <Grid mt={{ xs: '0px', xxs: '119px' }} position={'relative'} display={'flex'} alignItems={'flex-start'}>
                    <Grid display={{ xs: 'none', xxs: 'block' }}>
                        <SettingDrawer isActive={2} flag={false} />
                    </Grid>
                    <Grid className='main' pl={{ xs: '10px', xxs: '20px' }} pr={{ xs: '10px', xxs: '60px' }} mt={{ xs: '0', xxs: '80px' }} position={{ xxs: 'absolute' }} left={'20px'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }}>
                        <Grid position={'relative'} pb={'20px'} className='newEmail' display={{ xs: 'none', xxs: 'flex' }} alignItems={'center'} gap={'8px'}>
                            <Grid pt={'5px'} position={'absolute'} right={{ xxs: '-7%', md: '-6%', llg: '-4%' }} onClick={handleCloseOption} style={{ cursor: 'pointer' }} display={show ? 'block' : 'none'}>
                                <ArrowForwardIosIcon fontSize='large' />
                            </Grid>
                            <>
                                <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} width={'40px'} height={'40px'} borderRadius={'4px'} bgcolor={'rgb(78, 108, 154)'}>
                                    <EmailSettingPage fill={'white'} />
                                </Grid>
                                <Grid>
                                    <Typography variant='h5' color={'black'} fontWeight={700} lineHeight={'24px'}>ایمیل جدید</Typography>
                                    <Typography variant='h6' color={'rgb(111, 118, 167)'} fontWeight={500} lineHeight={'16px'}>یک نوع ایمیل در زیر برای ایجاد ایمیل خود انتخاب کنید.</Typography>
                                </Grid>
                            </>
                        </Grid>
                        <Grid display={{ xs: 'none', xxs: show ? 'none' : 'block' }}>
                            <Grid className='AddEmail' pb={'40px'}>
                                <Grid onClick={handleopenOption} px={'10px'} width={'100%'} height={'40px'} display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'8px'} bgcolor={'rgba(0,117,227,1)'} borderRadius={'4px'} style={{ cursor: 'pointer' }}>
                                    <AddCircleIcon style={{ color: 'white' }} />
                                    <Typography variant='h6' color={'white'} fontWeight={500} lineHeight={'1.125rem'}>ایمیل اضافه کنید</Typography>
                                </Grid>
                            </Grid>
                            <Grid className='Notification' display={notificationEmails.length > 0 ? 'none' : 'flex'} flexDirection={'column'} alignItems={'center'}>
                                <Typography variant='h6' color={'rgb(92, 171, 64)'} fontWeight={700} lineHeight={'1em'}>شما هیچ ایمیل ذخیره شده ای ندارید.</Typography>
                                <Typography variant='subtitle1' color={'rgb(151, 151, 151)'} fontWeight={500}>ایمیل های اعلان و پاسخ خودکار زیبا ایجاد کنید.</Typography>
                            </Grid>
                        </Grid>
                        <Grid display={{ xs: show || notificationEmails.length > 0 ? 'none' : 'block', xxs: 'none' }}>
                            <Grid className='AddEmail' pb={'40px'}>
                                <Link to={'/Setting/Email/Options'}>
                                    <Grid px={'10px'} width={'100%'} height={'40px'} display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'8px'} bgcolor={'rgba(0,117,227,1)'} borderRadius={'4px'} style={{ cursor: 'pointer' }}>
                                        <AddCircleIcon style={{ color: 'white' }} />
                                        <Typography variant='h6' color={'white'} fontWeight={500} lineHeight={'1.125rem'}>ایمیل اضافه کنید</Typography>
                                    </Grid>
                                </Link>
                            </Grid>
                            <Grid className='Notification' display={notificationEmails.length > 0 ? 'none' : 'flex'} flexDirection={'column'} alignItems={'center'}>
                                <Typography variant='h6' color={'rgb(92, 171, 64)'} fontWeight={700} lineHeight={'1em'}>شما هیچ ایمیل ذخیره شده ای ندارید.</Typography>
                                <Typography variant='subtitle1' color={'rgb(151, 151, 151)'} fontWeight={500}>ایمیل های اعلان و پاسخ خودکار زیبا ایجاد کنید.</Typography>
                            </Grid>
                        </Grid>
                        <Grid display={show ? 'block' : 'none'}>
                            {
                                EmailOptions.map(
                                    option =>
                                        <Link to={option.link} key={option.id}>
                                            <Grid mb={'12px'} p={'20px 16px'} borderRight={`7px solid ${option.borderColor}`} display={'flex'} alignItems={'center'} borderRadius={'4px'} bgcolor={'white'} gap={'16px'}>
                                                <Grid width={'40px'} height={'40px'} borderRadius={'4px'} bgcolor={option.borderColor} display={'flex'} alignItems={'center'} justifyContent={'center'} >
                                                    {option.image}
                                                </Grid>
                                                <Grid>
                                                    <Typography color={'#0a1551'} variant='h5' fontWeight={500} lineHeight={'24px'}>{option.title}</Typography>
                                                    <Typography variant='h6' color={'rgba(108,115,168,1)'} fontWeight={500}>{option.subTitle}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                )
                            }
                        </Grid>
                        <Grid display={notificationEmails.length > 0 && !show ? 'block' : 'none'}>
                            {
                                notificationEmails.map((item, index) => {
                                    const recipientIndex = item.findIndex(condition => condition.name.includes("recipient"));
                                    const pageIndex = item.findIndex(condition => condition.name.includes("page"));
                                    return (
                                        <Grid
                                            className='ConditionsList'
                                            border={'1px solid #c8ceed'} borderRadius={'4px'} display={'flex'} alignItems={'center'} maxHeight={'68px'} mb={'16px'}
                                            onMouseEnter={() => setHoveredIndex(index)}
                                            onMouseLeave={() => setHoveredIndex(null)}
                                        >
                                            <Grid className='Nav' width={'26px'} p={'4px 0px'} bgcolor={item[pageIndex]?.value === "NotificationEmail" ? 'rgb(255, 123, 28)' : 'rgb(127, 202, 0)'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} borderRadius={'0px 4px 4px 0px'} height={'68px'}>
                                                <Typography variant='subtitle1' color={'white'}>{index + 1}</Typography>
                                            </Grid>
                                            <Grid width={'100%'} className='condition' p={{ xs: '10px 12px' }} bgcolor={'white'} borderRadius={'4px 0px 0px 4px'} height={'68px'} position={'relative'}>
                                                <Grid display={'flex'} alignItems={'center'} gap={'8px'} mb={'4px'}>
                                                    <Typography variant='h6' color={item[pageIndex]?.value === "NotificationEmail" ? 'rgb(255, 123, 28)' : 'rgb(127, 202, 0)'} fontWeight={700}>{` اطلاع رسانی ${index + 1}`}</Typography>
                                                </Grid>
                                                <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                    <Grid>
                                                        <EmailListSetting fill={item[pageIndex]?.value === "NotificationEmail" ? 'rgb(255, 123, 28)' : 'rgb(127, 202, 0)'} />
                                                    </Grid>
                                                    <Typography variant='h6' color={'#0a1551'} fontWeight={500}>{'آلتن'}</Typography>
                                                    <Grid pt={'4px'}>
                                                        <ArrowCircleLeftIcon style={{ color: '#6c73a8' }} fontSize='large' />
                                                    </Grid>
                                                    <Typography variant='h6' color={'#0a1551'}>{item[recipientIndex]?.value}</Typography>
                                                </Grid>
                                                <Grid
                                                    className='option'
                                                    style={{
                                                        position: 'absolute',
                                                        left: '10px',
                                                        top: '6px',
                                                        display: hoveredIndex === index ? 'flex' : 'none',
                                                        zIndex: 10
                                                    }}
                                                >
                                                    <Grid width={'40px'} height={'40px'} bgcolor={item[pageIndex]?.value === "NotificationEmail" ? 'rgb(255, 123, 28)' : 'rgb(127, 202, 0)'} borderRadius={'4px'} display={openIndex !== index && hoveredIndex === index ? 'flex' : 'none'} alignItems={'center'} justifyContent={'center'} onClick={() => { handleOpenOption(index) }}>
                                                        <Grid width={'80px'} height={'60px'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
                                                            onMouseEnter={() => handleListMouseEnter(index + 1)}
                                                            onMouseLeave={handleListMouseLeave}
                                                            bgcolor={hoveredOptionListIndex === index + 1 ? '#ffffff4d' : 'transparent'}
                                                            borderRadius={hoveredOptionListIndex === index + 1 ? '10px' : '0px'}
                                                            onClick={() => handleDeleteCondition(index)} style={{ cursor: 'pointer' }}>
                                                            <DeleteConditionSetting fill={'white'} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )

                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default EmailSetting