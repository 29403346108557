import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import LayoutCondition from '../Component/LayoutCondition'
import SettingDrawer from '../Component/SettingDrawer'
import HeaderSetting from '../Component/HeaderSetting'
import Navbar from '../Component/Navbar'
// import Advance from '../Component/Advance'
import { EmailNotofication } from '../Component/Icons'
import { EmailProvider } from '../Context/EmailContext'
import Advance from '../Component/Advance/Advance'

const AdvanceNotificationEmail = () => {




    return (
        <Grid bgcolor={'#f3f3fe'}>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'تنظیمات ایمیل'} subTitle={'ارسال خودکار و اعلان ها'} link={'/Setting/Email'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={2} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={2} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'اطلاع رسانی'} subTitle={'زمانی که شخصی فرم شما را پر می کند، ایمیلی دریافت کنید'} link={'/Setting/Email'} icon={<EmailNotofication fill={'white'} />} color={'rgba(255,123,28,1)'} />
                    <Grid className='main' mb={'14px'}>
                        <Box width={'100%'} height={'48px'} display={'flex'} alignItems={'center'} bgcolor={'#fafafc'} borderBottom={'1px solid #eaebf2'}>
                            <Link to={'/Setting/EmailSetting/NotificationEmail'} style={{ width: '50%', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid width={'100%'} height={'48px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant='h5' fontWeight={500} color={'black'} >ایمیل</Typography>
                                </Grid>
                            </Link>
                            <Link to={'/Setting/EmailSetting/NotificationEmail/Sender'} style={{ width: '50%', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid width={'100%'} height={'48px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant='h5' fontWeight={500} color={'black'}>گیرندگان</Typography>
                                </Grid>
                            </Link>
                            <Link to={'/Setting/EmailSetting/NotificationEmail/Advance'} style={{ width: '50%', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid width={'100%'} height={'48px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderBottom={'3px solid #5e74e6'}>
                                    <Typography variant='h5' fontWeight={500} color={'#5e74e6'}>پیشرفته</Typography>
                                </Grid>
                            </Link>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Grid>
                                <Advance flag={true} />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AdvanceNotificationEmail