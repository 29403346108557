import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Grid, Typography  } from "@mui/material";
import {UploadSetting } from "./Icons";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import FolderIcon from '@mui/icons-material/Folder';
import { FileUploadContext } from '../Context/FileUploadContext';

const DrogAndDropFile = () => {


    const { uploadedFile, setUploadedFile } = useContext(FileUploadContext);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileInputValue, setFileInputValue] = useState('');
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState(null);
    const [upload, setUpload] = useState(false)

    const containerRef = useRef(null);





    const CustomToast = ({ closeToast, message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'red',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <ErrorIcon fontSize={'large'} />
                    {message}
                </Grid>

        );
    };


    const isZipOrPdf = (file) => {
        return (
            file.type === 'application/zip' ||
            file.type === 'application/pdf' ||
            file.type.startsWith('video/')
        );
    };

    const handleFileSelectChange = (e) => {
        const file = e.target.files[0];

        if (file && isZipOrPdf(file)) {
            setSelectedFileName(file.name);
            setFileInputValue(e.target.value);
            setSelectedFile(file);
            setUploadedFile(file);
            setUpload(true)
            toast(<CustomToast message="آپلود فایل با موفقیت انجام شد" state={'success'} />);
        } else {
            toast(<CustomToast message="لطفاً یک فایل معتبر (زیپ، pdf یا ویدیو) انتخاب کنید." state={'error'} />);

        }
    };


    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();

        const file = e.dataTransfer.files[0];

        if (file && isZipOrPdf(file)) {
            setSelectedFileName(file.name);
            setUploadedFile(file);
        } else {
            alert('Please drop a valid zip or pdf file.');
        }
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };



    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    return (
        <Grid mt={'20px'} mb={'12px'}
            bgcolor={'white'} ref={containerRef}
            border={'2px solid transparent'} borderRadius={'4px'}
            onClick={handleClickOutside}
            height={'120px'}
        >
            <Button
                component="label"
                htmlFor="fileInput"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{ width: '100%', height: '132px', marginBottom: '8px', border: '2px dashed #d9cec1', borderRadius: '4px', backgroundColor: '#fbfcff' }}
            >
                <Grid
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    gap={'8px'}
                >
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileSelectChange}
                        accept=".zip, .pdf"
                    />
                    {
                        upload ?
                            <FolderIcon fontSize='large' style={{ color: 'green', width: '50px', height: '50px' }} />
                            :
                            <>
                                <Grid p={'12px 10px'} bgcolor={'rgba(0,117,227,1)'} mb={'6px'} borderRadius={'4px'} display={'flex'} alignItems={'center'} gap={'8px'}>
                                    <UploadSetting fill={'white'} />
                                    <Typography variant={'h6'} color={'white'} fontWeight={500}>
                                    آپلود فایل
                                    </Typography>
                                </Grid>
                                <Typography variant={'h6'} color={'#9e9fb7'} fontWeight={500}>
                                    یا اینجا بکشید و رها کنید
                                </Typography>
                            </>
                    }
                    {selectedFileName && (
                        <Typography variant={'subtitle1'} color={'#57647e'} style={{ fontFamily: 'sans-serif' }}>
                            {selectedFileName}
                        </Typography>
                    )}
                </Grid>
            </Button>
        </Grid>

    )
}

export default DrogAndDropFile