import React, { createContext, useState } from 'react';

const SenderNotificationEmailContext = createContext();

const SenderNotificationEmailProvider = ({ children }) => {

    const senderOption = [
        { value: 'Altyn', label: 'Altyn ' },
        { value: 'لطفا انتخاب کنید ', label: 'لطفا انتخاب کنید' },
    ];

    const replyOption = [
        { value: 'لطفا انتخاب کنید ', label: 'لطفا انتخاب کنید' },
    ];

    const [selectValues, setSelectValues] = useState([
        { id: 1, name: 'SenderName', value: senderOption[0].value },
        { id: 2, name: 'ReplyEmail', value: replyOption[0].value },
        { id: 3, name: 'recipient', value: '' },
        { id: 4, name: 'page', value: 'NotificationEmail' },
        { id: 3, name: 'recipientAutoResponder', value: null },
        { id: 3, name: 'page', value: 'AutoResponderEmailSender' },
    ]);


    const [notificationEmails, setNotificationEmails] = useState([]);

    return (
        <SenderNotificationEmailContext.Provider value={{ selectValues, setSelectValues, notificationEmails, setNotificationEmails }}>
            {children}
        </SenderNotificationEmailContext.Provider>
    );
};

export { SenderNotificationEmailContext, SenderNotificationEmailProvider };
