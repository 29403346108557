import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const MediumIcon = ({ src, dir, rowFlag }) => {
  let height = rowFlag ? "400px" : "200px";

  const [title, setTitle] = useState("متشکریم");
  const [subTitle, setSubTitle] = useState("ارسالی شما دریافت شد");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const secondGridRef = useRef(null);
  const [firstGridHeight, setFirstGridHeight] = useState(height);

  useEffect(() => {
    if (secondGridRef.current) {
      const secondGridHeight = secondGridRef.current.clientHeight;
      setFirstGridHeight(
        rowFlag
          ? `calc(400px + ${secondGridHeight}px)`
          : `calc(200px + ${secondGridHeight}px)`
      );
    }
  }, [rowFlag, title, subTitle]);

  const getFlexDirection = () => {
    if (windowWidth < 600) {
      return "column";
    }
    return dir;
  };

  return (
    <Grid
      style={{
        display: "flex",
        // flexDirection: dir,
        flexDirection: getFlexDirection(),
        alignItems: "center",
        justifyContent: "center",
        marginTop: "30px",
        backgroundColor: "white",
        width: "100%",
      }}
    >
      <Grid
        style={{
          width: rowFlag ? "50%" : "100%",
          height: firstGridHeight,
          backgroundColor: "#e8f6ed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={src} alt="" style={{ width: "150px", height: "150px" }} />
      </Grid>
      <Grid
        ref={secondGridRef}
        style={{
          width: rowFlag ? "50%" : "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "70px 0px",
        }}
      >
        <div
          style={{
            fontSize: "36px",
            color: "#2C3345",
            fontWeight: 600,
            marginBottom: "16px",
          }}
        >
          {title}
        </div>
        <div
          style={{
            fontSize: "14px",
            color: "#2C3345",
            fontWeight: 500,
            opacity: 0.6,
          }}
        >
          {subTitle}
        </div>
      </Grid>
    </Grid>
  );
};

export default MediumIcon;
