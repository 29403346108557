import { Grid } from '@mui/material'
import React from 'react'
import Navbar from '../Component/Navbar'
import HeaderSetting from '../Component/HeaderSetting'
import SettingDrawer from '../Component/SettingDrawer'
import LayoutCondition from '../Component/LayoutCondition'
import { PreviewEnable } from '../Component/Icons'
import Condition from '../Component/Condition'
import { useComponentTitleContext } from '../Context/ComponentTitleContext';


const EnableAndRequire = () => {


    const {titles } = useComponentTitleContext();


    return (
        <Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'نمایش/پنهان کردن فیلد'} subTitle={'قابلیت مشاهده فیلدهای فرم را تغییر دهید'} link={'/Setting/Conditional'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={3} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={3} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'فعال/ضروری/پنهان‌سازی فیلد'} subTitle={'یک فیلد را الزام یا غیرفعال کنید یا یک ماسک تنظیم کنید'} link={'/Setting/Conditional'} icon={<PreviewEnable fill={'white'} />} color={'#09f'}/>
                    <Condition options={titles} flag={true} color={'#3498db'} fieldFlag={true} value={'فیلد'} placeholder={"فیلد را انتخاب کنید"}/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EnableAndRequire