import { Grid, Typography } from '@mui/material'
import React from 'react'
import FullName from './FullName'
import ShortText from './ShortText'
import Phone from './Phone'
import Email from './Email'
import Address from './Address'
import LongText from './LongText'
import DropDown from './DropDown'

const BusinessRegistrationForm = () => {



    const businessDropDownData = [
        'فروشگاه/کافه',
        'وام دادن',
        'فروشگاه',
        'اجاره ها',
        'بقیه را لطفا در زیر مشخص کنید.',
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Typography variant='h2' fontWeight={700} color={'#000000'} mb={'12px'}>
                کسب و کار خود را ثبت کنید</Typography>
            <Typography variant='h5' fontWeight={500} color={'#57647e'} mb={'40px'}>
                لطفا تمام مشخصات مورد نیاز برای ثبت کسب و کار خود را با ما ارائه دهید</Typography>
            <Grid width={'100%'} height={'1px'} bgcolor={'#57647e'} mb={'40px'}></Grid>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'صاحب کسب و کار'} />
                <ShortText header={'نام کسب و کار'} />
                <Phone header={'شماره تماس'} />
                <Email header={'ایمیل'} />
                <Address />
                <DropDown options={businessDropDownData} header={'نوع کسب و کار'} />
                <LongText header={'پیام'} />
            </Grid>
        </Grid>
    )
}

export default BusinessRegistrationForm