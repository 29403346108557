import React, { useEffect, useRef, useState } from 'react';
import DraggableComponent from './DraggableComponent';
import Heading from './Heading';
import FullName from './FullName';
import Email from './Email';
import Address from './Address';
import Phone from './Phone';
import DatePicker from './DatePicker';
import ShortText from './ShortText';
import LongText from './LongText';
import Number from './Number';
import FileUpload from './FileUpload';
import Time from './Time';
import Submit from './Submit';
import Spinner from './Spinner';
import Image from './Image';
import StarRating from './StarRating';
import ScaleRating from './ScaleRating';
import MultiChoice from './MultiChoice';
import SingleChoice from './SingleChoice';
import Signature from './Signature';
import DropDown from './DropDown';
import { Grid, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Paragraph from './Paragraph/Editor';
import Appointment from './Appointment';
import ProductList from './ProductList';
import Captcha from './Captcha';
import FillInTheBlank from './FillInTheBlank';
import Table from './Table';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from './Divider';
import Section from './Section';
import { useDroppable } from '@dnd-kit/core';
import { Droppable } from "./Droppable";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';
import { useFormContext } from '../Context/Context';
import useFormStore from '../zustand/useFormStore';
import FormItem from './FormItem';

const PublishFormItem = ({ active, data }) => {


    return (
        <>
            <FormItem active={active} />
        </>

    )
}

export default PublishFormItem