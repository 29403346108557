import React, { createContext, useContext } from 'react';
import useLocalStorage from 'use-local-storage';

const RemovedItemsContext = createContext();

export const RemovedItemsProvider = ({ children }) => {

    const [removedItems, setRemovedItems] = useLocalStorage('RemovedItems', []);

    const saveRemoved = (data) => {
        setRemovedItems(prevData => [...prevData, ...data]);
    };

    return (
        <RemovedItemsContext.Provider value={{ removedItems, setRemovedItems , saveRemoved }}>
            {children}
        </RemovedItemsContext.Provider>
    );
};

export const useRemovedItems = () => {
    return useContext(RemovedItemsContext);
};

