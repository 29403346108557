import React, { createContext, useState } from 'react';

const ThankYouMessageContext = createContext();

const ThankYouMessageProvider = ({ children }) => {
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);

  return (
    <ThankYouMessageContext.Provider value={{ showThankYouMessage, setShowThankYouMessage }}>
      {children}
    </ThankYouMessageContext.Provider>
  );
};

export { ThankYouMessageContext, ThankYouMessageProvider };
