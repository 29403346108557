import React, { createContext, useContext, useState } from 'react';

const ErrorContext = createContext();

export const useErrorContext = () => {
    return useContext(ErrorContext);
};

export const ErrorProvider = ({ children }) => {
    const [mistake, setMistake] = useState(false);

    return (
        <ErrorContext.Provider value={{ mistake, setMistake }}>
            {children}
        </ErrorContext.Provider>
    );
};
