import React from 'react'
import Navbar from '../Component/Navbar'
import { Grid, Typography } from '@mui/material';
import SettingDrawer from '../Component/SettingDrawer';
import FormSetting from './FormSetting';


const Setting = () => {
    return (
        <Grid bgcolor={'#f1f1f4'}>
            <Navbar active={2} state={true}/>
            <Grid display={{xs:'none' , xxs:'block'}}>
                {/* <SettingDrawer isActive={1} mt={'48px'}/> */}
                <FormSetting />
            </Grid>
            <Grid display={{xs:'block' , xxs:'none'}}>
                <SettingDrawer mt={'118px'}/>
            </Grid>
        </Grid>
    )
}

export default Setting