import React, { createContext, useState } from 'react';

export const AutoResponderContext = createContext();

export const AutoResponderProvider = ({ children }) => {
    const [AutoResponderPayload, setAutoResponderPayload] = useState(null);

    return (
        <AutoResponderContext.Provider value={{ AutoResponderPayload, setAutoResponderPayload }}>
            {children}
        </AutoResponderContext.Provider>
    );
};