
import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



const Preview = () => {

    const [components, setComponents] = useState([]);
    const refs = useRef([]);


    const navigate = useNavigate();



    const handleNavigate = ()=>{
        navigate('/')
    }


    useEffect(() => {
        // Function to fetch data from the server
        const fetchData = async () => {
            try {
                // Make GET request to fetch components array from the server
                const response = await axios.get('get-api-endpoint');
                // Update components state with the data received from the server
                setComponents(response.data.components);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Optionally, handle errors here
            }
        };

        // Call the fetchData function when the component mounts
        fetchData();

        // Cleanup function to cancel any pending requests if the component unmounts
        return () => {
            // Cancel any pending requests or cleanup tasks if needed
        };
    }, []);


    return (
        <>
            <Grid width={{ xs: '90%', xxs: '90%' }} maxWidth={'752px'} bgcolor={'#fff'} mx={'auto'} borderRadius={'3px'}
                boxShadow={'0 4px 4px rgba(87,100,126,.21)'} py={'40px'} mt={'16px'} px={'16px'} mb={'40px'}>
                <Grid style={{ cursor: 'move' }}>
                    {components.map((componentData, index) => {
                        const Com = components[componentData.title];
                        if (!refs.current[index]) {
                            refs.current[index] = React.createRef();
                        }
                        return (
                            <Grid key={index}>
                                <Grid style={{ position: 'relative' }} ref={refs.current[index]}>
                                    {React.createElement(Com)}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Button variant='contained' color='primary' style={{ width: '15%', padding: '15px 0px' }} onClick={handleNavigate}>
                    <Typography variant='h4' fontWeight={700}>ویرایش </Typography>
                </Button>
            </Grid>
        </>
    )
}

export default Preview