

import create from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useFormStore = create(
    persist(
        (set) => ({
            drawerData: [],
            deletedIndexes: [],

            handleDrawerData: (data) => set({ drawerData: Array.isArray(data) ? data : [] }),

            handleDelete: (id) => set((state) => ({
                deletedIndexes: [...state.deletedIndexes, id],
                drawerData: state.drawerData.filter(item => item.id !== id),
            })),

            updateComponentStatesById: (id, newStates) =>
                set((state) => ({
                    drawerData: state.drawerData.map((item) =>
                        item.id === id ? { ...item, states: newStates } : item
                    ),
                })),

            addComponent: (component) => set((state) => ({ drawerData: [...state.drawerData, component] })),
            updateDrawerData: (data) => set({ drawerData: data }),
            setDrawerData: (data) => set({ drawerData: data })
        }),
        {
            name: 'formDrawerData',
            storage: createJSONStorage(() => sessionStorage),
            onRehydrate: (state) => {
                // Update drawerData after rehydration
                state.updateDrawerData(state.drawerData);
            },
        }
    )
);

export default useFormStore;
