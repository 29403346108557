import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const Spinner = ({ id }) => {


    const { drawerData } = useFormContext();


    const location = useLocation();
    const currentPage = location.pathname;



    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);
    const existingComponentIndex = drawerData.findIndex(component => component.id === id);

    const [flag, setFlag] = useState(false)
    const [spinnerValue, setSpinnerValue] = useState('یک عنوان وارد کنید');
    const [spinnerInputValue, setSpinnerInputValue] = useState(0);
    const [subSpinnerValue, setSubSpinnerValue] = useState('');
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [condition, setCondition] = useState(false)




    const containerRef = useRef(null);


    const fillInputValue = () => {
        drawerData[existingComponentIndex].state.spinnerValue = spinnerValue
        drawerData[existingComponentIndex].state.mainSpinnerInputValue = spinnerInputValue
        drawerData[existingComponentIndex].state.subSpinnerValue = subSpinnerValue
        drawerData[existingComponentIndex].state.readOnly = readOnly
    }

    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly


    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'Spinner') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    const handleShortTextChange = (event) => {
        setSpinnerValue(event.target.value);
    };

    const handleShortTextInputChange = (event) => {
        setSpinnerInputValue(event.target.value);
    };

    const handleSubShortTextChange = (event) => {
        setSubSpinnerValue(event.target.value);
    };



    const handleHeadingFocus = (event) => {
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };





    let theme = useTheme()

    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));

    let SubHeadingInputFontSize = isUpXxl ? '14px' : '12px'


    let width = isUpXxl ? '310px' : '90%'

    let HeadingColor = theme.palette.blue[700]


    const handleIncrement = () => {
        if (!condition) {
            setSpinnerInputValue((prevValue) => Number(prevValue) + 1);
        }

    }

    const handleDecrement = () => {

        if (!condition) {
            if (spinnerInputValue === 0) {
                setFlag(true)
                setSpinnerInputValue(0)
            } else {
                setSpinnerInputValue((prevValue) => Math.max(Number(prevValue) - 1, 0));
            }
        }
    }



    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, spinnerInputValue, spinnerValue, subSpinnerValue]);


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])

    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '24px 36px'} borderRadius={'3px'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}>
            <Grid className={'HeadingInput'} pb={'14px'}>
                <input className="customHead-input" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                    value={spinnerValue}
                    onChange={handleShortTextChange}
                    onClick={handleClickOutside}
                    onFocus={handleHeadingFocus}
                />
            </Grid>
            <Grid display={'flex'} alignItems={'flex-start'} height={'40px'} border={'1px solid #b8bdc9'} width={width}
                mb={'16px'}>
                <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'3px 0px 0px 3px'}
                    bgcolor={'#f4f4f7'} width={'36px'} height={'38px'} onClick={currentPage === '/build' || currentPage === "/build/CardForm" || condition ? null : handleDecrement} style={{ cursor: 'pointer' }}>
                    <Typography variant={'h5'} color={'rgb(44, 51, 69)'}>-</Typography>
                </Grid>
                <input
                    style={{
                        border: '1px solid transparent',
                        borderRadius: '3px',
                        padding: '10px',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '238px',
                        height: '38px',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: '38px',
                        color: HeadingColor,
                        marginBottom: '8px',
                        textAlign: 'center',
                    }}
                    type='number'
                    readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                    value={spinnerInputValue === 0 ? null : spinnerInputValue}
                    onChange={handleShortTextInputChange}
                />
                <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'0px 3px 3px 0px'}
                    bgcolor={'#f4f4f7'} width={'36px'} height={'38px'} onClick={currentPage === '/build' || currentPage === "/build/CardForm" || condition ? null : handleIncrement} style={{ cursor: 'pointer' }}>
                    <Typography variant={'h5'} color={'rgb(44, 51, 69)'}>+</Typography>
                </Grid>
            </Grid>
            <Grid>
                {isSubHeaderVisible && (
                    <Grid className={'subHeader'} pb={'14px'}>
                        <input
                            className=""
                            placeholder={'یک SubLabel وارد کنید'}
                            style={{
                                border: 'none',
                                outline: 'none',
                                userSelect: 'text',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                width: '100%',
                                fontSize: SubHeadingInputFontSize,
                                fontWeight: 500,
                                lineHeight: 1.6,
                                color: '#57647e',
                            }}
                            readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                            value={subSpinnerValue}
                            onChange={handleSubShortTextChange}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
export default Spinner
