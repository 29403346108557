import { Grid } from '@mui/material';
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

const StateSelect = ({ title, placeholder }) => {


    const location = useLocation();
    const currentPage = location.pathname;

    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);
    const [data, setData] = useState([])


    const FullName = [
        { id: 1, OptionValue: 'Is Empty' },
        { id: 1, OptionValue: 'Is Filled' },
    ]


    return (
        <Grid width={currentPage === '/Setting/EmailSetting/NotificationEmail' || currentPage === '/Setting/EmailSetting/AutoresponderEmail' ? { xs: '100%' } : { xs: '100%', xxs: '70%' }}>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                name="color"
                options={title}
                placeholder={placeholder}
            />
        </Grid>

    )
}

export default StateSelect