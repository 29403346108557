import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Add, Remove, Clear } from "@mui/icons-material";
import { Division, Function } from "../Icons";
import { SelectValuesContext } from "../../Context/SelectValuesContext";
import Tooltip from '@mui/material/Tooltip';


const functions = [
  'min', 'max', 'pow', 'sqrt', 'avg', 'ln2', 'ln10', 'log2E', 'log10E', 'sqrt1/2', 'sqrt2', 'floor', 'round', 'abs', 'random', 'ceil', 'exp', 'sin', 'cos', 'asin', 'acos', 'tan', 'atan',
  'secToMin', 'secToHours', 'secToDays', 'secToWeeks', 'secToMonths', 'minToHours', 'minToDays', 'minToWeeks', 'minToMonths', 'hoursToDays', 'hoursToWeeks', 'hoursToMonths', 'daysToWeeks', 'daysToMonths'
];

const FunctionSelectorModal = ({ open, handleClose, onSelect }) => {

  const [searchTerm, setSearchTerm] = useState('');

  const filteredFunctions = functions.filter(func =>
    func.toLowerCase().includes(searchTerm.toLowerCase())
  );



  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="function-selector-title">
      <Box width={{ xs: '300px', xxs: '360px', md: '720px' }} sx={{ bgcolor: 'background.paper', padding: 2, margin: 'auto', mt: '2vh', borderRadius: 1, boxShadow: 3 }}>
        <Typography id="function-selector-title" variant="h6" component="h2" gutterBottom>
          یک تابع را انتخاب کنید
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="جستجو..."
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <Grid container spacing={1} style={{ height: '600px', overflowY: 'scroll' }}>
          {filteredFunctions.map((func) => (
            <Grid item xs={6} md={4} key={func}>
              <Button
                sx={{
                  fontSize: { xs: '12px', md: '14px' }, fontWeight: 700,
                  '&:hover': {
                    variant: 'contained',
                    bgcolor: 'primary.main', // Change to the desired hover color
                    color: 'white',
                    borderColor: 'primary.main',
                  },
                }}
                fullWidth
                variant="outlined"
                onClick={() => {
                  onSelect(func);
                  handleClose();
                }}
              >
                {func}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Modal>
  )


};



const Calculator = () => {

  const { selectValues, setSelectValues } = useContext(SelectValuesContext);

  const [display, setDisplay] = useState("");
  const [result, setResult] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const updateCalculateValue = (newValue) => {
    setSelectValues((prevValues) =>
      prevValues.map((item) =>
        item.name === "CalculateValue" ? { ...item, value: newValue } : item
      )
    );
  };



  const handleButtonClick = (value) => {
    if (value === '=') {
      try {
        // Check for conversion functions with expressions
        if (display.includes('secToMin')) {
          const match = display.match(/secToMin\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const minutesValue = evaluatedExpression / 60;
            setResult(minutesValue);
            updateCalculateValue(minutesValue);
            setDisplay('');
          }
        } else if (display.includes('secToHours')) {
          const match = display.match(/secToHours\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const hoursValue = evaluatedExpression / 3600;
            setResult(hoursValue);
            updateCalculateValue(hoursValue);
          }
        } else if (display.includes('secToDays')) {
          const match = display.match(/secToDays\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const daysValue = evaluatedExpression / 86400;
            setResult(daysValue);
            updateCalculateValue(daysValue);
          }
        } else if (display.includes('secToWeeks')) {
          const match = display.match(/secToWeeks\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const weeksValue = evaluatedExpression / (86400 * 7);
            setResult(weeksValue);
            updateCalculateValue(weeksValue);
          }
        } else if (display.includes('secToMonths')) {
          const match = display.match(/secToMonths\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const monthsValue = evaluatedExpression / 2628000;
            setResult(monthsValue);
            updateCalculateValue(monthsValue);
          }
        } else if (display.includes('minToHours')) {
          const match = display.match(/minToHours\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const hoursValue = evaluatedExpression / 60;
            setResult(hoursValue);
            updateCalculateValue(hoursValue);
          }
        } else if (display.includes('minToDays')) {
          const match = display.match(/minToDays\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const daysValue = evaluatedExpression / 1440;
            setResult(daysValue);
            updateCalculateValue(daysValue);
          }
        } else if (display.includes('minToWeeks')) {
          const match = display.match(/minToWeeks\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const weeksValue = evaluatedExpression / 10080;
            setResult(weeksValue);
            updateCalculateValue(weeksValue);
          }
        } else if (display.includes('minToMonths')) {
          const match = display.match(/minToMonths\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const monthsValue = evaluatedExpression / 43800;
            setResult(monthsValue);
            updateCalculateValue(monthsValue);
          }
        } else if (display.includes('hoursToDays')) {
          const match = display.match(/hoursToDays\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const daysValue = evaluatedExpression / 24;
            setResult(daysValue);
            updateCalculateValue(daysValue);
          }
        } else if (display.includes('hoursToWeeks')) {
          const match = display.match(/hoursToWeeks\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const weeksValue = evaluatedExpression / 168;
            setResult(weeksValue);
            updateCalculateValue(weeksValue);
          }
        } else if (display.includes('hoursToMonths')) {
          const match = display.match(/hoursToMonths\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const monthsValue = evaluatedExpression / 730;
            setResult(monthsValue);
            updateCalculateValue(monthsValue);

          }
        } else if (display.includes('daysToWeeks')) {

          const match = display.match(/daysToWeeks\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const weeksValue = evaluatedExpression / 7;
            setResult(weeksValue);
            updateCalculateValue(weeksValue);

          }

        } else if (display.includes('daysToMonths')) {

          const match = display.match(/daysToMonths\(([^)]+)\)/);
          if (match) {
            const expression = match[1];
            const evaluatedExpression = eval(expression);
            const monthsValue = evaluatedExpression / 30;
            setResult(monthsValue);
            updateCalculateValue(monthsValue);

          }

        } else {
          const sanitizedDisplay = display
            .replace(/min\(/g, 'Math.min(')
            .replace(/max\(/g, 'Math.max(')
            .replace(/pow\(/g, 'Math.pow(')
            .replace(/sqrt\(/g, 'Math.sqrt(')
            .replace(/avg\(/g, 'average(')
            .replace(/ln2/g, 'Math.log(2)')
            .replace(/ln10/g, 'Math.log(10)')
            .replace(/log2E/g, 'Math.log2(Math.E)')
            .replace(/log10E/g, 'Math.log10(Math.E)')
            .replace(/sqrt1\/2/g, 'Math.sqrt(1/2)')
            .replace(/sqrt2/g, 'Math.sqrt(2)')
            .replace(/floor\(/g, 'Math.floor(')
            .replace(/round\(/g, 'Math.round(')
            .replace(/abs\(/g, 'Math.abs(')
            .replace(/ceil\(/g, 'Math.ceil(')
            .replace(/exp\(/g, 'Math.exp(')
            .replace(/sin\(/g, 'Math.sin(')
            .replace(/cos\(/g, 'Math.cos(')
            .replace(/asin\(/g, 'Math.asin(')
            .replace(/acos\(/g, 'Math.acos(')
            .replace(/random\((\d+),\s*(\d+)\)/g, (_, min, max) => `Math.floor(Math.random() * (${max} - ${min} + 1)) + ${min}`)
            // Convert degrees to radians in sin, cos, asin, acos
            .replace(/Math.sin\(([^)]+)\)/g, (_, angle) => `Math.sin(${angle} * Math.PI / 180)`)
            .replace(/Math.cos\(([^)]+)\)/g, (_, angle) => `Math.cos(${angle} * Math.PI / 180)`)
            .replace(/Math.asin\(([^)]+)\)/g, (_, value) => `Math.asin(${value}) * 180 / Math.PI`)
            .replace(/Math.acos\(([^)]+)\)/g, (_, value) => `Math.acos(${value}) * 180 / Math.PI`);

          const average = (...numbers) => numbers.reduce((a, b) => a + b, 0) / numbers.length;


          const evalResult = eval(`(function() { return ${sanitizedDisplay}; })()`);
          setResult(evalResult);
          updateCalculateValue(evalResult);
        }
      } catch (e) {
        setResult('Error');
      }
    } else if (value === 'C') {
      setDisplay('');
      setResult(null);
    } else if (value === '(') {
      setDisplay(display + ')');
    } else if (value === ')') {
        setDisplay(display + '(');
    } else if (['min', 'max', 'pow', 'sqrt', 'avg', 'ln2', 'ln10', 'log2E', 'log10E', 'sqrt1/2', 'sqrt2', 'floor', 'round', 'abs', 'random', 'ceil', 'exp', 'sin', 'cos', 'asin', 'acos', 'tan', 'atan', 'e', 'secToMin', 'secToHours', 'secToDays', 'secToWeeks', 'secToMonths', 'minToHours', 'minToDays', 'minToWeeks', 'minToMonths', 'hoursToDays', 'hoursToWeeks', 'hoursToMonths', 'daysToWeeks', 'daysToMonths'].includes(value)) {
      setDisplay(display + `${value}(`);
    } else {
      setDisplay(display + value);
    }
  };




  const handleFunctionSelect = (func) => {
    handleButtonClick(func)
    setDisplay(display + `${func}(`);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };






  const renderButton = (value, xs = 3) => {
    let buttonContent = value;

    switch (value) {
      case '+':
        buttonContent = <Add />;
        break;
      case '-':
        buttonContent = <Remove />;
        break;
      case '*':
        buttonContent = <Clear />;
        break;
      case '/':
        buttonContent = <Division />;
        break;
      case '=':
        buttonContent = <Typography variant="h5">=</Typography>;
        break;
      case 'Function':
        // buttonContent = <Function fill={'white'}/>;
        buttonContent = <Typography variant="body1" fontStyle={'italic'}>fun</Typography>;
        break;
      default:
        buttonContent = <Typography variant="body1">{value}</Typography>;
    }

    return (
      <Grid item xs={xs} key={value}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => value === 'Function' ? handleOpenModal() : handleButtonClick(value)}
          sx={{ height: 36, width: 36, minWidth: 10, padding: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {buttonContent}
        </Button>
      </Grid>
    );
  };








  const buttons = [
    '(', ')', 'C', '/',
    '7', '8', '9', '*',
    '4', '5', '6', '-',
    '1', '2', '3', '+',
    '0', '.', '=', 'Function', ",",
  ];






  return (
    <Grid
      width={'100%'}
      display={'flex'}
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems={'flex-start'}
      gap={'20px'}
      mt={'20px'}
    >
      <Box
        width={{ xs: '100%', md: '60%' }}
        height={'296px'}
        sx={{
          backgroundColor: 'rgb(243, 243, 254)',
          padding: '10px',
          borderRadius: 1,
          boxShadow: 1,
          direction: 'ltr',
          textAlign: 'left',
        }}
      >
        <Typography variant="h5" align="right">
          {display}
        </Typography>
        {result !== null && (
          <Typography variant="h6" align="right" color="textSecondary">
            = {result}
          </Typography>
        )}
      </Box>
      <Box
        width={{ xs: '100%', md: '40%' }}
        display={'flex'}
        alignItems={'center'}
        bgcolor={'#f5f5f5'}
        boxShadow={{ xs: 0, md: 3 }}
        justifyContent={'center'}
        sx={{
          padding: '20px',
          borderRadius: 2,
        }}
      >
        <Grid
          container
          spacing={1}
          width={{
            xs: '200px',
            xxs: '150px',
            sm: '220px',
            md: '130px',
            lg: '150px',
            llg: '160px',
          }}
        >
          {buttons.map((button) => renderButton(button))}
        </Grid>
      </Box>
      <FunctionSelectorModal
        open={modalOpen}
        handleClose={handleCloseModal}
        onSelect={handleFunctionSelect}
      />
    </Grid>
  );
};

export default Calculator;

