import React, { createContext, useState } from 'react';

export const NotificationEmailContext = createContext();

export const NotificationEmailProvider = ({ children }) => {
    const [notificationEmailPayload, setNotificationEmailPayload] = useState(null);

    return (
        <NotificationEmailContext.Provider value={{ notificationEmailPayload, setNotificationEmailPayload }}>
            {children}
        </NotificationEmailContext.Provider>
    );
};
