import { Grid } from '@mui/material'
import React from 'react'

const CenterIcon = ({width}) => {
    return (
        <Grid style={{backgroundColor:'#e8f6ed' , display:'flex' , alignItems:'center' , justifyContent:'center' , padding:'100px 0px'}}>
            <Grid style={{ width: width, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' , padding:'24px 0px' , backgroundColor:'white'}}>
                <div style={{ fontSize: '36px', color: '#2C3345', fontWeight: 600, marginBottom: '16px' }}>متشکریم</div>
                <div style={{ fontSize: '14px', color: '#2C3345', fontWeight: 500, opacity: 0.6  , textAlign:'center'}}>ارسالی شما دریافت شد</div>
            </Grid>
        </Grid>
    )
}

export default CenterIcon