import { Grid, Typography } from '@mui/material'
import React from 'react'
import FullName from './FullName';
import Address from './Address';
import Phone from './Phone';
import Email from './Email';
import DropDown from './DropDown';
import LongText from './LongText';
import SingleChoice from './SingleChoice';
import Table from './Table';

const NewCustomerCard = () => {


    const singleChoiceData = [
        { id: 1, label: 'بله', checked: false },
        { id: 2, label: 'خیر', checked: false },
        { id: 3, label: 'شاید', checked: false },
    ]


    const dropDownData = ['روزنامه' , 'اینترنت' , 'مجله' , 'دیگر']


    return (
        <Grid p={{xs:'40px 13px' , md:'40px 52px'}} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{xs:'72px 0px' , md:'72px 42px'}}>
            <Typography variant='h2' fontWeight={700} color={'#000000'} mb={'52px'}>فرم ثبت نام مشتری جدید</Typography>
            <Typography variant='h5' fontWeight={700} color={'#000000'} mb={'40px'}>جزئیات مشتری:</Typography>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName />
                <Address />
                <Phone />
                <Email />
                <DropDown options={dropDownData} header={'چطور در مورد ما شنیدید؟'}/>
                <LongText header={'بازخورد درباره ما:'} />
                <LongText header={'پیشنهاداتی در صورت وجود برای بهبود بیشتر:'} />
                <SingleChoice options={singleChoiceData} header={'آیا حاضرید به ما توصیه ای کنید؟'}/>
                {/* <Table rowOption={['1' , '2']} columnOption={['نام و نام خانوادگی' , 'آدرس' , 'شماره تماس']}/> */}
            </Grid>
        </Grid>
    )
}

export default NewCustomerCard