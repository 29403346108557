import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Address,
  Appointment,
  Captcha,
  DatePicker,
  Divider,
  DropDown,
  Email,
  FileUpload,
  FillInTheBlank,
  FullName,
  Heading,
  Image,
  LongText,
  MultiChoice,
  Number,
  Paragraph,
  Phone,
  ProductList,
  ScaleRating,
  Section,
  ShortText,
  Signature,
  SingleChoice,
  Spinner,
  StarRating,
  Submit,
  Table,
  Time,
} from "./Icons";

import { useSpring, animated } from "react-spring";
import "../App.css";
import { DndContext } from "@dnd-kit/core";
import { useFormContext } from "../Context/Context";

const Drawer = () => {

  const { handleDrawerData, handleDelete } = useFormContext();

  const [active, setActive] = useState(1);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoveredBasic, setHoveredBasic] = useState(null);
  const [hoveredSurvey, setHoveredSurvey] = useState(null);
  const [hoveredPage, setHoveredPage] = useState(null);
  const [hoveredDivider, setHoveredDivider] = useState(null);
  const [SendToParentData, setSendToParentData] = useState([]);


  const drawerRef = useRef(null);

  const generateUniqueId = () => {
    const timestamp = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 1000000); // Adjust the range as needed
    return parseInt(`${timestamp}${randomNumber}`);
  };

  const ParentData = (id, title, state) => {
    handleDelete(id); // Delete from FormItem component state
    const uniqueId = generateUniqueId();
    setSendToParentData((prevData) => [
      ...prevData,
      { id: uniqueId, title, state: state },
    ]);
    // setSendToParentData((prevData) => [...prevData, { id: uniqueId, title }]);
  };

  const sendDataToParentHandler = () => {
    // Call the function passed from the parent with the data
    handleDrawerData(SendToParentData);
  };

  const [drawerProps, setDrawer] = useSpring(() => ({
    transform: "translateX(100%)",
  }));

  const showDrawer = () => {
    setDrawer({ transform: "translateX(0%)" });
  };

  const closeDrawer = () => {
    setDrawer({ transform: "translateX(100%)" });
  };

  const BasicElementData = [
    {
      id: 1,
      icon: <Heading />,
      title: "عنوان",
      SendToParent: "Heading",
      state: { headingValue: "", subHeadingValue: "" },
    },
    // { id: 1, icon: <Heading />, title: 'عنوان', SendToParent: 'Heading'},
    // { id: 2, icon: <FullName />, title: 'نام و نام خانوادگی', SendToParent: 'FullName' },
    {
      id: 2,
      icon: <FullName />,
      title: "نام و نام خانوادگی",
      SendToParent: "FullName",
      state: {
        nameValue: "",
        firstNameValue: "",
        lastNameValue: "",
        firstNameInput: "",
        lastNameInput: "",
      },
    },
    {
      id: 3,
      icon: <Email />,
      title: "ایمیل",
      SendToParent: "Email",
      state: {
        emailValue: "",
        emailInput: "",
        exEmailValue: "",
      },
    },
    {
      id: 4,
      icon: <Address />,
      title: "آدرس",
      SendToParent: "Address",
      state: {
        addressValue: "",
        streetInput: "",
        streetValue: "",
        streetInputSecond: "",
        streetValueSecond: "",
        cityInput: "",
        city: "",
        provinceInput: "",
        province: "",
        postalInput: "",
        postal: "",
      },
    },
    {
      id: 5,
      icon: <Phone />,
      title: "تلفن",
      SendToParent: "Phone",
      state: {
        phoneValue: "",
        phoneInput: null,
        alertValue: "",
      },
    },
    {
      id: 6,
      icon: <DatePicker />,
      title: "تقویم",
      SendToParent: "DatePicker",
      state: {
        date: null,
        dateValue: "",
        subHeader: "",
      },
    },
    {
      id: 7,
      icon: <Appointment />,
      title: "وقت ملاقات",
      SendToParent: "Appointment",
      state: {
        appointmentValue: '',
        savedSelectTime: '',
        persianDateTime: '',
        interval: null
      }
    },
    {
      id: 8,
      icon: <Signature />,
      title: "امضا",
      SendToParent: "Signature",
      state: { signatureValue: "", signatureImage: null },
    },
    {
      id: 9,
      icon: <FillInTheBlank />,
      title: "جای خالی را پر کنید",
      SendToParent: "FillInTheBlank",
      state: {
        innerHtml: '',
      }
    },
    {
      id: 10,
      icon: <ProductList />,
      title: "لیست محصولات",
      SendToParent: "ProductList",
      state: {
        products: [],
        productValue: '',
        totalPrice: null,
        totalQuantity: null
      }
    },
  ];

  const SurveyElementData = [
    {
      id: 11,
      icon: <ShortText />,
      title: "متن کوتاه",
      SendToParent: "ShortText",
      state: {
        shortTextValue: "",
        shortTextInputValue: "",
        subShortTextValue: "",
      },
    },
    {
      id: 12,
      icon: <LongText />,
      title: "متن طولانی",
      SendToParent: "LongText",
      state: {
        longTextValue: "",
        textareaValue: "",
        subLongTextValue: "",
      },
    },
    {
      id: 13,
      icon: <Paragraph />,
      title: "پاراگراف",
      SendToParent: "Paragraph",
      state: {
        content: "",
      },
    },
    {
      id: 14,
      icon: <DropDown />,
      title: "بازشونده",
      SendToParent: "DropDown",
      state: {
        dropDownValue: "",
        subDropDownValue: "",
        option: "",
        textareaValue: "",
      },
    },
    {
      id: 15,
      icon: <SingleChoice />,
      title: "تک گزینه ای",
      SendToParent: "SingleChoice",
      state: {
        titleValue: "",
        singleData: [],
      },
    },
    {
      id: 16,
      icon: <MultiChoice />,
      title: "چند گزینه ای",
      SendToParent: "MultiChoice",
      state: {
        titleValue: "",
        multiData: [],
      },
    },
    {
      id: 17,
      icon: <Number />,
      title: "شماره",
      SendToParent: "Number",
      state: {
        numberValue: "",
        numberInputValue: null,
        subNumberValue: "",
      },
    },
    {
      id: 18,
      icon: <Image />,
      title: "عکس",
      SendToParent: "Image",
      state: { image: null },
    },
    {
      id: 19,
      icon: <FileUpload />,
      title: "آپلود فایل",
      SendToParent: "FileUpload",
      state: {
        selectedFile: null,
        fileInputValue: "",
        selectedFileName: null,
        fileValue: "",
        subFileValue: "",
      },
    },
    {
      id: 20,
      icon: <Time />,
      title: "زمان",
      SendToParent: "Time",
      state: {
        selectedOption: "",
        timeValue: "",
        subTimeValue: "",
        inputValue: "",
      },
    },
    {
      id: 21,
      icon: <Captcha />,
      title: "کپچا",
      SendToParent: "Captcha",
      state: { select: null },
    },
    {
      id: 22,
      icon: <Spinner />,
      title: "شمارنده",
      SendToParent: "Spinner",
      state: {
        spinnerValue: "",
        spinnerInputValue: null,
        subSpinnerValue: "",
      },
    },
    { id: 23, icon: <Submit />, title: "ارسال", SendToParent: "Submit" },
  ];

  const PageElementData = [
    {
      id: 24,
      icon: <Table />,
      title: "جدول ورودی",
      SentToParent: "Table",
      state: {
        tableValue: '',
        rowTitles: [],
        columnTitles: [],
        selectedColumnIndex: [],
        show: false
      },
    },
    {
      id: 25,
      icon: <StarRating />,
      title: "رتبه بندی ستاره ای",
      SentToParent: "StarRating",
      state: {
        starValue: '',
        subStarValue: '',
        rate: null
      }
    },
    {
      id: 26,
      icon: <ScaleRating />,
      title: "رتبه بندی مقیاس",
      SentToParent: "ScaleRating",
      state: {
        scaleValue: '',
        subScaleValue: '',
        bestValue: '',
        worthValue: '',
        active: null
      }
    },
  ];

  const DividerElementData = [
    { id: 27, icon: <Divider />, title: "جدا کننده", SendToParent: "Divider" },
    {
      id: 28,
      icon: <Section />,
      title: "بخش جدا کننده",
      SendToParent: "Section",
      state: {
        sectionValue: ''
      }
    },
  ];

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      closeDrawer();
    }
  };

  useEffect(() => {
    sendDataToParentHandler();
  }, [SendToParentData]);


  useEffect(() => {
    const storedData = localStorage.getItem('formDrawerData');
    if (storedData) {
      setSendToParentData(JSON.parse(storedData));
    }
  }, []);


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (

    <Grid ref={drawerRef}>
      <Grid position={"relative"}>
        <Grid
          position={"fixed"}
          right={0}
          top={"178px"}
          width={"160px"}
          height={"54px"}
          p={"11px 12px 13px 16px"}
          borderRadius={"100px 0px 0px 100px"}
          bgcolor={"#3e4652"}
          display={{ xs: "none", xxs: "flex" }}
          alignItems={"center"}
          justifyContent={"space-between"}
          style={{ cursor: "pointer" }}
          onClick={showDrawer}
          zIndex={101}
        >
          <Typography color={"white"} fontWeight={500} variant={"h6"}>
            عنصر فرم را اضافه کنید
          </Typography>
          <AddIcon style={{ color: "white" }} />
        </Grid>
        <Grid
          display={{ xs: "flex", xxs: "none" }}
          alignItems={"center"}
          justifyContent={"center"}
          width={"54px"}
          height={"54px"}
          borderRadius={"50%"}
          style={{
            cursor: "pointer",
            backgroundColor: "#3e4652",
            position: "fixed",
            bottom: "20px",
            right: "10px",
          }}
          onClick={showDrawer}
          zIndex={101}
        >
          <AddIcon style={{ color: "white" }} fontSize={"large"} />
        </Grid>
      </Grid>
      <animated.div
        style={{
          position: "fixed",
          top: 119,
          right: 0,
          width: "310px",
          height: "100vh",
          backgroundColor: "#2f333a",
          zIndex: 102,
          ...drawerProps,
        }}
      >
        <Grid
          p={"18px 14px 14px 18px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant={"h4"} color={"white"} fontWeight={500}>
            عناصر فرم
          </Typography>
          <CloseIcon
            fontSize={"large"}
            style={{ color: "white", cursor: "pointer" }}
            onClick={closeDrawer}
          />
        </Grid>
        <Grid container bgcolor={"#2f333a"}>
          <Grid
            item
            xs={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            onClick={() => {
              setActive(1);
            }}
            borderBottom={
              hoveredItem === 1 || active === 1
                ? "4px solid #ff8a36"
                : "4px solid transparent"
            }
            style={{ cursor: "pointer", transition: "border-bottom 0.3s ease" }}
            onMouseEnter={() => setHoveredItem(1)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <Typography variant={"subtitle1"} color={"white"} p={"16px 0px"}>
              پایه ای
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            onClick={() => {
              setActive(2);
            }}
            borderBottom={
              hoveredItem === 2 || active === 2
                ? "4px solid #ff8a36"
                : "4px solid transparent"
            }
            style={{ cursor: "pointer", transition: "border-bottom 0.3s ease" }}
            onMouseEnter={() => setHoveredItem(2)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <Typography variant={"subtitle1"} color={"white"} p={"16px 0px"}>
              پرداخت
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            onClick={() => {
              setActive(3);
            }}
            borderBottom={
              hoveredItem === 3 || active === 3
                ? "4px solid #ff8a36"
                : "4px solid transparent"
            }
            style={{ cursor: "pointer", transition: "border-bottom 0.3s ease" }}
            onMouseEnter={() => setHoveredItem(3)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <Typography variant={"subtitle1"} color={"white"} p={"16px 0px"}>
              ابزارک ها
            </Typography>
          </Grid>
        </Grid>
        <Grid
          style={{ overflowY: "auto", maxHeight: "85vh" }}
          className={"custom-scrollbars"}
        >
          <DndContext>
            <Grid className={"basic"} width={"100%"}>
              {BasicElementData.map((item) => (
                <Grid
                  key={item.id}
                  width={"100%"}
                  height={"54px"}
                  display={"flex"}
                  alignItems={"center"}
                  style={{ cursor: "pointer" }}
                  bgcolor={hoveredBasic === item.id ? "#ff8a36" : "#545f6f"}
                  onMouseEnter={() => setHoveredBasic(item.id)}
                  onMouseLeave={() => setHoveredBasic(null)}
                  onClick={() => {
                    ParentData(item.id, item.SendToParent, item.state);
                    // ParentData(item.id, item.SendToParent);
                    // closeDrawer();
                  }}
                >
                  {/*<Draggable id={item.id}>*/}
                  <Grid
                    p={"15px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    height={"54px"}
                    borderBottom={item.id === 10 ? "none" : "1px solid #4a5361"}
                    bgcolor={hoveredBasic === item.id ? "#ffa130" : "#37404a"}
                    onMouseEnter={() => setHoveredBasic(item.id)}
                    onMouseLeave={() => setHoveredBasic(null)}
                  >
                    {item.icon}
                  </Grid>
                  {/*</Draggable>*/}
                  <Grid
                    px={"18px"}
                    py={0}
                    height={"54px"}
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    borderBottom={item.id === 10 ? "none" : "1px solid #4a5361"}
                  >
                    <Typography variant={"h6"} color={"white"} fontWeight={500}>
                      {item.title}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </DndContext>
          <Grid
            className={"BasicElementDivider"}
            display={"flex"}
            alignItems={"center"}
            bgcolor={"#3e4652"}
            justifyContent={"center"}
            width={"100%"}
            style={{
              borderWidth: "1px 0px",
              borderStyle: "solid",
              borderColor: "#4a5361",
            }}
          >
            <Typography
              variant={"body1"}
              color={"rgba(255,255,255,.6)"}
              p={"8px 0px"}
            >
              عناصر پایه ای
            </Typography>
          </Grid>
          <Grid className={"Survey"}>
            {SurveyElementData.map((item) => (
              <Grid
                key={item.id}
                width={"100%"}
                height={"54px"}
                display={"flex"}
                style={{ cursor: "pointer" }}
                alignItems={"center"}
                bgcolor={hoveredSurvey === item.id ? "#ff8a36" : "#545f6f"}
                onMouseEnter={() => setHoveredSurvey(item.id)}
                onMouseLeave={() => setHoveredSurvey(null)}
                onClick={() => {
                  ParentData(item.id, item.SendToParent, item.state);
                  // closeDrawer();
                }}
              >
                <Grid
                  p={"15px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"54px"}
                  borderBottom={item.id === 13 ? "none" : "1px solid #4a5361"}
                  bgcolor={hoveredSurvey === item.id ? "#ffa130" : "#37404a"}
                  onMouseEnter={() => setHoveredSurvey(item.id)}
                  onMouseLeave={() => setHoveredSurvey(null)}
                >
                  {item.icon}
                </Grid>
                <Grid
                  px={"18px"}
                  py={0}
                  height={"54px"}
                  width={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  borderBottom={item.id === 13 ? "none" : "1px solid #4a5361"}
                >
                  <Typography variant={"h6"} color={"white"} fontWeight={500}>
                    {item.title}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid
            className={"SurveyElementDivider"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            bgcolor={"#3e4652"}
            style={{
              borderWidth: "1px 0px",
              borderStyle: "solid",
              borderColor: "#4a5361",
            }}
          >
            <Typography
              variant={"body1"}
              color={"rgba(255,255,255,.6)"}
              p={"8px 0px"}
            >
              عناصر نظرسنجی
            </Typography>
          </Grid>
          <Grid className={"Page"}>
            {PageElementData.map((item) => (
              <Grid
                key={item.id}
                width={"100%"}
                height={"54px"}
                display={"flex"}
                alignItems={"center"}
                style={{ cursor: "pointer" }}
                bgcolor={hoveredPage === item.id ? "#ff8a36" : "#545f6f"}
                onMouseEnter={() => setHoveredPage(item.id)}
                onMouseLeave={() => setHoveredPage(null)}
                onClick={() => {
                  ParentData(item.id, item.SentToParent, item.state);
                  // closeDrawer();
                }}
              >
                <Grid
                  p={"15px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"54px"}
                  borderBottom={item.id === 3 ? "none" : "1px solid #4a5361"}
                  bgcolor={hoveredPage === item.id ? "#ffa130" : "#37404a"}
                  onMouseEnter={() => setHoveredPage(item.id)}
                  onMouseLeave={() => setHoveredPage(null)}
                >
                  {item.icon}
                </Grid>
                <Grid
                  px={"18px"}
                  py={0}
                  height={"54px"}
                  width={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  borderBottom={item.id === 3 ? "none" : "1px solid #4a5361"}
                >
                  <Typography variant={"h6"} color={"white"} fontWeight={500}>
                    {item.title}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid
            className={"PageElementDivider"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            bgcolor={"#3e4652"}
            style={{
              borderWidth: "1px 0px",
              borderStyle: "solid",
              borderColor: "#4a5361",
            }}
          >
            <Typography
              variant={"body1"}
              color={"rgba(255,255,255,.6)"}
              p={"8px 0px"}
            >
              عناصر صفحه
            </Typography>
          </Grid>
          <Grid className={"Divider"}>
            {DividerElementData.map((item) => (
              <Grid
                key={item.id}
                width={"100%"}
                height={"54px"}
                display={"flex"}
                alignItems={"center"}
                style={{ cursor: "pointer" }}
                bgcolor={hoveredDivider === item.id ? "#ff8a36" : "#545f6f"}
                onMouseEnter={() => setHoveredDivider(item.id)}
                onMouseLeave={() => setHoveredDivider(null)}
                onClick={() => {
                  ParentData(item.id, item.SendToParent, item.state);
                  // closeDrawer();
                }}
              >
                <Grid
                  p={"15px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"54px"}
                  borderBottom={item.id === 3 ? "none" : "1px solid #4a5361"}
                  onMouseEnter={() => setHoveredDivider(item.id)}
                  onMouseLeave={() => setHoveredDivider(null)}
                  bgcolor={hoveredDivider === item.id ? "#ffa130" : "#37404a"}
                >
                  {item.icon}
                </Grid>
                <Grid
                  px={"18px"}
                  py={0}
                  height={"54px"}
                  width={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  borderBottom={item.id === 3 ? "none" : "1px solid #4a5361"}
                >
                  <Typography variant={"h6"} color={"white"} fontWeight={500}>
                    {item.title}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </animated.div>
    </Grid>
  );
};
export default Drawer;
