import { Grid, Typography } from '@mui/material'
import React from 'react'
import FullName from './FullName'
import Email from './Email'
import ShortText from './ShortText'
import Number from './Number'
import FileUpload from './FileUpload'
import LongText from './LongText'

const ITServiceTicket = () => {
    return (
        <Grid p={{xs:'40px 13px' , md:'40px 52px'}} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{xs:'72px 0px' , md:'72px 42px'}}>
            <Typography variant='h2' fontWeight={700} color={'#000000'} mb={'12px'}>بلیط خدمات فناوری اطلاعات</Typography>
            <Typography variant='h5' fontWeight={500} color={'#57647e'} mb={'40px'}>لطفا جزئیات مشکل را ارائه دهید</Typography>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName />
                <Email />
                <ShortText header={'بخش'}/>
                <ShortText header={'شناسه کامپیوتر'}/>
                <FileUpload />
                <LongText header={'مشکل را شرح دهید'}/>
            </Grid>
        </Grid>
    )
}

export default ITServiceTicket