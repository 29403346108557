import React, { createContext, useContext } from 'react';
import useLocalStorage from 'use-local-storage';


const DraftContext = createContext();

export const DraftProvider = ({ children }) => {

    const [draftData, setDraftData] = useLocalStorage('draftsForm', []);

    const saveDraft = (data) => {
        setDraftData(prevData => [...prevData, ...data]);
    };

    return (
        <DraftContext.Provider value={{ draftData, setDraftData , saveDraft }}>
            {children}
        </DraftContext.Provider>
    );
};

export const useDraft = () => {
    return useContext(DraftContext);
};

