import React from 'react'
import InitialNotificationEmail from './InitialNotificationEmail.jsx';
import AutoResponder from './AutoResponder.jsx';

const InitialValue = ({page }) => {


  switch (page) {
    case 'NotificationEmail':
      return <InitialNotificationEmail />;
    case 'AutoResponderEmail':
      return <AutoResponder />;
    default:
      return '';
  }
}

export default InitialValue