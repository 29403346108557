import { Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import Navbar from '../Component/Navbar'
import HeaderSetting from '../Component/HeaderSetting'
import SettingDrawer from '../Component/SettingDrawer'
import LayoutCondition from '../Component/LayoutCondition'
import { Warning } from '../Component/Icons'
import Typography from '@mui/material/Typography';
import Divider from '../Component/Divider'
import axios from 'axios'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify'
import '../App.css'
import { useInputValues } from '../Context/InputValuesContext'

const FormWarnings = () => {

  const [searchQuery, setSearchQuery] = useState('');
  const [requierdTitle, setRequierdTitle] = useState('ضروری')
  const [formTitle, setFormTitle] = useState('خطاهای فرم')
  const [validateTitle, setValidateTitle] = useState('اعتبار سنجی')
  const [textareaTitle, setTextareaTitle] = useState("محیط متنی")
  const [emailTitle, setEmailTitle] = useState("ایمیل")
  const [radioTitle, setRadioTitle] = useState('Radio / Checkbox / Dropdown')
  const [numberTitle, setNumberTitle] = useState('شماره')
  const [dateTitle, setDateTitle] = useState('تاریخ')
  const [uploadTitle, setUploadTitle] = useState('بارگذاری')
  const [matrixTitle, setMatrixTitle] = useState('ماتریس')
  const [appointmentTitle, setAppointmentTitle] = useState('وقت ملاقات')
  const [paymentTitle, setPaymentTitle] = useState('پرداخت')
  const [submitTitle, setSubmitTitle] = useState('دکمه ارسال')
  const [otherTitle, setOtherTitle] = useState('دیگر')
  const [progressTitle, setProgressTitle] = useState('متن های پیشرفت')
  const [reviewTitle, setReviewTitle] = useState('بررسی و ارسال')
  const [accessibilityTitle, setAccessibilityTitle] = useState('دسترسی')
  const [navigationTitle, setavigationTitle] = useState('پیمایش خطا')




  const { inputValues, setInputValues } = useInputValues();

  const { handleReset } = useInputValues();



  const requiredData = [
    { id: 1, inputTitle: 'این فیلد الزامی است.' },
    { id: 2, inputTitle: 'حداقل یک فیلد مورد نیاز است.' },
  ]


  const formErrorsData = [
    { id: 3, inputTitle: 'متاسفیم! فقط یک بار ورود مجاز است. ارسال چندگانه برای این فرم غیرفعال است.' },
    { id: 4, inputTitle: 'اشکالاتی در فرم وجود دارد. لطفا قبل از ادامه آنها را اصلاح کنید.' },
    { id: 5, inputTitle: 'خطاهایی در این صفحه وجود دارد. لطفا قبل از ادامه آنها را اصلاح کنید.' },
    { id: 6, inputTitle: 'فیلدهای الزامی ناقص وجود دارد. لطفا آنها را تکمیل کنید.' },
  ]


  const validateData = [
    { id: 7, inputTitle: 'این فیلد فقط می تواند شامل حروف باشد' },
    { id: 8, inputTitle: 'این فیلد فقط می تواند حاوی نویسه های سیریلیک باشد' },
    { id: 9, inputTitle: 'این فیلد فقط می تواند حاوی یک URL معتبر باشد' },
    { id: 10, inputTitle: 'این فیلد فقط می تواند حاوی مقادیر عددی باشد' },
    { id: 11, inputTitle: 'این قسمت فقط می تواند شامل حروف و اعداد باشد.' },
    { id: 12, inputTitle: 'این فیلد فقط می تواند حاوی مقادیر ارز باشد.' },
    { id: 13, inputTitle: 'مقدار فیلد باید ماسک را پر کند.' },
    { id: 14, inputTitle: 'تعداد کاراکترها نباید از حداقل مقدار کمتر باشد:' },
    { id: 15, inputTitle: 'زمان معتبری وارد کنید' },
  ]


  const textareaData = [
    { id: 16, inputTitle: 'محدودیت این است که ، کلمات زیادی هستند' },
    { id: 17, inputTitle: 'کلمات بسیار کم است' },
    { id: 18, inputTitle: 'تعداد کاراکترها بسیار زیاد است' },
    { id: 19, inputTitle: 'تعداد کاراکترها بسیار کم است' },
  ]

  const emialData = [
    { id: 20, inputTitle: 'یک آدرس ایمیل معتبر وارد کنید' },
    { id: 21, inputTitle: 'ایمیل مطابقت ندارد' },
    { id: 22, inputTitle: 'حساب های ایمیل رایگان مجاز نیستند' },
    { id: 23, inputTitle: 'شما باید این ایمیل را تایید کنید' },
  ]

  const radioData = [
    { id: 24, inputTitle: 'حداقل تعداد مورد نیاز را انتخاب کنید' },
    { id: 25, inputTitle: 'حداکثر تعداد مورد نیاز مجاز است' },
  ]


  const numberData = [
    { id: 26, inputTitle: 'ورودی نباید کمتر از حداقل مقدار باشد:' },
    { id: 27, inputTitle: 'ورودی نباید از حداکثر مقدار بیشتر باشد:' },
    { id: 28, inputTitle: 'حداکثر رقم مجاز است' },
  ]


  const dateData = [
    { id: 29, inputTitle: 'تاریخ نباید در گذشته باشد.' },
    { id: 30, inputTitle: 'این تاریخ در دسترس نیست.' },
    { id: 31, inputTitle: 'این تاریخ معتبر نیست. قالب تاریخ {yyyy/mm/dd} است' },
    { id: 32, inputTitle: 'این تاریخ معتبر نیست. یک تاریخ معتبر وارد کنید.' },
    { id: 33, inputTitle: 'برای ارسال این فرم باید بیش از {minAge} سال سن داشته باشید.' },
  ]


  const uploadData = [
    { id: 34, inputTitle: 'پسوند {file} نامعتبر است. فقط {extensions} مجاز است.' },
    { id: 35, inputTitle: '{file} خیلی بزرگ است، حداکثر اندازه فایل {sizeLimit} است.' },
    { id: 36, inputTitle: '{file} خیلی کوچک است، حداقل اندازه فایل {minSizeLimit} است.' },
    { id: 37, inputTitle: '{file} خالی است، لطفاً دوباره فایل‌ها را بدون آن انتخاب کنید.' },
    { id: 38, inputTitle: 'آپلود فایل انجام نشد، لطفا آن را حذف کنید و دوباره فایل را آپلود کنید.' },
    { id: 39, inputTitle: 'فایل ها در حال آپلود هستند، اگر اکنون از آنجا خارج شوید، آپلود لغو می شود.' },
    { id: 40, inputTitle: 'فقط آپلود فایل {fileLimit} مجاز است.' },
    { id: 41, inputTitle: 'فایل ها را در اینجا بکشید و رها کنید' },
    { id: 42, inputTitle: 'حداکثر حجم فایل' },
    { id: 43, inputTitle: 'یک فایل را انتخاب کنید' },
  ]


  const matrixData = [
    { id: 44, inputTitle: 'حداقل یک فیلد مورد نیاز است.' },
    { id: 45, inputTitle: 'هر ردیف مورد نیاز است.' },
    { id: 46, inputTitle: 'هر سلول مورد نیاز است.' },
  ]


  const appointmentData = [
    { id: 47, inputTitle: 'زمان انتخاب شده' },
    { id: 48, inputTitle: 'زمان سابق' },
    { id: 49, inputTitle: 'لغو قرار' },
    { id: 50, inputTitle: 'لغو انتخاب' },
    { id: 51, inputTitle: 'بازه زمانی موجود نیست' },
    { id: 52, inputTitle: '{time} در {date} گرفته شده است. لطفا یک بازه زمانی دیگر انتخاب کنید.' },
  ]


  const paymentData = [
    { id: 53, inputTitle: 'شماره کارت اعتباری نامعتبر است.' },
    { id: 54, inputTitle: 'شماره CVC نامعتبر است.' },
    { id: 55, inputTitle: 'تاریخ انقضا نامعتبر است.' },
    { id: 56, inputTitle: 'لطفا مشخصات کارت اعتباری را پر کنید.' },
    { id: 57, inputTitle: 'لطفا حداقل یک کالا را برگزینید.' },
    { id: 58, inputTitle: 'لطفا مقادیر عددی را برای مبلغ کمک وارد کنید.' },
    { id: 59, inputTitle: 'لطفا یک عدد کامل وارد کنید' },
    { id: 60, inputTitle: 'حداقل مقدار {minAmount} {currency} است' },
    { id: 61, inputTitle: 'موجودی کافی برای انتخاب فعلی وجود ندارد' },
    { id: 62, inputTitle: 'موجودی کافی برای انتخاب فعلی موجود نیست ({count} مورد باقی مانده است)' },
    { id: 63, inputTitle: 'فروخته شده' },
    { id: 64, inputTitle: 'فروخته شد' },
    { id: 65, inputTitle: 'انتخاب فروخته شد' },
    { id: 66, inputTitle: '({count} مورد باقی مانده است)' },
  ]

  const submitData = [
    { id: 67, inputTitle: 'لطفا صبر کنید...' },
    { id: 68, inputTitle: 'آیا مطمئن هستید که می خواهید فرم را پاک کنید؟' },
  ]

  const otherData = [
    { id: 69, inputTitle: 'امتیاز شما باید کمتر یا مساوی باشد' },
    { id: 70, inputTitle: 'کل امتیاز فقط باید کمتر یا مساوی باشد' },
  ]

  const progressData = [
    { id: 71, inputTitle: 'مشاهده همه' },
    { id: 72, inputTitle: 'از' },
  ]

  const reviewData = [
    { id: 73, inputTitle: 'بررسی و ارسال کنید' },
    { id: 74, inputTitle: 'بازگشت به فرم' },
  ]

  const accessibilityData = [
    { id: 75, inputTitle: 'تمام فیلدهایی که با * مشخص شده اند الزامی هستند و باید پر شوند.' },
    { id: 76, inputTitle: 'فیلد خطا دارد' },
    { id: 77, inputTitle: 'خطا' },
  ]

  const navigationData = [
    { id: 78, inputTitle: 'در این صفحه {count} خطا وجود دارد. لطفا قبل از حرکت آنها را اصلاح کنید.' },
    { id: 79, inputTitle: 'خطای {count} در این صفحه وجود دارد. لطفا قبل از ادامه آن را اصلاح کنید.' },
    { id: 80, inputTitle: 'آفرین! تمام خطاها رفع شده است.' },
  ]


  const CustomToast = ({ message, state, color }) => {
    return (

      state === 'success' ?
        <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
          backgroundColor: 'white',
          color: color,
          fontSize: '16px',
          padding: '15px 10px',
          fontWeight: 900,
          textAlign: 'right'

        }}>
          <CheckCircleIcon fontSize={'large'} />
          {message}
        </Grid>
        :
        <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
          backgroundColor: 'white',
          color: 'red',
          fontSize: '16px',
          padding: '15px 10px',
          fontWeight: 900,
          textAlign: 'right'
        }}>
          <ErrorIcon fontSize={'large'} />
          {message}
        </Grid>

    );
  };


  const handleChange = (e, dataIndex) => {

    const { value } = e.target;

    setInputValues(prevValues => {
      const updatedValues = [...prevValues];
      updatedValues[dataIndex].value = value;
      return updatedValues;
    });

  };



  const handleSearch = e => {
    setSearchQuery(e.target.value);
  };



  const handleSave = () => {


    console.log(inputValues, 'inputValues')

    axios.post('YOUR_SERVER_ENDPOINT', inputValues)
      .then(response => {
        console.log('Data sent successfully:', response.data);
        toast(<CustomToast message="تغییرات با موفقیت ذخیره شد" state={'success'} color={'#1A4D2E'} />);
      })
      .catch(error => {
        toast(<CustomToast message="ذخیره تغییرات ناموفق بود. لطفا چند لحظه دیگر مجددا تلاش کنید" state={'erorr'} />);
        console.error('Error sending data:', error);
      });
  };


  const handleResetValues = () => {
    handleReset() // Reset inputValues to initial values
    toast(<CustomToast message="تنظیم مجدد انجام شد" state={'success'} color={'#DC6B19'} />);
  };


  const filteredData = [
    { title: requierdTitle, data: requiredData },
    { title: formTitle, data: formErrorsData },
    { title: validateTitle, data: validateData },
    { title: textareaTitle, data: textareaData },
    { title: emailTitle, data: emialData },
    { title: radioTitle, data: radioData },
    { title: numberTitle, data: numberData },
    { title: dateTitle, data: dateData },
    { title: uploadTitle, data: uploadData },
    { title: matrixTitle, data: matrixData },
    { title: appointmentTitle, data: appointmentData },
    { title: paymentTitle, data: paymentData },
    { title: submitTitle, data: submitData },
    { title: otherTitle, data: otherData },
    { title: progressTitle, data: progressData },
    { title: reviewTitle, data: reviewData },
    { title: accessibilityTitle, data: accessibilityData },
    { title: navigationTitle, data: navigationData },
  ].filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));



  return (
    <Grid bgcolor={"#f3f3fe"} >
      <Grid display={{ xs: "none", xxs: "block" }}>
        <Navbar state={true} active={2} />
      </Grid>
      <Grid display={{ xs: "block", xxs: "none" }}>
        <HeaderSetting
          title={"فرم هشدارها"}
          subTitle={"پیام های هشدار را تغییر دهید"}
          link={"/Settings/general"}
        />
      </Grid>
      <Grid
        mt={{ xxs: "118px" }}
        position={"relative"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"center"}
      >
        <Grid display={{ xs: "none", sm: "block" }}>
          <SettingDrawer isActive={1} />
        </Grid>
        <Grid display={{ xs: "none", xxs: "block", sm: "none" }}>
          <SettingDrawer isActive={1} />
        </Grid>
        <Grid
          className="main"
          position={"absolute"}
          left={{ xs: "10px", xxs: "20px" }}
          mx={"auto"}
          width={{ xs: "100%", xxs: "75%", sm: "80%", md: "65%" }}
          mt={{ xs: "0px", xxs: "80px" }}
          pr={{ xs: "30px", xxs: "60px" }}
          pl={{ xs: "10px", xxs: "20px" }}
        >
          <LayoutCondition
            title={"فرم هشدارها"}
            subTitle={"زمانی که شخصی فرم شما را پر می کند، ایمیلی دریافت کنید"}
            link={"/Settings/general"}
            icon={<Warning />}
            color={"rgba(255,123,28,1)"}
          />
          <Grid>
            <Grid className='search' mb={'12px'}>
              <input
                placeholder='جستجو کردن'
                style={{
                  padding: '9px 15px',
                  borderRadius: '4px',
                  border: '1px solid #c5c5c5',
                  width: '100%',
                  backgroundColor: 'white',
                  color: '#564632',
                  fontSize: '14px',
                  lineHeight: '24px',
                  boxShadow: '0 2px 4px rgba(86,70,50,.1)',
                  outline: 0
                }}
                onChange={handleSearch}
              />
            </Grid>
            <Grid className='warnings' border={'1px solid rgb(222, 217, 211)'} bgcolor={'white'} mb={'14px'}>
              {filteredData.map((item, index) => (
                <Grid key={index}>
                  <Grid p={'30px'}>
                    <Typography variant="h5" color="#596066" fontWeight={700} mb={'8px'}>
                      {item.title}
                    </Typography>
                    {item.data.map((dataItem, dataIndex) => (
                      <Grid key={dataIndex} mb={dataIndex === item.data.length - 1 ? '0px' : '24px'}>
                        <Typography variant='h6' color={'#596066'} fontWeight={700} pb="4px">
                          {dataItem.inputTitle}
                        </Typography>
                        <input
                          className='input-with-overflow'
                          name={inputValues[dataIndex].name}
                          value={inputValues[dataItem.id - 1].value}
                          onChange={(e) => handleChange(e, dataItem.id - 1)}
                          style={{
                            padding: '9px 15px',
                            borderRadius: '4px',
                            border: '1px solid #c5c5c5',
                            width: '100%',
                            backgroundColor: 'white',
                            color: '#564632',
                            fontSize: '14px',
                            lineHeight: '24px',
                            boxShadow: '0 2px 4px rgba(86,70,50,.1)',
                            outline: 0,
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Divider flag={true} />
                </Grid>
              ))}
            </Grid>
            <Grid className='buttons' display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'32px'}>
              <Button onClick={handleSave} className='save' sx={{ bgcolor: '#5cab40', color: 'white', padding: '12px 17px', borderRadius: '4px', fontSize: '15px', cursor: 'pointer', boxShadow: '0 2px 4px rgba(86,70,50,.1)', '&:hover': { bgcolor: '#77c25c' } }}>ذخیره تغییرات</Button>
              <Button onClick={handleResetValues} className='reset' sx={{ bgcolor: '#9b8a73', color: 'white', padding: '12px 17px', borderRadius: '4px', fontSize: '15px', cursor: 'pointer', boxShadow: '0 2px 4px rgba(86,70,50,.1)', '&:hover': { bgcolor: '#b0a391' } }}>تنظیم مجدد</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormWarnings;