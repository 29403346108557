// ReadOnlyContext.js
import React, { createContext, useContext, useState } from 'react';

const ReadOnlyContext = createContext();

export const ReadOnlyProvider = ({ children }) => {
  const [readOnly, setReadOnly] = useState(false);

  return (
    <ReadOnlyContext.Provider value={{ readOnly, setReadOnly }}>
      {children}
    </ReadOnlyContext.Provider>
  );
};

export const useReadOnly = () => {
  return useContext(ReadOnlyContext);
};
