import React, { useState } from 'react'
import CreateFormNavbar from '../Component/CreateFormNavbar'
import { Box, Button, Grid, Typography } from '@mui/material'
import MainHeader from '../Component/MainHeader'
import SearchIcon from '@mui/icons-material/Search';
import { useData } from '../Context/DataContext';
import { useFormContext } from '../Context/Context';
import { useNavigate } from 'react-router-dom';

const CloneExistingForm = () => {


    const navigate = useNavigate()


    const { sendToServerData } = useData();
    const { setDrawerData } = useFormContext();



    const [active, setActive] = useState(null)
    const [searchQuery, setSearchQuery] = useState('');


    const handleChoiceForm = (id, state) => {
        setActive(id)
        setDrawerData(state)
    }


    const handleNavigate = () => {
        navigate('/build')
    }



    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = sendToServerData.filter(item =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );



    return (
        <Grid width={'100%'} height={'100vh'} bgcolor={'white'}>
            <MainHeader />
            <Grid p={'28px 32px'}>
                <CreateFormNavbar title={"فرم موجود خود را شبیه‌سازی کنید"} subTitle={"یک نسخه از یکی از فرم‌های موجود خود ایجاد کنید"} back={'/createForm/ImportForm'} flag={true} />
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width={'100%'} maxWidth={'720px'} mx={'auto'}>
                    <Grid className='searchInput' width={'100%'} sx={{ position: 'relative' }} mb={'24px'}>
                        <input
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder='در فرم های خود جستجو کنید'
                            style={{
                                color: '#0a1551',
                                backgroundColor: '#f3f3fe',
                                width: '100%',
                                border: 'none',
                                borderRadius: '4px',
                                padding: '1rem 3.5rem 1rem 1rem',
                                fontSize: '16px',
                                outline: 'none'
                            }}
                        />
                        <Grid sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                            <SearchIcon style={{ color: '#686D76', rotate: '90deg' }} fontSize='large' />
                        </Grid>
                    </Grid>
                    <Grid className='formList' width={'100%'}>
                        {
                            filteredData.map(
                                item =>
                                    <Box
                                        onClick={() => { handleChoiceForm(item.id, item.state) }}
                                        sx={{
                                            p: '15px',
                                            border: active === item.id ? '1px solid rgb(0, 153, 255)' : '1px solid rgb(200, 206, 237)',
                                            bgcolor: active === item.id ? 'rgb(237, 248, 255)' : 'rgb(255, 255, 255)',
                                            borderRadius: '2px',
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '16px',
                                            mb: '16px',
                                            cursor: 'pointer',
                                            transition: 'background-color 0.3s ease',
                                            '&:hover': {
                                                backgroundColor: active === null ? 'rgb(243, 243, 254)' : 'rgb(219, 241, 255)',
                                            }
                                        }}
                                    >
                                        <Grid width={'32px'} height={'32px'}>
                                            <img src='/assets/images/checklist.png' alt='' style={{ width: '100%', height: '100%' }} />
                                        </Grid>
                                        <Typography pt={'4px'} variant='h4' fontWeight={700} color={'rgb(10, 21, 81)'}>{item.title}</Typography>
                                    </Box>
                            )
                        }
                    </Grid>
                    <Grid mt={'40px'}>
                        <Button onClick={handleNavigate} disabled={active === null ? true : false} variant='contained' style={{ backgroundColor: '#4675e2', padding: '12px 28px', borderRadius: '4px', cursor: 'pointer', opacity: active === null ? 0.5 : 1 }}>
                            <Typography color={'#fff'} variant='h6' pt={'2px'} fontWeight={700}>
                                فرم ایجاد کنید
                            </Typography>
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default CloneExistingForm