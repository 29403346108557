import { Button, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const CreateFormButton = () => {
    return (
        <Grid width={{ xs: '100%' }} maxWidth={{ md: '300px' }} p={{ xs: '14px 10px', md: '8px 13px' }} display={{ xs:'flex' }} alignItems={'center'} justifyContent={'center'} bgcolor={{ xs: '#f3f3fe', md: 'white' }} borderBottom={{ xs: '1px solid #c8ceed', md: 'none' }} >
            <Link to={'/createForm'} style={{ width: '100%' }}>
                <Button sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '42px', p: '14px', bgcolor: '#ff6100', color: 'white', fontSize: '16px', borderRadius: '4px', '&:hover': { bgcolor: '#DC5F00' } }}>
                    فرم ایجاد کنید
                </Button>
            </Link>
        </Grid>
    )
}

export default CreateFormButton