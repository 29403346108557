import React, { createContext, useState } from 'react';

const ThankYouInitialContentContext = createContext();

export const ThankYouInitialContentProvider = ({ children }) => {
    const [src, setSrc] = useState('');
    const [id, setId] = useState(null);

    return (
        <ThankYouInitialContentContext.Provider value={{ src, setSrc, id, setId }}>
            {children}
        </ThankYouInitialContentContext.Provider>
    );
};

export default ThankYouInitialContentContext;
