import { Grid } from '@mui/material'
import React from 'react'
import Navbar from '../Component/Navbar'
import HeaderSetting from '../Component/HeaderSetting'
import SettingDrawer from '../Component/SettingDrawer'
import LayoutCondition from '../Component/LayoutCondition'
import { PreviewConfirm } from '../Component/Icons'
import Condition from '../Component/Condition'
import { useComponentTitleContext } from '../Context/ComponentTitleContext';


const ThankYou = () => {


    const { titles } = useComponentTitleContext();



    return (
        <Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'نمایش/پنهان کردن فیلد'} subTitle={'قابلیت مشاهده فیلدهای فرم را تغییر دهید'} link={'/Setting/Conditional'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={3} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={3} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'تغییر صفحه تشکر'} subTitle={'سفارشی‌سازی عملکرد صفحه تشکر شما'} link={'/Setting/Conditional'} icon={<PreviewConfirm fill={'white'} />} color={'#049e38'} />
                    <Condition options={titles} flag={false} color={'#049e38'} fieldFlag={false} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ThankYou