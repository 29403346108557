import { Button, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import FilteredValuesContext from '../Context/FilteredValuesContext';
import UrlPayloadContext from '../Context/UrlPayloadContext';
import { PayloadContext } from '../Context/PayloadContext';
import ReactDOMServer from 'react-dom/server';
import { useFormContext } from '../Context/Context';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SelectValuesContext } from '../Context/SelectValuesContext';
import { ThankYouConditionEditorContext } from '../Context/ThankYouConditionEditorContext';
import { ThankYouMessageContext } from '../Context/ThankYouMessageContext';


const ThankYouModal = () => {


    const { filteredSelectValuesList, setFilteredSelectValuesList } = useContext(FilteredValuesContext);
    const { urlPayload, setUrlPayload } = useContext(UrlPayloadContext); // Using UrlPayloadContext
    const { payload, setPayload } = useContext(PayloadContext);
    const { drawerData, handleDrawerData, handleDelete, updateComponentStatesById } = useFormContext();
    const { selectValues, setSelectValues } = useContext(SelectValuesContext);
    const { finalPayload, setFinalPayload } = useContext(ThankYouConditionEditorContext)
    const { showThankYouMessage, setShowThankYouMessage } = useContext(ThankYouMessageContext);








    const [message, setMessage] = useState('message');


    const navigate = useNavigate()


    const defaultDataCondition = [

        { id: uuidv4(), name: 'ifCondition', value: '' },
        { id: uuidv4(), name: 'ifConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'ifConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'ifConditionThankYou', value: '' },
        { id: uuidv4(), name: 'ifConditionSkip', value: '' },
        { id: uuidv4(), name: 'ifConditionEmail', value: '' },
        { id: uuidv4(), name: 'StateCondition', value: '' },
        { id: uuidv4(), name: 'StateConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'StateConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'StateConditionThankYou', value: '' },
        { id: uuidv4(), name: 'StateConditionSkip', value: '' },
        { id: uuidv4(), name: 'StateConditionEmail', value: '' },
        { id: uuidv4(), name: 'valueCondition', value: '' },
        { id: uuidv4(), name: 'valueConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'valueConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'valueConditionThankYou', value: '' },
        { id: uuidv4(), name: 'valueConditionSkip', value: '' },
        { id: uuidv4(), name: 'valueConditionEmail', value: '' },
        { id: uuidv4(), name: 'DoCondition', value: '' },
        { id: uuidv4(), name: 'DoConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'DoConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'DoConditionThankYou', value: '' },
        { id: uuidv4(), name: 'DoConditionSkip', value: '' },
        { id: uuidv4(), name: 'DoConditionEmail', value: '' },
        { id: uuidv4(), name: 'FieldCondition', value: '' },
        { id: uuidv4(), name: 'FieldConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'FieldConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'TargetStatus', value: null },
        { id: uuidv4(), name: 'TargetStatusUpdateAndCalculate', value: null },
        { id: uuidv4(), name: 'TargetStatusEnableAndRequire', value: null },
        { id: uuidv4(), name: 'TargetStatusThankYou', value: null },
        { id: uuidv4(), name: 'TargetStatusSkip', value: null },
        { id: uuidv4(), name: 'TargetStatusEmail', value: null },
        { id: uuidv4(), name: 'ifFieldCondition', value: null },
        { id: uuidv4(), name: 'ifFieldConditionUpdateAndCalculate', value: null },
        { id: uuidv4(), name: 'ifFieldConditionEnableAndRequire', value: null },
        { id: uuidv4(), name: 'ifFieldConditionThankYou', value: null },
        { id: uuidv4(), name: 'valueEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'urlValue', value: '' },
        { id: uuidv4(), name: 'emailValue', value: '' },
        { id: uuidv4(), name: 'editorValue', value: '' },
        { id: uuidv4(), name: 'fromValue', value: '' },
        { id: uuidv4(), name: 'toValue', value: '' },
        { id: uuidv4(), name: 'summaryValue', value: '' },
        { id: uuidv4(), name: 'FinalThankYouContent', value: '' },
        { id: uuidv4(), name: 'CalculateValue', value: '' },


    ]


    let thankYouIndex;
    let thankYouValue;
    let urlIndex;
    let urlValue;
    let payloadIndex;
    let payloadValue;

    // Find the index where the condition "DoConditionThankYou" exists
    thankYouIndex = filteredSelectValuesList.findIndex(item => item.some(condition => condition.name === "DoConditionThankYou"));

    payloadIndex = filteredSelectValuesList.findIndex(item => item.some(condition => condition.name === "FinalThankyouContent"));

    urlIndex = filteredSelectValuesList.findIndex(item => item.some(condition => condition.name === "urlValue"));


    // Ensure thankYouIndex is valid and get the value
    if (thankYouIndex !== -1) {
        let thankYouFindValue = filteredSelectValuesList[thankYouIndex].find(condition => condition.name === "DoConditionThankYou");
        thankYouValue = thankYouFindValue.value;
    }


    if (payloadIndex !== -1) {
        let payloadFindValue = filteredSelectValuesList[thankYouIndex].find(condition => condition.name === "FinalThankyouContent");
        payloadValue = payloadFindValue.value;
    }

    if (urlIndex !== -1) {
        let UrlFindValue = filteredSelectValuesList[urlIndex].find(condition => condition.name === "urlValue");
        urlValue = UrlFindValue.value;
    }


    console.log(urlValue)



    const handleClose = () => {

        setMessage(false)
        navigate('/');
        handleDrawerData([])
        setSelectValues(defaultDataCondition)
        setFilteredSelectValuesList([])

    }


    useEffect(() => {
        if (thankYouValue !== 'نمایش پیام سفارشی' && (urlValue || urlPayload)) {
            window.location.href = urlValue || urlPayload[0].redirectUri;

            setTimeout(() => {
                handleDrawerData([])
                setSelectValues(defaultDataCondition)
                setFilteredSelectValuesList([])
            }, [3000])

            setTimeout(() => {
                navigate('/')
            }, [5000])
        }
    }, [thankYouValue, urlPayload, navigate, urlValue]);




    return (
        showThankYouMessage &&
        <Grid>
            {
                thankYouValue === 'نمایش پیام سفارشی' ?
                    <Grid width={'100%'} height={'100vh'} position={'fixed'} right={0} top={0} bgcolor={'#BBB'} display={thankYouIndex !== -1 && thankYouValue === 'نمایش پیام سفارشی' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'center'} zIndex={10000000000000} style={{ opacity: 0.9 }}>
                        <Grid width={'80%'} height={'60%'} bgcolor={'white'} borderRadius={'8px'} pb={'48px'} position={'relative'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                            <Grid position={'absolute'} left={'20px'} top={'20px'} onClick={handleClose}>
                                <CloseIcon fontSize='large' style={{ color: 'black', cursor: 'pointer' }} />
                            </Grid>
                            <div dangerouslySetInnerHTML={{ __html: finalPayload.content }} />
                            <Grid>
                                <Button variant='contained' onClick={handleClose}>
                                    <Typography>بستن</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <a href={urlValue || (urlPayload && urlPayload[0].redirectUri)} target='_blank' rel='noopener noreferrer'></a>
            }
        </Grid>
    )
}

export default ThankYouModal