import React, { createContext, useState } from 'react';

// Create the context
export const AdvanceNotificationEmailContext = createContext();

// Create a provider component
export const AdvanceNotificationEmailProvider = ({ children }) => {
    const [emails, setEmails] = useState([]);

    const addEmails = (newEmails) => {
        setEmails(prevEmails => [...prevEmails, ...newEmails]);
    };

    return (
        <AdvanceNotificationEmailContext.Provider value={{ emails, addEmails }}>
            {children}
        </AdvanceNotificationEmailContext.Provider>
    );
};
