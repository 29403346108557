import React, { createContext, useState, useContext } from 'react';

const SelectedItemContext = createContext();

export const SelectedItemProvider = ({ children }) => {
    const [selectedItem, setSelectedItem] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState(null);

    return (
        <SelectedItemContext.Provider value={{ selectedItem, setSelectedItem ,selectedItemId , setSelectedItemId}}>
            {children}
        </SelectedItemContext.Provider>
    );
};

export const useSelectedItem = () => useContext(SelectedItemContext);
