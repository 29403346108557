import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import Navbar from '../Component/Navbar'
import HeaderSetting from '../Component/HeaderSetting'
import SettingDrawer from '../Component/SettingDrawer'
import LayoutCondition from '../Component/LayoutCondition'
import { EmailLink, EmailSetting } from '../Component/Icons'
import TinyEditor from '../Component/EmailContentEditor/TinyEditor'
import { useTitleContext } from '../Context/TitleContext'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'



const EmailTab = () => {


    const { title } = useTitleContext();



    const [show, setShow] = useState(false)
    const [subject, setSubject] = useState('بعداً این فرم را پر کنید:')


    const editorRef = useRef(null);




    const handleShowDropDown = () => {
        setShow(!show)
    }

    const handleSubjectChange = (event) => {
        setSubject(event.target.value)
    }


    const handleSubmit = () => {
        if (editorRef.current) {
            const content = editorRef.current.getContent();

            const id = uuidv4();
            const data = [{ id, subject, content }];


            console.log(data)

            axios.post('/your-server-endpoint', data)
                .then(response => {
                    console.log('Success:', response.data);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    return (
        <Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'سفارشی‌سازی ایمیل ذخیره و ادامه دهید'} subTitle={'محتوا و آدرس فرستنده را سفارشی کنید'} link={'/Settings/general'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={1} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={1} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'سفارشی‌سازی ایمیل ذخیره و ادامه دهید'} subTitle={'محتوا و آدرس فرستنده را سفارشی کنید'} link={'/Settings/general'} icon={<EmailSetting fill={'white'} />} color={'rgb(78, 108, 154)'} />
                    <Grid>
                        <Grid className='main' bgcolor={'white'} mb={'14px'}>
                            <Box width={'100%'} height={'48px'} display={'flex'} alignItems={'center'} bgcolor={'#fafafc'} borderBottom={'1px solid #eaebf2'}>
                                <Link to={'/Setting/general/CustomizeSaveEmail'} style={{ width: '50%', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid width={'100%'} height={'48px'} borderBottom={'3px solid #5e74e6'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant='h5' fontWeight={500} color={'#5e74e6'}>ایمیل</Typography>
                                    </Grid>
                                </Link>
                                <Link to={'/Setting/general/CustomizeSaveEmail/Advance'} style={{ width: '50%', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid width={'100%'} height={'48px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant='h5' fontWeight={500} color={'black'}>پیشرفته</Typography>
                                    </Grid>
                                </Link>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Grid p={'21px 42px'}>
                                    <Grid className='subject' mb={'22px'}>
                                        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Grid display={'flex'} alignItems={'center'}>
                                                <Typography variant='h5' fontWeight={700} color={'#23283a'}>موضوع نامه الکترونیکی</Typography>
                                                <Typography variant='h5' fontWeight={700} color={'#d53049'}>*</Typography>
                                            </Grid>
                                            <Grid position={'relative'}>
                                                <Button onClick={handleShowDropDown} sx={{ padding: '7px', paddingLeft: '21px', display: 'flex', alignItems: 'center', gap: '4px', '&:hover': { backgroundColor: '#f0f2fd' } }} >
                                                    <Grid width={'16px'} height={'16px'} borderRadius={'50%'} bgcolor={'#5e74e6'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                        <EmailLink />
                                                    </Grid>
                                                    <Typography color={'#5e74e6'} variant='h6' fontWeight={500}>فیلدهای فرم</Typography>
                                                    <KeyboardArrowDownIcon fontSize='small' style={{ color: '#5e74e6' }} />
                                                </Button>
                                                <Grid display={show ? 'block' : 'none'} p={'8px 4px 16px 4px'} borderRadius={'4px'} bgcolor={'white'} position={'absolute'} width={'288px'} left={0}>
                                                    <Grid width={'100%'} bgcolor={'#f2f3f8'} p={'8px'} mb={'10px'}>
                                                        <Typography variant='h6' color={'#6a6e8f'} fontWeight={500}>فرم</Typography>
                                                    </Grid>
                                                    <Button onClick={handleShowDropDown} sx={{ width: '100%', backgroundColor: 'transparent', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', padding: '7px', '&:hover': { backgroundColor: '#f6f6f6' } }}>
                                                        <Typography variant='h6' color={'#23283a'} fontWeight={500}>عنوان فرم</Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid className='input'>
                                            <input
                                                value={subject}
                                                onChange={handleSubjectChange}
                                                style={{
                                                    lineHeight: '32px',
                                                    padding: '2px 9px',
                                                    width: '100%',
                                                    fontSize: '12px',
                                                    border: '1px solid #d8dae9',
                                                    borderRadius: '4px',
                                                    whiteSpace: 'break-spaces',
                                                    outline: 0
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid className='content'>
                                        <Grid display={'flex'} alignItems={'center'} mb={'8px'}>
                                            <Typography variant='h5' fontWeight={700} color={'#23283a'}>محتوای ایمیل</Typography>
                                            <Typography variant='h5' fontWeight={700} color={'#d53049'}>*</Typography>
                                        </Grid>
                                        <Grid>
                                            <TinyEditor title={`شما یک پیش نویس برای ${title} ذخیره کرده اید`} subTitle={`شما پیش‌نویسی را برای «${title}» ذخیره کرده‌اید. لطفا روی دکمه زیر کلیک کنید تا فرم خود را ادامه دهید.`} inputTitle={'بعداً این فرم را پر کنید:'} titleSize={'18px'} subTitleSize={'14px'} page={'CustomizeSaveEmail'} align={'center'} editorRef={editorRef} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'24px'}>
                            <Grid onClick={handleSubmit} style={{ cursor: 'pointer' }} className='save' p={'12px 16px'} bgcolor={'rgba(100,178,0,1)'} fontSize={'14px'} color={'white'} borderRadius={'4px'}>ذخیره</Grid>
                            <Grid style={{ cursor: 'pointer' }} className='text' p={'12px 16px'} bgcolor={'rgba(218,222,243,1)'} fontSize={'14px'} color={'rgba(52,60,106,1)'} borderRadius={'4px'}>تست ایمیل</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EmailTab