import React, { useEffect, useRef, useState } from 'react'
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ReCaptcha from "./ReCaptcha";
import HiCaptcha from "./HiCaptcha";
import SimpleCaptcha from "./SimpleCaptcha";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';

const Captcha = ({ id }) => {


    const { updateComponentStatesById, drawerData, addComponent } = useFormContext();

    const location = useLocation();
    const currentPage = location.pathname;




    const existingComponentIndex = drawerData.findIndex(component => component.id === id);

    const [captchaValue, setCaptchaValue] = useState("خواهشمندیم اعلام کنید که ربات نیستید.");
    const [border, setBorder] = useState(false);
    const [select, setSelect] = useState(3)
    const [show, setShow] = useState(false)

    const gridRef = useRef(null);
    const theme = useTheme();
    const isSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const isXxs = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let HeadingColor = theme.palette.blue[700];


    const data = [
        { id: 1, src: '/assets/images/recaptcha.svg' },
        { id: 2, src: '/assets/images/hcaptcha-2.svg' },
        { id: 3, src: '/assets/images/ic8.svg' }
    ]


    const fillInputValue = () => {
        drawerData[existingComponentIndex].state.select = select
    }


    const handlePhoneChange = (event) => {
        setCaptchaValue(event.target.value);
    };


    const handleClick = (id) => {
        setSelect(id);
        setShow(false)
    }

    const handleHeadingFocus = (event) => {
        event.target.select();
    };

    const handleOutsideClick = (event) => {
        if (gridRef.current && !gridRef.current.contains(event.target)) {
            setBorder(false);
        }
    };


    // useEffect(() => {
    //     const existingComponentIndex = drawerData.findIndex(component => component.id === id);
    //     if (existingComponentIndex !== -1) {
    //         updateComponentStatesById(id, { select , captchaValue });
    //     } else {
    //         addComponent({ id, select , captchaValue });
    //     }
    // }, [select]);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, select]);


    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const width = isSm ? '45%' : '100%'
    const font = isXxs ? '16px' : '12px'



    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '24px 36px'}
            borderRadius={'3px'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'} bgcolor={'white'}
            ref={gridRef} onClick={() => setBorder(true)}
            border={border ? '2px solid #0293e2' : '2px solid transparent'}>
            <Grid pb={'16px'} display={'flex'} alignItems={'center'} gap={'8px'}>
                <input
                    className="customHead-input"
                    placeholder={'Type a question'}
                    style={{
                        border: 'none',
                        outline: 'none',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: width,
                        fontSize: font,
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                    }}
                    value={captchaValue}
                    onChange={handlePhoneChange}
                    onFocus={handleHeadingFocus}
                />
                <Grid onClick={() => {
                    setShow(!show)
                }} width={'30px'} height={'30px'} display={'flex'} alignItems={'center'} justifyContent={'center'}
                    style={{ cursor: 'pointer' }}
                    borderRadius={'4px'} bgcolor={'#7f859c'} >
                    <SettingsIcon style={{ color: 'white' }} />
                </Grid>
            </Grid>
            <Grid display={show ? 'flex' : 'none'} alignItems={'center'} gap={'12px'} pb={'16px'}>
                {
                    data.map(
                        item =>
                            <Grid width={'100px'} height={'80px'} borderRadius={'4px'} bgcolor={'white'}
                                border={'1px solid #33384a'} display={'flex'} alignItems={'center'}
                                justifyContent={'center'}
                                style={{ cursor: 'pointer' }}
                                onClick={() => { handleClick(item.id) }}
                            >
                                <img src={item.src} alt={''} width={'100%'} height={'60px'} />
                            </Grid>
                    )
                }
            </Grid>
            <Grid display={select === 1 ? 'block' : 'none'}>
                <ReCaptcha />
            </Grid>
            <Grid display={select === 2 ? 'block' : 'none'}>
                <HiCaptcha />
            </Grid>
            <Grid display={select === 3 ? 'block' : 'none'}>
                <SimpleCaptcha />
            </Grid>
        </Grid>
    )
}
export default Captcha
