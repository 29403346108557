import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Grid,
    useMediaQuery,
    useTheme,
    Button
} from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import { useFormContext } from "../Context/Context";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { useReadOnly } from "../Context/ReadOnlyContext";
import { FinalValuesContext } from "../Context/FinalValuesContext";
import ConvertTitle from "./ConvertTitle";
import { useLocation } from "react-router-dom";




const CustomToast = ({ message, state }) => {
    return (

        state === 'success' ?
            <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                backgroundColor: 'white',
                color: 'green',
                fontSize: '16px',
                padding: '15px 10px',
                fontWeight: 900,
            }}>
                <CheckCircleIcon fontSize={'large'} />
                {message}
            </Grid>
            :
            <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                backgroundColor: 'white',
                color: 'red',
                fontSize: '16px',
                padding: '15px 10px',
                fontWeight: 900,
            }}>
                <ErrorIcon fontSize={'large'} />
                {message}
            </Grid>

    );
};



const SignatureBox = ({ onSaveSignature, readOnly }) => {


    const { finalSelectValues } = useContext(FinalValuesContext);
    const { drawerData } = useFormContext();
    // const { readOnly } = useReadOnly();


    // Reference to the signature canvas element
    const [condition, setCondition] = useState(false)

    const signatureCanvas = useRef(null);


    const location = useLocation();
    const currentPage = location.pathname;


    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly

    // Function to clear the signature
    const clearSignature = () => {
        if (!readOnly && signatureCanvas.current) {
            signatureCanvas.current.clear();
        }
    };


    const saveSignature = () => {
        if (signatureCanvas.current.isEmpty()) {
            toast(<CustomToast message={'ابتدا امضا خود را وارد کنید'} state={'error'} />)
            return;
        }
        const dataURL = signatureCanvas.current.toDataURL();
        onSaveSignature(dataURL);
        toast(<CustomToast message={'امضا با موفقیت ذخیره شد'} state={'success'} />)
        clearSignature()
    };

    return (
        <div>
            <SignatureCanvas
                readOnly={readOnly}
                ref={signatureCanvas}
                penColor="black"
                canvasProps={{ width: 300, height: 110, className: 'sigCanvas' }}
            />
            <Button disabled={readOnly} variant="contained" style={{ fontSize: '12px', marginLeft: '24px' }} onClick={clearSignature}>پاک کردن</Button>
            <Button disabled={readOnly} variant="contained" color="success" style={{ fontSize: '12px' }} onClick={saveSignature}>ذخیره</Button>
        </div>
    );
};




const Signature = ({ id }) => {


    const { drawerData } = useFormContext();
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);



    const existingComponentIndex = drawerData.findIndex(component => component.id === id);



    const [signatureValue, setSignatureValue] = useState("امضا");
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const [signatureImage, setSignatureImage] = useState(null);
    const [condition, setCondition] = useState(false)


    const containerRef = useRef(null);
    const signatureRef = useRef();


    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly


    const location = useLocation();
    const currentPage = location.pathname;





    const fillInputValue = () => {
        drawerData[existingComponentIndex].state.signatureValue = signatureValue
        drawerData[existingComponentIndex].state.mainSignatureImage = signatureImage
        drawerData[existingComponentIndex].state.readOnly = readOnly
    }



    const isReadOnly = readOnly || currentPage === '/build' || currentPage === "/build/CardForm" || condition;


    const handleCondition = () => {
        if (currentPage === '/Publish') {
            setCondition(readOnly || (dataCondition && title === 'Signature'));
        } else {
            setCondition(false);
        }
    };


    let theme = useTheme();
    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up("xxs"));
    let HeadingColor = theme.palette.blue[700];

    const gridStyle = {
        width: isUpXxl ? "310px" : "90%",
        height: "112px",
        border: "1px solid #b8bdc9",
        color: "#2c3345",
        bgcolor: "white",
        borderRadius: "4px",
        backgroundImage: isDrawing ? "none" : 'url("assets/images/Signature.svg")',
        backgroundSize: "230px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%",
    };

    const handleSaveSignature = (dataURL) => {
        setSignatureImage(dataURL);
    };

    const handleMouseMove = () => {
        if (isDrawing) {
            if (signatureRef.current) {
                signatureRef.current.fromDataURL("data:image/png;base64,");
            }
        }
    };

    const handleMouseUp = () => {
        setIsDrawing(false);
    };

    const handleStarChange = (event) => {
        setSignatureValue(event.target.value);
    };

    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            // Clicked outside the component
            setIsDrawing(false);
            setSubHeaderVisible(false);
            setHeadingFocused(false);
        } else {
            // Clicked inside the component
            setSubHeaderVisible(true);
            setHeadingFocused(true);
        }
    };


    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, signatureImage, signatureValue]);


    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDrawing]);


    useEffect(() => {
        handleCondition();
    }, [finalSelectValues, readOnly, currentPage]);

    return (
        <Grid
            mb={currentPage === '/build'|| currentPage === '/Publish' ? '28px' : '50px'}
            p={currentPage === '/build'|| currentPage === '/Publish' ? '12px 10px' : '24px 36px'}
            borderRadius={'3px'}
            mx={currentPage === '/build'|| currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={"white"}
            ref={containerRef}
            border={`2px solid ${isHeadingFocused ? "#0293e2" : "transparent"}`}
            onClick={handleClickOutside}
        >
            <Grid className={"HeadingInput"} pb={"14px"}>
                <input
                    className="customHead-input"
                    placeholder={"یک عنوان وارد کنید"}
                    style={{
                        border: "none",
                        outline: "none",
                        userSelect: "text",
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        width: "100%",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                        "::placeholder": {
                            color: "#b8bdc9", // Placeholder text color
                        },
                    }}
                    value={signatureValue}
                    onChange={handleStarChange}
                    onClick={handleClickOutside}
                    onFocus={handleHeadingFocus}
                    readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                />
            </Grid>
            <Grid style={gridStyle} mb={"4px"}>
                <SignatureBox onSaveSignature={handleSaveSignature} readOnly={isReadOnly} />
            </Grid>
        </Grid>
    );
};
export default Signature;
