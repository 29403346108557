import React, { useState } from 'react'
import { AutoEmail, DigestEmail, EmailNotofication, EmailSettingPage } from '../Component/Icons'
import { Grid, Typography } from '@mui/material';
import Navbar from '../Component/Navbar';
import HeaderSetting from '../Component/HeaderSetting';
import SettingDrawer from '../Component/SettingDrawer';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';

const EmailSettingOptions = () => {

    const [show, setShow] = useState(false)


    const handleopenOption = () => {
        setShow(true)
    }


    const handleCloseOption = () => {
        setShow(false)
    }


    const EmailOptions = [
        { id: 1, link:'/Setting/EmailSetting/NotificationEmail' ,  borderColor: 'rgba(255,123,28,1)', image: <EmailNotofication />, title: 'ایمیل اطلاع رسانی', subTitle: 'زمانی که شخصی فرم شما را پر می کند، ایمیلی دریافت کنید' },
        { id: 2, link: '/Setting/EmailSetting/AutoresponderEmail', borderColor: 'rgba(127,202,0,1)', image: <AutoEmail />, title: 'ایمیل پاسخگوی خودکار', subTitle: 'برای شخصی که فرم شما را پر می کند ایمیل بفرستید' },
        { id: 3, borderColor: 'rgba(156,77,211,1)', image: <DigestEmail />, title: 'ایمیل خلاصه', subTitle: 'برای ارسال‌های جدید ایمیل‌های خلاصه دریافت کنید' },
    ]


    return (
        <>
            <Grid>
                <Grid display={{ xs: 'none', xxs: 'block' }}>
                    <Navbar active={2} state={true} />
                </Grid>
                <Grid display={{ xs: 'block', xxs: 'none' }} >
                    <Grid width={'100%'} bgcolor={'#3e4652'} display={'flex'} alignItems={'center'} mb={'60px'}>
                        <Link to={'/Setting/Email'}>
                            <Grid p={'12px'}>
                                <ArrowForwardIosIcon style={{ color: 'white' }} />
                            </Grid>
                        </Link>
                        <Grid width={'1px'} height={'68px'} bgcolor={'white'}></Grid>
                        <Grid p={'12px'}>
                            <Typography variant='h4' color={'white'} fontWeight={700}>{'ایمیل جدید'}</Typography>
                            <Typography variant='h6' color={'rgba(255,255,255,.6)'}>{'ارسال خودکار و اعلان ها'}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid mt={{ xs: '0px', xxs: '118px' }} position={'relative'} display={'flex'} alignItems={'flex-start'}>
                    <Grid display={{ xs: 'none', xxs: 'block' }}>
                        <SettingDrawer isActive={7} flag={show} />
                    </Grid>
                    <Grid className='main' pl={{ xs: '10px', xxs: '20px' }} pr={{ xs: '10px', xxs: '60px' }} mt={{ xs: '0', xxs: '80px' }} position={{ xxs: 'absolute' }} left={'20px'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }}>
                        <Grid pb={'20px'} className='newEmail' display={{ xs: 'none', xxs: 'flex' }} alignItems={'center'} gap={'8px'}>
                            <Grid pt={'5px'} position={'fixed'} right={'35%'} onClick={handleCloseOption} style={{ cursor: 'pointer' }} display={show ? 'block' : 'none'}>
                                <ArrowForwardIosIcon fontSize='large' />
                            </Grid>
                            <>
                                <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} width={'40px'} height={'40px'} borderRadius={'4px'} bgcolor={'rgb(78, 108, 154)'}>
                                    <EmailSettingPage fill={'white'} />
                                </Grid>
                                <Grid>
                                    <Typography variant='h5' color={'black'} fontWeight={700} lineHeight={'24px'}>ایمیل جدید</Typography>
                                    <Typography variant='h6' color={'rgb(111, 118, 167)'} fontWeight={500} lineHeight={'16px'}>یک نوع ایمیل در زیر برای ایجاد ایمیل خود انتخاب کنید.</Typography>
                                </Grid>
                            </>
                        </Grid>
                        <Grid>
                            {
                                EmailOptions.map(
                                    option =>
                                        <Link to={option.link}> 
                                            <Grid mb={'12px'} key={option.id} p={'20px 16px'} borderRight={`7px solid ${option.borderColor}`} display={'flex'} alignItems={'center'} borderRadius={'4px'} bgcolor={'white'} gap={'16px'}>
                                                <Grid width={'40px'} height={'40px'} borderRadius={'4px'} bgcolor={option.borderColor} display={'flex'} alignItems={'center'} justifyContent={'center'} >
                                                    {option.image}
                                                </Grid>
                                                <Grid>
                                                    <Typography color={'#0a1551'} variant='h5' fontWeight={500} lineHeight={'24px'}>{option.title}</Typography>
                                                    <Typography variant='h6' color={'rgba(108,115,168,1)'} fontWeight={500}>{option.subTitle}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Link>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default EmailSettingOptions