import React, { useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from 'react-router-dom';

const Submit = () => {

    const location = useLocation();
    const currentPage = location.pathname;

    const theme = useTheme()

    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));

    const submitWidth = isUpXxl ? '180px' : '100px'


    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const containerRef = useRef(null);


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    return (
        <Grid display={'flex'} alignItems={'center'} justifyContent={'center'}
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '24px 36px'} borderRadius={'3px'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'} bgcolor={'white'}
            ref={containerRef}
            border={`2px solid ${isHeadingFocused ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}
        >
            <button style={{ backgroundColor: theme.palette.SubmitButtonColor, width: submitWidth, borderRadius: '4px', border: 'none', cursor: 'pointer', outline: 'none', fontSize: '16px', fontWeight: 500, height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant={'h5'} color={'white'}>ارسال</Typography>
            </button>
        </Grid>
    )
}
export default Submit
