import React, { createContext, useContext, useEffect, useState } from 'react';

const TitleContext = createContext();

export const useTitleContext = () => useContext(TitleContext);

export const TitleProvider = ({ children }) => {
  const [title, setTitle] = useState('فرم'); // Initial title state

  const setTitleValue = (newTitle) => {
    setTitle(newTitle);
  };

  // useEffect(()=>{
  //   return ()=>{
  //     setTitle('فرم')
  //   }
  // } , [])

  return (
    <TitleContext.Provider value={{ title, setTitle: setTitleValue }}>
      {children}
    </TitleContext.Provider>
  );
};
