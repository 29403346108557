import { Grid } from '@mui/material'
import React, { useRef, useState } from 'react'
import Navbar from '../Component/Navbar'
import HeaderSetting from '../Component/HeaderSetting'
import SettingDrawer from '../Component/SettingDrawer'
import LayoutCondition from '../Component/LayoutCondition'
import { AutoEmail } from '../Component/Icons'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StateSelect from '../Component/StateSelect'
import CustomizeSaveEmail from '../Component/CustomizeSaveEmail'
import { useTitleContext } from '../Context/TitleContext'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom'
import Advance from '../Component/Advance/Advance'


const AutoresponderEmail = () => {

    const { title } = useTitleContext();
    const [subject, setSubject] = useState("ما پاسخ شما را دریافت کرده ایم");
    const [value, setValue] = useState(0);


    const location = useLocation();
    const currentPage = location.pathname;


    const editorRef = useRef(null);


    const senderOption = []

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }



    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };


    const handleSaveClick = () => {
        if (editorRef.current) {
            const content = editorRef.current.getContent();

            const id = uuidv4();
            const data = [{ id, subject, content }];


            console.log(data)

            axios.post('/your-server-endpoint', data)
                .then(response => {
                    console.log('Success:', response.data);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };


    return (
        <Grid bgcolor={'#f3f3fe'}>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'تنظیمات ایمیل'} subTitle={'ارسال خودکار و اعلان ها'} link={'/Setting/Email'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={2} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={2} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'پاسخگوی خودکار'} subTitle={'برای شخصی که فرم شما را پر می کند ایمیل بفرستید'} link={'/Setting/Email'} icon={<AutoEmail fill={'white'} />} color={'rgba(127,202,0,1)'} />
                    <Grid>
                        <Grid className='main'  mb={'14px'}>
                            <Box sx={{ width: '100%' }}>
                                <Box
                                    width={"100%"}
                                    height={"48px"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    bgcolor={"#fafafc"}
                                    borderBottom={"1px solid #eaebf2"}
                                >
                                    <Link
                                        to={"/Setting/EmailSetting/AutoresponderEmail"}
                                        style={{
                                            width: "50%",
                                            height: "48px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Grid
                                            width={"100%"}
                                            height={"48px"}
                                            borderBottom={"3px solid #5e74e6"}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                        >
                                            <Typography variant="h5" fontWeight={500} color={"#5e74e6"}>
                                                ایمیل
                                            </Typography>
                                        </Grid>
                                    </Link>
                                    <Link
                                        to={"/Setting/EmailSetting/AutoresponderEmail/Sender"}
                                        style={{
                                            width: "50%",
                                            height: "48px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Grid
                                            width={"100%"}
                                            height={"48px"}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                        >
                                            <Typography variant="h5" fontWeight={500} color={"black"}>
                                                گیرندگان
                                            </Typography>
                                        </Grid>
                                    </Link>
                                    <Link
                                        to={"/Setting/EmailSetting/AutoresponderEmail/Advance"}
                                        style={{
                                            width: "50%",
                                            height: "48px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Grid
                                            width={"100%"}
                                            height={"48px"}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                        >
                                            <Typography variant="h5" fontWeight={500} color={"black"}>
                                                پیشرفته
                                            </Typography>
                                        </Grid>
                                    </Link>
                                </Box>
                                <Box sx={{width:'100%'}}>
                                    <CustomizeSaveEmail page={'AutoResponderEmail'} name={'AutoResponderEmail'} />
                                </Box>
                                <CustomTabPanel value={value} index={1}>
                                    <Grid p={'21px 18px'} width={'100%'}>
                                        <Grid className='Sender' mb={'22px'}>
                                            <Typography variant='h5' color={'#23283a'} fontWeight={600} pb={'4px'}>نام فرستنده</Typography>
                                            <input
                                                placeholder='Altyn'
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #d8dae9',
                                                    backgroundColor: 'white',
                                                    borderRadius: '4px',
                                                    padding: '9px 12px',
                                                    color: '#23283a',
                                                    fontSize: '16px',
                                                    outline: 'none'
                                                }}
                                            />
                                        </Grid>
                                        <Grid className='reply' mb={'22px'}>
                                            <Typography variant='h5' color={'#23283a'} fontWeight={600} pb={'4px'}>پاسخ به ایمیل</Typography>
                                            <input
                                                placeholder='noreply@jotform.com'
                                                style={{
                                                    textAlign: 'left',
                                                    width: '100%',
                                                    border: '1px solid #d8dae9',
                                                    backgroundColor: 'white',
                                                    borderRadius: '4px',
                                                    padding: '9px 12px',
                                                    color: '#23283a',
                                                    fontSize: '16px',
                                                    outline: 'none'
                                                }}
                                            />
                                        </Grid>
                                        <Grid className='recipient'>
                                            <Grid display={'flex'} alignItems={'center'} gap={'2px'} mb={'4px'}>
                                                <Typography variant='h5' color={'#23283a'} fontWeight={600}>ایمیل گیرنده</Typography>
                                                <Typography color={'#d53049'} fontWeight={600} variant='h5'>*</Typography>
                                            </Grid>
                                            <StateSelect placeholder={'لطفا انتخاب کنید '} title={senderOption} />
                                        </Grid>
                                    </Grid>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={2}>
                                    <Advance flag={false} />
                                </CustomTabPanel>
                            </Box>
                        </Grid>
                        <Grid>
                            <Grid display={currentPage === '/Setting/EmailSetting/AutoresponderEmail'?'none':'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'8px'}>
                                <Grid onClick={handleSaveClick} className='save' p={'12px 16px'} bgcolor={'rgba(100,178,0,1)'} fontSize={'14px'} color={'white'} borderRadius={'4px'}>ذخیره</Grid>
                                <Grid className='text' p={'12px 16px'} bgcolor={'rgba(218,222,243,1)'} fontSize={'14px'} color={'rgba(52,60,106,1)'} borderRadius={'4px'}>تست ایمیل</Grid>
                            </Grid>
                            <Typography mb={'30px'} variant='h6' fontWeight={500} color={'#2c3345'} lineHeight={'26px'}>
                                لطفاً توجه داشته باشید که ایمیل‌های آزمایشی فقط به آدرس ایمیل مرتبط با حساب شما ارسال می‌شوند.
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default AutoresponderEmail