import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import MainHeader from "../Component/MainHeader";
import CloseIcon from "@mui/icons-material/Close";
import {
  AddCreateForm,
  ImportCreateForm,
  TemplateIcon,
} from "../Component/Icons";
import { useNavigate } from "react-router-dom";

const CreateForm = () => {
  const navigate = useNavigate();

  const templateData = [
    {
      id: 1,
      image: <AddCreateForm fill={"#6f76a7"} />,
      title: "از صفر شروع کنید",
      subTitle: "یک لوح خالی تمام چیزی است که نیاز دارید",
      link: "/createForm/Scratch",
    },
    {
      id: 2,
      image: <TemplateIcon />,
      title: "از قالب استفاده کنید",
      subTitle: "از بیش از 10000 فرم از پیش ساخته شده انتخاب کنید",
      link: "/createForm/Template",
    },
    {
      id: 3,
      image: <ImportCreateForm />,
      title: "وارد کردن فرم",
      subTitle: "یک فرم موجود را در چند ثانیه تبدیل کنید",
      link: "/createForm/ImportForm",
    },
  ];

  const handleClose = () => {
    navigate("/Main");
  };

  const handleNavigate = (link) => {
    console.log(link);
    navigate(link);
  };

  return (
    <Grid width={"100%"} height={"100vh"} bgcolor={"white"}>
      <MainHeader />
      <Grid
        onClick={handleClose}
        width={"40px"}
        height={"40px"}
        borderRadius={"50%"}
        bgcolor={"#e3e5f5"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        m={{ xs: "20px", sm: "20px 40px" }}
        style={{ cursor: "pointer" }}
      >
        <CloseIcon fontSize="large" style={{ color: "#6f76a7" }} />
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mb: "50px",
          }}
        >
          <Typography
            variant="h2"
            fontWeight={700}
            color={"#0a1551"}
            mb={"10px"}
          >
            یک فرم ایجاد کنید
          </Typography>
          <Typography variant="h5" fontWeight={500} color={"#6f76a7"}>
            برای شروع جمع آوری داده ها، فرمی ایجاد یا وارد کنید
          </Typography>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "flex-start",
            gap: { xs: "40px", sm: "60px" },
            mb: "45px",
          }}
        >
          {templateData.map((item) => (
            <Box
              key={item.id}
              onClick={() => {
                handleNavigate(item.link);
              }}
              sx={{
                display: "flex",
                flexDirection: { xs: "row", sm: "column" },
                alignItems: { xs: "flex-start", sm: "center" },
                justifyContent: "center",
                cursor: "pointer",
                width: "100%",
                gap: { xs: "0px", sm: "0px" },
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-3%)',
                  '& .title': {
                    color: '#09f',
                  },
                  '& .image': {
                    border: '2px solid #09f',
                  }
                },
              }}
            >
              <Grid
                className="image"
                mb={{ xs: "14px", sm: "28px" }}
                p={"20px"}
                bgcolor={"#c8ceed"}
                borderRadius={{ xs: "0px 10px 10px 0px", sm: "10px" }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  border: "2px solid transparent",
                  width: { xs: "105px", sm: "163px" },
                  height: { xs: "130px", sm: "153px" },
                  cursor: "pointer",
                  "&:hover": { border: "2px solid #09f" },
                }}
              >
                {item.image}
              </Grid>
              <Grid
                bgcolor="#f3f4f7"
                display={{ xs: "flex", sm: "none" }}
                flexDirection={"column"}
                alignItems={"flex-start"}
                justifyContent={"center"}
                sx={{ height: { xs: "130px", sm: "153px" } }}
                borderRadius={"10px 0px 0px 10px"}
                p={"16px"}
              >
                <Typography
                  className="title"
                  mb={{ xs: "9px", sm: "18px" }}
                  style={{ width: "153px" }}
                  variant="h5"
                  fontWeight={700}
                  sx={{ ":&hover": { color: "#09f" } }}
                >
                  {item.title}
                </Typography>
                <Typography
                  style={{ width: "153px" }}
                  variant="h6"
                  color={"#6f76a7"}
                  fontWeight={500}
                >
                  {item.subTitle}
                </Typography>
              </Grid>
              <Grid display={{ xs: "none", sm: "block" }}>
                <Typography
                  className="title"
                  mb={{ xs: "9px", sm: "18px" }}
                  textAlign={"center"}
                  style={{ width: "153px" }}
                  variant="h4"
                  fontWeight={700}
                  sx={{ ":&hover": { color: "#09f" } }}
                >
                  {item.title}
                </Typography>
                <Typography
                  textAlign={"center"}
                  style={{ width: "153px" }}
                  variant="h5"
                  color={"#6f76a7"}
                  fontWeight={500}
                >
                  {item.subTitle}
                </Typography>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default CreateForm;