import React, { useEffect, useRef, useState } from 'react'
import { LoadCanvasTemplate, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';
import { Grid } from "@mui/material";
import { useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';

const SimpleCaptcha = () => {

    const [userInput, setUserInput] = useState('');
    const captchaRef = useRef(null);


    const location = useLocation();
    const currentPage = location.pathname;


    const CustomToast = ({ closeToast, message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'red',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <ErrorIcon fontSize={'large'} />
                    {message}
                </Grid>

        );
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        if (captchaRef.current) {
            if (userInput.trim() === '') {
                // Reset captcha if userInput is empty
                toast(<CustomToast message="مقدار کپچا را وارد کنید" state={'not'} />);
                loadCaptchaEnginge(6, '#7f859c', '#f5f5f5'); // Re-load the captcha engine
                setUserInput('');
            } else {
                // Validate captcha if userInput is not empty
                const isValid = validateCaptcha(userInput);
                if (isValid) {
                    toast(<CustomToast message="تایید شد" state={'success'} />);
                    setUserInput('');
                    loadCaptchaEnginge(6, '#7f859c', '#f5f5f5'); // Re-load the captcha engine
                } else {
                    toast(<CustomToast message="کپچای نامعتبر است. لطفا دوباره تلاش کنید." state={'not'} />);
                }
            }
        } else {
            console.error('CAPTCHA component not yet ready');
        }
    };



    useEffect(() => {
        loadCaptchaEnginge(6, "#7f859c", "#f5f5f5"); // Customize colors as needed
    }, []);


    useEffect(() => {
        if (captchaRef.current) {
            // CAPTCHA is ready, proceed with actions
        } else {
            // CAPTCHA is not ready, wait or display feedback
        }
    }, [captchaRef]);



    return (
        <form onSubmit={handleSubmit}>
            <LoadCanvasTemplate ref={captchaRef} />
            <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                <input
                    type="text"
                    value={userInput}
                    onChange={(event) => setUserInput(event.target.value)}
                    style={{ height: '30px', width: '150px', outline: 'none' }}
                    readOnly={currentPage === '/Publish' ? false : true}
                />
                <button type="submit" disabled={!captchaRef.current} style={{ backgroundColor: '#0066c3', border: 'none', outline: 'none', color: 'white', height: '30px', width: '50px', textAlign: 'center', paddingTop: '2px', borderRadius: '4px', cursor: 'pointer' }}>
                    ورود
                </button>
            </Grid>
        </form>
    )
}
export default SimpleCaptcha
