import { Box, Grid, Typography } from '@mui/material'
import React , {useState} from 'react'
import CreateFormNavbar from '../Component/CreateFormNavbar'
import MainHeader from '../Component/MainHeader'
import { CardForm, ClassicForm, DesktopCardForm, DesktopClassicForm } from '../Component/Icons'
import { useNavigate } from 'react-router-dom'
import { useSwitchForm } from '../Context/SwitchFormContext'; 

const ScratchCreateFormMainPage = () => {


    const { switchForm, setSwitchForm } = useSwitchForm();



    const navigate = useNavigate()



    const SelectCardData = [
        { id: 1, image: <ClassicForm />, desktopImage: <DesktopClassicForm />, title: 'فرم کلاسیک' , enTitle:'classic', subTitle: 'نمایش تمام سوالات در یک صفحه', bgColor: '#9c9aff' , link:'/build' },
        { id: 2, image: <CardForm />, desktopImage: <DesktopCardForm />, title: 'فرم کارت' , enTitle:'card', subTitle: 'نمایش تک سوال در هر صفحه', bgColor: '#63b7fc' , link:"/build/CardForm" },
    ]


    const handleNavigatePage = (link , enTitle) => {
        navigate(link)
        setSwitchForm(enTitle)
    }


    return (
        <Grid>
            <MainHeader />
            <Grid p={'28px 32px'}>
                <CreateFormNavbar title={'طرح بندی فرم را انتخاب کنید'} subTitle={'با توجه به نیاز خود یک طرح را انتخاب کنید'} back={'/createForm'} />
                <Grid display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} alignItems={'center'} justifyContent={'center'} gap={'30px'}>
                    {
                        SelectCardData.map(
                            item =>
                                <Box key={item.id} onClick={()=>{handleNavigatePage(item.link , item.enTitle)}} display={'flex'} flexDirection={{ xs: 'row', md: 'column' }} alignItems={{ xs: 'flex-start', md: 'center' }} justifyContent={'center'}
                                    sx={{
                                        cursor: 'pointer',
                                        transition: 'transform 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'translateY(-3%)',
                                            '& .title': {
                                                color: '#09f',
                                            },
                                            '& .image': {
                                                border: '2px solid #09f',
                                            }
                                        },
                                    }}
                                >
                                    <Grid className='image' mb={'24px'} p={{ xs: '14px', md: '0px' }} width={{ xs: '50%', md: '340px' }} height={{ xs: '115px', md: '225px' }} borderRadius={{ xs: '0px 10px 10px 0px', md: '10px' }} bgcolor={item.bgColor} display={{ xs: 'flex', md: 'none' }} alignItems={'center'} justifyContent={'center'} border={'2px solid transparent'}>
                                        {item.image}
                                    </Grid>
                                    <Grid className='image' mb={'24px'} p={{ xs: '14px', md: '0px' }} width={{ xs: '50%', md: '340px' }} height={{ xs: '115px', md: '225px' }} borderRadius={{ xs: '0px 10px 10px 0px', md: '10px' }} bgcolor={item.bgColor} display={{ xs: 'none', md: 'flex' }} alignItems={item.id === 1 ? 'flex-end' : 'center'} justifyContent={'center'} border={'2px solid transparent'}>
                                        {item.desktopImage}
                                    </Grid>
                                    <Grid bgcolor={{ xs: '#e3e5f5', md: 'transparent' }} width={{ xs: '50%', md: '100%' }} height={{ xs: '115px', md: "100%" }} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} borderRadius={{ xs: '10px 0px 0px 10px', md: '0px' }} px={{ xs: '8px', md: '0px' }}>
                                        <Typography className='title' mb={'8px'} textAlign={'center'}
                                            sx={{
                                                typography: {
                                                    xs: 'h5',
                                                    md: 'h4',
                                                },
                                            }}
                                            style={{ fontWeight: 700 }}
                                        >
                                            {item.title}
                                        </Typography>
                                        <Typography color={'#6f76a7'} fontWeight={500} textAlign={'center'}
                                            sx={{
                                                typography: {
                                                    xs: 'h6',
                                                    md: 'h5',
                                                },
                                            }}
                                        >
                                            {item.subTitle}
                                        </Typography>
                                    </Grid>
                                </Box>
                        )
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ScratchCreateFormMainPage