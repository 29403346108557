import React, { createContext, useState } from 'react';

// Create a context
const FinalValuesContext = createContext();

// Create a provider component
const FinalValuesProvider = ({ children }) => {
    
    const [finalSelectValues, setFinalSelectValues] = useState([]);

    return (
        <FinalValuesContext.Provider value={{ finalSelectValues, setFinalSelectValues }}>
            {children}
        </FinalValuesContext.Provider>
    );
};

export { FinalValuesContext, FinalValuesProvider };
