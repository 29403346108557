import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Component/Navbar'
import HeaderSetting from '../Component/HeaderSetting'
import SettingDrawer from '../Component/SettingDrawer'
import LayoutCondition from '../Component/LayoutCondition'
import { EmailSetting } from '../Component/Icons'
import { Link } from 'react-router-dom'
import FormSettingOption from '../Component/FormSettingOption'
import FormSettingSelect from '../Component/FormSettingSelect'
import axios from 'axios'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const AdvanceTab = () => {



    const modalOption = [
        { value: 'noreply@jotform.com', label: 'noreply@jotform.com' },
        { value: '+ Add email address', label: '+ Add email address' }
    ]

    const emailTypeModal = [
        { value: 'Please Select', label: 'Please Select' },
        { value: 'SMTP', label: 'SMTP' },
    ]


    const securityProtocolData = [
        { value: 'TLS', label: 'TLS' },
        { value: 'SSL', label: 'SSL' },
    ]



    const [selectValues, setSelectValues] = useState([
        { id: 1, name: 'advanceCustomizeSaveEmail', value: modalOption[0].value, typeError: '' },
        { id: 2, name: 'EmailType', value: emailTypeModal[0].value, typeError: '' },
        { id: 3, name: 'Address', title: 'آدرس ایمیل', placeholder: 'john@smith.com', value: '', error: 'آدرس ایمیل نا معتبر است', touched: false, type: 'email', typeError: '' },
        { id: 4, name: 'Host', title: 'نام Host', placeholder: 'smtp.smith.com', value: '', error: 'نام Host نامعتبر است', touched: false, type: 'text', typeError: '' },
        { id: 5, name: 'Port', title: 'Port', placeholder: '25', value: '', error: 'پورت نامعتبر است', touched: false, type: 'number', typeError: '' },
        { id: 6, name: 'UserName', title: 'نام کاربری', placeholder: 'john', value: '', error: 'نام کاربری نامعتبر است', touched: false, type: 'text', typeError: '' },
        { id: 7, name: 'Password', title: 'رمز عبور', placeholder: '#supersecretpassword', value: '', error: 'رمز عبور نامعتبر است', touched: false, type: 'password', typeError: '' },
        { id: 8, name: 'Security Protocol', value: securityProtocolData[0].value, typeError: '' }
    ]);

    const [show, setShow] = useState(false)



    const optionRef = useRef();
    const emailTypeSelectRef = useRef();



    const CustomToast = ({ closeToast, message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                    textAlign: 'right'
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'red',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                    textAlign: 'right'
                }}>
                    <ErrorIcon fontSize={'large'} />
                    {message}
                </Grid>

        );
    };


    const handleSelectChange = (name, value) => {
        // Check if a select with the given name already exists in the array
        const existingIndex = selectValues.findIndex((select) => select.name === name);

        if (existingIndex !== -1) {
            // If a select with the given name exists, update its value
            const updatedSelects = [...selectValues];
            updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value };
            setSelectValues(updatedSelects);
        } else {
            // If a select with the given name doesn't exist, add it to the array
            setSelectValues((prevSelectValues) => [...prevSelectValues, { name, value }]);
        }
    };


    const handleInputChange = (e, id) => {
        const { value, type } = e.target;
        let errorMessage = '';
        if (type === 'email') {
            // Check if the entered value is a valid email address
            const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
            if (!emailPattern.test(value)) {
                errorMessage = 'آدرس ایمیل نامعتبر است';
            }
        } else if (type === 'number') {
            // Check if the entered value is a valid number
            if (isNaN(value)) {
                errorMessage = 'پورت نامعتبر است';
            }
        }

        setSelectValues(prevState =>
            prevState.map(item =>
                item.id === id ? { ...item, value: value, typeError: errorMessage } : item
            )
        );
    };



    const isFormDataValid = () => {
        return selectValues.some(item => item.value.trim() === '');
    };



    const handlePasswordInput = () => {
        setShow(prevShow => !prevShow);
        const updatedFormData = selectValues.map(item =>
            item.name === 'Password' ? { ...item, type: !show ? 'text' : 'password' } : item
        );
        setSelectValues(updatedFormData);
    }


    const handleValidate = () => {



        if (selectValues[0].value === 'noreply@jotform.com') {
            return true;
        }

        if (selectValues[1].value === 'Please Select') {
            toast(<CustomToast message={'لطفا تمام فرم ها را با دقت پر کنید'} state={'error'} />)
            return false; // Exit the function if form data is invalid
        }

        if (isFormDataValid()) {
            toast(<CustomToast message={'لطفا تمام فرم ها را با دقت پر کنید'} state={'error'} />)
            return false; // Exit the function if form data is invalid
        }

        const hasInputErrors = selectValues.some(item => item.typeError !== '');

        if (hasInputErrors) {
            toast(<CustomToast message={'لطفا تمام فرم ها را با دقت پر کنید'} state={'error'} />);
            return false; // Exit the function if there are input errors
        }


        return true;


    }


    const handleAddEmail = () => {



        if (handleValidate()) {
            const dataToSendServer = selectValues[0].value === 'noreply@jotform.com' ? [selectValues[0]] : selectValues;


            const filteredDataToSend = dataToSendServer.map(({ id, name, value, type }) => ({
                id,
                name,
                value,
                type: type || '' // Ensure type is defined, even if empty
            }));

            console.log(filteredDataToSend)

        }
    }


    const sendDataToServer = () => {

        if (handleValidate()) {
            const dataToSendServer = selectValues[0].value === 'noreply@jotform.com' ? [selectValues[0]] : selectValues;


            const filteredDataToSend = dataToSendServer.map(({ id, name, value, type }) => ({
                id,
                name,
                value,
                type: type || '' // Ensure type is defined, even if empty
            }));


            console.log(filteredDataToSend)

            // Your server URL
            const serverURL = ''; // Replace with your actual server URL

            // Your Axios request
            axios.post(serverURL, { selectValues: filteredDataToSend })
                .then(response => {
                    console.log('Data sent successfully:', response.data);
                    <CustomToast message={'افزودن ایمیل با موفقیت انجام شد'} state={'success'} />
                })
                .catch(error => {
                    console.error('Error sending data:', error);
                    toast(<CustomToast message={"ذخیره ایمیل ناموفق بود. لطفا چند لحظه دیگر مجددا تلاش کنید"} state={'error'} />);

                });
        }
    };



    return (
        <Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'سفارشی‌سازی ایمیل ذخیره و ادامه دهید'} subTitle={'محتوا و آدرس فرستنده را سفارشی کنید'} link={'/Settings/general'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={1} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={1} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'سفارشی‌سازی ایمیل ذخیره و ادامه دهید'} subTitle={'محتوا و آدرس فرستنده را سفارشی کنید'} link={'/Settings/general'} icon={<EmailSetting fill={'white'} />} color={'rgb(78, 108, 154)'} />
                    <Grid>
                        <Grid className='main' bgcolor={'white'} mb={'14px'}>
                            <Box width={'100%'} height={'48px'} display={'flex'} alignItems={'center'} bgcolor={'#fafafc'} borderBottom={'1px solid #eaebf2'}>
                                <Link to={'/Setting/general/CustomizeSaveEmail'} style={{ width: '50%', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid width={'100%'} height={'48px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant='h5' fontWeight={500} color={'black'}>ایمیل</Typography>
                                    </Grid>
                                </Link>
                                <Link to={'/Setting/general/CustomizeSaveEmail/Advance'} style={{ width: '50%', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid width={'100%'} height={'48px'} borderBottom={'3px solid #5e74e6'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant='h5' fontWeight={500} color={'#5e74e6'}>پیشرفته</Typography>
                                    </Grid>
                                </Link>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Grid p={'21px 42px'}>
                                    <Grid>
                                        <Typography variant='h6' color={'#23283a'} fontWeight={600}>ایمیل فرستنده</Typography>
                                        <Typography variant='h6' color={'#23283a'} fontWeight={500}>یک آدرس ایمیل را برای نشان دادن به عنوان فرستنده انتخاب کنید</Typography>
                                    </Grid>
                                    <Grid>
                                        <FormSettingSelect
                                            options={modalOption}
                                            id={0}
                                            name={'advanceCustomizeSaveEmail'}
                                            onSelectChange={handleSelectChange}
                                            ref={optionRef}
                                        />
                                    </Grid>
                                    <Grid className='moreOption' mt={'55px'} display={selectValues.length === 0 || selectValues[0].value === "noreply@jotform.com" ? 'none' : 'block'}>
                                        <Grid mb={'12px'}>
                                            <Typography variant='h6' color={'#23283a'} fontWeight={600} pb={'6px'}>نوع ایمیل</Typography>
                                            <FormSettingSelect ref={emailTypeSelectRef} options={emailTypeModal} name={'EmailType'} id={0} onSelectChange={handleSelectChange} border={selectValues.length === 1 || selectValues[1]?.value === "Please Select" ? true : false}
                                            />
                                            <Typography variant='body1' color={'#d53049'} p={'8px 0'} display={selectValues.length === 1 || selectValues[1]?.value === "Please Select" ? 'block' : 'none'}>{'نوع ایمیل مورد نیاز است.'}</Typography>
                                        </Grid>
                                        <Grid display={selectValues.length === 1 || selectValues[1]?.value === "Please Select" ? 'none' : 'block'}>
                                            <Grid mb={'18px'}>
                                                <Typography variant='h6' color={'#23283a'} fontWeight={600}>جزئیات SMTP</Typography>
                                                <Typography variant='h6' color={'#23283a'} fontWeight={500}>SMTP (پروتکل انتقال ایمیل ساده) شما را قادر می سازد تا ایمیل خود را از طریق تنظیمات سرور مشخص شده ارسال کنید.</Typography>
                                            </Grid>
                                            <Grid>
                                                {
                                                    selectValues.map(
                                                        item =>
                                                            <Grid mb={'22px'} key={item.id} display={item.id === 1 || item.id === 2 || item.id === 8 ? 'none' : 'block'}>
                                                                <Typography variant='h6' color={'#23283a'} fontWeight={600} pb={'8px'}>{item.title}</Typography>
                                                                <Grid style={{ position: 'relative' }}>
                                                                    <input
                                                                        type={item.type}
                                                                        placeholder={item.placeholder}
                                                                        name={item.name}
                                                                        value={item.value}
                                                                        onChange={e => handleInputChange(e, item.id)}
                                                                        onBlur={() => {
                                                                            const updatedFormData = [...selectValues];
                                                                            const index = updatedFormData.findIndex(formDataItem => formDataItem.id === item.id);
                                                                            updatedFormData[index].touched = true;
                                                                            setSelectValues(updatedFormData);
                                                                        }}
                                                                        style={{
                                                                            border: `1px solid ${item.touched && item.value.trim() === '' ? '#d53049' : '#d8dae9'}`,
                                                                            color: '#23283a',
                                                                            backgroundColor: 'white',
                                                                            borderRadius: '4px',
                                                                            padding: '9px 12px',
                                                                            width: '100%',
                                                                            fontSize: '14px',
                                                                        }}
                                                                    />
                                                                    <Grid onClick={handlePasswordInput} display={item.name === 'Password' ? 'block' : 'none'} sx={{ position: 'absolute', top: '13px', left: '2%', cursor: 'pointer' }}>
                                                                        <RemoveRedEyeIcon style={{ color: 'darkgray' }} />
                                                                    </Grid>
                                                                </Grid>
                                                                <Typography variant='body1' fontWeight={700} color={'#d53049'} p={'8px 0'} display={item.touched && item.value.trim() === '' ? 'block' : 'none'}>{item.error}</Typography>
                                                                <Typography variant='body1' fontWeight={700} color={'#d53049'} p={'8px 0'} display={item.touched && item.value.trim() !== '' && item.typeError !== '' ? 'block' : 'none'}>{item.typeError}</Typography>
                                                            </Grid>
                                                    )
                                                }
                                            </Grid>
                                            <Grid mb={'8px'}>
                                                <Typography variant='h6' color={'#23283a'} fontWeight={600} mb={'8px'}>پروتکل امنیتی</Typography>
                                                <Grid>
                                                    <FormSettingSelect options={securityProtocolData} name={'Security Protocol'} id={0} onSelectChange={handleSelectChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid className='button' mb={'16px'}>
                                                <Button onClick={handleAddEmail} sx={{ padding: '11px', backgroundColor: 'rgba(0,117,227,1)', borderRadius: '4px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', '&:hover': { backgroundColor: 'rgba(0,117,227,1)' } }}>
                                                    <Typography variant='h6' color={'white'} fontWeight={500}>افزودن ایمیل</Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid mb={'30px'}>
                            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'8px'}>
                                <Grid style={{ cursor: 'pointer' }} className='save' p={'12px 16px'} bgcolor={'rgba(100,178,0,1)'} fontSize={'14px'} color={'white'} borderRadius={'4px'} onClick={sendDataToServer}>ذخیره</Grid>
                                <Grid style={{ cursor: 'pointer' }} className='text' p={'12px 16px'} bgcolor={'rgba(218,222,243,1)'} fontSize={'14px'} color={'rgba(52,60,106,1)'} borderRadius={'4px'}>تست ایمیل</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AdvanceTab