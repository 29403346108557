// UrlPayloadContext.js
import { createContext, useState } from 'react';

const UrlPayloadContext = createContext();

export const UrlPayloadProvider = ({ children }) => {
    
    const [urlPayload, setUrlPayload] = useState();

    return (
        <UrlPayloadContext.Provider value={{ urlPayload, setUrlPayload }}>
            {children}
        </UrlPayloadContext.Provider>
    );
};

export default UrlPayloadContext;
