import { Grid } from '@mui/material'
import React, { useRef, useState } from 'react'
import Navbar from '../Component/Navbar'
import HeaderSetting from '../Component/HeaderSetting'
import SettingDrawer from '../Component/SettingDrawer'
import LayoutCondition from '../Component/LayoutCondition'
import { AutoEmail, EmailNotofication } from '../Component/Icons'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StateSelect from '../Component/StateSelect'
// import Advance from '../Component/Advance'
import CustomizeSaveEmail from '../Component/CustomizeSaveEmail'
import { useTitleContext } from '../Context/TitleContext'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
import { Link } from 'react-router-dom'
import Advance from '../Component/Advance/Advance'

const AutoresponderEmailAdvance = () => {


    const { title } = useTitleContext();
    const [subject, setSubject] = useState("ما پاسخ شما را دریافت کرده ایم");
    const [value, setValue] = useState(0);


    const editorRef = useRef(null);


    const senderOption = []


    const replyOption = []

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleSaveClick = () => {
        if (editorRef.current) {
            const content = editorRef.current.getContent();

            const id = uuidv4();
            const data = [{ id, subject, content }];


            console.log(data)

            axios.post('/your-server-endpoint', data)
                .then(response => {
                    console.log('Success:', response.data);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };


    return (
        <Grid bgcolor={'#f3f3fe'}>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'تنظیمات ایمیل'} subTitle={'ارسال خودکار و اعلان ها'} link={'/Setting/Email'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={2} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={2} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'پاسخگوی خودکار'} subTitle={'برای شخصی که فرم شما را پر می کند ایمیل بفرستید'} link={'/Setting/Email'} icon={<AutoEmail fill={'white'} />} color={'rgba(127,202,0,1)'} />
                    <Grid>
                        <Grid className='main' mb={'14px'}>
                            <Box sx={{ width: '100%' }}>
                                <Box
                                    width={"100%"}
                                    height={"48px"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    bgcolor={"#fafafc"}
                                    borderBottom={"1px solid #eaebf2"}
                                >
                                    <Link
                                        to={"/Setting/EmailSetting/AutoresponderEmail"}
                                        style={{
                                            width: "50%",
                                            height: "48px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Grid
                                            width={"100%"}
                                            height={"48px"}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                        >
                                            <Typography variant="h5" fontWeight={500} color={"black"}>
                                                ایمیل
                                            </Typography>
                                        </Grid>
                                    </Link>
                                    <Link
                                        to={"/Setting/EmailSetting/AutoresponderEmail/Sender"}
                                        style={{
                                            width: "50%",
                                            height: "48px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Grid
                                            width={"100%"}
                                            height={"48px"}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                        >
                                            <Typography variant="h5" fontWeight={500} color={"black"}>
                                                گیرندگان
                                            </Typography>
                                        </Grid>
                                    </Link>
                                    <Link
                                        to={"/Setting/EmailSetting/AutoresponderEmail/Advance"}
                                        style={{
                                            width: "50%",
                                            height: "48px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Grid
                                            width={"100%"}
                                            height={"48px"}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            borderBottom={"3px solid #5e74e6"}
                                        >
                                            <Typography variant="h5" fontWeight={500} color={"#5e74e6"}>
                                                پیشرفته
                                            </Typography>
                                        </Grid>
                                    </Link>
                                </Box>
                                <Box sx={{ width: '100%'}}>
                                    <Advance flag={false} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AutoresponderEmailAdvance