import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import FolderIcon from '@mui/icons-material/Folder';
import StarIcon from '@mui/icons-material/Star';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import DraftsIcon from '@mui/icons-material/Drafts';
import { Link } from 'react-router-dom';
import CreateFormButton from './CreateFormButton';
import { DrawerContext } from '../Context/DrawerContext';

const MainDrawer = () => {


    const { selectDrawerItem, setSelectDrawerItem } = useContext(DrawerContext);


    const data = [
        { id: 1, enTitle: 'Favorite', title: 'موارد دلخواه', icon: <StarIcon fontSize='large' style={{ color: 'rgba(255,196,44,1)' }} /> },
        { id: 2, enTitle: 'Archive', title: 'بایگانی', icon: <ArchiveIcon fontSize='large' /> },
        { id: 3, enTitle: 'Trash', title: 'سطل زباله', icon: <DeleteIcon fontSize='large' /> },
    ]


    return (
        <Box width={{ xs: '100%', md: '35%' }} maxWidth={{ xs: '250px', xxs: '300px' }} height={'100vh'} sx={{ bgcolor: '#f3f3fe' }} borderLeft={'1px solid #c8ceed'} display={{ xs: 'none', md: 'block' }}>
            <Grid display={{ xs: 'none', md: 'block' }}>
                <CreateFormButton />
            </Grid>
            <Grid width={'100%'} borderBottom={'1px solid #c8ceed'} p={'18px 8px'} pb={'18px'} mt={{ xs: '0px', md: '0px' }}>
                <Typography variant='h5' color={'#6c73a8'} p={'11px 14px'} fontWeight={700}>فرم های من</Typography>
                <Button onClick={() => { setSelectDrawerItem('AllForm') }} sx={{ bgcolor: selectDrawerItem === 'AllForm' ? '#DDDDDD':'transparent', '&:hover': '#6c73a8', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', p: '11px 14px', width: '100%' }}>
                    <FolderIcon style={{ color: '#1976d2' }} fontSize='large' />
                    <Typography variant='h5' color={'#0a1551'}>همه فرم ها</Typography>
                </Button>
            </Grid>
            <Grid borderBottom={'1px solid #c8ceed'} p={'18px 8px'} >
                <Button onClick={() => { setSelectDrawerItem('Drafts') }} sx={{ bgcolor: selectDrawerItem === 'Drafts' ? '#DDDDDD':'transparent', '&:hover': '#6c73a8', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', p: '11px 14px', width: '100%' }}>
                    <DraftsIcon fontSize='large' />
                    <Typography variant='h5' color={'#0a1551'}>پیش نویس های من</Typography>
                </Button>
            </Grid>
            <Grid p={'18px 8px'}>
                {
                    data.map(
                        item =>
                            <Button onClick={() => { setSelectDrawerItem(item.enTitle) }} key={item.id} sx={{ bgcolor: selectDrawerItem === item.enTitle ? '#DDDDDD':'transparent', '&:hover': '#6c73a8', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', p: '11px 14px', width: '100%' }}>
                                {item.icon}
                                <Typography variant='h5' color={'#0a1551'}>{item.title}</Typography>
                            </Button>
                    )
                }
            </Grid>
        </Box>
    )
}

export default MainDrawer