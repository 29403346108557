import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { UploadFileIcon } from "./Icons";
import { useFormContext } from '../Context/Context';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import FolderIcon from '@mui/icons-material/Folder';
import { useLocation } from 'react-router-dom';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const FileUpload = ({ id, header }) => {


    const { drawerData } = useFormContext();


    const existingComponentIndex = drawerData.findIndex(component => component.id === id);




    const location = useLocation();
    const currentPage = location.pathname;


    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.fileValue ? drawerData[existingComponentIndex].state.fileValue : 'آپلود فایل'



    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);


    const [selectedFile, setSelectedFile] = useState(null);
    const [fileValue, setFileValue] = useState(switchHeader);
    const [fileInputValue, setFileInputValue] = useState('');
    const [subFileValue, setSubFileValue] = useState('');
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState(null);
    const [upload, setUpload] = useState(false)
    const [condition, setCondition] = useState(false)


    const containerRef = useRef(null);



    const fillInputValue = () => {

        if (drawerData.length > 0) {

            if (drawerData[existingComponentIndex]) {

                drawerData[existingComponentIndex].state.selectedFile = selectedFile
                drawerData[existingComponentIndex].state.fileValue = fileValue
                drawerData[existingComponentIndex].state.mainFileInputValue = fileInputValue
                drawerData[existingComponentIndex].state.subFileValue = subFileValue
                drawerData[existingComponentIndex].state.mainSelectedFileName = selectedFileName
                drawerData[existingComponentIndex].state.readOnly = readOnly

            }

        }


    }




    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly




    const CustomToast = ({ closeToast, message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'red',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <ErrorIcon fontSize={'large'} />
                    {message}
                </Grid>

        );
    };


    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'FileUpload') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    const isZipOrPdf = (file) => {
        return (
            file.type === 'application/zip' ||
            file.type === 'application/pdf' ||
            file.type.startsWith('video/') ||
            file.type.startsWith('image/')
        );
    };

    const handleFileSelectChange = (e) => {
        const file = e.target.files[0];

        if (file && isZipOrPdf(file)) {
            setSelectedFileName(file.name);
            setFileInputValue(e.target.value);
            setSelectedFile(file);
            setUpload(true)
            toast(<CustomToast message="آپلود فایل با موفقیت انجام شد" state={'success'} />);
        } else {
            toast(<CustomToast message="لطفاً یک فایل معتبر (زیپ، pdf یا ویدیو) انتخاب کنید." state={'error'} />);

        }
    };


    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();

        const file = e.dataTransfer.files[0];

        if (file && isZipOrPdf(file)) {
            setSelectedFileName(file.name);
        } else {
            alert('Please drop a valid zip or pdf file.');
        }
    };


    const handleFileChange = (event) => {
        setFileValue(event.target.value);
    };


    const handleSubFileChange = (event) => {
        setSubFileValue(event.target.value);
    };


    // Add a global click event listener to handle clicks outside the first grid


    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };


    const handleHeadingBlur = () => {
        setHeadingFocused(false); // Set focus state to false on blur
    };


    const handleFileUpload = () => {
        // if (selectedFile) {
        //     // Send selectedFile to the server
        //     const formData = new FormData();
        //     formData.append('file', selectedFile);
        //     // Here you can use fetch or any other method to send formData to the server
        //     console.log('Sending file to the server...');
        // } else {
        //     toast(<CustomToast message="لطفا ابتدا یک فایل را انتخاب کنید." state={'error'} />);
        // }
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, selectedFile, fileInputValue, selectedFileName, fileValue, subFileValue]);



    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    let theme = useTheme()

    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let SubHeadingInputFontSize = isUpXxl ? '14px' : '12px'
    let HeadingColor = theme.palette.blue[700]


    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '24px 36px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused && drawerData.length > 0 && drawerData[existingComponentIndex] ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}>
            <Grid className={'HeadingInput'} pb={'14px'}>
                <input className="customHead-input" placeholder={'File Upload'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                    value={fileValue}
                    onChange={handleFileChange}
                    onClick={handleClickOutside}
                    onFocus={handleHeadingFocus}
                />
            </Grid>
            {
                currentPage === '/build' || currentPage === "/build/CardForm" || !condition ?
                    <Button
                        style={{ width: '100%', height: '132px', marginBottom: '8px', border: '1px dashed #2c3345', borderRadius: '4px', backgroundColor: '#fbfcff' }}
                    >
                        <Grid
                            width={'100%'}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={'8px'}
                        >
                            <input
                                readOnly={true}
                                type="file"
                                id="fileInput"
                                style={{ display: 'none' }}
                                accept=".zip, .pdf, image/*, video/*"
                            />
                            {
                                upload ?
                                    <FolderIcon fontSize='large' style={{ color: 'green', width: '50px', height: '50px' }} />
                                    :
                                    <>
                                        <UploadFileIcon />
                                        <Typography variant={'h4'} color={'#2c3345'} fontWeight={700} style={{ fontFamily: 'sans-serif' }}>
                                            Browse Files
                                        </Typography>
                                        <Typography variant={'h6'} color={'#57647e'} fontWeight={500} style={{ fontFamily: 'sans-serif' }}>
                                            Drag and drop files here
                                        </Typography>
                                    </>
                            }
                            {selectedFileName && (
                                <Typography variant={'subtitle1'} color={'#57647e'} style={{ fontFamily: 'sans-serif' }}>
                                    {selectedFileName}
                                </Typography>
                            )}
                        </Grid>
                    </Button>
                    :
                    <Button
                        component="label"
                        htmlFor="fileInput"
                        onClick={handleFileUpload}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        style={{ width: '100%', height: '132px', marginBottom: '8px', border: '1px dashed #2c3345', borderRadius: '4px', backgroundColor: '#fbfcff' }}
                    >
                        <Grid
                            width={'100%'}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={'8px'}
                        >
                            <input
                                type="file"
                                id="fileInput"
                                style={{ display: 'none' }}
                                onChange={handleFileSelectChange}
                                accept=".zip, .pdf, image/*, video/*"
                            />
                            {
                                upload ?
                                    <FolderIcon fontSize='large' style={{ color: 'green', width: '50px', height: '50px' }} />
                                    :
                                    <>
                                        <UploadFileIcon />
                                        <Typography variant={'h4'} color={'#2c3345'} fontWeight={700} style={{ fontFamily: 'sans-serif' }}>
                                            Browse Files
                                        </Typography>
                                        <Typography variant={'h6'} color={'#57647e'} fontWeight={500} style={{ fontFamily: 'sans-serif' }}>
                                            Drag and drop files here
                                        </Typography>
                                    </>
                            }
                            {selectedFileName && (
                                <Typography variant={'subtitle1'} color={'#57647e'} style={{ fontFamily: 'sans-serif' }}>
                                    {selectedFileName}
                                </Typography>
                            )}
                        </Grid>
                    </Button>

            }

            <Grid>
                {isSubHeaderVisible && (
                    <Grid className={'subHeader'} pb={'14px'}>
                        <input
                            className=""
                            placeholder={'یک عنوان فرعی وارد کنید'}
                            style={{
                                border: 'none',
                                outline: 'none',
                                userSelect: 'text',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                width: '100%',
                                fontSize: SubHeadingInputFontSize,
                                fontWeight: 500,
                                lineHeight: 1.6,
                                color: '#57647e',
                            }}
                            readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                            value={subFileValue}
                            onChange={handleSubFileChange}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
export default FileUpload
