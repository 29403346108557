import React, { createContext, useContext, useState } from 'react';

// Create context
const SwitchFormContext = createContext();

// Create a custom hook for easy access to the context
export const useSwitchForm = () => useContext(SwitchFormContext);

// Create a provider component
export const SwitchFormProvider = ({ children }) => {
    const [switchForm, setSwitchForm] = useState('classic');

    return (
        <SwitchFormContext.Provider value={{ switchForm, setSwitchForm }}>
            {children}
        </SwitchFormContext.Provider>
    );
};
