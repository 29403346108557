import React, { useState } from 'react'
import { useRef, useMemo, useContext, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import InitialValue from './InitialValue';
import { Hourglass } from 'react-loader-spinner';
import { Box, Button, Typography } from '@mui/material';
import Editor from '../Editor/Editor';
import { PayloadContext } from '../../Context/PayloadContext';
import { v4 as uuidv4 } from 'uuid';
import  axios  from 'axios';
import { Grid } from '@mui/material';
import { JoditEditor } from 'jodit-react';


const TinyEditor = ({ page }) => {

    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);

    const { setPayload } = useContext(PayloadContext);

    const config = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/docs/,
            // placeholder: placeholder || 'Start typings...'
        }),
        []
    );

    const handleEditorLoad = () => {
        setLoading(false); // Set loading to false when the editor has finished loading
    };


    const handleSubmit = () => {

        if (editor.current) {

            const content = editor.current.value;

            const uniqueId = uuidv4();

            const payload = [
                {
                    id: uniqueId,
                    content: content
                }
            ];


            setPayload(payload)

            axios.post('/your-content-upload-endpoint', payload)
                .then(response => {
                    console.log('Content successfully uploaded:', response.data);
                })
                .catch(error => {
                    console.error('Error uploading content:', error);
                });
        }
    };




    useEffect(() => {

        if (editor.current) {

            const content = editor.current.value;


            const uniqueId = uuidv4();

            const payload = [
                {
                    id: uniqueId,
                    content: content
                }
            ];


            setPayload(payload)
        }

    }, [editor.current])


    useEffect(() => {
        // Convert the InitialValue component to an HTML string
        const initialContent = ReactDOMServer.renderToStaticMarkup(<InitialValue />);
        setContent(initialContent);
        
    }, [page]);

    return (

        <Grid>
            {loading && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
                    <Hourglass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#306cce', '#72a1ed']}
                    />
                </Box>
            )}
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => { }}
                onInit={handleEditorLoad} // Call the function when the editor is initialized
            />
            <Button variant='contained' color='success' onClick={handleSubmit} sx={{ padding: '12px 24px', marginTop: '24px' }}>
                <Typography variant='h6' fontWeight={700} color={'white'}>ذخیره</Typography>
            </Button>
        </Grid>

    )
}

export default TinyEditor