import React, { useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material';
import Navbar from '../Component/Navbar';
import SettingDrawer from '../Component/SettingDrawer';
import { Message } from '../Component/Icons';
import Switch from 'react-switch'
import '../App.css'
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HeaderSetting from '../Component/HeaderSetting';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';



const Notification = () => {


    const DropDownData = [
        { id: 1, title: 'Submission ID', content: '{id}' },
        { id: 2, title: 'Form Title', content: '{form_title}' },
        { id: 3, title: 'IP Address', content: '{ip}' },
    ]


    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(false)
    const [openContent, setOpenContent] = useState(false)
    const [title, setTilte] = useState('')
    const [content, setContent] = useState('')
    const [titleContent, setTitleContent] = useState('')
    const [notifContent, setNotifContent] = useState('')

    const drop = useRef(null)
    const dropContent = useRef(null)


    const CustomToast = ({ message, state }) => {
        return (
            <Grid display="flex" alignItems="start" justifyContent="end" gap="5px" style={{
                backgroundColor: 'white',
                color: state === 'success' ? 'green' : 'red',
                fontSize: '16px',
                padding: '15px 10px',
                fontWeight: 900,
            }}>
                {state === 'success' ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
                {message}
            </Grid>
        );
    };

    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const handleOpen = () => {
        setOpen(!open)
    }


    const handleOpenContent = () => {
        setOpenContent(!openContent)
    }

    const handleTitle = (content) => {
        setTilte(content)
        setOpen(false)
    }


    const handleContent = (content) => {
        setContent(content)
        setOpenContent(false)
    }


    const handleChangeTitleContent = (e) => {
        setTitleContent(e.target.value)
    }

    const handleChangeNotifContent = (e) => {
        setNotifContent(e.target.value)
    }


    const handleClickOutside = (event) => {
        if (drop.current && !drop.current.contains(event.target)) {
            setOpen(false)
        }
    };


    const handleClickOutsideContent = (event) => {
        if (dropContent.current && !dropContent.current.contains(event.target)) {
            setOpenContent(false)
        }
    };


    const handleReset = ()=>{
        setTitleContent('');
        setNotifContent('')
    }



    const handleSaveNotification = () => {


        const uniqueId = uuidv4();


        const notification = [
            {
                id:uniqueId,
                title:titleContent,
                content:notifContent
            }
        ]


        const headers = {
            'Content-Type': 'application/json',
            // Add any other headers you need
        };

        axios.post('SERVER_ENDPOINT', notification, { headers })
            .then(response => {
                console.log(response.data);
                if (response.status === 200) {
                    toast(<CustomToast message={'اعلان با موفقیت افزوده شد'} state={'success'} />);
                } else {
                    toast(<CustomToast message={'خطا در ارسال اعلان'} state={'error'} />);
                }
            })
            .catch(error => {
                toast(<CustomToast message={'خطا در ارسال اعلان'} state={'error'} />);
                console.error('Error sending email:', error);
            });


            handleReset()
    };


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);



    useEffect(() => {
        document.addEventListener('click', handleClickOutsideContent);

        return () => {
            document.removeEventListener('click', handleClickOutsideContent);
        };
    }, []);


    useEffect(() => {
        setTitleContent(titleContent.concat(title))
    }, [title]);


    useEffect(() => {
        setNotifContent(notifContent.concat(content))
    }, [content]);


    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    const isLgXxl = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    let height = isUpXxl ? '200px' : '100px'
    let heightImage = isLgXxl ? '225px' : '250px'
    let maxWidth = isLgXxl ? '225px' : '115px'

    return (
        <Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'اعلان های موبایل'} subTitle={'اعلان‌های برنامه تلفن همراه را سفارشی کنید'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={8} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={8} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <Grid className='signIcon' display={'flex'} alignItems={'center'} gap={'12px'} mb={'16px'}>
                        <Grid bgcolor={'rgb(78, 121, 246)'} width={'40px'} height={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'4px'}>
                            <Message fill={'white'} />
                        </Grid>
                        <Grid>
                            <Typography variant='h5' color={'h5'} fontWeight={500}>اعلان های موبایل</Typography>
                            <Typography variant='h6' color={'h6'} fontWeight={500}>اعلان‌های برنامه تلفن همراه را برای این فرم فعال کنید</Typography>
                        </Grid>
                    </Grid>
                    <Grid mb={'24px'} bgcolor={'white'} borderRadius={'4px'} boxShadow={'0 0 9px rgba(0,0,0,.1)'} p={'16px 24px'}>
                        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'40px'}>
                            <Grid>
                                <Typography variant='h5' color={'#0a1551'} fontWeight={700}>نمایش اعلانها را فعال کنید</Typography>
                                <Typography variant='h6' color={'#6f76a7'}>فرم های خود را با ویژگی های Jotform Sign تقویت کنید</Typography>
                            </Grid>
                            <Grid>
                                <label htmlFor="small-radius-switch">
                                    <Switch
                                        checked={checked}
                                        onChange={handleChange}
                                        handleDiameter={28}
                                        offColor="#f98e03"
                                        onColor="#ddd"
                                        height={40}
                                        width={70}
                                        borderRadius={6}
                                        uncheckedIcon={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    fontSize: 15,
                                                    color: "white",
                                                    paddingRight: 2,
                                                    paddingTop: '4px'

                                                }}
                                            >
                                                Yes
                                            </div>
                                        }
                                        checkedIcon={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    fontSize: 15,
                                                    color: "white",
                                                    paddingRight: 2,
                                                    paddingTop: '4px'

                                                }}
                                            >
                                                No
                                            </div>
                                        }
                                        uncheckedHandleIcon={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    fontSize: 20,
                                                    backgroundColor: '#fff',
                                                    borderRadius: '4px'

                                                }}
                                            >
                                                <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                            </div>
                                        }
                                        checkedHandleIcon={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    fontSize: 20,
                                                    backgroundColor: '#fff',
                                                    borderRadius: '4px'
                                                }}
                                            >
                                                <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                            </div>
                                        }
                                        className="react-switch"
                                        id="small-radius-switch"
                                    />
                                </label>
                            </Grid>
                        </Grid>
                        {
                            checked ?
                                <Grid width={'100%'} p={'10px 15px'} bgcolor={'#69c3ff'} borderRadius={'4px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} pb>
                                    <Grid width={'50%'}>
                                        <Typography variant='body1' color={'white'} pb={'4px'}>برای دریافت اعلان‌ها، برنامه <b>Jotform Mobile Forms</b> را دانلود کنید</Typography>
                                        <Grid display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'8px'}>
                                            <a href='https://apps.apple.com/us/app/jotform-mobile-forms-survey/id1391524277' target="_blank" rel='noreferrer'>
                                                <Grid width={'100px'} height={'33px'}>
                                                    <img src='/assets/images/app-store.svg' alt='app' />
                                                </Grid>
                                            </a>
                                            <a href='https://play.google.com/store/apps/details?id=com.jotform.v2' target="_blank" rel='noreferrer'>
                                                <Grid width={'100px'} height={'33px'}>
                                                    <img src='/assets/images/google-play.svg' alt='google' />
                                                </Grid>
                                            </a>
                                        </Grid>
                                    </Grid>
                                    <Grid width={'50%'} height={'100%'} display={{ xs: 'none', md: 'block' }}>
                                        <img src='/assets/images/banner.png' alt='' style={{ height: height, width: '100%' }} />
                                    </Grid>
                                </Grid>
                                :
                                <Grid>
                                    <Grid mb={'40px'} width={'100%'} p={'10px 15px'} bgcolor={'#69c3ff'} borderRadius={'4px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid width={'100%'} display={{ xs: 'block', md: 'flex' }} alignItems={'center'} justifyContent={'space-between'}>
                                            <Typography variant='body1' color={'white'} pb={'4px'}>برای دریافت اعلان‌ها، برنامه <b>Jotform Mobile Forms</b> را دانلود کنید</Typography>
                                            <Grid display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'8px'}>
                                                <a href='https://apps.apple.com/us/app/jotform-mobile-forms-survey/id1391524277' target="_blank" rel='noreferrer'>
                                                    <Grid width={'100px'} height={'33px'}>
                                                        <img src='/assets/images/app-store.svg' alt='app' />
                                                    </Grid>
                                                </a>
                                                <a href='https://play.google.com/store/apps/details?id=com.jotform.v2' target="_blank" rel='noreferrer'>
                                                    <Grid width={'100px'} height={'33px'}>
                                                        <img src='/assets/images/google-play.svg' alt='google' />
                                                    </Grid>
                                                </a>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid display={'flex'} alignItems={'center'} gap={'8px'} mb={'40px'}>
                                        <Grid width={'32px'} height={'32px'}>
                                            <img src='/assets/images/builder-settings-icon.svg' alt='' />
                                        </Grid>
                                        <Grid>
                                            <Typography variant='h5' fontWeight={700} color={'#596066'}>ویرایش متن اعلان</Typography>
                                            <Typography variant='body1' color={'#2b2b2b'} fontWeight={500}>محتوای اعلان‌های برنامه تلفن همراه خود را سفارشی کنید</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid mb={'40px'}>
                                        <Typography variant='body1' color={'#2c3345'} pb={'12px'}>پیش نمایش</Typography>
                                        <Grid width={'100%'} bgcolor={'#c8ceed'} p={'20px 24px 24px'} pb={0} height={'250px'}>
                                            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} p={'80px'} pb={'0px'} height={'100%'} width={{ xs: '100%', sm: '70%' }} mx={'auto'} position={'relative'}>
                                                <Grid position={'absolute'} top={{ xs: '-20px', lg: '4px' }} mx={'auto'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    <img src='/assets/images/iphone-xr.svg' alt='' style={{ width: '85%', height: heightImage }} />
                                                </Grid>
                                                <Grid className='message' display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} position={'absolute'} top={'80px'} mx={'auto'} width={'100%'} style={{ height: '150px', overflowY: 'scroll' }}>
                                                    <Typography className='Title' variant='subtitle1' fontWeight={700} color={'#091141'} style={{ maxWidth: maxWidth, wordBreak: 'break-word' }}>{titleContent}</Typography>
                                                    <Typography className='Content' variant='subtitle1' fontWeight={500} color={'#091141'} style={{ maxWidth: maxWidth, wordBreak: 'break-word' }}>{notifContent}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid mb={'40px'}>
                                        <Typography variant='h6' color={'#564632'}>عنوان اطلاعیه</Typography>
                                        <Grid>
                                            <Grid className='DropDown' p={'12px 8px'} bgcolor={'#050c34'}>
                                                <Grid position={'relative'}>
                                                    <Grid ref={drop} style={{ width: 'fit-content', cursor: 'pointer' }} display={'flex'} alignItems={'center'} onClick={handleOpen}>
                                                        <Typography variant='h6' color={'white'}>فیلدهای فرم</Typography>
                                                        <ArrowDropDownIcon style={{ color: 'white' }} />
                                                    </Grid>
                                                    <Grid display={open ? 'block' : 'none'} position={'absolute'} top={'25px'} right={0} p={'6px 4px'} bgcolor={'#565f6f'} width={'178px'}>
                                                        {
                                                            DropDownData.map(
                                                                item =>
                                                                    <Grid onClick={() => { handleTitle(item.content) }} style={{ cursor: 'pointer' }}>
                                                                        <Typography variant='h6' color={'white'}>{item.title}</Typography>
                                                                    </Grid>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                <textarea
                                                    onChange={handleChangeTitleContent}
                                                    value={titleContent}
                                                    style={{
                                                        width: '100%',
                                                        height: '90px',
                                                        resize: 'none',
                                                        padding: '8px'
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        <Typography variant='h6' color={'#564632'}>محتوای اطلاع رسانی</Typography>
                                        <Grid>
                                            <Grid className='DropDown' p={'12px 8px'} bgcolor={'#050c34'}>
                                                <Grid position={'relative'} ref={dropContent}>
                                                    <Grid ref={dropContent} style={{ width: 'fit-content', cursor: 'pointer' }} display={'flex'} alignItems={'center'} onClick={handleOpenContent}>
                                                        <Typography variant='h6' color={'white'}>فیلدهای فرم</Typography>
                                                        <ArrowDropDownIcon style={{ color: 'white' }} />
                                                    </Grid>
                                                    <Grid display={openContent ? 'block' : 'none'} position={'absolute'} top={'25px'} right={0} p={'6px 4px'} bgcolor={'#565f6f'} width={'178px'}>
                                                        {
                                                            DropDownData.map(
                                                                item =>
                                                                    <Grid onClick={() => { handleContent(item.content) }} style={{ cursor: 'pointer' }}>
                                                                        <Typography variant='h6' color={'white'}>{item.title}</Typography>
                                                                    </Grid>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                <textarea
                                                    onChange={handleChangeNotifContent}
                                                    value={notifContent}
                                                    style={{
                                                        width: '100%',
                                                        height: '90px',
                                                        resize: 'none',
                                                        padding: '8px'
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
                    <Grid mb={'48px'} onClick={handleSaveNotification} style={{ cursor: 'pointer' }} className='save' p={'12px 16px'} bgcolor={'rgba(100,178,0,1)'} borderRadius={'4px'} width={'15%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Typography variant='h6' color={'white'}>ذخیره</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Notification