// SelectValuesContext.js
import React, { createContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Create a Context for SelectValues
export const SelectValuesContext = createContext();

export const SelectValuesProvider = ({ children }) => {
    const [selectValues, setSelectValues] = useState([

        { id: uuidv4(), name: 'ifCondition', value: '' },
        { id: uuidv4(), name: 'ifConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'ifConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'ifConditionThankYou', value: '' },
        { id: uuidv4(), name: 'ifConditionSkip', value: '' },
        { id: uuidv4(), name: 'ifConditionEmail', value: '' },
        { id: uuidv4(), name: 'StateCondition', value: '' },
        { id: uuidv4(), name: 'StateConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'StateConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'StateConditionThankYou', value: '' },
        { id: uuidv4(), name: 'StateConditionSkip', value: '' },
        { id: uuidv4(), name: 'StateConditionEmail', value: '' },
        { id: uuidv4(), name: 'valueCondition', value: '' },
        { id: uuidv4(), name: 'valueConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'valueConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'valueConditionThankYou', value: '' },
        { id: uuidv4(), name: 'valueConditionSkip', value: '' },
        { id: uuidv4(), name: 'valueConditionEmail', value: '' },
        { id: uuidv4(), name: 'DoCondition', value: '' },
        { id: uuidv4(), name: 'DoConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'DoConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'DoConditionThankYou', value: '' },
        { id: uuidv4(), name: 'DoConditionSkip', value: '' },
        { id: uuidv4(), name: 'DoConditionEmail', value: '' },
        { id: uuidv4(), name: 'FieldCondition', value: '' },
        { id: uuidv4(), name: 'FieldConditionUpdateAndCalculate', value: '' },
        { id: uuidv4(), name: 'FieldConditionEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'TargetStatus', value: null },
        { id: uuidv4(), name: 'TargetStatusUpdateAndCalculate', value: null },
        { id: uuidv4(), name: 'TargetStatusEnableAndRequire', value: null },
        { id: uuidv4(), name: 'TargetStatusThankYou', value: null },
        { id: uuidv4(), name: 'TargetStatusSkip', value: null },
        { id: uuidv4(), name: 'TargetStatusEmail', value: null },
        { id: uuidv4(), name: 'ifFieldCondition', value: null },
        { id: uuidv4(), name: 'ifFieldConditionUpdateAndCalculate', value: null },
        { id: uuidv4(), name: 'ifFieldConditionEnableAndRequire', value: null },
        { id: uuidv4(), name: 'ifFieldConditionThankYou', value: null },
        { id: uuidv4(), name: 'valueEnableAndRequire', value: '' },
        { id: uuidv4(), name: 'urlValue', value: '' },
        { id: uuidv4(), name: 'emailValue', value: '' },
        { id: uuidv4(), name: 'editorValue', value: '' },
        { id: uuidv4(), name: 'fromValue', value: '' },
        { id: uuidv4(), name: 'toValue', value: '' },
        { id: uuidv4(), name: 'summaryValue', value: '' },
        { id: uuidv4(), name: 'FinalThankYouContent', value: '' },
        { id: uuidv4(), name: 'CalculateValue', value: '' },

    ]);

    return (
        <SelectValuesContext.Provider value={{ selectValues, setSelectValues }}>
            {children}
        </SelectValuesContext.Provider>
    );
};
