



import { createBrowserRouter } from "react-router-dom";

import Home from "../pages/Home";
import Preview from "../pages/Preview";
import Edit from "../pages/Edit";
import Setting from '../pages/Setting';
import JotFormSign from "../pages/JotFormSign";
import Notification from "../pages/Notification";
import Publish from "../pages/Publish";
import EmailSetting from "../pages/EmailSetting";
import EmailSettingOptions from "../pages/EmailSettingOptions";
import Condition from "../pages/Condition";
import ShowAndHide from "../pages/ShowAndHide";
import UpdateAndCalculate from "../pages/UpdateAndCalculate";
import EnableAndRequire from "../pages/EnableAndRequire";
import Skip from "../pages/Skip";
import ThankYou from "../pages/ThankYou";
import EmailRecipient from "../pages/EmailRecipient";
import FormSetting from '../pages/FormSetting';
import Approval from "../pages/Approval";
import SettingThankYou from "../pages/SettingThankYou";
import NotificationEmail from "../pages/NotificationEmail";
import AutoresponderEmail from "../pages/AutoresponderEmail";
import FormWarnings from "../pages/FormWarnings";
// import CustomizeSaveEmail from "../pages/CustomizeSaveEmail";
import EmailTab from "../pages/EmailTab";
import AdvanceTab from "../pages/AdvanceTab";
import SenderNotificationEmail from "../pages/SenderNotificationEmail";
import AdvanceNotificationEmail from "../pages/AdvanceNotificationEmail";
import AutoresponderEmailSender from "../pages/AutoresponderEmailSender";
import AutoresponderEmailAdvance from "../pages/AutoresponderEmailAdvance";
import ConditionList from "../pages/ConditionList";
import Main from "../pages/Main";
import CreateForm from "../pages/CreateForm";
import ScratchCreateFormMainPage from "../pages/ScratchCreateFormMainPage";
import TemplateMainPage from "../pages/TemplateMainPage";
import ImportForm from "../pages/ImportForm";
import CloneExistingForm from "../pages/CloneExistingForm";
import Login from "../pages/Login";
import ForgetPassword from "../pages/ForgetPassword";
import ResetPassword from "../pages/ResetPassword";




const router = createBrowserRouter([
    {
        path: "/",
        element: <Login/>,
    },
    {
        path: "/SignUp",
        element: <Login/>,
    },
    {
        path: "/Main",
        element: <Main/>,
    },
    {
        path: "/ForgetPassword",
        element: <ForgetPassword/>,
    },
    {
        path: "/ResetPassword",
        element: <ResetPassword/>,
    },
    {
        path: "/build",
        element: <Home state={true}/>,
    },
    {
        path: "/build/CardForm",
        element: <Home state={true}/>,
    },
    {
        path: "/createForm",
        element: <CreateForm/>,
    },
    {
        path: "/createForm/Scratch",
        element: <ScratchCreateFormMainPage/>,
    },
    {
        path: "/createForm/Template",
        element: <TemplateMainPage/>,
    },
    {
        path: "/createForm/ImportForm",
        element: <ImportForm/>,
    },
    {
        path: "/createForm/ImportForm/CloneExistingForm",
        element: <CloneExistingForm/>,
    },
    {
        path: "/Edit",
        element: <Home state={true}/>,
    },
    {
        path: "/Preview",
        element: <Preview />,
    },
    {
        path: "/Publish",
        element: <Publish />,
    },
    {
        path: "/Publish/CardForm",
        element: <Publish />,
    },
    {
        path: "/EditForm",
        element: <Edit />,
    },
    {
        path: "/Setting",
        element: <Setting />,
    },
    {
        path: "/Settings/general",
        element: <FormSetting />,
    },
    {
        path: "/Setting/Sign",
        element: <JotFormSign />,
    },
    {
        path: "/Setting/Notification",
        element: <Notification />,
    },
    {
        path: "/Setting/Email",
        element: <EmailSetting />,
    },

    {
        path: "/Setting/Email/Options",
        element: <EmailSettingOptions />,
    },
    {
        path: "/Setting/Conditional",
        element: <Condition />,
    },
    {
        path: "/Setting/Conditional/ShowAndHide",
        element: <ShowAndHide />,
    },
    {
        path: "/Setting/Conditional/UpdateAndCalculate",
        element: <UpdateAndCalculate />,
    },
    {
        path: "/Setting/Conditional/EnableAndRequire",
        element: <EnableAndRequire />,
    },
    {
        path: "/Setting/Conditional/Skip",
        element: <Skip />,
    },
    {
        path: "/Setting/Conditional/ThankYou",
        element: <ThankYou />,
    },
    {
        path: "/Setting/Conditional/EmailRecipient",
        element: <EmailRecipient />,
    },
    {
        path: "/Setting/Apprival",
        element: <Approval />,
    },
    {
        path: "/Setting/ThankYou",
        element: <SettingThankYou />,
    },
    {
        path: "/Setting/EmailSetting/NotificationEmail",
        element: <NotificationEmail />,
    },
    {
        path: "/Setting/EmailSetting/NotificationEmail/Sender",
        element: <SenderNotificationEmail />,
    },
    {
        path: "/Setting/EmailSetting/NotificationEmail/Advance",
        element: <AdvanceNotificationEmail />,
    },
    {
        path: "/Setting/EmailSetting/AutoresponderEmail",
        element: <AutoresponderEmail />,
    },
    {
        path: "/Setting/EmailSetting/AutoresponderEmail/Sender",
        element: <AutoresponderEmailSender />,
    },
    {
        path: "/Setting/EmailSetting/AutoresponderEmail/Advance",
        element: <AutoresponderEmailAdvance />,
    },
    {
        path: "/Setting/general/FormWarnings",
        element: <FormWarnings />,
    },
    {
        path: "/Setting/general/CustomizeSaveEmail",
        element: <EmailTab />,
    },
    {
        path: "/Setting/general/CustomizeSaveEmail/Advance",
        element: <AdvanceTab />,
    },
    {
        path: "/Setting/ConditionList",
        element: <ConditionList />,
    },
])
export default router