import { Box, Grid, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import StateSelect from '../Component/StateSelect'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import LayoutCondition from '../Component/LayoutCondition'
import SettingDrawer from '../Component/SettingDrawer'
import HeaderSetting from '../Component/HeaderSetting'
import Navbar from '../Component/Navbar'
import { EmailNotofication } from '../Component/Icons'
import FormSettingSelect from '../Component/FormSettingSelect'
import axios from 'axios'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { SenderNotificationEmailContext } from '../Context/SenderNotificationEmailContext'

const SenderNotificationEmail = () => {


    const navigate = useNavigate()

    const { selectValues, setSelectValues, notificationEmails, setNotificationEmails } = useContext(SenderNotificationEmailContext);



    const senderOption = [
        { value: 'Altyn', label: 'Altyn ' },
        { value: 'لطفا انتخاب کنید ', label: 'لطفا انتخاب کنید' },
    ]


    const replyOption = [
        { value: 'لطفا انتخاب کنید ', label: 'لطفا انتخاب کنید' },
    ]


    const [recipient, setRecipient] = useState('')
    const [errorEmail, setErorrEmail] = useState('')
    const [senderId , setSenderId] = useState(0)
    const [replyId , setReplyId] = useState(0)



    const CustomToast = ({ closeToast, message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'red',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <ErrorIcon fontSize={'large'} />
                    {message}
                </Grid>

        );
    };


    const handleSelectChange = (name, value) => {
        // Check if a select with the given name already exists in the array
        const existingIndex = selectValues.findIndex((select) => select.name === name);

        if (existingIndex !== -1) {
            // If a select with the given name exists, update its value
            const updatedSelects = [...selectValues];
            updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value };
            setSelectValues(updatedSelects);
        } else {
            // If a select with the given name doesn't exist, add it to the array
            setSelectValues((prevSelectValues) => [...prevSelectValues, { name, value }]);
        }
    };


    const handleRecipient = (e) => {
        const { value, type } = e.target;
        setRecipient(value)


        if (type === 'email') {
            // Check if the entered value is a valid email address
            const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
            if (!emailPattern.test(value)) {
                setErorrEmail('آدرس ایمیل نامعتبر است');
            } else {
                // Reset error message if the email is valid
                setErorrEmail('');
            }
        }
    }


    const handleReset = () => {
        setRecipient('')
        setErorrEmail('')
        setSelectValues([
            { id: 1, name: 'SenderName', value: senderOption[0].value },
            { id: 2, name: 'ReplyEmail', value: replyOption[0].value },
            { id: 3, name: 'recipient', value: recipient },
            { id: 4, name: 'page', value: 'NotificationEmail' },
        ]);
        setReplyId(1)
        setSenderId(1)
    }




    const handleSubmit = () => {

        const hasRecipientInputEmpty = selectValues.some(item => item.name === 'recipient' && item.value === '');


        if (hasRecipientInputEmpty) {
            toast(<CustomToast message={'لطفا فرم ایمیل گیرنده را با دقت پر کنید'} state={'erorr'} />)
            return
        }


        if (errorEmail !== '') {
            toast(<CustomToast message={errorEmail} state={'erorr'} />)
            return
        }


        console.log(selectValues, 'parsa')

        setNotificationEmails(prevEmails => [...prevEmails, selectValues]);


        navigate("/Setting/Email")


        handleReset()

    }






    return (
        <Grid bgcolor={'#f3f3fe'}>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'تنظیمات ایمیل'} subTitle={'ارسال خودکار و اعلان ها'} link={'/Setting/Email'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={2} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={2} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'اطلاع رسانی'} subTitle={'زمانی که شخصی فرم شما را پر می کند، ایمیلی دریافت کنید'} link={'/Setting/Email'} icon={<EmailNotofication fill={'white'} />} color={'rgba(255,123,28,1)'} />
                    <Grid>
                        <Grid className='main' bgcolor={'white'} mb={'14px'}>
                            <Box width={'100%'} height={'48px'} display={'flex'} alignItems={'center'} bgcolor={'#fafafc'} borderBottom={'1px solid #eaebf2'}>
                                <Link to={'/Setting/EmailSetting/NotificationEmail'} style={{ width: '50%', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid width={'100%'} height={'48px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant='h5' fontWeight={500} color={'black'}>ایمیل</Typography>
                                    </Grid>
                                </Link>
                                <Link to={'/Setting/EmailSetting/NotificationEmail/Sender'} style={{ width: '50%', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid width={'100%'} height={'48px'} borderBottom={'3px solid #5e74e6'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant='h5' fontWeight={500} color={'#5e74e6'}>گیرندگان</Typography>
                                    </Grid>
                                </Link>
                                <Link to={'/Setting/EmailSetting/NotificationEmail/Advance'} style={{ width: '50%', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid width={'100%'} height={'48px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                        <Typography variant='h5' fontWeight={500} color={'black'}>پیشرفته</Typography>
                                    </Grid>
                                </Link>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Grid p={{xs:'21px' , sm:'21px 42px'}} width={'100%'}>
                                    <Grid className='Sender' mb={'22px'}>
                                        <Typography variant='h5' color={'#23283a'} fontWeight={600} pb={'4px'}>نام فرستنده</Typography>
                                        <FormSettingSelect id={senderId} onSelectChange={handleSelectChange} options={senderOption} name={'SenderName'}/>
                                    </Grid>
                                    <Grid className='reply' mb={'22px'} width={'100%'}>
                                        <Typography variant='h5' color={'#23283a'} fontWeight={600} pb={'4px'}>پاسخ به ایمیل</Typography>
                                        <FormSettingSelect id={replyId} onSelectChange={handleSelectChange} options={replyOption} name={'ReplyEmial'}/>
                                    </Grid>
                                    <Grid className='recipient'>
                                        <Grid display={'flex'} alignItems={'center'} gap={'2px'} mb={'4px'}>
                                            <Typography variant='h5' color={'#23283a'} fontWeight={600}>ایمیل گیرنده</Typography>
                                            <Typography color={'#d53049'} fontWeight={600} variant='h5'>*</Typography>
                                        </Grid>
                                        <input
                                            name='recipient'
                                            placeholder='اضافه کردن بیشتر...'
                                            value={recipient}
                                            onChange={handleRecipient}
                                            onBlur={() => { handleSelectChange('recipient', recipient) }}
                                            type='email'
                                            style={{
                                                width: '100%',
                                                border: '1px solid #b7bbd7',
                                                backgroundColor: 'white',
                                                borderRadius: '4px',
                                                padding: '8px 12px',
                                                color: '#23283a',
                                                fontSize: '14px',
                                                outline: 'none',
                                                marginBottom: errorEmail !== '' ? '4px' : '0px'
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid>
                            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'8px'}>
                                <Grid style={{ cursor: 'pointer' }} className='save' p={'12px 16px'} bgcolor={'rgba(100,178,0,1)'} fontSize={'14px'} color={'white'} borderRadius={'4px'} onClick={handleSubmit}>ذخیره</Grid>
                                <Grid style={{ cursor: 'pointer' }} className='text' p={'12px 16px'} bgcolor={'rgba(218,222,243,1)'} fontSize={'14px'} color={'rgba(52,60,106,1)'} borderRadius={'4px'}>تست ایمیل</Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default SenderNotificationEmail