import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import MainHeader from '../Component/MainHeader'
import MainNavbar from '../Component/MainNavbar'
import MainDrawer from '../Component/MainDrawer'
import { useData } from '../Context/DataContext'
import FormList from '../Component/FormList'
import CreateFormButton from '../Component/CreateFormButton'
import { useDraft } from '../Context/DraftContext'
import { useFormContext } from '../Context/Context'
import { useEditMode } from '../Context/EditModeContext'
import { useTitleContext } from '../Context/TitleContext'

const Main = () => {


  const { handleDrawerData } = useFormContext()
  const { setEditMode } = useEditMode();
  const { title, setTitle } = useTitleContext();



  useEffect(() => {
    handleDrawerData([])
    setEditMode(false)
    setTitle('فرم')
  }, []);


  return (
    <Grid>
      <MainHeader />
      <Grid display={{ xs: 'block', md: 'flex' }} alignItems={'flex-start'}>
        <MainDrawer />
        <Grid display={{ xs: "block", md: 'none' }}>
          <CreateFormButton />
        </Grid>
        <MainNavbar />
      </Grid>
    </Grid>
  )
}

export default Main