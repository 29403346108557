import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, Rating, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useInputValues } from '../Context/InputValuesContext';
import { useErrorContext } from '../Context/ErrorContext';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const ScaleRating = ({ id }) => {


    //data
    const ScaleData = [
        { id: 1, ActiveNumber: 1 },
        { id: 2, ActiveNumber: 2 },
        { id: 3, ActiveNumber: 3 },
        { id: 4, ActiveNumber: 4 },
        { id: 5, ActiveNumber: 5 },
    ]


    //context
    const { drawerData } = useFormContext();
    const { inputValues } = useInputValues();
    const { setMistake } = useErrorContext();
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);

    const location = useLocation();
    const currentPage = location.pathname;




    const existingComponentIndex = drawerData.findIndex(component => component.id === id);



    //state
    const [active, setActive] = useState(0)
    const [activeColor, setActiveColor] = useState('')
    const [scaleValue, setScaleValue] = useState('یک عنوان وارد کنید');
    const [subScaleValue, setSubScaleValue] = useState('');
    const [bestValue, setBestValue] = useState('بهترین');
    const [worthValue, setWorthValue] = useState('بدترین');
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const containerRef = useRef(null);
    const [condition, setCondition] = useState(false)




    const fillInputValue = () => {
        drawerData[existingComponentIndex].state.scaleValue = scaleValue
        drawerData[existingComponentIndex].state.subScaleValue = subScaleValue
        drawerData[existingComponentIndex].state.bestValue = bestValue
        drawerData[existingComponentIndex].state.worthValue = worthValue
        drawerData[existingComponentIndex].state.mainActive = active
        drawerData[existingComponentIndex].state.readOnly = readOnly

    }

    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly





    //style
    let theme = useTheme()

    let HeadingColor = theme.palette.blue[700]
    let worthColor = '#C70039'
    let badColor = '#FF6969'
    let midColor = theme.palette.yellow[600]
    let betterColor = '#65B741'
    let bestColor = '#508D69'


    //function

    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'ScaleRating') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    const handleBest = (e) => {
        setBestValue(e.target.value)
    }

    const handleWorth = (e) => {
        setWorthValue(e.target.value)
    }


    const handleActive = (id) => {
        setActive(id);
        switch (id) {
            case 1:
                setActiveColor(worthColor);
                break;
            case 2:
                setActiveColor(badColor);
                break;
            case 3:
                setActiveColor(midColor);
                break;
            case 4:
                setActiveColor(betterColor);
                break;
            case 5:
                setActiveColor(bestColor);
                break;
            default:
                setActiveColor(''); // Handle edge cases
                break;
        }
    };

    const handleScaleChange = (event) => {
        setScaleValue(event.target.value);
    };


    const handleSubScaleChange = (event) => {
        setSubScaleValue(event.target.value);
    };


    // Add a global click event listener to handle clicks outside the first grid


    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };


    const handleHeadingBlur = () => {
        setHeadingFocused(false); // Set focus state to false on blur
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };


    //effect



    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, scaleValue, subScaleValue, bestValue, worthValue, active]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    return (
        <Grid
            mb={currentPage === '/build'|| currentPage === '/Publish' ? '28px' : '50px'}
            p={currentPage === '/build'|| currentPage === '/Publish' ? '12px 10px' : '24px 36px'} borderRadius={'3px'}
            mx={currentPage === '/build'|| currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}>
            <Grid className={'HeadingInput'} pb={'14px'}>
                <input className="customHead-input" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                    value={scaleValue}
                    onChange={handleScaleChange}
                    onClick={handleClickOutside}
                    onFocus={handleHeadingFocus}
                />
            </Grid>
            <Grid >
                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={'25px'} pb={'8px'} width={'100%'} pl={'15px'}>
                    {

                        ScaleData.map(
                            item =>
                                <Grid key={item.id} width={'40px'} height={'40px'} borderRadius={'50%'}
                                    border={'1px solid #8894ab'} display={'flex'} alignItems={'center'}
                                    justifyContent={'center'} style={{ cursor: "pointer" }}
                                    onClick={currentPage === '/build' || currentPage === "/build/CardForm" || condition ? null : () => {
                                        handleActive(item.ActiveNumber)
                                    }}
                                    bgcolor={active === item.ActiveNumber ? activeColor : 'white'}

                                >
                                    <Typography variant={'subtitle1'} pt={'4px'}
                                        color={active === item.ActiveNumber ? 'white' : '#8894ab'}>{item.ActiveNumber}</Typography>
                                </Grid>
                        )

                    }
                </Grid>
                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <input className="" placeholder={'بدترین متن را تایپ کنید'} style={{
                        border: 'none',
                        outline: 'none',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: 1.45,
                        color: 'rgb(44, 51, 69)',
                        width: '50px'
                    }}
                        readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                        value={worthValue}
                        onChange={handleWorth}
                        onFocus={handleHeadingFocus}
                    />
                    <input className="" placeholder={'بهترین متن را تایپ کنید'} style={{
                        border: 'none',
                        outline: 'none',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: 1.45,
                        color: 'rgb(44, 51, 69)',
                        width: '50px'
                    }}
                        readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                        value={bestValue}
                        onChange={handleBest}
                        onFocus={handleHeadingFocus}

                    />
                </Grid>
            </Grid>

        </Grid>
    )
}
export default ScaleRating
