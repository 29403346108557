import React, { createContext, useState } from 'react';

// Create a context with an empty array as the default value
const FilteredValuesContext = createContext([]);

export const FilteredValuesProvider = ({ children }) => {
  const [filteredSelectValuesList, setFilteredSelectValuesList] = useState([]);

  return (
    <FilteredValuesContext.Provider value={{ filteredSelectValuesList, setFilteredSelectValuesList }}>
      {children}
    </FilteredValuesContext.Provider>
  );
};

export default FilteredValuesContext;
