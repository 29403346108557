import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import JoditEditor from 'jodit-react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Typography, Grid } from '@mui/material';
import { Hourglass } from 'react-loader-spinner';
import { PayloadContext } from '../../Context/PayloadContext';
import InitialValue from '../ThankYouEditor/InitialValue';
import ReactDOMServer from 'react-dom/server';
import { useLocation } from 'react-router-dom';

const Editor = ({ src, id }) => {

    const { payload, setPayload } = useContext(PayloadContext);

    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);


    const config = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/docs/,
            // placeholder: placeholder || 'Start typings...'
        }),
        []
    );



    const handleSubmit = () => {

        if (editor.current) {

            const content = editor.current.value;

            const uniqueId = uuidv4();

            const newPayload = 
                {
                    id: uniqueId,
                    name: 'ThankYouContent',
                    content: content
                }
            ;

            console.log(newPayload)

            setPayload(newPayload);

            axios.post('/your-content-upload-endpoint', payload)
                .then(response => {
                    console.log('Content successfully uploaded:', response.data);
                })
                .catch(error => {
                    console.error('Error uploading content:', error);
                });
        }
    };




    useEffect(() => {

        handleSubmit()

    }, [editor.current])


    useEffect(() => {
        // Convert the InitialValue component to an HTML string
        const initialContent = ReactDOMServer.renderToStaticMarkup(<InitialValue src={src} id={id} />);
        setContent(initialContent);
        setLoading(false);
    }, [src, id]);



    useEffect(() => {
        if (content) {
            setLoading(false);
        }
    }, [ content]);

    useEffect(() => {
        if (editor.current) {
            editor.current.value = content;
        }
    }, [content]);


    useEffect(() => {
        if (editor.current && payload) {
            editor.current.value = payload.content;
        }
    }, [payload]);


    return (
        <Grid>
            {loading && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
                    <Hourglass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#306cce', '#72a1ed']}
                    />
                </Box>
            )}
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => setContent(newContent)}
            />
            <Grid>
                <Button variant='contained' color='success' onClick={handleSubmit} sx={{ padding: '12px 24px', marginTop: '24px' }}>
                    <Typography variant='h6' fontWeight={700} color={'white'}>ذخیره</Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export default Editor