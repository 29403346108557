import React, { createContext, useContext } from 'react';
import useLocalStorage from 'use-local-storage';

const ArchivedItemsContext = createContext();

export const ArchivedItemsProvider = ({ children }) => {
    
    const [archiveItems, setArchiveItems] = useLocalStorage('ArchivedItems', []);

    const saveArchived = (data) => {
        setArchiveItems(prevData => [...prevData, ...data]);
    };

    return (
        <ArchivedItemsContext.Provider value={{ archiveItems, setArchiveItems , saveArchived }}>
            {children}
        </ArchivedItemsContext.Provider>
    );
};

export const useArchivedItems = () => {
    return useContext(ArchivedItemsContext);
};

