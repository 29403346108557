import {createTheme} from "@mui/material/styles";
import '../index.css'
const Theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontFamily: 'YekanBakh',
                    fontSize: "62.5%",
                    padding: 0,
                    margin: 0,
                    boxSizing: "border-box",
                    direction:'rtl'
                },
                body: {
                    backgroundColor: "#f1f1f4",
                    a: {
                        textDecoration: "none",
                        fontSize: "1.3rem",
                    },
                    label: {
                        fontSize: "1.6rem",
                        fontWeight: 500,
                    },
                    stronge: {
                        fontSize: "2.2rem",
                    },
                },
            },
        },
    },
    palette: {
        white: '#fff',
        black: '#000',
        blue: {
            100: '#edf8ff',
            200: '#77cfff',
            300: '#4dbefc',
            400: '#09f',
            500: '#0075e3',
            600: '#0066c3',
            700: '#2c3345'
        },
        green: {
            100: '#edfed1',
            200: '#cbfb7b',
            300: '#a8eb38',
            400: '#7fca00',
            500: '#64b200',
            600: '#529300',
        },
        yellow: {
            100: '#fff5d2',
            200: '#ffdc7b',
            300: '#ffc42c',
            400: '#ffb629',
            500: '#f9a400',
            600: '#f49200',
        },
        orange: {
            100: '#ffe4cc',
            200: '#fec48e',
            300: '#ffa34f',
            400: '#ff7b1c',
            500: '#ff6100',
            600: '#e55300',
        },
        red: {
            100: '#fef2f2',
            200: '#fecaca',
            300: '#f87171',
            400: '#dc2626',
            500: '#c90909',
            600: '#ab0101'
        },
        purple: {
            100: '#f3e2ff',
            200: '#e0b7fd',
            300: '#bf78f0',
            400: '#9c4dd3',
            500: '#892dca',
            600: '#6915a4',
        },
        navy: {
            10: '#f9f9ff',
            25: '#f3f3fe',
            50: '#e3e5f5',
            75: '#dadef3',
            100: '#c8ceed',
            200: '#979dc6',
            300: '#6c73a8',
            400: '#454e80',
            500: '#343c6a',
            600: '#252d5b',
            700: '#0a1551',
            800: '#091141',
            900: '#050c34',
        },
        gary: {
            25: '#f1f1f4',
            50: '#e2e3e9',
            75: '#d3d6de',
            100: '#bfc3ce',
            200: '#a0a6b6',
            300: '#7f859c',
            400: '#4c536f',
            500: '#434a60',
            600: '#33384a',
            700: '#292d3c',
            800: '#131620',
            900: '#08090b'
        },
        'analytics-light': '#b9bedf',
        'analytics-default': '#8f96cc',
        'analytics-dark': '#737cbf',
        'approvals-light': '#008f75',
        'approvals-default': '#007862',
        'approvals-dark': '#006653',
        'apps-light': '#bf78f0',
        'apps-default': '#9c4dd3',
        'apps-dark': '#892dca',
        'forms-light': '#ff7b1c',
        'forms-default': '#ff6100',
        'forms-dark': '#e55300',
        'inbox-light': '#59bed2',
        'inbox-default': '#249bb4',
        'inbox-dark': '#1989a0',
        'pages-light': '#5887bf',
        'pages-default': '#034f96',
        'pages-dark': '#03294c',
        'pdf-light': '#628eff',
        'pdf-default': '#3e62c8',
        'pdf-dark': '#2f4ead',
        'reports-light': '#728ddd',
        'reports-default': '#5369ab',
        'reports-dark': '#344067',
        'sign-light': '#94cd2a',
        'sign-default': '#7bb60f',
        'sign-dark': '#6da900',
        'tables-light': '#37c768',
        'tables-default': '#049e38',
        'tables-dark': '#007b2a',
        'SubmitButtonColor':'#18bd5b',
        'teams-light': '#303c77',
        'teams-default': '#18235c',
        'teams-dark': '#0c1540',
    },


    breakpoints: {
        values: {
            xs: 0,
            xxs: 480,
            sm: 700,
            md: 900,
            l: 1000,
            lg: 1200,
            llg: 1400,
            g: 1536,
            xg: 1788,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: 'YekanBakh',
        h1: {
            fontSize: "36px",
            fontWeight: 900,
        },
        h2: {
            fontSize: "24px",
        },
        h3: {
            fontSize: "21px",
        },
        h4: {
            fontSize: '18px'
        },
        h5: {
            fontSize: "16px",
        },
        h6: {
            fontSize: "14px",
        },
        body1:{
            fontSize:'13px'
        },
        subtitle1: {
            fontSize: "12px"
        },
        subtitle2: {
            fontSize: '10px'
        },
        body2:{
            fontSize:'8px'
        },
    },
});

export default Theme;