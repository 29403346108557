import { Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import './LoginSelect.css'
import { useCountry } from '../../Context/CountryContext';
import { RotatingLines } from 'react-loader-spinner';

const LoginSelect = ({ options }) => {


    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [countryName, setCountryName] = useState("");
    const { countryId, setCountryId } = useCountry();
    const dropdownRef = useRef(null);


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setSearchTerm("");
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleOptionSelect = (option, id) => {
        setCountryName(option)
        setCountryId(id)
        setIsOpen(false);
        setSearchTerm("");
    };

    const filteredOptions = options.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    return (
        <Grid ref={dropdownRef} className="select-with-search-container" width={{ xs: '100%' }}>
            <Typography variant='h5' mb={'4px'}>کشور</Typography>
            <Grid className="custom-select" onClick={toggleDropdown} width={'100%'} p={'14px'} height={'50px'}>
                {countryName || "کشور خود را انتخاب کنید"}
            </Grid>
            {isOpen && (
                <Grid className="dropdown-content" p={'12px'}>
                    <Grid className="search-container" borderBottom={'1px solid #ccc'}>
                        <SearchIcon className="search-icon" />
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="جستجو..."
                        />
                    </Grid>
                    {filteredOptions.length > 0 ?
                        filteredOptions.map((option) => (
                            <Grid
                                display={'flex'}
                                alignItems={'center'}
                                gap={'4px'}
                                key={option.id}
                                onClick={() => handleOptionSelect(option.name, option.id)}
                                className="option"
                            >
                                <Typography variant="h6" color={'#0a1551'}>{option.name}</Typography>
                            </Grid>
                        ))
                        :
                        <Grid mt={'24px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <RotatingLines
                                visible={true}
                                height="48"
                                width="48"
                                color="#4379F2"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </Grid>
                    }
                </Grid>
            )}
        </Grid>
    )
}

export default LoginSelect