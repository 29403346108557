const ConvertTitle = ({ title }) => {

    const handleConvertTitle = (title) => {
        switch (title) {
            case "عنوان":
                return "Heading";
            case "نام و نام خانوادگی":
                return "FullName";
            case "ایمیل":
                return "Email";
            case "آدرس":
                return "Address";
            case "تلفن":
                return "Phone";
            case "تقویم":
                return "DatePicker";
            case "وقت ملاقات":
                return "Appointment";
            case "امضا":
                return "Signature";
            case 'جای خالی را پر کنید':
                return "FillInTheBlank";
            case "لیست محصولات":
                return "ProductList";
            case "متن کوتاه":
                return "ShortText";
            case "متن طولانی":
                return "LongText";
            case "پاراگراف":
                return "Paragraph";
            case "بازشونده":
                return "DropDown";
            case "تک گزینه ای":
                return "SingleChoice";
            case "چند گزینه ای":
                return "MultiChoice";
            case "شماره":
                return "Number";
            case "عکس":
                return "Image";
            case "آپلود فایل":
                return "FileUpload";
            case "زمان":
                return "Time";
            case "شمارنده":
                return "Spinner";
            case "جدول ورودی":
                return "Table";
            case "رتبه بندی ستاره ای":
                return "StarRating";
            case "رتبه بندی مقیاس":
                return "ScaleRating";
            case "جدا کننده":
                return "Divider";
            case "بخش جدا کننده":
                return "Section";
            default:
                return "";
        }
    }
    return handleConvertTitle(title);
}

export default ConvertTitle;

