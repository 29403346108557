import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Grid, Rating, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useInputValues } from '../Context/InputValuesContext';
import { useErrorContext } from '../Context/ErrorContext';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const StarRating = ({ id }) => {


    const { drawerData } = useFormContext();
    const existingComponentIndex = drawerData.findIndex(component => component.id === id);
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);



    const location = useLocation();
    const currentPage = location.pathname;


    const [starValue, setStarValue] = useState('یک عنوان وارد کنید');
    const [subStarValue, setSubStartValue] = useState('');
    const [rate, setRate] = useState(2);
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [condition, setCondition] = useState(false)



    const containerRef = useRef(null);



    const fillInputValue = () => {
        drawerData[existingComponentIndex].state.starValue = starValue
        drawerData[existingComponentIndex].state.subStarValue = subStarValue
        drawerData[existingComponentIndex].state.mainRate = rate
        drawerData[existingComponentIndex].state.readOnly = readOnly

    }


    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly



    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'StarRating') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }




    const handleStarChange = (event) => {
        setStarValue(event.target.value);
    };


    const handleSubStarChange = (event) => {
        setSubStartValue(event.target.value);
    };


    // Add a global click event listener to handle clicks outside the first grid


    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };


    const handleHeadingBlur = () => {
        setHeadingFocused(false); // Set focus state to false on blur
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, starValue, subStarValue, rate]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    let theme = useTheme()
    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let SubHeadingInputFontSize = isUpXxl ? '14px' : '12px'
    let HeadingColor = theme.palette.blue[700]


    return (
        <Grid
            mb={currentPage === '/build'|| currentPage === '/Publish' ? '28px' : '50px'}
            p={currentPage === '/build'|| currentPage === '/Publish' ? '12px 10px' : '24px 36px'} borderRadius={'3px'}
            mx={currentPage === '/build'|| currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}>
            <Grid className={'HeadingInput'} pb={'14px'}>
                <input className="customHead-input" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                    value={starValue}
                    onChange={handleStarChange}
                    onClick={handleClickOutside}
                    onFocus={handleHeadingFocus}
                />
            </Grid>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'flex-start'} pb={'16px'} mr={'24px'}>
                <Rating
                    readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                    style={{ gap: '30px', direction: 'ltr', cursor: 'pointer' }}
                    size={'large'}
                    name="simple-controlled"
                    value={rate}
                    onChange={(event, newValue) => {
                        setRate(newValue);
                    }}
                />
            </Grid>
            <Grid>
                {isSubHeaderVisible && (
                    <Grid className={'subHeader'} pb={'14px'}>
                        <input
                            className=""
                            placeholder={'یک عنوان فرعی وارد کنید'}
                            style={{
                                border: 'none',
                                outline: 'none',
                                userSelect: 'text',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                width: '100%',
                                fontSize: SubHeadingInputFontSize,
                                fontWeight: 500,
                                lineHeight: 1.6,
                                color: '#57647e',
                            }}
                            readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                            value={subStarValue}
                            onChange={handleSubStarChange}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
export default StarRating
