import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'

const Sicon = ({src , dir , columnFlag , radius}) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isOver1200, setIsOver1200] = useState(window.innerWidth > 1200);


    useEffect(() => {
        const handleResize = () => {
          const width = window.innerWidth;
          setWindowWidth(width);
          setIsOver1200(width > 1200);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup function to remove the event listener when component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [windowWidth]);



  return (
    <Grid style={{display:'flex' , flexDirection:dir , alignItems:'center' , justifyContent:'center' , marginTop:'30px' , gap:columnFlag?'0px':'32px' , backgroundColor:'white' , padding:columnFlag?'80px':'120px'}}>
        <Grid className='image' style={{width:isOver1200?'150px':'100px' , height:isOver1200?'150px':'100px' , marginBottom:dir === 'column-reverse'?'0px':'27px' , borderRadius:radius?'50%':'0px'}}>
            <img src={src} alt='' style={{width:'100%' , height:'100%' , borderRadius:radius?'50%':'0px'}}/>
        </Grid>
        <Grid className='content' style={{marginBottom:dir === 'column' ? '0px':'27px'}}>
            <div style={{fontSize:'36px' , color:'#2C3345' , fontWeight:600 , marginBottom:'16px' , textAlign:'center'}}>متشکریم</div>
            <div style={{fontSize:'14px' , color:'#2C3345' , fontWeight:500 , opacity:0.6 , textAlign:'center'}}>ارسالی شما دریافت شد</div>
        </Grid>
    </Grid>
  )
}

export default Sicon