import React, { createContext, useState } from 'react';

const SelectedOptionContext = createContext();

const SelectedOptionProvider = ({ children }) => {
    const [selectedOption, setSelectedOption] = useState("");

    return (
        <SelectedOptionContext.Provider value={{ selectedOption, setSelectedOption }}>
            {children}
        </SelectedOptionContext.Provider>
    );
};

export { SelectedOptionContext, SelectedOptionProvider };
