import React, { createContext, useContext } from 'react';
import useLocalStorage from 'use-local-storage';

const FavoriteItemsContext = createContext();

export const FavoriteItemsProvider = ({ children }) => {

    const [favoriteItems, setFavoriteItems] = useLocalStorage('FavoriteItems', []);

    const saveFavorite = (data) => {
        setFavoriteItems(prevData => [...prevData, ...data]);
    };

    return (
        <FavoriteItemsContext.Provider value={{ favoriteItems, setFavoriteItems , saveFavorite }}>
            {children}
        </FavoriteItemsContext.Provider>
    );
};

export const useFavoriteItems = () => {
    return useContext(FavoriteItemsContext);
};

