import { Box, Grid, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SettingDrawer from '../Component/SettingDrawer'
import HeaderSetting from '../Component/HeaderSetting'
import Navbar from '../Component/Navbar'
import LayoutCondition from '../Component/LayoutCondition'
import { EnableFormSetting, GeneralSetting, SettingFormStatus } from '../Component/Icons'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Switch from 'react-switch'
import '../App.css'
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import Checkbox from '@mui/material/Checkbox';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FormSettingOption from '../Component/FormSettingOption'
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'
import FormSettingSelect from '../Component/FormSettingSelect'
import { useTitleContext } from '../Context/TitleContext'
import axios from 'axios'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify'

const FormSetting = () => {


    const { title, setTitle } = useTitleContext();


    const [newTitle, setNewTitle] = useState(title);
    const [checked, setChecked] = useState(true);
    const [check, setCheck] = useState(false);
    const [show, setShow] = useState(false)
    const [showOption, setShowOption] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [selectValues, setSelectValues] = useState([
        { id: 0, name: 'title', value: 'فرم' },
        { id: 1, name: 'Save and Continue Later', value: 'غیرفعال' },
        { id: 2, name: 'FormStatus', value: 'فعال' },
        { id: 3, name: 'protection', value: '' },
        { id: 4, name: 'Unique Submission', value: 'بدون چک' },
        { id: 5, name: 'Unique Field', value: 'بدون چک' },
        { id: 6, name: 'Form Accessibility', value: 'غیرفعال' },
        { id: 7, name: 'Clear Hidden Field Values', value: 'وقتی پنهان است پاک شود' },
        { id: 8, name: 'Highlight Effect', value: 'غیرفعال' },
        { id: 9, name: 'Form Layout', value: 'همه سوالات در یک صفحه' },
        { id: 10, name: 'Show Error Navigation', value: 'بله' },
        { id: 11, name: 'Prevent Cloning', value: 'بله' },
        { id: 12, name: 'Allow Browser Autocomplete', value: 'بله' },
    ]);
    const [protection, setProtection] = useState('')




    const CustomToast = ({ closeToast, message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'flex-start'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-start'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'red',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                    textAlign: 'right'
                }}>
                    <ErrorIcon fontSize={'large'} />
                    {message}
                </Grid>

        );
    };





    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const handleChangeTitle = (event) => {
        setNewTitle(event.target.value)
    }


    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };


    const handleChangeCheckBox = (event) => {
        setCheck(event.target.checked);
    };



    const handleSelectChange = (name, value) => {
        // Check if a select with the given name already exists in the array
        const existingIndex = selectValues.findIndex((select) => select.name === name);

        if (existingIndex !== -1) {
            // If a select with the given name exists, update its value
            const updatedSelects = [...selectValues];
            updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value };
            setSelectValues(updatedSelects);
        } else {
            // If a select with the given name doesn't exist, add it to the array
            setSelectValues((prevSelectValues) => [...prevSelectValues, { name, value }]);
        }
    };


    const handleBlurTitle = () => {
        setTitle(newTitle);
        // Update selectValues with the new title
        const existingIndex = selectValues.findIndex((select) => select.name === 'title');

        if (existingIndex !== -1) {
            // If a select with the name 'title' exists, update its value
            const updatedSelects = [...selectValues];
            updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value: newTitle };
            setSelectValues(updatedSelects);
        } else {
            // If a select with the name 'title' doesn't exist, add it to the array
            setSelectValues((prevSelectValues) => [...prevSelectValues, { name: 'title', value: newTitle }]);
        }
    };

    const handleChangeProtection = (event) => {
        setProtection(event.target.value);

        // Update selectValues with the new protection value
        const existingIndex = selectValues.findIndex((select) => select.name === 'protection');

        if (existingIndex !== -1) {
            // If a select with the name 'protection' exists, update its value
            const updatedSelects = [...selectValues];
            updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value: event.target.value };
            setSelectValues(updatedSelects);
        } else {
            // If a select with the name 'protection' doesn't exist, add it to the array
            setSelectValues((prevSelectValues) => [...prevSelectValues, { name: 'protection', value: event.target.value }]);
        }
    };


    const handleModalSelectChange = (name, value) => {
        if (value !== undefined) {
            // Check if a select with the given name already exists in the array
            const existingIndex = selectValues.findIndex((select) => select.name === name);

            if (existingIndex !== -1) {
                // If a select with the given name exists, update its value
                const updatedSelects = [...selectValues];
                updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value };
                setSelectValues(updatedSelects);
            } else {
                // If a select with the given name doesn't exist, add it to the array
                setSelectValues((prevSelectValues) => [...prevSelectValues, { name, value }]);
            }
        }
        handleClose();
    };



    const handleSave = () => {
        // Send selectValues to the server using Axios
        console.log(selectValues, 'values')
        axios.post('YOUR_SERVER_ENDPOINT', { data: selectValues })
            .then(response => {
                // Handle success response if needed
                toast(<CustomToast message={'فرم با موفقیت ذخیره شد'} state={'success'} />)
                console.log('Data saved successfully:', response.data);
            })
            .catch(error => {
                // Handle error
                toast(<CustomToast message={"ذخیره تغییرات ناموفق بود. لطفا چند لحظه دیگر مجددا تلاش کنید"} state={'error'} />)
                console.error('Error saving data:', error);
            });
    };







    const modalOption = [
        { value: 'فعال ', label: ' فعال' },
        { value: 'غیرفعال ', label: 'غیرفعال ' },
        { value: 'در تاریخ خاصی غیرفعال کنید ', label: 'در تاریخ خاصی غیرفعال کنید ' },
        { value: 'غیرفعال کردن در حد مجاز ارسال ', label: 'غیرفعال کردن در حد مجاز ارسال ' },
        { value: 'در تاریخ خاص یا در محدودیت ارسال غیرفعال شود ', label: 'در تاریخ خاص یا در محدودیت ارسال غیرفعال شود ' },
    ]


    const optionData = [
        {
            id: 0,
            name: 'Save and Continue Later',
            title: 'ذخیره و بعدا ادامه دهید',
            subTitle: 'به کاربران فرم اجازه دهید فرم ارسالی خود را ذخیره کنند و بعداً آن را ادامه دهند',
            optionValue: [
                { value: 'غیرفعال', label: 'غیرفعال' },
                { value: 'فعال', label: 'فعال' },
            ],
            flag: true,
            linkFlag: true,
            active: 0
        },
        {
            id: 1,
            name: 'Unique Submission',
            title: 'ارسال منحصر به فرد',
            subTitle: 'از کوکی ها یا آدرس IP برای جلوگیری از ارسال چند فرم استفاده کنید',
            optionValue: [
                { value: 'بدون چک', label: 'بدون چک' },
                { value: 'فقط کوکی ها را بررسی کنید', label: 'فقط کوکی ها را بررسی کنید' },
                { value: 'کوکی ها و آدرس IP را بررسی کنید', label: 'کوکی ها و آدرس IP را بررسی کنید' },
            ],
            flag: true,
            linkFlag: false,
            active: 0
        },
        {
            id: 2,
            name: 'Unique Field',
            title: 'فیلد منحصر به فرد',
            subTitle: 'مقادیر وارد شده پیش‌تر را برای یک فیلد خاص قبول نکنید.',
            optionValue: [
                { value: 'بدون چک', label: 'بدون چک' },
            ],
            flag: true,
            linkFlag: false,
            active: 0
        },
        {
            id: 3,
            name: 'Form Accessibility',
            title: 'قابلیت دسترسی به فرم',
            subTitle: 'دسترسی فرم خود را بررسی کنید',
            optionValue: [
                { value: 'غیرفعال', label: 'غیرفعال' },
                { value: 'فعال', label: 'فعال' },
            ],
            flag: true, linkFlag: false,
            active: 0
        },
        { id: 4, title: 'عنوان صفحه', subTitle: 'لطفاً یک عنوان وارد کنید که به برای عنوان صفحه در مرورگر نمایش داده شود.', optionValue: '', flag: true, linkFlag: false, active: 0 },
        {
            id: 5,
            name: 'Clear Hidden Field Values',
            title: 'پاک کردن مقادیر فیلدهای پنهان',
            subTitle: 'انتخاب کنید که آیا مقادیر برای فیلدهای پنهان شده توسط منطق شرطی پاک شوند یا خیر',
            optionValue: [
                { value: 'وقتی پنهان است پاک شود', label: 'وقتی پنهان است پاک شود' },
                { value: 'هنگام ارسال پاک شود', label: 'هنگام ارسال پاک شود' },
                { value: 'پاک نشود', label: 'پاک نشود' },
            ],
            flag: true,
            linkFlag: false,
            active: 0
        },
        {
            id: 6,
            name: 'Highlight Effect',
            title: 'جلوه برجسته',
            subTitle: 'هایلایت پس زمینه زرد را برای فیلدهای فرم فعال یا غیرفعال کنید',
            optionValue: [
                { value: 'غیرفعال', label: 'غیرفعال' },
                { value: 'فعال', label: 'فعال' },
            ],
            flag: true,
            linkFlag: false,
            active: 0
        },
        {
            id: 7,
            name: 'Form Layout',
            title: 'طرح بندی فرم',
            subTitle: '',
            optionValue: [
                { value: 'همه سوالات در یک صفحه', label: 'همه سوالات در یک صفحه' },
                { value: 'تک سوال در هر صفحه', label: 'تک سوال در هر صفحه' },
            ],
            flag: true,
            linkFlag: false,
            active: 0
        },
        {
            id: 8,
            name: 'Show Error Navigation',
            title: 'نمایش راهنمای خطا',
            subTitle: 'اجازهٔ پیمایش بین خطاهای فرم ',
            optionValue: [
                { value: 'بله', label: 'بله' },
                { value: 'خیر', label: 'خیر' },
            ],
            flag: true,
            linkFlag: false,
            active: 0
        },
        {
            id: 9,
            name: 'Prevent Cloning',
            title: 'جلوگیری از شبیه سازی',
            subTitle: 'از شبیه سازی این فرم توسط سایر کاربران جلوگیری کنید',
            optionValue: [
                { value: 'بله', label: 'بله' },
                { value: 'خیر', label: 'خیر' },
            ],
            flag: true,
            linkFlag: false,
            active: 0
        },
        {
            id: 10,
            name: 'Allow Browser Autocomplete',
            title: 'اجازه تکمیل خودکار مرورگر',
            subTitle: 'به مرورگرها اجازه ذخیره و تکمیل خودکار فیلدهای فرم را بدهید.',
            optionValue: [
                { value: 'بله', label: 'بله' },
                { value: 'خیر', label: 'خیر' },
            ],
            flag: true,
            linkFlag: false,
            active: 0
        },
    ]


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'rgba(255,255,255,1)',
        boxShadow: 24,
        borderRadius: '8px'
    };



    useEffect(() => {
        setNewTitle(title)
    }, [title]);






    return (
        <Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'تنظیمات فرم'} subTitle={'وضعیت و خصوصیات فرم را سفارشی کنید'} link={'/Setting'} />
            </Grid>
            <Grid mt={{ xxs: '119px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={1} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={1} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '60px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'تنظیمات فرم'} subTitle={'وضعیت و خصوصیات فرم را سفارشی کنید'} link={''} icon={<GeneralSetting fill={'white'} />} color={'rgb(255, 97, 0)'} />
                    <Grid bgcolor={'white'} border={'1px solid #f2f2f2'} borderRadius={'4px'} mb={'1em'}>
                        <Grid className='title' borderBottom={'1px solid #f3f3fe'} p={'30px'}>
                            <Typography variant='h5' color={'#596066'} fontWeight={700} lineHeight={'1.618em'}>عنوان</Typography>
                            <Typography variant='h6' color={'#596066'} fontWeight={500} mb={'10px'}>یک نام برای فرم خود وارد کنید</Typography>
                            <input
                                value={newTitle}
                                onChange={handleChangeTitle}
                                onBlur={handleBlurTitle}
                                style={{
                                    border: '1px solid #c5c5c5',
                                    borderRadius: '4px',
                                    width: '100%',
                                    padding: '6px 12px',
                                    boxShadow: '0 2px 4px rgba(86,70,50,.1)',
                                    fontSize: '14px',
                                    backgroundColor: 'transparent',
                                    outline: '0px'
                                }}
                            />
                        </Grid>
                        <Grid>
                            <Grid className='FormStatus' borderBottom={'1px solid #f3f3fe'} p={'30px'} onClick={handleOpen}>
                                <Typography variant='h5' color={'#596066'} fontWeight={700} lineHeight={'1.618em'}>وضعیت فرم</Typography>
                                <Typography variant='h6' color={'#596066'} fontWeight={500} mb={'10px'}>فرم خود را فعال، غیرفعال یا مشروط کنید</Typography>
                                <Grid p={'12px 16px'} border={'1px solid rgb(197, 197, 197)'} borderRadius={'0.25rem'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                        <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} width={'32px'} height={'32px'} borderRadius={'0.25rem'} bgcolor={'rgba(100,178,0,1)'}>
                                            <EnableFormSetting fill={'white'} />
                                        </Grid>
                                        <Grid>
                                            <Typography mb={'4px'} variant='h5' fontWeight={500} color={'rgba(100,178,0,1)'} lineHeight={'20px'}>فعال شد</Typography>
                                            <Typography variant='h6' fontWeight={500} color={'rgb(89, 96, 102)'} lineHeight={'18px'}>فرم شما در حال حاضر قابل مشاهده است و می تواند موارد ارسالی را دریافت کند</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid pt={'10px'}>
                                        <ExpandCircleDownIcon style={{ rotate: '90deg', color: 'darkgray' }} fontSize={'large'} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style} width={{ xs: '95%', xxs: '85%', sm: '70%', md: '50%' }}>
                                        <Grid p={'24px'} width={'100%'} borderBottom={'1px solid rgba(227,229,245,1)'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} width={{ xs: '40px' }} height={'40px'} borderRadius={'4px'} bgcolor={'rgba(0,117,227,1)'}>
                                                    <SettingFormStatus fill={'white'} />
                                                </Grid>
                                                <Grid>
                                                    <Typography variant='h5' color={'rgba(10,21,81,1)'} fontWeight={500} lineHeight={'20px'}>وضعیت فرم</Typography>
                                                    <Typography variant='h6' color={'rgba(108,115,168,1)'} fontWeight={500} lineHeight={'18px'}>وضعیت فرم خود را انتخاب کنید</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid onClick={handleClose} display={'flex'} alignItems={'center'} justifyContent={'center'} width={{ xs: '24px', xxs: '40px' }} height={{ xs: '24px', xxs: '40px' }} borderRadius={'50%'} bgcolor={'rgba(218,222,243,1)'}>
                                                <CloseIcon style={{ color: 'rgba(52,60,106,1)' }} />
                                            </Grid>
                                        </Grid>
                                        <Grid p={'32px'}>
                                            <Grid>
                                                <Typography mb={'4px'} variant='h5' color={'rgba(10,21,81,1)'} fontWeight={500} lineHeight={'20px'}>وضعیت فرم</Typography>
                                                <Typography mb={'12px'} variant='h6' color={'rgba(108,115,168,1)'} fontWeight={500} lineHeight={'18px'}>فرم خود را فعال، غیرفعال یا مشروط کنید</Typography>
                                            </Grid>
                                            <Grid>
                                                <FormSettingSelect options={modalOption} id={0} onSelectChange={handleSelectChange} name={'FormStatus'} />
                                            </Grid>
                                        </Grid>
                                        <Grid p={'16px 24px'} bgcolor={'rgba(243,243,254,1)'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Grid p={'8px 18px'} bgcolor={'rgba(100,178,0,1)'} borderRadius={'4px'} onClick={(event) => { handleModalSelectChange(event) }}>
                                                <Typography variant='h6' color={'white'} >ذخیره</Typography>
                                            </Grid>
                                            <Grid p={'8px 18px'} bgcolor={'rgba(218,222,243,1)'} borderRadius={'4px'} onClick={handleClose}>
                                                <Typography variant='h6' color={'rgba(52,60,106,1)'}>لغو کردن</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Modal>
                            </Grid>
                        </Grid>

                        <Grid className='Encrypt' borderBottom={'1px solid #f3f3fe'} p={'30px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Grid>
                                <Typography variant='h5' color={'#596066'} fontWeight={700} lineHeight={'1.618em'}>رمزگذاری داده های فرم</Typography>
                                <Typography variant='h6' color={'#596066'} fontWeight={500} mb={'10px'}>پاسخ های فرم خود را برای ذخیره ایمن داده های حساس رمزگذاری کنید.</Typography>
                            </Grid>
                            <Grid>
                                <label htmlFor="small-radius-switch">
                                    <Switch
                                        checked={checked}
                                        onChange={handleChange}
                                        handleDiameter={28}
                                        offColor="#f98e03"
                                        onColor="#ddd"
                                        // offHandleColor="#0ff"
                                        // onHandleColor="#08f"
                                        height={40}
                                        width={70}
                                        borderRadius={6}
                                        // activeBoxShadow="0px 0px 1px 2px #fffc35"
                                        uncheckedIcon={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    fontSize: 15,
                                                    color: "white",
                                                    paddingRight: 2,
                                                    paddingTop: '4px'

                                                }}
                                            >
                                                Yes
                                            </div>
                                        }
                                        checkedIcon={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    fontSize: 15,
                                                    color: "white",
                                                    paddingRight: 2,
                                                    paddingTop: '4px'

                                                }}
                                            >
                                                No
                                            </div>
                                        }
                                        uncheckedHandleIcon={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    fontSize: 20,
                                                    backgroundColor: '#fff',
                                                    borderRadius: '4px'

                                                }}
                                            >
                                                <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                            </div>
                                        }
                                        checkedHandleIcon={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    fontSize: 20,
                                                    backgroundColor: '#fff',
                                                    borderRadius: '4px'
                                                }}
                                            >
                                                <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                                            </div>
                                        }
                                        className="react-switch"
                                        id="small-radius-switch"
                                    />
                                </label>
                            </Grid>
                        </Grid>
                        <Grid className='MoreOptions' display={showOption ? 'block' : 'none'}>
                            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} className='warning' p={'20px'} bgcolor={'white'} borderBottom={'1px solid #f3f3fe'}>
                                <Grid>
                                    <Typography variant='h5' color={'#596066'} fontWeight={700}>هشدارهای فرم</Typography>
                                    <Typography variant='h6' color={'#596066'} fontWeight={500}>پیام های هشدار را در فرم خود تغییر دهید.</Typography>
                                </Grid>
                                <Link to={'/Setting/general/FormWarnings'}>
                                    <Grid p={'11px 16px'} bgcolor={'#9b8a73'} borderRadius={'4px'} width={'fit-content'}>
                                        <Typography variant='h6' color={'white'} fontWeight={500}>ویرایش</Typography>
                                    </Grid>
                                </Link>
                            </Grid>
                            <Grid className='Password Protection' p={'20px'} bgcolor={'white'} borderBottom={'1px solid #f3f3fe'}>
                                <Typography variant='h5' color={'#596066'} fontWeight={700} mb={'10px'}>حفاظت از رمز عبور</Typography>
                                <Typography variant='h6' color={'#596066'} fontWeight={500} mb={'10px'}>یک رمز عبور برای محدود کردن دسترسی به فرم خود تنظیم کنید</Typography>
                                <Grid mb={check ? '10px' : '0px'} display={'flex'} alignItems={'center'} gap={'4px'}>
                                    <Checkbox
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 24 }, width: '24px', height: '24px', borderColor: 'lightgrey', borderRadius: '4px' }}
                                        checked={check}
                                        onChange={handleChangeCheckBox}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                    />
                                    <Typography pt={'2px'} variant='h6' color={'#596066'} fontWeight={500}>محافظت از رمز عبور را فعال کنید </Typography>
                                </Grid>
                                <Grid position={'relative'} display={check ? 'block' : 'none'}>
                                    <input
                                        value={protection}
                                        onChange={handleChangeProtection}
                                        type={show ? 'text' : 'password'}
                                        name={'protection'}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            border: '1px solid #c5c5c5',
                                            borderRadius: '4px',
                                            padding: '6px 12px',
                                            boxShadow: '0 2px 4px rgba(86,70,50,.1)',
                                            color: '#564632',
                                            fontSize: '14px',
                                            backgroundColor: 'white',
                                            outline: 0,
                                            direction: 'ltr'
                                        }}
                                    />
                                    <Grid onClick={() => { setShow(!show) }} position={'absolute'} top={'50%'} left={'2%'} style={{ translate: '-30% -35%' }}>
                                        <RemoveRedEyeIcon fontSize='large' style={{ color: '#596066' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                {
                                    optionData.map(
                                        item =>
                                            <Grid key={item.id}>
                                                <FormSettingOption
                                                    header={item.title}
                                                    subTitle={item.subTitle}
                                                    options={item.optionValue}
                                                    id={item.active}
                                                    flag={item.id === 4 ? false : true}
                                                    linkFlag={item.linkFlag}
                                                    check={item.id === 3 ? true : false}
                                                    name={item.name}
                                                    onSelectChange={handleSelectChange}
                                                    selectValue={selectValues}
                                                    accessibilityFlag={item.id === 3 ? true : false}
                                                />
                                            </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className='buttons' display={'flex'} flexDirection={!showOption ? 'row' : 'row-reverse'} alignItems={'center'} justifyContent={'space-between'}>
                        <Grid mb={showOption ? '36px' : '0px'} width={'fit-content'} p={'11px 16px'} bgcolor={'#9b8a73'} borderRadius={'4px'} onClick={() => { setShowOption(!showOption) }} style={{ cursor: 'pointer' }}>
                            {
                                !showOption ?
                                    <Typography variant='h6' color={'white'} fontWeight={500}>نمایش گزینه های بیشتر</Typography>
                                    :
                                    <Typography variant='h6' color={'white'} fontWeight={500}>نمایش کمتر</Typography>
                            }

                        </Grid>
                        <Grid display={showOption ? 'block' : 'none'} onClick={handleSave} mb={showOption ? '36px' : '0px'} width={'fit-content'} p={'11px 16px'} bgcolor={'rgba(100,178,0,1)'} borderRadius={'4px'} style={{ cursor: 'pointer' }}>
                            <Typography variant='h6' color={'white'} fontWeight={500}>ذخیره</Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormSetting