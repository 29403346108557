import React, {useState} from 'react'
import axios from "axios";
import {Grid} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import '../App.css'

const ReCaptcha = () => {

    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

    // const handleCaptchaChange = (value) => {
    //     // Called when the user submits a successful CAPTCHA response
    //     console.log('Captcha value:', value);
    //
    //     // Assuming you have a state variable to track the form data
    //     const formData = {
    //         // ... your form data
    //     };
    //
    //     // Perform additional actions, e.g., make an API call
    //     // Here, you might want to send the captcha response to your server for verification
    //
    //     // Using Axios to submit data to your server
    //     axios.post('API_ENDPOINT', {
    //         ...formData,
    //         captchaResponse: value, // Include the captcha response in your server request
    //     })
    //         .then((response) => {
    //             // Handle the server response
    //             console.log('Server response:', response.data);
    //
    //             // Update state or perform other actions based on the server response
    //             // For example, if the server validates the captcha, enable the submit button
    //             if (response.data.success) {
    //                 setIsCaptchaVerified(true);
    //             } else {
    //                 // Handle case where captcha verification on the server failed
    //                 // You might want to reset the captcha state or show an error message
    //                 setIsCaptchaVerified(false);
    //                 // Additional error handling logic can go here
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error submitting captcha:', error);
    //             // Handle error, reset state, or show an error message
    //         });
    // };


    const handleCaptchaChange = (value) => {
        // Called when the user submits a successful CAPTCHA response
        console.log('Captcha value:', value);
        setIsCaptchaVerified(true);
    };
    return (
        <Grid width={'100%'}>
            <form style={{ width: '90%' }}>
                <div className="captcha-container" style={{width:'60px'}}>
                    <ReCAPTCHA
                        sitekey="YOUR_RECAPTCHA_SITE_KEY"
                        onChange={handleCaptchaChange}
                        className='g-recaptcha'
                    />
                </div>
            </form>
        </Grid>
    )
}
export default ReCaptcha
