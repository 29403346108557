import React from 'react'
import { Grid, Typography } from '@mui/material';
import Navbar from '../Component/Navbar';
import HeaderSetting from '../Component/HeaderSetting';
import SettingDrawer from '../Component/SettingDrawer';
import { ArrowCondition, ConditionPage, PreviewCalculate, PreviewCondition, PreviewConfirm, PreviewEnable, PreviewMessage, PreviewSkip } from '../Component/Icons';
import '../App.css'
import { Link } from 'react-router-dom';
const Condition = () => {


    const conditionData = [
        { id: 1, title: 'نمایش/پنهان کردن فیلد', subTilte: 'قابلیت مشاهده فیلدهای فرم خاص را تغییر دهید', color: '#09f', icon: <PreviewCondition fill={'white'} />, link: '/Setting/Conditional/ShowAndHide' },
        { id: 2, title: 'به روز رسانی/محاسبه فیلد', subTilte: 'مقدار یک فیلد را کپی کنید یا محاسبات پیچیده را انجام دهید', color: '#09f', icon: <PreviewCalculate fill={'white'} />, link: '/Setting/Conditional/UpdateAndCalculate' },
        { id: 3, title: 'فعال/ضروری/پنهان‌سازی فیلد', subTilte: 'یک فیلد را الزام یا غیرفعال کنید یا یک ماسک تنظیم کنید', color: '#09f', icon: <PreviewEnable fill={'white'} />, link: '/Setting/Conditional/EnableAndRequire' },
        { id: 4, title: 'رفتن به/مخفی کردن صفحه', subTilte: 'به صفحه خاصی پرش کنید یا آن را پنهان کنید', color: '#049e38', icon: <PreviewSkip fill={'white'} />, link: '/Setting/Conditional/Skip' },
        { id: 5, title: 'تغییر صفحه تشکر', subTilte: 'سفارشی‌سازی عملکرد صفحه تشکر شما', color: '#049e38', icon: <PreviewConfirm fill={'white'} />, link: '/Setting/Conditional/ThankYou' },
        { id: 6, title: 'گیرنده ایمیل را تغییر دهید', subTilte: 'برای افراد خاصی ایمیل بفرستید', color: '#9c4dd3', icon: <PreviewMessage fill={'white'} />, link: '/Setting/Conditional/EmailRecipient' },
    ]


    return (
        <Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'شرایط جدید'} subTitle={'در صورت برآورده شدن یک شرط، به طور خودکار یک عمل را راه اندازی کنید'} link={'/Setting'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={3} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={3} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <Grid mb={'24px'} className='NewCondition' display={{ xs: 'none', xxs: 'flex' }} alignItems={'center'} gap={'16px'}>
                        <Grid width={'40px'} height={'40px'} bgcolor={'rgb(10, 21, 81)'} borderRadius={'4px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <ConditionPage />
                        </Grid>
                        <Grid>
                            <Typography variant='h5' fontWeight={700} color={'rgb(10, 21, 81)'} lineHeight={'24px'} >شرایط جدید</Typography>
                            <Typography variant='h6' fontWeight={500} color={'rgb(111, 118, 167)'}>در صورت برآورده شدن یک شرط، به طور خودکار یک عمل را راه اندازی کنید</Typography>
                        </Grid>
                    </Grid>
                    <Grid className='OptionCondition' mb={'48px'}>
                        {
                            conditionData.map(
                                item =>
                                    <Link to={item.link} key={item.id}>
                                        <Grid className="item-hover" mb={'12px'}  p={'20px 16px'} borderRight={`7px solid ${item.color}`} display={'flex'} alignItems={'center'} justifyContent={'space-between'} borderRadius={'4px'} bgcolor={'white'} style={{ cursor: 'pointer' }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.backgroundColor = item.color;
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.backgroundColor = 'white';
                                            }}
                                        >
                                            <Grid display={'flex'} alignItems={'center'} gap={'16px'}>
                                                <Grid className={`iconCondition fill${item.id}`} width={'40px'} height={'40px'} borderRadius={'4px'} bgcolor={item.color} display={'flex'} alignItems={'center'} justifyContent={'center'} >
                                                    {item.icon}
                                                </Grid>
                                                <Grid>
                                                    <Typography className="title" color={'#0a1551'} variant='h5' fontWeight={700} lineHeight={'24px'}>{item.title}</Typography>
                                                    <Typography className="subtitle" variant='h6' color={'rgba(108,115,168,1)'} fontWeight={500} >{item.subTilte}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'row-reverse'} style={{ rotate: '180deg' }}>
                                                <ArrowCondition />
                                            </Grid>
                                        </Grid></Link>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Condition