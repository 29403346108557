import React, { useEffect, useRef, useState } from 'react'
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';

const Heading = ({ id }) => {

    const { updateComponentStatesById, drawerData, addComponent } = useFormContext();


    const location = useLocation();
    const currentPage = location.pathname;







    const [headingValue, setHeadingValue] = useState('عنوان');
    const [subHeadingValue, setSubHeadingValue] = useState('');
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const containerRef = useRef(null);


    const fillInputValue = () => {
        const existingComponentIndex = drawerData.findIndex(component => component.id === id);
        drawerData[existingComponentIndex].state.headingValue = headingValue
        drawerData[existingComponentIndex].state.subHeadingValue = subHeadingValue
    }





    const handleHeadingChange = (event) => {
        const newValue = event.target.value;
        setHeadingValue(newValue);
        // updateComponentStatesById(id, { headingValue: newValue, subHeadingValue });
    };

    const handleSubHeadingChange = (event) => {
        const newValue = event.target.value;
        setSubHeadingValue(newValue);
        // updateComponentStatesById(id, { headingValue, subHeadingValue: newValue });
    };


    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };

    // useEffect(() => {
    //     // Check if a component with the same ID already exists in drawerData
    //     const existingComponentIndex = drawerData.findIndex(component => component.id === id);
    //     if (existingComponentIndex !== -1) {
    //         // If it exists, update its state
    //         updateComponentStatesById(id, { headingValue, subHeadingValue });
    //     } else {
    //         // If it doesn't exist, add a new component with the current state
    //         addComponent({ id, headingValue, subHeadingValue });
    //     }
    // }, [id , headingValue , subHeadingValue]);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, headingValue, subHeadingValue]);



    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);



    let theme = useTheme()
    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let HeadingInputFontSize = isUpXxl ? '24px' : '18px'
    let SubHeadingInputFontSize = isUpXxl ? '14px' : '12px'
    let HeadingColor = theme.palette.blue[700]


    return (
        <Grid
            mb={currentPage === '/build'|| currentPage === '/Publish' ? '28px' : '50px'}
            p={currentPage === '/build'|| currentPage === '/Publish' ? '12px 10px' : '24px 36px'}
            borderRadius={'3px'}
            mx={currentPage === '/build'|| currentPage === '/Publish' ? '4px' : '0px'} className={'Heading'}
            borderBottom={isHeadingFocused ? 'none' : '1px solid #ededf1'} bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}>
            <Grid className={'HeadingInput'} pb={'14px'}>
                <input className="" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: HeadingInputFontSize,
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor
                }}
                    // readOnly={currentPage === '/Publish' ? false : true}
                    value={headingValue}
                    onChange={handleHeadingChange}
                    onClick={handleClickOutside}
                    onFocus={handleHeadingFocus}
                />
            </Grid>
            <Grid borderBottom={'1px solid #ededf1'}>
                {isSubHeaderVisible && (
                    <Grid className={'subHeader'} pb={'14px'}>
                        <input
                            className=""
                            placeholder={'یک عنوان فرعی وارد کنید'}
                            style={{
                                border: 'none',
                                outline: 'none',
                                userSelect: 'text',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                width: '80%',
                                fontSize: SubHeadingInputFontSize,
                                fontWeight: 500,
                                lineHeight: 1.6,
                                color: '#57647e',
                            }}
                            value={subHeadingValue}
                            onChange={handleSubHeadingChange}
                        // readOnly={currentPage === '/Publish' ? false : true}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}
export default Heading