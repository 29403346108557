import React, { createContext, useContext } from 'react';
import useLocalStorage from 'use-local-storage';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [sendToServerData, setSendToServerData] = useLocalStorage('sendToServerData', []);

    const saveData = (data) => {
        setSendToServerData(prevData => [...prevData, ...data]);
    };

    return (
        <DataContext.Provider value={{ sendToServerData, saveData, setSendToServerData }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => useContext(DataContext);
