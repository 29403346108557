import React, { createContext, useState } from 'react';

// Create the context
export const FileUploadContext = createContext();

// Create the provider component
export const FileUploadProvider = ({ children }) => {
    const [uploadedFile, setUploadedFile] = useState(null);

    return (
        <FileUploadContext.Provider value={{ uploadedFile, setUploadedFile }}>
            {children}
        </FileUploadContext.Provider>
    );
};
