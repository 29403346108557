import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material';
import { SettingIcon, EmailSetting, Conditions, Thanks, Puzzle, Approval, Sign, Mobile } from './Icons';
import { Link, useNavigate } from 'react-router-dom';



const SettingDrawer = ({ isActive, mt, flag }) => {


  const darwer = [
    { id: 1, link: '/Settings/general', icon: <SettingIcon fill={isActive === 1 ? '#3e4652' : '#fff'} />, title: 'تنظیمات فرم', des: 'وضعیت و خصوصیات فرم را سفارشی کنید' },
    { id: 2, link: '/Setting/Email', icon: <EmailSetting fill={isActive === 2 ? '#3e4652' : '#fff'} />, title: 'ایمیل ها', des: 'ارسال خودکار و اعلان ها' },
    { id: 3, link: '/Setting/Conditional', icon: <Conditions fill={isActive === 3 ? '#3e4652' : '#fff'} />, title: 'شرایط', des: 'منطق شرطی را تنظیم کنید' },
    { id: 4, link: '/Setting/ThankYou', icon: <Thanks fill={isActive === 4 ? '#3e4652' : '#fff'} />, title: 'صفحه تشکر', des: 'نمایش صفحه پس از ارسال' },
    // { id: 5, link: '', icon: <Puzzle fill={isActive === 5 ? '#3e4652' : '#fff'} />, title: 'ادغام ها', des: 'فرم خود را به برنامه های دیگر متصل کنید' },
    { id: 6, link: '/Setting/Apprival', icon: <Approval fill={isActive === 6 ? '#3e4652' : '#fff'} />, title: 'جریان های تایید', des: 'یک جریان تایید ایجاد کنید' },
    { id: 7, link: '/Setting/Sign', icon: <Sign fill={isActive === 7 ? '#3e4652' : '#fff'} />, title: 'علامت Jotform', des: 'فرم های خود را با Jotform Sign قدرت دهید' },
    { id: 8, link: '/Setting/Notification', icon: <Mobile fill={isActive === 8 ? '#3e4652' : '#fff'} />, title: 'اعلان های موبایل', des: 'اعلان‌های برنامه تلفن همراه را سفارشی کنید' }
  ]




  return (
    <>
      <Grid position={'fixed'} right={0} top={{xs:'0px' , sm:'119px'}} display={flag ? 'none' : { xs: 'block', xxs: 'none', md: 'block' }} py={'20px'} pt={'40px'} pr={'16px'} pl={0} bgcolor={'#3e4652'} height={'100vh'} width={{ xs: '100%', sm: '318px' }} mt={mt}>
        {
          darwer.map(
            item =>
              <Link to={item.link} key={item.id}>
                <Grid style={{ cursor: 'pointer' }} borderRadius={'0px 4px 4px 0px'} bgcolor={isActive === item.id ? '#f1f1f4' : 'transparent'} display={'flex'} alignItems={'center'} gap={'8px'} py={'16px'} borderBottom={item.id === 8 ? 'none' : '1px solid #31373f'} px={'16px'}>
                  <Grid>
                    {item.icon}
                  </Grid>
                  <Grid>
                    <Typography variant='h5' color={item.id === isActive ? '#3e4652' : '#fff'}>{item.title}</Typography>
                    <Typography variant='subtitle1' color={item.id === isActive ? '#3e4652' : '#fff'}>{item.des}</Typography>
                  </Grid>
                </Grid>
              </Link>
          )
        }
      </Grid>
      <Grid position={'fixed'} right={0} top={'119px'} display={flag ? 'block' : { xs: 'none', xxs: 'block', md: 'none' }} py={'20px'} bgcolor={'#3e4652'} width={'144px'} mt={mt} height={'100vh'}>
        {
          darwer.map(
            item =>
              <Link to={item.link} key={item.id}>
                <Grid style={{ cursor: 'pointer' }} bgcolor={isActive === item.id ? '#f1f1f4' : 'transparent'} display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'8px'} py={'16px'} borderBottom={item.id === 8 ? 'none' : '1px solid #31373f'} px={'16px'}>
                  <Grid width={'25px'} height={'25px'}>
                    {item.icon}
                  </Grid>
                  <Grid>
                    <Typography variant='body1' color={item.id === isActive ? '#3e4652' : '#fff'}>{item.title}</Typography>
                  </Grid>
                </Grid>
              </Link>
          )
        }
      </Grid>
    </>

  )
}

export default SettingDrawer