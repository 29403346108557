import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormSettingSelect from './FormSettingSelect'
import { Link } from 'react-router-dom'
import { useTitleContext } from '../Context/TitleContext'

const FormSettingOption = ({ header, subTitle, options, id, flag, linkFlag, check, name, onSelectChange, selectValue , accessibilityFlag }) => {


    const { title, setTitle } = useTitleContext();


    const [value, setValue] = useState(title)


    const handleChangeTilte = (event) => {
        setValue(event.target.value)
    }


    const handleBlurTitle = () => {
        setTitle(value);
    };


    useEffect(() => {
        setValue(title)
    }, [title]);


    const index = selectValue.findIndex(item => item.name === "Save and Continue Later");
    const accessibilityIndex = selectValue.findIndex(item => item.name === "Form Accessibility");


    return (
        <Grid className='FormSettingOption' p={name === 'advanceCustomizeSaveEmail' ? '0px' : '20px'} bgcolor={'white'} borderBottom={'1px solid #f3f3fe'}>
            <Typography variant='h5' color={'#596066'} fontWeight={700}>{header}</Typography>
            <Typography variant='h6' color={'#596066'} fontWeight={500} mb={'10px'}>{subTitle}</Typography>
            {
                flag ?
                    <Grid>
                        <FormSettingSelect options={options} id={id} onSelectChange={onSelectChange} name={name} />
                        <Grid className='link' mt={'10px'} display={linkFlag ? 'block' : 'none'}>
                            {selectValue.length === 0 || index === -1 ? (
                                <Typography className='default' variant='h6' fontWeight={700} color={'#09f'} style={{ opacity: 0.7, cursor: 'not-allowed' }}>شخصی‌سازی ایمیل ذخیره</Typography>
                            ) : (
                                <Link to={'/Setting/general/CustomizeSaveEmail'} style={{ cursor: 'pointer' }}>
                                    <Typography key={index} variant='h6' fontWeight={700} color={'#09f'} style={{ opacity: selectValue[index].value === "فعال" ? 1 : 0.7, cursor: selectValue[index].value === "فعال" ? 'pointer' : 'not-allowed' }}>شخصی‌سازی ایمیل ذخیره</Typography>
                                </Link>
                            )}
                        </Grid>
                        <Grid className='ShowAccessibility' mt={'10px'} display={accessibilityFlag ? 'block' : 'none'}>
                            <Typography mb={'10px'} fontWeight={600} variant='h6' color={'rgb(96, 169, 75)'} style={{opacity:accessibilityFlag && selectValue[accessibilityIndex].value === 'فعال'?1:0.7 , cursor: accessibilityFlag && selectValue[accessibilityIndex].value === 'فعال' ? 'pointer'  :'not-allowed'}}>بدون مشکل دسترسی</Typography>
                            <Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <input
                        value={value}
                        onChange={handleChangeTilte}
                        onBlur={handleBlurTitle}
                        style={{
                            border: '1px solid #c5c5c5',
                            borderRadius: '4px',
                            boxShadow: '0 2px 8px rgba(86,70,50,.15)',
                            padding: '6px 12px',
                            width: '100%',
                            color: '#564632',
                            lineHeight: '24px',
                            fontSize: '14px',
                            backgroundColor: '#fff',
                            outline: 0
                        }}
                    />
            }

        </Grid>
    )
}

export default FormSettingOption