import React from 'react'
import { Grid, Typography } from '@mui/material';
import { Sign } from '../Component/Icons';
import Navbar from '../Component/Navbar';
import SettingDrawer from '../Component/SettingDrawer';
import { Link } from 'react-router-dom';
import HeaderSetting from '../Component/HeaderSetting';



const JotFormSign = () => {


    const rule = [
        { id: 1, title: 'به طور خودکار موارد ارسالی امضا شده را به اسناد PDF تبدیل کنید' },
        { id: 2, title: 'برای هر سند امضا شده یک مسیر حسابرسی دریافت کنید' },
        { id: 3, title: 'غیرفعال کردن ویرایش ارسال' },
        { id: 4, title: 'از کاربران فرم بخواهید که برای امضای خود رضایت دهند' }
    ]

    return (
        <>
            <Grid>
                <Grid display={{xs:'none' , xxs:'block'}}>
                    <Navbar active={2} state={true} />
                </Grid>
                <Grid display={{ xs: 'block', xxs: 'none' }} >
                    <HeaderSetting title={'علامت Altyn'} subTitle={'فرم های خود را با ویژگی های Sign تقویت کنید'} />
                </Grid>
                <Grid mt={'48px'} position={'relative'} display={'flex'} alignItems={'flex-start'}>
                    <Grid display={{ xs: 'none', xxs: 'block' }}>
                        <SettingDrawer isActive={7} />
                    </Grid>
                    <Grid className='main' pl={'20px'} pr={'60px'} mt={{xs:'0' , xxs:'150px'}} position={'absolute'} left={'20px'} width={{ xs: '100%', xxs: '65%', sm: '80%', md: '65%' }}>
                        <Grid className='signIcon' display={'flex'} alignItems={'center'} gap={'12px'} mb={'16px'}>
                            <Grid bgcolor={'rgb(88, 185, 102)'} width={'40px'} height={'40px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'4px'}>
                                <Sign fill={'white'} width={'25px'} height={'25px'} />
                            </Grid>
                            <Grid>
                                <Typography variant='h5' color={'h5'} fontWeight={500}>علامت JOTFORM</Typography>
                                <Typography variant='h6' color={'h6'} fontWeight={500}>فرم های خود را با ویژگی های Jotform Sign تقویت کنید</Typography>
                            </Grid>
                        </Grid>
                        <Grid bgcolor={'white'} borderRadius={'4px'} boxShadow={'0 0 9px rgba(0,0,0,.1)'} p={'16px 24px'}>
                            <Grid mb={'16px'}>
                                <Typography variant='h5' color={'#0a1551'} fontWeight={700}>Jotform Sign Automation را فعال کنید</Typography>
                                <Typography variant='h6' color={'#6f76a7'}>فرم های خود را با ویژگی های Jotform Sign تقویت کنید</Typography>
                            </Grid>
                            <ul style={{ marginBottom: '16px' }}>
                                {
                                    rule.map(
                                        item =>
                                            <li style={{ fontSize: '14px', color: '#6f76a7' }}>{item.title}</li>
                                    )
                                }
                            </ul>
                            <Link to={'/'}>
                                <Grid style={{ cursor: 'pointer' }} p={'16px'} width={'100%'} border={'2px dashed #8bacff'} borderRadius={'4px'} bgcolor={'#e1ecff'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <Typography color={'#09f'} variant='h6'>+ فیلد امضا را اضافه کنید</Typography>
                                </Grid>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>

    )
}

export default JotFormSign