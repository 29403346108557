import React from 'react'
import axios from "axios";
// import HCaptcha from "react-hcaptcha";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Grid } from "@mui/material";


const HiCaptcha = () => {




    const handleVerification = (token, ekey) => {

        // Assuming you have a server endpoint for hCaptcha verification
        const verificationEndpoint = 'https://server/verify-hcaptcha';

        // Make a POST request to your server with the hCaptcha token using Axios
        axios.post(verificationEndpoint, { token })
            .then(response => {
                // Handle the response from your server
                const data = response.data;
                if (data.success) {
                    console.log('hCaptcha verification successful');
                    // Continue with your form submission or any other action
                } else {
                    console.error('hCaptcha verification failed');
                    // Optionally, you can handle failure silently without showing any messages
                }
            })
            .catch(error => {
                console.error('Error verifying hCaptcha:', error);
                // Optionally, you can handle unexpected errors silently without showing any messages
            });
    };


    // const handleVerification = (token) => {
    //     console.log('hCaptcha token:', token);
    //     // You can send the token to your server for verification
    // };
    return (
        <>
            <Grid display={{ xs: 'flex', xxs: 'none' }} alignItems={'center'} mr={'10%'}>
                <HCaptcha
                    size='compact'
                    sitekey="your-sitekey"
                    onVerify={(token, ekey) => handleVerification(token, ekey)}
                />
            </Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <HCaptcha
                    size='normal'
                    sitekey="your-sitekey"
                    onVerify={(token, ekey) => handleVerification(token, ekey)}
                />
            </Grid>
        </>

    )
}
export default HiCaptcha
