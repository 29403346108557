import React, { useEffect, useRef, useState } from 'react'
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';

const Section = ({ id }) => {


    const { drawerData } = useFormContext();


    const location = useLocation();
    const currentPage = location.pathname;




    const existingComponentIndex = drawerData.findIndex(component => component.id === id);

    const [sectionValue, setSectionValue] = useState('برای ویرایش این متن کلیک کنید...');
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const containerRef = useRef(null);


    const fillInputValue = () => {
        drawerData[existingComponentIndex].state.sectionValue = sectionValue
    }



    const handleSectionChange = (event) => {
        setSectionValue(event.target.value);
    };



    // Add a global click event listener to handle clicks outside the first grid


    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };


    const handleHeadingBlur = () => {
        setHeadingFocused(false); // Set focus state to false on blur
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, sectionValue]);



    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    let theme = useTheme()
    let HeadingColor = theme.palette.blue[700]

    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '24px 36px'} borderRadius={'3px'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'rgba(195,202,216,.3)'} ref={containerRef}
            border={`2px solid ${isHeadingFocused ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}>
            <Grid className={'HeadingInput'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <input className="customHead-input" placeholder={'متن دکمه را تایپ کنید'} style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    value={sectionValue}
                    onChange={handleSectionChange}
                    onClick={handleClickOutside}
                    onFocus={handleHeadingFocus}
                />
                <Grid bgcolor={'#b6bcc8'} width={'28px'} height={'28px'} borderRadius={'50%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <ArrowDropDownIcon fontSize={'large'} style={{ color: 'white' }} />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Section
