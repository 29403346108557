import React from 'react'
import Sicon from '../ThankYouMessage/Sicon';
import MediumIcon from '../ThankYouMessage/MediumIcon';
import CenterIcon from '../ThankYouMessage/CenterIcon';
import SmallIcon from '../ThankYouMessage/SmallIcon';
import BigIcon from '../ThankYouMessage/BigIcon';
import NoneIcon from '../ThankYouMessage/NoneIcon';

const InitialValue = ({ src, id }) => {

    switch (id) {
        case 0:
            return <Sicon src={src} dir={'column'} columnFlag={true} radius={false} />;
        case 1:
            return <Sicon src={src} dir={'column-reverse'} columnFlag={true} radius={false} />;
        case 2:
            return <Sicon src={src} dir={'row'} columnFlag={false} radius={false} />;
        case 3:
            return <Sicon src={src} dir={'row-reverse'} columnFlag={false} radius={false} />;
        case 4:
            return <Sicon src={src} dir={'column'} columnFlag={true} radius={true} />;
        case 5:
            return <Sicon src={src} dir={'column-reverse'} columnFlag={true} radius={true} />;
        case 6:
            return <Sicon src={src} dir={'row'} columnFlag={false} radius={true} />;
        case 7:
            return <Sicon src={src} dir={'row-reverse'} columnFlag={false} radius={true} />;
        case 8:
            return <MediumIcon src={src} dir={'column'} rowFlag={false} />;
        case 9:
            return <MediumIcon src={src} dir={'column-reverse'} rowFlag={false} />;
        case 10:
            return <MediumIcon src={src} dir={'row-reverse'} rowFlag={true} />;
        case 11:
            return <MediumIcon src={src} dir={'row'} rowFlag={true} />;
        case 12:
            return <CenterIcon width={'50%'} />;
        case 13:
            return <CenterIcon width={'25%'} />;
        case 14:
            return <CenterIcon width={'100%'} />;
        case 15:
            return <SmallIcon dir={'row-reverse'} src={src} rowFlag={true} />;
        case 16:
            return <SmallIcon dir={'row'} src={src} rowFlag={true} />;
        case 17:
            return <SmallIcon dir={'column-reverse'} src={src} rowFlag={false} />;
        case 18:
            return <SmallIcon dir={'column'} src={src} rowFlag={false} />;
        case 19:
            return <BigIcon dir={'column-reverse'} src={src} rowFlag={false} />;
        case 20:
            return <BigIcon dir={'column'} src={src} rowFlag={false} />;
        case 21:
            return <BigIcon dir={'row'} src={src} rowFlag={true} />;
        case 22:
            return <BigIcon dir={'row-reverse'} src={src} rowFlag={true} />;
        case 23:
            return <NoneIcon />;
        default:
            return <Sicon src={'/assets/images/Thankyou1_4.png'} dir={'column'} columnFlag={true} radius={false} />;
    }
}

export default InitialValue