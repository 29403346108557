import { Grid } from '@mui/material'
import React, { useContext, useState } from 'react'
import Navbar from '../Component/Navbar'
import HeaderSetting from '../Component/HeaderSetting'
import SettingDrawer from '../Component/SettingDrawer'
import LayoutCondition from '../Component/LayoutCondition'
import { AutoEmail } from '../Component/Icons'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import FormSettingSelect from '../Component/FormSettingSelect'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify';
import { SenderNotificationEmailContext } from '../Context/SenderNotificationEmailContext'

const AutoresponderEmailSender = () => {


    const navigate = useNavigate()


    const { selectValues, setSelectValues, notificationEmails, setNotificationEmails } = useContext(SenderNotificationEmailContext);



    const receiverOption = [
        { value: 'parsa@gmail.com', label: 'parsa@gmail.com ' },
        { value: 'saman@gmail.com', label: 'saman@gmail.com' },
    ]



    const CustomToast = ({ closeToast, message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'red',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <ErrorIcon fontSize={'large'} />
                    {message}
                </Grid>

        );
    };




    const [sender, setSender] = useState('')

    const [reply, setReply] = useState('')

    const [errorEmail, setErorrEmail] = useState('')


    const handleSelectChange = (name, value) => {
        // Check if a select with the given name already exists in the array
        const existingIndex = selectValues.findIndex((select) => select.name === name);

        if (existingIndex !== -1) {
            // If a select with the given name exists, update its value
            const updatedSelects = [...selectValues];
            updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value };
            setSelectValues(updatedSelects);
        } else {
            // If a select with the given name doesn't exist, add it to the array
            setSelectValues((prevSelectValues) => [...prevSelectValues, { name, value }]);
        }
    };


    const handleSenderChange = (e) => {
        setSender(e.target.value)
    }

    const handleReplyChange = (e) => {
        const { value, type } = e.target
        setReply(value)

        if (type === 'email') {
            // Check if the entered value is a valid email address
            const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
            if (!emailPattern.test(value)) {
                setErorrEmail('آدرس ایمیل نامعتبر است');
            } else {
                // Reset error message if the email is valid
                setErorrEmail('');
            }
        }
    }


    const handleReset = () => {
        setReply('')
        setSender('')
        setErorrEmail('')
        setSelectValues([
            { id: 1, name: 'SenderName', value: '' },
            { id: 2, name: 'ReplyEmial', value: '' },
            { id: 3, name: 'recipient', value: null },
        ]);
    }

    const handleSaveClick = () => {


        // const hasRecipientInputEmpty = selectValues.some(item => item.value === '');


        // if (hasRecipientInputEmpty) {
        //     toast(<CustomToast message={'لطفا فرم ها را با دقت پر کنید'} state={'erorr'} />)
        //     return
        // }

        if (errorEmail !== '') {
            toast(<CustomToast message={errorEmail} state={'erorr'} />)
            return
        }


        setNotificationEmails(prevEmails => [...prevEmails, selectValues]);


        navigate("/Setting/Email")

        console.log(selectValues)


        axios.post('/your-server-endpoint', selectValues)
            .then(response => {
                console.log('Success:', response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });

            handleReset()
    };


    return (
        <Grid bgcolor={'#f3f3fe'}>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'تنظیمات ایمیل'} subTitle={'ارسال خودکار و اعلان ها'} link={'/Setting/Email'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={2} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={2} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'پاسخگوی خودکار'} subTitle={'برای شخصی که فرم شما را پر می کند ایمیل بفرستید'} link={'/Setting/Email'} icon={<AutoEmail fill={'white'} />} color={'rgba(127,202,0,1)'} />
                    <Grid>
                        <Grid className='main' bgcolor={'white'} mb={'14px'}>
                            <Box sx={{ width: '100%' }}>
                                <Box
                                    width={"100%"}
                                    height={"48px"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    bgcolor={"#fafafc"}
                                    borderBottom={"1px solid #eaebf2"}
                                >
                                    <Link
                                        to={"/Setting/EmailSetting/AutoresponderEmail"}
                                        style={{
                                            width: "50%",
                                            height: "48px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Grid
                                            width={"100%"}
                                            height={"48px"}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                        >
                                            <Typography variant="h5" fontWeight={500} color={"black"}>
                                                ایمیل
                                            </Typography>
                                        </Grid>
                                    </Link>
                                    <Link
                                        to={"/Setting/EmailSetting/AutoresponderEmail/Sender"}
                                        style={{
                                            width: "50%",
                                            height: "48px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Grid
                                            width={"100%"}
                                            height={"48px"}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            borderBottom={"3px solid #5e74e6"}
                                        >
                                            <Typography variant="h5" fontWeight={500} color={"#5e74e6"}>
                                                گیرندگان
                                            </Typography>
                                        </Grid>
                                    </Link>
                                    <Link
                                        to={"/Setting/EmailSetting/AutoresponderEmail/Advance"}
                                        style={{
                                            width: "50%",
                                            height: "48px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Grid
                                            width={"100%"}
                                            height={"48px"}
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"center"}

                                        >
                                            <Typography variant="h5" fontWeight={500} color={"black"}>
                                                پیشرفته
                                            </Typography>
                                        </Grid>
                                    </Link>
                                </Box>
                                <Box sx={{ width: '100%'}} p={{xs:'21px' , sm:'21px 42px'}}>
                                    <Grid className='Sender' mb={'22px'}>
                                        <Typography variant='h5' color={'#23283a'} fontWeight={600} pb={'4px'}>نام فرستنده</Typography>
                                        <input
                                            name='SenderName'
                                            value={sender}
                                            onChange={handleSenderChange}
                                            onBlur={() => { handleSelectChange('SenderName', sender) }}
                                            placeholder='Altyn'
                                            style={{
                                                width: '100%',
                                                border: '1px solid #d8dae9',
                                                backgroundColor: 'white',
                                                borderRadius: '4px',
                                                padding: '9px 12px',
                                                color: '#23283a',
                                                fontSize: '16px',
                                                outline: 'none'
                                            }}
                                        />
                                    </Grid>
                                    <Grid className='reply' mb={'22px'}>
                                        <Typography variant='h5' color={'#23283a'} fontWeight={600} pb={'4px'}>پاسخ به ایمیل</Typography>
                                        <input
                                            type='email'
                                            name='ReplyEmail'
                                            value={reply}
                                            onChange={handleReplyChange}
                                            onBlur={() => { handleSelectChange('ReplyEmail', reply) }}
                                            placeholder='noreply@jotform.com'
                                            style={{
                                                textAlign: 'left',
                                                width: '100%',
                                                border: '1px solid #d8dae9',
                                                backgroundColor: 'white',
                                                borderRadius: '4px',
                                                padding: '9px 12px',
                                                color: '#23283a',
                                                fontSize: '16px',
                                                outline: 'none'
                                            }}
                                        />
                                        {
                                            errorEmail !== '' && <Typography variant='h6' color={'black'} fontWeight={700}></Typography>
                                        }
                                    </Grid>
                                    <Grid className='recipient'>
                                        <Grid display={'flex'} alignItems={'center'} gap={'2px'} mb={'4px'}>
                                            <Typography variant='h5' color={'#23283a'} fontWeight={600}>ایمیل گیرنده</Typography>
                                            <Typography color={'#d53049'} fontWeight={600} variant='h5'>*</Typography>
                                        </Grid>
                                        <FormSettingSelect id={0} onSelectChange={handleSelectChange} options={receiverOption} name={'SenderName'} />
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid>
                            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'8px'}>
                                <Grid onClick={handleSaveClick} className='save' p={'12px 16px'} bgcolor={'rgba(100,178,0,1)'} fontSize={'14px'} color={'white'} borderRadius={'4px'}>ذخیره</Grid>
                                <Grid className='text' p={'12px 16px'} bgcolor={'rgba(218,222,243,1)'} fontSize={'14px'} color={'rgba(52,60,106,1)'} borderRadius={'4px'}>تست ایمیل</Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default AutoresponderEmailSender