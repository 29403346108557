import React, { createContext, useContext, useEffect, useState } from 'react';
import useFormStore from '../zustand/useFormStore';

const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

export const FormProvider = ({ children }) => {
    
    const { drawerData, deletedIndexes, handleDrawerData, handleDelete, updateComponentStatesById, addComponent , updateDrawerData , setDrawerData } = useFormStore();


    return (
        <FormContext.Provider
            value={{
                drawerData: Array.isArray(drawerData) ? drawerData.filter(item => !deletedIndexes.includes(item.id)) : [],
                handleDrawerData,
                handleDelete,
                updateComponentStatesById,
                addComponent,
                updateDrawerData,
                setDrawerData
            }}
        >
            {children}
        </FormContext.Provider>
    );
};
