import React from 'react'
import { FormProvider } from '../Context/Context'
import { Grid } from '@mui/material';
import PublishFormItem from '../Component/PublishFormItem';

const Publish = () => {



    return (
        <FormProvider>
            <Grid mt={'170px'}>
                <PublishFormItem active={3}/>
            </Grid>
        </FormProvider>
    )
}

export default Publish