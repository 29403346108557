import React, { createContext, useState, useContext, useEffect } from 'react';
import { Address, Appointment, Captcha, DatePicker, Divider, DropDown, Email, FileUpload, FillInTheBlank, FullName, Heading, Image, LongText, MultiChoice, Number, Paragraph, Phone, ProductList, ScaleRating, Section, ShortText, Signature, SingleChoice, Spinner, StarRating, Submit, Table, Time } from '../Component/Icons';

const ComponentTitleContext = createContext();

export const ComponentTitleProvider = ({ children }) => {
    const [titles, setTitles] = useState([]);
    const [triggerAddIcons, setTriggerAddIcons] = useState(false);

    const updateTitles = (newTitles) => {
        setTitles(newTitles);
        setTriggerAddIcons(true);
    };


    const BasicElementData = [
        { id: 1, icon: <Heading />, title: "عنوان", SendToParent: "Heading", state: { headingValue: "", subHeadingValue: "" } },
        { id: 2, icon: <FullName />, title: "نام و نام خانوادگی", SendToParent: "FullName", state: { nameValue: "", firstNameValue: "", lastNameValue: "", firstNameInput: "", lastNameInput: "" } },
        { id: 3, icon: <Email />, title: "ایمیل", SendToParent: "Email", state: { emailValue: "", emailInput: "", exEmailValue: "" } },
        { id: 4, icon: <Address /> , title: "آدرس", SendToParent: "Address", state: { addressValue: "", streetInput: "", streetValue: "", streetInputSecond: "", streetValueSecond: "", cityInput: "", city: "", provinceInput: "", province: "", postalInput: "", postal: "" } },
        { id: 5, icon: <Phone />, title: "تلفن", SendToParent: "Phone", state: { phoneValue: "", phoneInput: null, alertValue: "" } },
        { id: 6, icon: <DatePicker />, title: "تقویم", SendToParent: "DatePicker", state: { date: null, dateValue: "", subHeader: "" } },
        { id: 7, icon: <Appointment />, title: "وقت ملاقات", SendToParent: "Appointment", state: { appointmentValue: "", savedSelectTime: "", persianDateTime: "", interval: null } },
        { id: 8, icon: <Signature />, title: "امضا", SendToParent: "Signature", state: { signatureValue: "", signatureImage: null } },
        { id: 9, icon: <FillInTheBlank />, title: "جای خالی را پر کنید", SendToParent: "FillInTheBlank", state: { innerHtml: "" } },
        { id: 10, icon: <ProductList />, title: "لیست محصولات", SendToParent: "ProductList", state: { products: [], productValue: "", totalPrice: null, totalQuantity: null } }
    ];

    const SurveyElementData = [
        { id: 11, icon: <ShortText />, title: "متن کوتاه", SendToParent: "ShortText", state: { shortTextValue: "", shortTextInputValue: "", subShortTextValue: "" } },
        { id: 12, icon: <LongText />, title: "متن طولانی", SendToParent: "LongText", state: { longTextValue: "", textareaValue: "", subLongTextValue: "" } },
        { id: 13, icon: <Paragraph />, title: "پاراگراف", SendToParent: "Paragraph", state: { content: "" } },
        { id: 14, icon: <DropDown />, title: "بازشونده", SendToParent: "DropDown", state: { dropDownValue: "", subDropDownValue: "", option: "", textareaValue: "" } },
        { id: 15, icon: <SingleChoice />, title: "تک گزینه ای", SendToParent: "SingleChoice", state: { titleValue: "", singleData: [] } },
        { id: 16, icon: <MultiChoice />, title: "چند گزینه ای", SendToParent: "MultiChoice", state: { titleValue: "", multiData: [] } },
        { id: 17, icon: <Number />, title: "شماره", SendToParent: "Number", state: { numberValue: "", numberInputValue: null, subNumberValue: "" } },
        { id: 18, icon: <Image />, title: "عکس", SendToParent: "Image", state: { image: null } },
        { id: 19, icon: <FileUpload />, title: "آپلود فایل", SendToParent: "FileUpload", state: { selectedFile: null, fileInputValue: "", selectedFileName: null, fileValue: "", subFileValue: "" } },
        { id: 20, icon: <Time />, title: "زمان", SendToParent: "Time", state: { selectedOption: "", timeValue: "", subTimeValue: "", inputValue: "" } },
        { id: 21, icon: <Captcha />, title: "کپچا", SendToParent: "Captcha", state: { select: null } },
        { id: 22, icon: <Spinner />, title: "شمارنده", SendToParent: "Spinner", state: { spinnerValue: "", spinnerInputValue: null, subSpinnerValue: "" } },
        { id: 23, icon: <Submit />, title: "ارسال", SendToParent: "Submit" }
    ];

    const PageElementData = [
        { id: 24, icon: <Table />, title: "جدول ورودی", SendToParent: "Table", state: { tableValue: "", rowTitles: [], columnTitles: [], selectedColumnIndex: [], show: false } },
        { id: 25, icon: <StarRating />, title: "رتبه بندی ستاره ای", SendToParent: "StarRating", state: { starValue: "", subStarValue: "", rate: null } },
        { id: 26, icon: <ScaleRating />, title: "رتبه بندی مقیاس", SendToParent: "ScaleRating", state: { scaleValue: "", subScaleValue: "", bestValue: "", worthValue: "", active: null } }
    ];

    const DividerElementData = [
        { id: 27, icon: <Divider />, title: "جدا کننده", SendToParent: "Divider" },
        { id: 28, icon: <Section />, title: "بخش جدا کننده", SendToParent: "Section", state: { sectionValue: "" } }
    ];


    const allElementData = [
        ...BasicElementData,
        ...SurveyElementData,
        ...PageElementData,
        ...DividerElementData
    ];


    useEffect(() => {
        if (triggerAddIcons) {
            setTitles((prevTitles) =>
                prevTitles.map((title) => {
                    const element = allElementData.find((item) => item.SendToParent === title.title);
                    return element ? { ...title, icon: element.icon, peTitle: element.title } : title;
                })
            );
            setTriggerAddIcons(false);
        }
    }, [triggerAddIcons, titles]);


    // useEffect(() => {
    //     const storedData = localStorage.getItem('formDrawerData');
    //     if (storedData) {
    //         updateTitles(JSON.parse(storedData));
    //     }
    // }, []);

    return (
        <ComponentTitleContext.Provider value={{ titles, updateTitles }}>
            {children}
        </ComponentTitleContext.Provider>
    );
};

export const useComponentTitleContext = () => useContext(ComponentTitleContext);
