import React from 'react'
import { Grid, Typography } from '@mui/material';
import HeaderSetting from './HeaderSetting';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link, useLocation } from 'react-router-dom';

const LayoutCondition = ({ title, subTitle, link, icon, color }) => {


    const location = useLocation();


    return (

        <Grid position={'relative'} pb={'20px'} className='DesktopHeader' display={{ xs: 'none', xxs: 'flex' }} alignItems={'center'} gap={'8px'}>
            <Link to={link} style={{ display: location.pathname === '/Settings/general' ? 'none' : 'block' }}>
                <Grid pt={'5px'} position={'absolute'} right={{ xxs: '-6%', sm: '-6%', md: '-6%', llg: '-7%' }} top={'7%'} style={{ cursor: 'pointer' }}>
                    <ArrowForwardIosIcon fontSize='large' />
                </Grid>
            </Link>
            <>
                <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} width={'40px'} height={'40px'} borderRadius={'4px'} bgcolor={color}>
                    {icon}
                </Grid>
                <Grid>
                    <Typography variant='h5' color={'black'} fontWeight={700} lineHeight={'24px'}>{title}</Typography>
                    <Typography variant='h6' color={'rgb(111, 118, 167)'} fontWeight={500} lineHeight={'16px'}>{subTitle}</Typography>
                </Grid>
            </>
        </Grid>
    )
}

export default LayoutCondition