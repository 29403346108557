import { Grid } from '@mui/material';
import Cursor from 'quill/blots/cursor';
import React, { useEffect, useState } from 'react'
import Select from 'react-select';

const FormSettingSelect = ({ options, id, onSelectChange, name, border, placeholder, search, multi }) => {


    const [isClearable, setIsClearable] = useState(false);
    const [isSearchable, setIsSearchable] = useState(search);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);
    const [defaultValue, setDefaultValue] = useState(options[id]);

    const handleChange = (selectedOption) => {
        // Check if multi is true, then pass an array of values
        const value = multi ? selectedOption.map(option => option.value) : selectedOption.value;
        onSelectChange(name, value);
    };


    // Style for the select component
    const selectStyle = {
        control: base => ({
            ...base,
            border: `1px solid ${border ? '#d53049' : '#ced4da'}`,
            borderRadius: '4px',
            cursor:'pointer'
        }),
    };


    useEffect(() => {
        // Set the default value when options or id change
        setDefaultValue(options[id]);
    }, [options, id]);



    return (
        <Grid width={'100%'}>
            <Select
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                name={name}
                options={options}
                defaultValue={search ? 'لطفا جستجو یا انتخاب کنید ...' : defaultValue}
                onChange={handleChange}
                styles={selectStyle}
                placeholder={placeholder}
                isMulti={multi}
            />
        </Grid>
    )
}

export default FormSettingSelect