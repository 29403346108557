import { createContext, useContext } from 'react';
import useLocalStorage from 'use-local-storage';

const UnifiedDataContext = createContext();

export const useUnifiedData = () => useContext(UnifiedDataContext);

export const UnifiedDataProvider = ({ children }) => {

    const [unifiedData, setUnifiedData] = useLocalStorage('UnifiedData', []);

    return (
        <UnifiedDataContext.Provider value={{ unifiedData, setUnifiedData }}>
            {children}
        </UnifiedDataContext.Provider>
    );
};
