import { Grid } from '@mui/material'
import React from 'react'

const NoneIcon = () => {
    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '30px', backgroundColor: 'white' , padding:'175px' }}>
            <Grid style={{ width:'100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '36px', color: '#2C3345', fontWeight: 600, marginBottom: '16px' }}>متشکریم</div>
                <div style={{ fontSize: '14px', color: '#2C3345', fontWeight: 500, opacity: 0.6 }}>ارسالی شما دریافت شد</div>
            </Grid>
        </Grid>
    )
}

export default NoneIcon