import React from 'react'
import { Grid, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';

const HeaderSetting = ({ title, subTitle , link }) => {
    return (
        <Grid width={'100%'} bgcolor={'#3e4652'} display={'flex'} alignItems={'center'} mb={'60px'}>
            <Link to={link}>
                <Grid p={'12px'}>
                    <ArrowForwardIosIcon style={{ color: 'white' }} />
                </Grid>
            </Link>
            <Grid width={'1px'} height={'68px'} bgcolor={'white'}></Grid>
            <Grid p={'12px'}>
                <Typography variant='h4' color={'white'} fontWeight={700}>{title}</Typography>
                <Typography variant='h6' color={'rgba(255,255,255,.6)'}>{subTitle}</Typography>
            </Grid>
        </Grid>
    )
}

export default HeaderSetting