import { Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./selectWithSearch.css";
import SearchIcon from '@mui/icons-material/Search';
import { SelectedOptionContext } from "../../Context/SelectedOptionContext";

const SelectWithSearch = ({ options }) => {



    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const { selectedOption, setSelectedOption } = useContext(SelectedOptionContext);
    const dropdownRef = useRef(null);


    const handleFilterOption = () => {

        const titlesToRemove = ["Heading", "Image", "Submit", "Divider", "Section" , "Paragraph"];

        titlesToRemove.forEach(title => {
            const index = options.findIndex(item => item.title === title);
            if (index !== -1) {
                options.splice(index, 1);
            }
        });


    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setSearchTerm("");
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option.peTitle);
        setIsOpen(false);
        setSearchTerm("");
    };

    const normalizeText = (text) => {
        return text.normalize("NFKD").replace(/[\u064B-\u065F\u0610-\u061A]/g, "").toLowerCase();
    };

    const filteredOptions = options.filter((option) =>
        normalizeText(option.peTitle).includes(normalizeText(searchTerm))
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    useEffect(() => {

        handleFilterOption()

    }, [])


    return (
        <Grid ref={dropdownRef} className="select-with-search-container" width={{ xs: '100%', xxs: '70%' }}>
            <Grid className="custom-select" onClick={toggleDropdown} width={'100%'}>
                {selectedOption || "فیلد را انتخاب کنید"}
            </Grid>
            {isOpen && (
                <Grid className="dropdown-content">
                    <Grid className="search-container" borderBottom={'1px solid #ccc'}>
                        <SearchIcon className="search-icon" />
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="جستجو..."
                        />
                    </Grid>
                    {filteredOptions.map((option) => (
                        <Grid
                            display={'flex'}
                            alignItems={'center'}
                            gap={'4px'}
                            key={option.id}
                            onClick={() => handleOptionSelect(option)}
                            className="option"
                        >
                            <Grid pt={'5px'}>{option.icon}</Grid>
                            <Typography variant="h6" color={'#0a1551'}>{option.peTitle}</Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Grid>
    );
};

export default SelectWithSearch;
