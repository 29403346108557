import axios from 'axios';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { PayloadContext } from '../../Context/PayloadContext';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Hourglass } from 'react-loader-spinner';
import JoditEditor from 'jodit-react';
import { ThankYouConditionEditorContext } from '../../Context/ThankYouConditionEditorContext';
import { SelectValuesContext } from '../../Context/SelectValuesContext';
import ReactDOMServer from 'react-dom/server';
import InitialValue from '../ThankYouEditor/InitialValue';
import Sicon from '../ThankYouMessage/Sicon';
// import InitialValue from '../EmailContentEditor/InitialValue';


const ThankYouConditionEditor = () => {


    const { payload } = useContext(PayloadContext);

    const { setFinalPayload } = useContext(ThankYouConditionEditorContext)

    const { selectValues, setSelectValues } = useContext(SelectValuesContext);


    const editor = useRef(null);
    const [content, setContent] = useState(payload?.content);
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const currentPage = location.pathname;


    const config = useMemo(
        () => ({
            readonly: false, // all options from https://xdsoft.net/jodit/docs/,
            // placeholder: placeholder || 'Start typings...'
        }),
        []
    );


    const updateSelectValues = (newContent) => {
        console.log(newContent)
        const uniqueId = uuidv4();
        const newPayload = {
            id: uniqueId,
            name: "FinalThankyouContent",
            content: newContent
        };

        const updatedSelectValues = selectValues.map(item =>
            item.name === "FinalThankyouContent"
                ? { ...item, value: newPayload.content }
                : item
        );

        setSelectValues(updatedSelectValues);
        setFinalPayload(newPayload);
    };



    const handleContentChange = (newContent) => {
        setContent(newContent);
        updateSelectValues(newContent);
    };


    useEffect(() => {
        if (payload === null) {
            const initialContent = ReactDOMServer.renderToStaticMarkup(<Sicon src={'/assets/images/Thankyou1_4.png'} dir={'column'} columnFlag={true} radius={false} />);
            setContent(initialContent);
            updateSelectValues(initialContent);
        }
    }, [payload]);


    useEffect(() => {
        if (content) {
            setLoading(false);
        }
    }, [content]);


    useEffect(() => {
        if (editor.current && payload) {
            editor.current.value = payload.content;
        }
    }, [payload]);


    useEffect(() => {
        return () => {
            updateSelectValues(editor.current.value);
        };
    }, []);



    return (
        <Grid>
            {loading && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
                    <Hourglass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={['#306cce', '#72a1ed']}
                    />
                </Box>
            )}
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => handleContentChange(newContent)}
                onChange={(newContent) => handleContentChange(newContent)}
            />
        </Grid>
    )
}

export default ThankYouConditionEditor