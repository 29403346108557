import React, { useState } from 'react'
import FormSettingSelect from './FormSettingSelect';
import { SearchIcon } from '@mui/icons-material/Search';

const PassOption = ({ selectedOption , name , onSelectChange , placeholder , search , multi }) => {



    const fullNameStatus = [
        { value: 'خالی است', label: 'خالی است' },
        { value: 'پر شده است', label: 'پر شده است' },
    ]

    const emialStatus = [
        { value: 'برابر است با', label: 'برابر است با' },
        { value: 'برابر نیست', label: 'برابر نیست' },
        { value: 'شامل', label: 'شامل' },
        { value: 'شامل نمی شود', label: 'شامل نمی شود' },
        { value: 'شروع می شود با', label: 'شروع می شود با' },
        { value: 'شروع نمی شود با', label: 'شروع نمی شود با' },
        { value: 'به پایان می رسد با', label: 'به پایان می رسد با' },
        { value: 'به پایان نمی رسد با', label: 'به پایان نمی رسد با' },
        { value: 'خالی است', label: 'خالی است' },
        { value: 'پر شده است', label: 'پر شده است' },
    ]


    const dateStatus = [
        { value: 'خالی است', label: 'خالی است' },
        { value: 'پر شده است', label: 'پر شده است' },
        { value: 'قبل', label: 'قبل' },
        { value: 'بعد', label: 'بعد' },
        { value: 'برابر با تاریخ است', label: 'برابر با تاریخ است' },
        { value: 'برابر با تاریخ نیست', label: 'برابر با تاریخ نیست' },
        { value: 'برابر با روز است', label: 'برابر با روز است' },
        { value: 'برابر با روز نیست', label: 'برابر با روز نیست' },
    ]


    const appointmentStatus = [
        { value: 'برابر است با', label: 'برابر است با' },
        { value: 'برابر نیست', label: 'برابر نیست' },
        { value: 'خالی است', label: 'خالی است' },
        { value: 'پر شده است', label: 'پر شده است' },
        { value: 'قبل', label: 'قبل' },
        { value: 'بعد', label: 'بعد' },
        { value: 'برابر با تاریخ است', label: 'برابر با تاریخ است' },
        { value: 'برابر با تاریخ نیست', label: 'برابر با تاریخ نیست' },
        { value: 'برابر با روز است', label: 'برابر با روز است' },
        { value: 'برابر با روز نیست', label: 'برابر با روز نیست' },
    ]


    const fillintheBlankStatus = [
        { value: 'برابر است با', label: 'برابر است با' },
        { value: 'برابر نیست', label: 'برابر نیست' },
        { value: 'شامل', label: 'شامل' },
        { value: 'شامل نمی شود', label: 'شامل نمی شود' },
        { value: 'شروع می شود با', label: 'شروع می شود با' },
        { value: 'شروع نمی شود با', label: 'شروع نمی شود با' },
        { value: 'به پایان می رسد با', label: 'به پایان می رسد با' },
        { value: 'به پایان نمی رسد با', label: 'به پایان نمی رسد با' },
        { value: 'خالی است', label: 'خالی است' },
        { value: 'پر شده است', label: 'پر شده است' },
    ]


    const productListStatus = [
        { value: 'برابر است با', label: 'برابر است با' },
        { value: 'برابر نیست', label: 'برابر نیست' },
        { value: 'خالی است', label: 'خالی است' },
        { value: 'پر شده است', label: 'پر شده است' },
    ]


    const numberStatus = [
        { value: 'برابر است با', label: 'برابر است با' },
        { value: 'برابر نیست', label: 'برابر نیست' },
        { value: 'خالی است', label: 'خالی است' },
        { value: 'پر شده است', label: 'پر شده است' },
        { value: 'کمتر از', label: 'کمتر از' },
        { value: 'بزرگتر از', label: 'بزرگتر از' },
    ]


    const timeStatus = [
        { value: 'برابر است با', label: 'برابر است با' },
        { value: 'برابر نیست', label: 'برابر نیست' },
        { value: 'خالی است', label: 'خالی است' },
        { value: 'پر شده است', label: 'پر شده است' },
        { value: 'قبل', label: 'قبل' },
        { value: 'بعد', label: 'بعد' },
    ]


    const inputStatus = [
        { value: 'شامل', label: 'شامل' },
        { value: 'شامل نمی شود', label: 'شامل نمی شود' },
        { value: 'خالی است', label: 'خالی است' },
        { value: 'پر شده است', label: 'پر شده است' },
    ]





    const handlePassOption = () => {
        switch (selectedOption) {
            case 'نام و نام خانوادگی':
                return fullNameStatus;
            case 'ایمیل':
                return emialStatus;
            case 'آدرس':
                return emialStatus;
            case 'تلفن':
                return fullNameStatus;
            case 'تقویم':
                return dateStatus;
            case 'وقت ملاقات':
                return appointmentStatus;
            case 'امضا':
                return fullNameStatus;
            case 'جای خالی را پر کنید':
                return fillintheBlankStatus;
            case 'لیست محصولات':
                return productListStatus;
            case 'متن کوتاه':
                return fillintheBlankStatus;
            case 'متن طولانی':
                return fillintheBlankStatus;
            case 'بازشونده':
                return productListStatus;
            case 'تک گزینه ای':
                return productListStatus;
            case 'چند گزینه ای':
                return productListStatus;
            case 'شماره':
                return numberStatus;
            case 'آپلود فایل':
                return fullNameStatus;
            case 'زمان':
                return timeStatus;
            case 'شمارنده':
                return numberStatus;
            case "جدول ورودی":
                return inputStatus;
            case "رتبه بندی ستاره ای":
                return timeStatus;
            case "رتبه بندی مقیاس":
                return timeStatus;
            default:
                return []
        }
    }


    return (
        <FormSettingSelect options={handlePassOption()} id={0} name={name} onSelectChange={onSelectChange} placeholder={placeholder} multi={multi} search={search}/>
    )
}

export default PassOption