import { Box, Button, Checkbox, Grid, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import FolderIcon from '@mui/icons-material/Folder';
import StarIcon from '@mui/icons-material/Star';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import DraftsIcon from '@mui/icons-material/Drafts';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { DrawerContext } from '../Context/DrawerContext'
import RestoreIcon from '@mui/icons-material/Restore';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { useData } from '../Context/DataContext'
import { useDraft } from '../Context/DraftContext'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify'
import { animated, useTransition } from '@react-spring/web';
import EditIcon from '@mui/icons-material/Edit';
import { useFormContext } from '../Context/Context'
import { useSelectedItem } from '../Context/SelectedItemContext'
import { useEditMode } from '../Context/EditModeContext'
import { useTitleContext } from '../Context/TitleContext'
import axios from 'axios'
import { useUnifiedData } from '../Context/UnifiedDataContext'
import { useRemovedItems } from '../Context/RemovedItemsContext'
import { useArchivedItems } from '../Context/ArchivedItemsContext'
import { useFavoriteItems } from '../Context/FavoriteItemsContext'


const MainNavbar = () => {



    const { sendToServerData, setSendToServerData } = useData();
    const { selectDrawerItem, setSelectDrawerItem } = useContext(DrawerContext);
    const { draftData, saveDraft, setDraftData } = useDraft()
    const { drawerData, setDrawerData } = useFormContext();
    const { setSelectedItem, selectedItemId, setSelectedItemId } = useSelectedItem();
    const { setEditMode } = useEditMode();
    const { title, setTitle } = useTitleContext();
    const { unifiedData, setUnifiedData } = useUnifiedData();
    const { removedItems, setRemovedItems } = useRemovedItems();
    const { archiveItems, setArchiveItems } = useArchivedItems()
    const { favoriteItems, setFavoriteItems } = useFavoriteItems();



    console.log(draftData)




    const navigate = useNavigate()


    const [isOpen, setIsOpen] = useState(false)
    const [listData, setListData] = useState(sendToServerData);
    const [checked, setChecked] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [list, setList] = useState(sendToServerData);
    const [active, setActive] = useState(false);
    const [heading, setHeading] = useState('');

    const dropdownRef = useRef(null);

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }


    const CustomToast = ({ message, state }) => {
        return (
            <Grid display="flex" alignItems="start" justifyContent="end" gap="5px" style={{
                backgroundColor: 'white',
                color: state === 'success' ? 'green' : 'red',
                fontSize: '16px',
                padding: '15px 10px',
                fontWeight: 900,
            }}>
                {state === 'success' ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
                {message}
            </Grid>
        );
    };


    const transitions = useTransition(active, {
        from: { opacity: 0, transform: 'translateY(-20px)' },
        enter: { opacity: 1, transform: 'translateY(0)' },
        leave: { opacity: 0, transform: 'translateY(-20px)' },
    });


    const items = [
        { id: 1, enTitle: 'Favorite', title: 'موارد دلخواه', icon: <StarIcon fontSize='large' style={{ color: 'rgba(255,196,44,1)' }} /> },
        { id: 2, enTitle: 'Archive', title: 'بایگانی', icon: <ArchiveIcon fontSize='large' /> },
        { id: 3, enTitle: 'Trash', title: 'سطل زباله', icon: <DeleteIcon fontSize='large' /> },
    ]


    const sortItems = [
        { id: 1, title: 'عنوان [ الف تا ی ]' },
        { id: 2, title: 'عنوان [ ی تا الف ]' },
        { id: 3, title: 'جدیدترین ارسال' },
        { id: 4, title: 'قدیمی ترین ارسال' },
    ]

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


    const getCurrentListSetter = () => {

        if (selectDrawerItem === 'Trash') {
            return setRemovedItems;
        } else if (selectDrawerItem === 'Archive') {
            return setArchiveItems;
        } else if (selectDrawerItem === 'Drafts') {
            return setDraftData
        } else if (selectDrawerItem === 'Favorite') {
            return setFavoriteItems
        } else {
            return setListData;
        }

    };


    const getCurrentList = () => {

        if (selectDrawerItem === 'Trash') {
            return removedItems;
        } else if (selectDrawerItem === 'Archive') {
            return archiveItems;
        } else if (selectDrawerItem === 'Drafts') {
            return draftData;
        } else if (selectDrawerItem === 'Favorite') {
            return favoriteItems;
        } else {
            return listData;
        }

    };

    const handleChangeCheckBox = (id) => (event) => {

        const targetList = getCurrentList();
        const setTargetList = getCurrentListSetter();

        const updatedList = targetList.map(item =>
            item.id === id ? { ...item, checked: event.target.checked } : item
        );
        setTargetList(updatedList);

    };


    const handleChangeStarCheckBox = (id) => (event) => {

        const targetList = getCurrentList();
        const setTargetList = getCurrentListSetter();

        const updatedList = targetList.map(item =>
            item.id === id ? { ...item, favorite: event.target.checked } : item
        );
        setSendToServerData(updatedList)
        setTargetList(updatedList);
        setFavoriteItems(updatedList)
        toast(<CustomToast message={'فرم مورد نظر به علاقمندی ها اضافه شد'} state={'success'} />);

    };


    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredListData = list.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );



    console.log(filteredListData)



    // Check if any item is checked
    console.log(getCurrentList())
    const isAnyItemChecked = getCurrentList()?.some(item => item.checked);


    const handleRemoveItem = () => {

        const targetList = getCurrentList();
        const setTargetList = getCurrentListSetter();

        const newListData = targetList.filter(item => !item.checked);
        const removed = targetList
            .filter(item => item.checked)
            .map(item => ({ ...item, checked: false }));

        if (selectDrawerItem === 'Trash') {
            // Permanently delete items from the trash
            setRemovedItems(newListData);
            setSendToServerData(sendToServerData.filter(item => !removed.some(removedItem => removedItem.id === item.id)));
            toast(<CustomToast message={'فرم مورد نظر از سطل زباله حذف شد'} state={'success'} />);

        } else if (selectDrawerItem === 'Archive') {
            // Permanently delete items from the archive
            setArchiveItems(newListData);
            setSendToServerData(sendToServerData.filter(item => !removed.some(removedItem => removedItem.id === item.id)));
            toast(<CustomToast message={'فرم مورد نظر از آرشیو ها حذف شد'} state={'success'} />);

        } else {
            // Move items to trash
            setRemovedItems([...removedItems, ...removed]);
            setTargetList(newListData);
            setSendToServerData(sendToServerData.filter(item => !removed.some(removedItem => removedItem.id === item.id)));
            toast(<CustomToast message={'فرم مورد نظر با موفقیت حذف شد'} state={'success'} />);

        }

    };



    const handleArchiveItem = () => {

        const targetList = getCurrentList();
        const setTargetList = getCurrentListSetter();

        const newListData = targetList.filter(item => !item.checked);
        const archive = targetList
            .filter(item => item.checked)
            .map(item => ({ ...item, checked: false }));

        setArchiveItems([...archiveItems, ...archive]);
        setTargetList(newListData);
        setSendToServerData(sendToServerData.filter(item => !archive.some(archiveItem => archiveItem.id === item.id)));
        toast(<CustomToast message={'فرم مورد نظر با موفقیت آرشیو شد'} state={'success'} />);

    };

    const handleRestoreItem = () => {

        const targetList = getCurrentList();
        const setTargetList = getCurrentListSetter();

        const newListData = targetList.filter(item => !item.checked);
        const restored = targetList
            .filter(item => item.checked)
            .map(item => ({ ...item, checked: false }));

        let draft = restored.filter(item => item.draft === true)
        let main = restored.filter(item => item.draft === false)

        // setListData([...listData, ...restored]);
        setListData([...listData, ...main]);
        setSendToServerData([...sendToServerData, ...restored]);
        setDraftData([...draftData, ...draft]);
        setTargetList(newListData);

        toast(<CustomToast message={'فرم مورد نظر بازگردانده شد'} state={'success'} />);

    };


    const handleUnArchiveItem = () => {

        const targetList = getCurrentList();
        const setTargetList = getCurrentListSetter();

        const newListData = targetList.filter(item => !item.checked);

        const unArchive = targetList
            .filter(item => item.checked)
            .map(item => ({ ...item, checked: false }));

        let drafts = unArchive.filter(item => item.draft === true)
        let main = unArchive.filter(item => item.draft === false)

        // setListData([...listData, ...unArchive]);
        setListData([...listData, ...main]);
        setDraftData([...draftData, ...drafts]);
        setSendToServerData([...sendToServerData, ...unArchive]);
        setTargetList(newListData);

        toast(<CustomToast message={'فرم مورد نظر از آرشیو ها خارج شد'} state={'success'} />);

    }


    const handleEdit = (id) => {

        let index = list.findIndex(item => item.id === id)
        setDrawerData(list[index].state)
        setSelectedItem([list[index]]);
        setSelectedItemId(list[index].id)
        setEditMode(true)
        navigate('/build')
        setTitle(list[index].title)

    }

    const processDuplicates = (data) => {

        const latestItems = new Map();
        const firstOccurrenceMap = new Map();

        // Traverse the array to find the last occurrence of each id
        data.forEach((item, index) => {
            if (!latestItems.has(item.id)) {
                // Record the index of the first occurrence
                firstOccurrenceMap.set(item.id, index);
            }
            latestItems.set(item.id, item);
        });

        // Create a new list with updated items
        const updatedData = data.reduce((acc, item, index) => {
            if (firstOccurrenceMap.get(item.id) === index) {
                // Keep the first occurrence with the latest data
                acc.push(latestItems.get(item.id));
            }
            return acc;
        }, []);

        return updatedData;

    };

    const createUnifiedDataObject = () => {

        return {
            allForm: sendToServerData,
            archivedForms: archiveItems,
            removedForms: removedItems,
            draftedForms: draftData,
            favorites: favoriteItems
        };

    };

    const handleSaveToServer = async () => {

        const unifiedDataObject = createUnifiedDataObject();

        console.log(unifiedDataObject)

        try {
            const response = await axios.post('', unifiedDataObject);
            console.log('Data saved:', response.data);
            toast(<CustomToast message={'داده‌ها با موفقیت ذخیره شدند'} state={'success'} />);
        } catch (error) {
            console.error('Error saving data:', error);
            toast(<CustomToast message={'خطا در ذخیره داده‌ها'} state={'error'} />);
        }

    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://your-api-endpoint.com/data');
                const fetchedData = response.data;

                // Process the fetched data and save to unifiedData
                const unifiedDataObject = createUnifiedDataObject(fetchedData);

                console.log('Data fetched and saved:', unifiedDataObject);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);



    useEffect(() => {

        const processedData = processDuplicates(sendToServerData);
        const processedDraftData = processDuplicates(draftData);
        const processedFavoriteData = processDuplicates(favoriteItems);
        setFavoriteItems(processedFavoriteData)
        setDraftData(processedDraftData)
        setSendToServerData(processedData); // Ensure sendToServerData is always unique
        setList(processedData)
        setListData(processedData);

    }, []);


    useEffect(() => {

        if (selectDrawerItem === 'AllForm') {
            setList(listData)
        } else if (selectDrawerItem === 'Favorite') {
            let favoriteList = listData?.filter(item => item.favorite === true)
            setList(favoriteList)
            setFavoriteItems(favoriteList)
        } else if (selectDrawerItem === 'Trash') {
            setList(removedItems)
        } else if (selectDrawerItem === 'Archive') {
            setList(archiveItems)
        } else if (selectDrawerItem === 'Drafts') {
            setList(draftData)
        }

    }, [listData, removedItems, archiveItems, selectDrawerItem, draftData, favoriteItems])


    useEffect(() => {

        let sortedList = [];

        const applySorting = (data) => {
            switch (heading) {
                case 'عنوان [ الف تا ی ]':
                    return data.sort((a, b) => a.title.localeCompare(b.title));
                case 'عنوان [ ی تا الف ]':
                    return data.sort((a, b) => b.title.localeCompare(a.title));
                case 'قدیمی ترین ارسال':
                    return data; // Original order
                case 'جدیدترین ارسال':
                    return data.reverse(); // Reverse order
                default:
                    return data;
            }
        };

        if (selectDrawerItem === 'AllForm') {
            sortedList = applySorting([...listData]);
        } else if (selectDrawerItem === 'Favorite') {
            sortedList = applySorting([...listData.filter(item => item.favorite === true)]);
        } else if (selectDrawerItem === 'Trash') {
            sortedList = applySorting([...removedItems]);
        } else if (selectDrawerItem === 'Archive') {
            sortedList = applySorting([...archiveItems]);
        } else if (selectDrawerItem === 'Drafts') {
            sortedList = applySorting([...draftData]);
        }

        setList(sortedList);

    }, [listData, removedItems, archiveItems, draftData, selectDrawerItem, heading]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const unifiedDataObject = createUnifiedDataObject();
        setUnifiedData(unifiedDataObject);
        console.log('Unified data saved:', unifiedDataObject);
    }, [favoriteItems, removedItems, archiveItems, sendToServerData]);


    useEffect(() => {
        let favID = []
        unifiedData.favorites.map(item => favID.push(item.id))
        for (let i = 0; i < favID.length; i++) {
            unifiedData.allForm.map(
                item => item.id === favID[i] ? item.favorite = true : false
            )
        }
    }, []);


    useEffect(() => {
        setRemovedItems(removedItems);
    }, [removedItems, setRemovedItems]);

    useEffect(() => {
        setArchiveItems(archiveItems);
    }, [archiveItems, setArchiveItems]);


    useEffect(() => {
        console.log(favoriteItems)
        setFavoriteItems(favoriteItems);
    }, [favoriteItems, setFavoriteItems]);


    // useEffect(() => {
    //     setDraftData(draftData);
    // }, [draftData, setDraftData]);

    useEffect(() => {
        return () => {
            setSendToServerData(sendToServerData)
        };
    }, []);


    return (
        <Grid width={{ xs: '100%' }} display={'block'}>
            <Grid width={{ xs: '100%' }} p={{ xs: '14px 10px', md: '9px 13px' }} display={'flex'} alignItems={'center'} justifyContent={{ xs: 'space-between', md: 'flex-end' }} bgcolor={'white'}>
                <Box display={{ xs: 'block', md: 'none' }}>
                    <Grid onClick={toggleDrawer} display={'flex'} alignItems={'center'} p={'0px 10px'} border={'1px solid rgba(200,206,237,1)'} width={'40px'} height={'40px'} borderRadius={'4px'}>
                        <img src='/assets/images/threeDots.svg' alt='' style={{ width: '100%', height: '100%' }} />
                    </Grid>
                </Box>
                <Drawer
                    open={isOpen}
                    onClose={toggleDrawer}
                    direction='right'
                    className='bla bla bla'
                >
                    <Box width={{ xs: '100%' }} maxWidth={{ xs: '250px', xxs: '300px' }} height={'100vh'} sx={{ bgcolor: '#f3f3fe' }} p={'4px 8px'} borderLeft={'1px solid #c8ceed'}>
                        <Grid width={'100%'} borderBottom={'1px solid #c8ceed'} pb={'18px'} mt={{ xs: '36px', md: '0px' }}>
                            <Typography variant='h5' color={'#6c73a8'} p={'11px 14px'} fontWeight={700}>فرم های من</Typography>
                            <Button sx={{ bgcolor: 'transparent', '&:hover': '#6c73a8', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', p: '11px 14px', width: '100%' }}>
                                <FolderIcon style={{ color: '#1976d2' }} fontSize='large' />
                                <Typography variant='h5' color={'#0a1551'}>همه فرم ها</Typography>
                            </Button>
                        </Grid>
                        <Grid p={'18px 0px'} borderBottom={'1px solid #c8ceed'}>
                            <Button sx={{ bgcolor: 'transparent', '&:hover': '#6c73a8', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', p: '11px 14px', width: '100%' }}>
                                <DraftsIcon fontSize='large' />
                                <Typography variant='h5' color={'#0a1551'}>پیش نویس های من</Typography>
                            </Button>
                        </Grid>
                        <Grid p={'18px 0px'}>
                            {
                                items.map(
                                    item =>
                                        <Button key={item.id} sx={{ bgcolor: 'transparent', '&:hover': '#6c73a8', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', p: '11px 14px', width: '100%' }}>
                                            {item.icon}
                                            <Typography variant='h5' color={'#0a1551'}>{item.title}</Typography>
                                        </Button>
                                )
                            }
                        </Grid>
                    </Box>
                </Drawer>
                {
                    isAnyItemChecked ?
                        (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <Grid display={selectDrawerItem === 'Trash' || selectDrawerItem === 'Archive' ? 'none' : 'block'}>
                                    <Tooltip title={<Typography variant='subtitle1'>حذف</Typography>} arrow>
                                        <Button variant='outlined' color='error' style={{ width: '40px', height: '40px' }} onClick={handleRemoveItem}>
                                            <DeleteIcon style={{ color: '#B80000', width: '20px', height: '20px' }} />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid display={selectDrawerItem === 'Trash' || selectDrawerItem === 'Archive' ? 'none' : 'block'}>
                                    <Tooltip title={<Typography variant='subtitle1'>بایگانی</Typography>} arrow>
                                        <Button variant='outlined' color='primary' style={{ width: '40px', height: '40px' }} onClick={handleArchiveItem}>
                                            <ArchiveIcon style={{ color: '#478CCF', width: '20px', height: '20px' }} />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid display={selectDrawerItem === 'Trash' ? 'block' : 'none'}>
                                    <Tooltip title={<Typography variant='subtitle1'>بازیابی</Typography>} arrow>
                                        <Button variant='outlined' color='warning' style={{ width: '40px', height: '40px' }} onClick={handleRestoreItem}>
                                            <RestoreIcon style={{ color: '#FF9100', width: '20px', height: '20px' }} />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid display={selectDrawerItem === 'Archive' ? 'block' : 'none'}>
                                    <Tooltip title={<Typography variant='subtitle1'>خروج از آرشیو</Typography>} arrow>
                                        <Button variant='outlined' color='secondary' style={{ width: '40px', height: '40px' }} onClick={handleUnArchiveItem}>
                                            <UnarchiveIcon style={{ color: '#7469B6', width: '20px', height: '20px' }} />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid className='DeleteRemove' display={selectDrawerItem === 'Trash' || selectDrawerItem === 'Archive' ? 'block' : 'none'}>
                                    <Tooltip title={<Typography variant='subtitle1'>حذف</Typography>} arrow>
                                        <Button variant='contained' color='error' style={{ width: '40px', height: '40px' }} onClick={handleRemoveItem}>
                                            <DeleteIcon style={{ color: 'white', width: '20px', height: '20px' }} />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Box>
                        )
                        :
                        (

                            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                <Grid sx={{ position: 'relative' }}>
                                    <input
                                        style={{
                                            height: '40px',
                                            borderRadius: '4px',
                                            color: 'rgba(10,21,81,1)',
                                            border: '1px solid rgba(200,206,237,1)',
                                            backgroundColor: 'rgba(255,255,255,1)',
                                            outline: 'rgba(0,0,0,1)',
                                            padding: '0px 32px'
                                        }}
                                        placeholder='جستجو'
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                    <Grid width={'20px'} height={'20px'} sx={{ position: 'absolute', top: '10px', right: '10px' }}>
                                        <img src='/assets/images/magnifyingGlass.svg' alt='' style={{ width: '100%', height: '100%' }} />
                                    </Grid>
                                </Grid>
                                <Grid style={{ position: 'relative', cursor: 'pointer' }} ref={dropdownRef}>
                                    <Grid p={'0px 10px'} border={active ? '1px solid rgb(44, 51, 69)' : '1px solid rgba(200,206,237,1)'} width={'40px'} height={'40px'} borderRadius={'4px 4px 4px 0px'} onClick={() => { setActive(!active) }} bgcolor={active ? 'rgb(44, 51, 69)' : 'white'}>
                                        <img src='/assets/images/sort.svg' alt='' style={{ width: '100%', height: '100%' }} />
                                    </Grid>
                                    {transitions(
                                        (styles, item) =>
                                            item && (
                                                <animated.div
                                                    style={{
                                                        ...styles,
                                                        position: 'absolute',
                                                        top: '38px',
                                                        left: '0px',
                                                        zIndex: 100,
                                                        backgroundColor: 'rgb(44, 51, 69)',
                                                        borderRadius: '0px 4px 4px 4px',
                                                        padding: '8px',
                                                        width: '244px',
                                                    }}
                                                >
                                                    {sortItems.map((item) => (
                                                        <Button
                                                            onClick={() => setHeading(item.title)}
                                                            key={item.id}
                                                            sx={{
                                                                backgroundColor: 'transparent',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                color: 'white',
                                                                width: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-start',
                                                                '&:hover': { backgroundColor: 'white', color: 'rgb(44, 51, 69)' },
                                                            }}
                                                        >
                                                            {item.title}
                                                        </Button>
                                                    ))}
                                                </animated.div>
                                            )
                                    )}
                                </Grid>
                            </Box>
                        )
                }

            </Grid>
            {
                filteredListData?.map(
                    (item, index) =>
                        <Grid key={index} width={'100%'} px={'14px'}>
                            <Button sx={{ p: '14px 23px', m: '18px 0px', bgcolor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', '&:hover': { bgcolor: 'white' } }}>
                                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '24px' }}>
                                    <input
                                        className='check'
                                        type='checkbox'
                                        style={{ width: checked ? '18px' : '18px', height: checked ? '18px' : '18px', margin: '0px', display: selectDrawerItem === 'Favorite' ? 'none' : 'block' }}
                                        checked={item.checked || false}
                                        onChange={handleChangeCheckBox(item.id)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />

                                    <Grid width={'36px'} height={'36px'} display={selectDrawerItem === 'AllForm' ? 'block' : 'none'}>
                                        <Checkbox
                                            style={{ width: '36px', height: '36px' }}
                                            {...label}
                                            icon={<StarOutlineIcon fontSize='large' />} checkedIcon={<StarIcon style={{ color: '#ffb629' }} fontSize='large' />}
                                            checked={item.favorite}
                                            onChange={handleChangeStarCheckBox(item.id)}
                                        />
                                    </Grid>
                                    <Grid width={'32px'} height={'32px'}>
                                        <img src='/assets/images/checklist.png' alt='' style={{ width: '100%', height: '100%' }} />
                                    </Grid>
                                    <Grid pt={'4px'}>
                                        <Typography variant='h4' color={'rgb(10, 21, 81)'} fontWeight={700}>{item.title}</Typography>
                                    </Grid>
                                </Grid>
                                <Button sx={{ display: selectDrawerItem === 'AllForm' || selectDrawerItem === 'Drafts' ? 'flex' : 'none', alignItems: "center", justifyContent: 'center', '&:hover': { variant: 'contained', bgcolor: '#E2DAD6' } }} onClick={() => { handleEdit(item.id) }}>
                                    <Grid width={'24px'} height={'24px'}>
                                        <Tooltip title={<Typography variant='subtitle1'>ویرایش</Typography>} arrow>
                                            <EditIcon fontSize='large' />
                                        </Tooltip>
                                    </Grid>
                                </Button>
                            </Button>
                        </Grid>
                )
            }
            <Grid display={filteredListData.length > 0 && selectDrawerItem === 'AllForm' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'center'} mb={'36px'} mt={'200px'} width={'100%'}>
                <Button variant='contained' color='success' style={{ width: '30%', padding: '15px 0px' }} onClick={handleSaveToServer}>
                    <Typography variant='h4' fontWeight={700} pt={'5px'}>ذخیره</Typography>
                </Button>
            </Grid>

        </Grid>
    )
}

export default MainNavbar