import React, { createContext, useState } from 'react';

export const ThankYouConditionEditorContext = createContext();

export const ThankYouConditionEditorProvider = ({ children }) => {
    const [finalPayload, setFinalPayload] = useState(null);

    return (
        <ThankYouConditionEditorContext.Provider value={{ finalPayload, setFinalPayload }}>
            {children}
        </ThankYouConditionEditorContext.Provider>
    );
};