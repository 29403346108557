import React from "react";
import { Grid } from "@mui/material";
import Drawer from "../Component/Drawer";
import FormItem from "../Component/FormItem";
import { FormProvider } from "../Context/Context";

const Home = () => {

    return (
        <FormProvider>
            <Grid mt={'170px'} className="HomeContainer">
                <Drawer />
                <FormItem active={1} />
            </Grid>
        </FormProvider>

    )
}

export default Home