import { Grid } from '@mui/material'
import React from 'react'

const SmallIcon = ({dir , rowFlag , src}) => {
    return (
        <Grid style={{ display: 'flex', flexDirection: dir, alignItems: 'center', justifyContent: 'center', marginTop: '30px' , backgroundColor:'white' , height:'100%' }}>
            <Grid style={{ width: rowFlag ? '30%':'100%', height:rowFlag?'400px':'130px', backgroundColor: '#e8f6ed', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={src} alt='' style={{ width: '100px', height: '100px' }} />
            </Grid>
            <Grid style={{ width: rowFlag ? '70%':'100%', height:'100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' , padding:rowFlag?'135px 0px':'100px 0px' }}>
                <div style={{ fontSize: '36px', color: '#2C3345', fontWeight: 600, marginBottom: '16px' }}>متشکریم</div>
                <div style={{ fontSize: '14px', color: '#2C3345', fontWeight: 500, opacity: 0.6 }}>ارسالی شما دریافت شد</div>
            </Grid>
        </Grid>
    )
}

export default SmallIcon